import memoize from 'memoize-one';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { BorrowerProfileAttributeSelection } from '@borrowmydoggy/profile-components';
import { IBorrowerSearchResult } from '@borrowmydoggy/search-components';

import { ProfileAPI } from '../api';

import { BorrowerProfile } from './BorrowerProfile';
import { IBorrowerLikeResult } from './IBorrowerLikeResult';
import { IProfileFeedback } from './IProfileFeedback';
import { ReportType } from './ReportType';

export interface ISearchContextBorrowerProfileProps {
  searchResults: IBorrowerSearchResult[];
  totalResults: number;
  searchId: string;
  currentUserPremiumOwner: boolean;
  premiumPath: string;
  onBackLinkClick?: (lastProfileId: string) => void;
  onNavigateToProfile?: (profileId: string) => void;
  onNextProfileRequired?: () => void;
  onProfileLiked?: (profileId: string, searchResultIndex: number) => Promise<IBorrowerLikeResult>;
  onProfileUnliked?: (profileId: string, searchResultIndex: number) => Promise<IBorrowerLikeResult>;
}

export const SearchContextBorrowerProfile: FunctionComponent<ISearchContextBorrowerProfileProps> = (props: ISearchContextBorrowerProfileProps) => {
  // Here, searchId is used to bust the memoized cache if the search results change.
  const memoizedSearchResultIndex = memoize((profileId: string, searchResults: IBorrowerSearchResult[], searchId: string) =>
    searchResults.findIndex(sr => sr.profileId === profileId)
  );

  const profileIdParam = useParams()['profileId'];

  const [focusedImageIndex, setFocusedImageIndex] = useState(0);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [profileCreatedAtUtc, setProfileCreatedAtUtc] = useState(new Date());
  const [company, setCompany] = useState(false);
  const [exercise, setExercise] = useState(false);
  const [acceptingMessages, setAcceptingMessages] = useState(true);
  const [conversationInProgress, setConversationInProgress] = useState(false);
  const [conversationPath, setConversationPath] = useState('');
  const [profileFeedback, setProfileFeedback] = useState<IProfileFeedback[]>([]);
  const [attributes, setAttributes] = useState<BorrowerProfileAttributeSelection>({ ownedDog: false, certified: false, garden: false, childrenAtHome: false });
  const [picsRequested, setPicsRequested] = useState(false);
  const [reportProfileOpen, setReportProfileOpen] = useState(false);

  if (profileIdParam) {
    useEffect(() => {
      loadProfile(profileIdParam);
    }, [profileIdParam]);

    const searchResultIndex = memoizedSearchResultIndex(profileIdParam, props.searchResults, props.searchId);
    const searchResult = props.searchResults[searchResultIndex];
    let previousResultPath: string | undefined;
    if (searchResultIndex > 0) {
      previousResultPath = `/borrower_profile/${props.searchResults[searchResultIndex - 1].profileId}`;
    }
    let nextResultPath: string | undefined;
    // NB: We can't retrieve the ID of a profile which hasn't yet been returned from a search.
    if (searchResultIndex < props.searchResults.length - 1) {
      nextResultPath = `/borrower_profile/${props.searchResults[searchResultIndex + 1].profileId}`;
    }

    function handleBackLinkClick(profileId: string): void {
      if (props.onBackLinkClick) {
        props.onBackLinkClick(profileId);
      }
    }

    function handlePreviousResultClick(): void {
      if (searchResultIndex > 0 && props.onNavigateToProfile) {
        const previousSearchResultIndex = searchResultIndex - 1;
        const previousProfileId = props.searchResults[previousSearchResultIndex].profileId;
        props.onNavigateToProfile(previousProfileId);
      }
    }

    function handleNextResultClick(): void {
      if (props.onNavigateToProfile) {
        if (searchResultIndex < props.searchResults.length - 1) {
          const nextSearchResultIndex = searchResultIndex + 1;
          const nextProfileId = props.searchResults[nextSearchResultIndex].profileId;
          props.onNavigateToProfile(nextProfileId);
        } else if (props.onNextProfileRequired) {
          props.onNextProfileRequired();
        }
      }
    }

    async function handleProfileLiked(profileId: string): Promise<void> {
      if (props.onProfileLiked) {
        const likeResult = await props.onProfileLiked(profileId, searchResultIndex);
        if (likeResult.successful) {
          setLiked(true);
          setLikeCount(likeResult.borrowerLikeCount);
        }
      }
    }

    async function handleProfileUnliked(profileId: string): Promise<void> {
      if (props.onProfileUnliked) {
        const unlikeResult = await props.onProfileUnliked(profileId, searchResultIndex);
        if (unlikeResult.successful) {
          setLiked(false);
          setLikeCount(unlikeResult.borrowerLikeCount);
        }
      }
    }

    async function handlePhotoRequested(profileId: string): Promise<void> {
      const result = await requestPics(profileId);
      setPicsRequested(result);
    }

    function handlePhotoSelected(index: number): void {
      setFocusedImageIndex(index);
    }

    function handleReportProfileOpen(): void {
      setReportProfileOpen(true);
    }

    function handleReportProfileClose(): void {
      setReportProfileOpen(false);
    }

    function handleProfileReported(profileId: string, reportType: ReportType, explanation?: string): void {
      reportProfile(profileId, reportType, explanation);
    }

    async function loadProfile(profileId: string): Promise<void> {
      setReportProfileOpen(false); // Do this before issuing the API call.
      const response = await ProfileAPI.partialBorrowerProfile(profileId);
      if (response.error === undefined && response.data && response.data.borrowerProfile) {
        setFocusedImageIndex(0);
        setLiked(response.data.borrowerProfile.liked);
        setLikeCount(response.data.borrowerProfile.likeCount);
        setProfileCreatedAtUtc(new Date(response.data.borrowerProfile.profileCreatedAtUtc));
        setCompany(response.data.borrowerProfile.company);
        setExercise(response.data.borrowerProfile.exercise);
        setAcceptingMessages(response.data.borrowerProfile.acceptingMessages);
        setConversationInProgress(response.data.borrowerProfile.conversationInProgress);
        setPicsRequested(response.data.borrowerProfile.picsRequested);
        setConversationPath(response.data.borrowerProfile.conversationPath);
        setProfileFeedback((response.data.borrowerProfile.profileFeedback || []).map(pf => ({ ...pf, portraitImageUrl: pf.portraitImageUrl || undefined })));
        setAttributes(response.data.borrowerProfile.borrowerAttributes);
        window.scrollTo(0, 0);
      } else {
        console.warn('API failure when attempting to load profile.', response.error);
      }
    }

    async function requestPics(profileId: string): Promise<boolean> {
      const response = await ProfileAPI.createBorrowerPictureRequest(profileId);
      if (response.error === undefined && response.data && response.data.createBorrowerPictureRequest) {
        return response.data.createBorrowerPictureRequest.pictureRequested;
      } else {
        console.warn('API failure when attempting to request pictures.', response.error);
        return false;
      }
    }

    async function reportProfile(profileId: string, reportType: ReportType, explanation?: string): Promise<boolean> {
      const response = await ProfileAPI.createBorrowerProfileReport(profileId, reportType, explanation);
      if (response.error === undefined && response.data && response.data.createBorrowerProfileReport) {
        return response.data.createBorrowerProfileReport.profileReported;
      } else {
        console.warn('API failure when attempting to report profile.', response.error);
        return false;
      }
    }

    return (
      <BorrowerProfile
        profileId={profileIdParam}
        name={searchResult.name}
        direction={searchResult.direction}
        distanceInMiles={searchResult.distanceInMiles}
        imageUrls={searchResult.imageUrls}
        focusedImageIndex={focusedImageIndex}
        liked={liked}
        likeCount={likeCount}
        premium={searchResult.premium}
        ambassador={false}
        description={searchResult.description}
        profileCreatedAtUtc={profileCreatedAtUtc}
        activityLevel={searchResult.activityLevel}
        company={company}
        exercise={exercise}
        acceptingMessages={acceptingMessages}
        conversationInProgress={conversationInProgress}
        conversationPath={conversationPath}
        currentUserPremiumOwner={props.currentUserPremiumOwner}
        availability={searchResult.availability}
        profileFeedback={profileFeedback}
        attributes={attributes}
        picsRequested={picsRequested}
        showNavigationLinks
        previousLinkEnabled={searchResultIndex > 0}
        nextLinkEnabled={searchResultIndex < props.totalResults - 1}
        previousProfilePath={previousResultPath}
        nextProfilePath={nextResultPath}
        premiumPath={props.premiumPath}
        reportProfileOpen={reportProfileOpen}
        onProfileLiked={handleProfileLiked.bind({}, profileIdParam)}
        onProfileUnliked={handleProfileUnliked.bind({}, profileIdParam)}
        onBackLinkClick={handleBackLinkClick.bind({}, profileIdParam)}
        onNextLinkClick={handleNextResultClick}
        onPreviousLinkClick={handlePreviousResultClick}
        onPhotoRequested={handlePhotoRequested.bind({}, profileIdParam)}
        onPhotoSelected={handlePhotoSelected}
        onReportProfileOpen={handleReportProfileOpen}
        onReportProfileClose={handleReportProfileClose}
        onProfileReported={handleProfileReported.bind({}, profileIdParam)}
        />
    );
  }

  return <Navigate to='/search/dogs' replace />;
};
