import React, { FunctionComponent } from 'react';

import { GoPremiumOwnerMessageBanner } from '@borrowmydoggy/product-components';

export interface IGoPremiumOwnerBannerProps {
  subscribeUrl: string;
  subscriptionUpgrade: boolean;
  dogName: string;
  multipleDogs: boolean;
}

export const GoPremiumOwnerBanner: FunctionComponent<IGoPremiumOwnerBannerProps> = (props: IGoPremiumOwnerBannerProps) => {
  return (
    <GoPremiumOwnerMessageBanner
      subscribeUrl={props.subscribeUrl}
      subscriptionUpgrade={props.subscriptionUpgrade}
      dogName={props.dogName}
      multipleDogs={props.multipleDogs}
    />
  );
};
