import React, { FunctionComponent } from 'react';
import { Link } from './Link';
import { Image, cloudfrontUrl } from './Image';
import { Icon, IconTextButton } from '@borrowmydoggy/core-components';

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

export interface IHeroProps {
	heading: string;
	textBlocks: string[];
	buttonTextBorrower: string;
	buttonTextOwner: string;
	heroImageDog: 'digby' | 'herodog2';
	children?: React.ReactNode;
}

const heroImage = (hero: string): string => {
	switch (hero) {
		case 'digby':
			return 'images/content/hero/digby.png';
		case 'herodog2':
			return 'images/content/hero/hero-dog-2.png';
		case 'new_hero':
			return 'images/content/hero/hero.png';
		case 'experiment_173':
			return 'images/content/hero/man_walking.jpg';
		default:
			return 'images/content/hero/digby.png';
	}
};

export const Hero: FunctionComponent<IHeroProps> = ({ textBlocks, buttonTextBorrower, buttonTextOwner, heroImageDog }: IHeroProps) => {
	return (
		<div
			className='flex flex-col items-start justify-start bg-no-repeat bg-[top_-310px_right_-80px] md:bg-center md:bg-cover'
			style={{
				backgroundImage: `url(${cloudfrontUrl}/${heroImage(heroImageDog)})`
			}}
		>
			<div className='bg-white px-8 py-10 flex flex-col w-full gap-5 box-border md:box-content items-center justify-center mt-[270px] md:m-20 md:w-[350px] lg:w-[500px]'>
				<h1> {/* h1 wrapper means alt text should be used for SEO purposes. */}
					<Image path='images/content/hero/share_the_love_white_bg.svg' alt='Share the love of dogs' className='max-w-[300px] md:w-[300px] lg:w-[470px]' />
				</h1>

				<div className='flex flex-col items-center justify-center text-center gap-5 max-w-[500px] md:w-[250px] lg:w-[500px]'>
					{textBlocks.map((subHeading: string, index: number) => (
						<span key={index} className='text-slate-600'>
							{subHeading}
						</span>
					))}
				</div>

				<div className='grid grid-flow-rows grid-cols-2 gap-5 lg:gap-10 md:mt-5'>
					<Link href='https://www.borrowmydoggy.com/signup?pt=borrower' looksLike='button' className='py-5 px-8'>
						{buttonTextBorrower}
						<Icon definition={faChevronRight} className='text-yellow-300 pl-2' />
					</Link>

					<Link href='https://www.borrowmydoggy.com/signup?pt=owner' looksLike='button' className='py-5 px-8'>
						{buttonTextOwner}
						<Icon definition={faChevronRight} className='text-yellow-300 pl-2' />
					</Link>
				</div>
			</div>
			<div className='bg-white px-8 flex flex-col w-full box-border items-center justify-center md:hidden'>
				<hr className='w-full border-solid border-0 border-t border-slate-600' />
			</div>
		</div>
	);
};

export default Hero;
