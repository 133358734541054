interface IParsedDogName {
  multipleDogs: boolean;
  quantityText: string;
  compositeName: string;
  firstDogName: string;
  compositeNameExcludingFirstDog: string;
  subject: string;
}

const numberWords = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'many'] as const;

export function parseDogNames(dogNames: string[]): IParsedDogName {
  if (dogNames.length === 0) {
    // Fallback.
    return {
      multipleDogs: false,
      quantityText: 'one dog',
      compositeName: 'your dog',
      firstDogName: 'your dog',
      compositeNameExcludingFirstDog: '',
      subject: 'your dog'
    };
  }
  const multipleDogs = dogNames.length > 1;
  return {
    multipleDogs,
    quantityText: multipleDogs ? `${numberWords[Math.min(dogNames.length, 11)]} dogs` : 'one dog',
    compositeName: multipleDogs ? `${dogNames.slice(0, -1).join(', ')} and ${dogNames.at(-1)}` : dogNames[0],
    firstDogName: dogNames[0],
    compositeNameExcludingFirstDog: multipleDogs ? (dogNames.length > 2 ? `${dogNames.slice(1, -1).join(', ')} and ${dogNames.at(-1)}` : dogNames[1]) : '',
    subject: multipleDogs ? 'your dogs' : dogNames[0]
  };
}
