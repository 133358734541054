import { TileQuote } from '@borrowmydoggy/core-components';
import React, { useEffect } from 'react';

export interface Testimonial {
	quote: string;
	attribution: string;
	image_url?: string;
}

export interface TestimonialsProps {
	testimonials: Testimonial[];
}

// exp160-todo: if the new variant wins, move items to the backend
export const Testimonials = ({ testimonials }: TestimonialsProps) => {
	return (
		<div className='expander max-w-[960] sm:gap-10'>
			{testimonials.slice(0, 3).map((item, index) => (
				<div key={index} className='flex flex-col max-w-[320px]'>
					<img src={item.image_url} />
					<TileQuote quote={item.quote} attribution={item.attribution} />
				</div>
			))}
		</div>
	);
};

export interface CarouselProps {
	children: React.ReactNode;
}

export const Carousel = ({ children }: CarouselProps) => {
	let [current, setCurrent] = React.useState(0);

	let previousSlide = () => {
		if (!children) {
			return;
		}

		let items = children as React.ReactElement[];
		let lastIndex = items.length - 1;
		let shouldResetIndex = current === 0;
		let index = shouldResetIndex ? lastIndex : current - 1;
		setCurrent(index);
	};

	let rotations = 0;

	let nextSlide = () => {
		if (!children) {
			return;
		}

		let items = children as React.ReactElement[];
		let lastIndex = items.length - 1;
		let shouldResetIndex = current === lastIndex;
		let index = shouldResetIndex ? 0 : current + 1;
		rotations++;
		setCurrent(index);
	};

	useEffect(() => {
		setTimeout(() => {
			nextSlide();
		}, 5000);
	}, [current]);

	return (
		<div className='overflow-hidden'>
			<div className={`flex relative transition-transform ease-out duration-400`} style={{ transform: `translateX(-${current * 100}%)` }}>
				{children}
			</div>
		</div>
	);
};
