import React from 'react';

import { BorrowingInfoUpdateEvent } from './BorrowingInfoUpdateEvent';
import { IBorrowingInfoUpdateActivityStreamEvent } from './IBorrowingInfoUpdateActivityStreamEvent';
import { IActivityStreamEventComponent, IActivityStreamEventComponentProps } from './IActivityStreamEventComponent';

export const BorrowingInfoUpdateBorrowerActivityStreamEvent: IActivityStreamEventComponent<IBorrowingInfoUpdateActivityStreamEvent> = (
  props: IActivityStreamEventComponentProps<IBorrowingInfoUpdateActivityStreamEvent>) => {
  return (
    <BorrowingInfoUpdateEvent
      eventData={props.eventData}
      missingBorrowerImageURL={props.missingBorrowerImageURL}
      missingOwnerImageURL={props.missingOwnerImageURL}
    >
      <div className='activity-stream-event__content-heading-text'>
        <span className='green'>
          <a href={props.eventData.profileUrl} className='link_standard-green'>{props.eventData.dogName}</a>
        </span>
        {' '}
        <span className='gray'>updated</span>
        {' '}
        <span className='blue'>
          <a href={props.eventData.borrowingInfoUrl}>doggy info sheet</a>
        </span>
      </div>
    </BorrowingInfoUpdateEvent>
  );
};
