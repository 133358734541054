import { z } from 'zod';
import { Dog, OwnerProfile } from '@borrowmydoggy/core-models';

export const ownerProfileCreationDetails = z.object({
  dogNames: OwnerProfile.dogNames,
  dogGender: Dog.gender,
  dogBreedCode: Dog.breed,
  dogDateOfBirth: Dog.dateOfBirth,
  goodWithChildren: Dog.goodWithChildren,
  tolerantOfCats: Dog.tolerantOfCats,
  okayWithOtherDogs: Dog.okayWithOtherDogs,
  hypoallergenicBreed: Dog.hypoallergenicBreed,
  neutered: Dog.neutered,
  rescueDog: Dog.rescueDog,
  dogNeeds: Dog.needs,
  availability: OwnerProfile.availability,
  description: Dog.description,
  uploadedPhotoKey: z.string().optional(),
  photoMimeType: z.string().optional(),
  photoTaskId: z.string().optional(),
  previewImageDataUrl: z.string().optional(),
  permissionToUse: OwnerProfile.permissionToUse
});

export type OwnerProfileCreationDetails = z.infer<typeof ownerProfileCreationDetails>;
