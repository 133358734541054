import React, { FunctionComponent, MouseEvent } from 'react';
import styled from 'styled-components';

import { InlineTextButton, XSmallText } from '@borrowmydoggy/core-components';

export interface IHelpMessageProps {
  className?: string;
}

const HelpContainer = styled.div`
  text-align: center;
`;

export const HelpMessage: FunctionComponent<IHelpMessageProps> = (props: IHelpMessageProps) => {
  function handleChatClicked(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
    event.preventDefault();
    /* eslint @typescript-eslint/no-explicit-any: 'off' */
    if (typeof (window as any)['zE'] !== 'undefined') {
      (window as any)['zE'](() => {
        (window as any)['zE'].activate();
      });
    }
  }

  return (
    <HelpContainer className={props.className}>
      <XSmallText>
        Need help?{' '}
        <InlineTextButton size='xSmall' onClick={handleChatClicked}>
          Send us a message
        </InlineTextButton>{' '}
        or give us a ring on{' '}
        <InlineTextButton size='xSmall' link={{ href: 'tel:442038268628' }}>
          020 3826 8628
        </InlineTextButton>
      </XSmallText>
    </HelpContainer>
  );
};
