import React, { Component, ReactNode } from 'react';

import styled from 'styled-components';

import { EmailAddressField, Form, TextField } from '../forms';
import { IValidationState, LengthValidator, PresenceValidator } from '@borrowmydoggy/bmd-validators';

export interface IPurchaserDetailsFormProps {
  title: string;
  formattedPrice: string;
  purchaserName: string;
  purchaserEmailAddress: string;
  onPurchaserNameChange?: (value: string) => void;
  onPurchaserEmailAddressChange?: (value: string) => void;
  onPurchaserDetailsComplete?: () => void;
}

interface IPurchaserDetailsFormState {
  errorMessage: string;
  purchaserNameValid: boolean;
  purchaserNameMessage: string;
  purchaserEmailAddressValid: boolean;
}

const StyledTextField = styled(TextField)`
  &.invalid>input {
    border-color: var(--toy-red);
    background-color: var(--toy-red-light);
  }
`;

export class PurchaserDetailsForm extends Component<IPurchaserDetailsFormProps, IPurchaserDetailsFormState> {
  constructor(props: IPurchaserDetailsFormProps) {
    super(props);

    this.state = {
      errorMessage: '',
      purchaserNameValid: true,
      purchaserNameMessage: '',
      purchaserEmailAddressValid: true
    };

    this.handleFormSubmission = this.handleFormSubmission.bind(this);

    this.handlePurchaserNameChange = this.handlePurchaserNameChange.bind(this);
    this.handlePurchaserEmailAddressChange = this.handlePurchaserEmailAddressChange.bind(this);
    this.handlePurchaserNameBlur = this.handlePurchaserNameBlur.bind(this);
    this.handlePurchaserEmailAddressBlur = this.handlePurchaserEmailAddressBlur.bind(this);
  }

  public render(): ReactNode {
    return (
      <Form className='new-form' onSubmit={this.handleFormSubmission}>
        <h1 className='page-title gift'>{this.props.title}</h1>
        <span className='highlight-heading space-above-medium'>just {this.props.formattedPrice}</span>
        <fieldset>
          <legend>Your Details</legend>
          <div className='field'>
            <label htmlFor='purchaserName'>Your name</label>
            <StyledTextField
              value={this.props.purchaserName}
              id='purchaserName'
              name='purchaserName'
              maxLength={30}
              autoComplete='given-name'
              inputPattern='^[^0-9@]*$'
              blurValidators={[
                new PresenceValidator('Please tell us your name'),
                new LengthValidator("We can't accept names that long, is there a shorter version that you use?", { max: 30 })
              ]}
              className={this.state.purchaserNameValid ? '' : 'invalid'}
              onChange={this.handlePurchaserNameChange}
              onBlur={this.handlePurchaserNameBlur}
            />
            <span className='feedback'>{this.state.purchaserNameMessage}</span>
          </div>
          <div className='field'>
            <label htmlFor='purchaserEmailAddress'>Your email</label>
            <EmailAddressField
              value={this.props.purchaserEmailAddress}
              id='purchaserEmailAddress'
              name='purchaserEmailAddress'
              validState={this.state.purchaserEmailAddressValid}
              validateOnBlur
              onChange={this.handlePurchaserEmailAddressChange}
              onBlur={this.handlePurchaserEmailAddressBlur}
            />
          </div>
          {this.renderErrorMessage()}
          <div className='action'>
            <button type='submit' className='new-button primary wide'>Next</button>
          </div>
        </fieldset>
      </Form>
    );
  }

  private handlePurchaserNameChange(value: string): void {
    if (this.props.onPurchaserNameChange) {
      this.props.onPurchaserNameChange(value);
    }
  }

  private handlePurchaserEmailAddressChange(value: string): void {
    if (this.props.onPurchaserEmailAddressChange) {
      this.props.onPurchaserEmailAddressChange(value);
    }
  }

  private handlePurchaserNameBlur(validationState: IValidationState): void {
    this.setState({ purchaserNameValid: validationState.status === 'valid', purchaserNameMessage: validationState.message || '' });
  }

  private handlePurchaserEmailAddressBlur(validationState: IValidationState): void {
    this.setState({ purchaserEmailAddressValid: validationState.status === 'valid' });
  }

  private handleFormSubmission(): void {
    const purchaserNameAcceptable = this.state.purchaserNameValid && this.props.purchaserName.length > 0;
    const purchaserEmailAddressAcceptable = this.state.purchaserEmailAddressValid && this.props.purchaserEmailAddress.length > 0;

    if (purchaserNameAcceptable && purchaserEmailAddressAcceptable && this.props.onPurchaserDetailsComplete) {
      this.props.onPurchaserDetailsComplete();
    }
  }

  private renderErrorMessage(): ReactNode | undefined {
    if (this.state.errorMessage.length > 0) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{this.state.errorMessage}</p>
          </article>
        </div>
      );
    }
  }
}
