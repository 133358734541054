import React, { useContext } from 'react';
import { z } from 'zod';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { Form, FormSubmissionResult, PasswordField } from '@borrowmydoggy/form-components';
import { PasswordAuthenticationProvider } from '@borrowmydoggy/core-models';
import { StepContainer, StepComponent, StepHeading, StandardStepDescription, StepNavigation, IStepComponentProps } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const passwordSchema = z.object({ password: PasswordAuthenticationProvider.password });
type PasswordSchema = z.infer<typeof passwordSchema>;

export const User6: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  function handleSubmit(data: PasswordSchema): FormSubmissionResult<PasswordSchema> {
    props.record({ authenticationProvider: { authenticationType: 'password', password: data.password }});
    return { outcome: 'success' };
  }

  function handleChangeEmail(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void {
    event.preventDefault();
    props.record({ emailAddressCheckStatus: 'pending' });
  }

  let title: string;
  if (props.manuscript.variant === 'new') {
    title = `Check out local ${props.manuscript.intendedProfileType == 'owner' ? 'borrowers' : 'dogs'} for free`;
  } else {
    title = `Welcome new ${props.manuscript.intendedProfileType == 'owner' ? 'pup' : 'borrower'}!`;
  }

  return (
    <StepContainer
      stepTitle={title}
      totalSteps={props.totalStepCount}
      currentStep={6}
      stepImageUrl={context.images['illustrations/img-dog-digging-flat.svg']}
    >
      <StepHeading text='...and how about a password' />
      <StandardStepDescription text='The longer the better (minimum 10 characters)' />
      <Form schema={passwordSchema} onSubmit={handleSubmit}>
        {({ fields, handleChange, handleValidate }) => (
          <>
            <PasswordField
              id='password'
              label='Password'
              placeholder='Minimum 10 characters'
              value={fields.password.value}
              validationState={fields.password.validationState}
              onValidate={handleValidate}
              onChange={handleChange}
            />
            <StepNavigation
              nextButton={{ text: 'Next', icon: faChevronRight, iconAlignment: 'right' }}
              backButton={{ text: 'Back' }}
              onBackClick={handleChangeEmail}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
