import React, { FunctionComponent, ReactNode } from 'react';

import { WarningMessageBanner } from '@borrowmydoggy/product-components';
import { IPremiumButtonProps, ITextButtonProps, PremiumButton, TextButton } from '@borrowmydoggy/core-components';

export interface IWarningBannerProps {
  buttonText: string;
  buttonType: 'primary' | 'premium';
  buttonHref: string;
  children?: ReactNode;
}

export const WarningBanner: FunctionComponent<IWarningBannerProps> = (props: IWarningBannerProps) => {
  let ButtonComponent: FunctionComponent<IPremiumButtonProps> | FunctionComponent<ITextButtonProps>;
  if (props.buttonType === 'premium') {
    ButtonComponent = PremiumButton;
  } else {
    ButtonComponent = TextButton;
  }
  const button = <ButtonComponent text={props.buttonText} link={{ href: props.buttonHref }} />;
  return <WarningMessageBanner button={button}>{props.children}</WarningMessageBanner>;
};
