import React, { FunctionComponent, ReactNode, useContext } from 'react';
import styled from 'styled-components';

import { PremiumButton, SectionHeading, responsiveQuery } from '@borrowmydoggy/core-components';

import { WebContext } from '../utils/AppContext';

export interface IDiscountNoticeProps {
  discountApplied: boolean;
  formattedAmountOff?: string;
  formattedExpiryDate?: string;
  subscribePath: string;
  dashboardPath: string;
}

const StyledDiscountNoticePage = styled.div`
  display: grid;
  max-width: 100vw;

  &>.explanation { grid-area: explanation; }
  &>.graphic { grid-area: graphic; }
  &>.details { grid-area: details; }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    max-width: 600px;
    margin: 70px auto;
    grid-template-columns: 1fr 218px;
    grid-template-areas:    'explanation graphic'
                            'details details';
    grid-gap: 30px 15px;
    &>.explanation {
      align-self: center;
      text-align: center;
      &>p { font-size: 1.6rem; }
    }
  }

  ${responsiveQuery('mobilePortrait')} {
    margin: 50px 0;
    grid-template-columns: 1fr;
    grid-template-areas:    'graphic'
                            'explanation'
                            'details';
    grid-gap: 25px;
    &>.explanation {
      padding: 0 10px;
      align-self: center;
      text-align: center;
      &>p { font-size: 1.6rem; }
    }
    &>.graphic {
      padding: 0 10px;
      width: 218px;
      justify-self: center;
    }
  }
`;

const StyledSectionHeading = styled(SectionHeading)`
  text-align: center;
`;

const StyledButton = styled(PremiumButton)`
  margin: 15px auto 0 auto;
`;

const StyledLink = styled.a`
  display: block;
  margin: 15px auto 0 auto;
  font-family: Lato, sans-serif;
  font-size: 1.4rem;
  color: ${props => props.theme.primary.mid};
  text-decoration: none;
`;

export const DiscountNotice: FunctionComponent<IDiscountNoticeProps> = (props: IDiscountNoticeProps) => {
  const context = useContext(WebContext);

  function renderDiscountApplied(
    imageUrl: string,
    formattedAmountOff: string,
    formattedExpiryDate: string,
    subscribePath: string,
    dashboardPath: string
  ): ReactNode {
    return (
      <StyledDiscountNoticePage>
        <div className='explanation'>
          <StyledSectionHeading>Pawsome, you got a treat!</StyledSectionHeading>
          <p>
            You&rsquo;ve got {formattedAmountOff} off your BorrowMyDoggy subscription.{' '}
            It&rsquo;s only valid until {formattedExpiryDate} so make sure you use it soon!
          </p>
          <StyledButton text='Go Premium now' link={{ href: subscribePath }} />
          <StyledLink href={dashboardPath}>Go to dashboard</StyledLink>
        </div>
        <div className='graphic'>
          <img src={imageUrl} alt='Dog receiving gift' />
        </div>
      </StyledDiscountNoticePage>
    );
  }

  function renderDiscountNotApplied(imageUrl: string, dashboardPath: string): ReactNode {
    return (
      <StyledDiscountNoticePage>
        <div className='explanation'>
          <StyledSectionHeading>We couldn&rsquo;t find that treat</StyledSectionHeading>
          <p>Sorry, we couldn&rsquo;t apply that discount.</p>
          <StyledLink href={dashboardPath}>Go to dashboard</StyledLink>
        </div>
        <div className='graphic'>
          <img src={imageUrl} alt='Dog digging' />
        </div>
      </StyledDiscountNoticePage>
    );
  }

  if (props.discountApplied && props.formattedAmountOff && props.formattedExpiryDate) {
    return (
      <>
        {renderDiscountApplied(
          context.images['illustrations/img-dog-receiving-gift.svg'],
          props.formattedAmountOff,
          props.formattedExpiryDate,
          props.subscribePath,
          props.dashboardPath
        )}
      </>
    );
  } else {
    return <>{renderDiscountNotApplied(context.images['illustrations/img-dog-digging.svg'], props.dashboardPath)}</>;
  }
};
