import { CommandResultPromise } from '@borrowmydoggy/api-client';
import {
  ActivityStreamFilter,
  BorrowerSearchFiltersArgument,
  DogSearchFiltersArgument,
  Profile as ProfileType,
  ProfileReport as ProfileReportType,
  ProfileSearchCriteriaArgument,
  SearchView as SearchViewType
} from '@borrowmydoggy/bmd-api';

import { buildBorrowerProfileDetails, buildOwnerProfileDetails } from './adapters';
import { BorrowerProfileCreationDetails, OwnerProfileCreationDetails } from '../../schemas';
import { IProfilePhotoImportRequest } from '../../profile';

import { ClientBase } from '../ClientBase';
import { mutations } from './mutations';
import { queries } from './queries';

export class ProfileAPI extends ClientBase {
  public static createBorrowerProfile(borrowerProfileDetails: BorrowerProfileCreationDetails): CommandResultPromise<typeof mutations.createBorrowerProfile> {
    return this.client.mutation({
      command: mutations.createBorrowerProfile,
      args: { borrowerProfileDetails: buildBorrowerProfileDetails(borrowerProfileDetails) }
    });
  }

  public static createProfilePhotoUploadRequest(
    profileType: ProfileType
  ): CommandResultPromise<typeof mutations.createProfilePhotoUploadRequest> {
    return this.client.mutation({
      command: mutations.createProfilePhotoUploadRequest,
      args: { profileType }
    });
  }

  public static importProfilePhotos(
    profileType: ProfileType,
    photos: IProfilePhotoImportRequest[]
  ): CommandResultPromise<typeof mutations.importProfilePhotos> {
    return this.client.mutation({
      command: mutations.importProfilePhotos,
      args: {
        profileType,
        photos: photos.map(p => ({ fileName: p.photoFileName, fileMimeType: p.photoMimeType }))
      }
    });
  }

  public static updateProfilePrimaryPhoto(
    profileType: ProfileType,
    primaryPhotoImageId: string
  ): CommandResultPromise<typeof mutations.updateProfilePrimaryPhoto> {
    return this.client.mutation({
      command: mutations.updateProfilePrimaryPhoto,
      args: {
        profileType,
        primaryPhotoImageId
      }
    });
  }

  public static destroyProfilePhoto(profileType: ProfileType, photoImageId: string): CommandResultPromise<typeof mutations.destroyProfilePhoto> {
    return this.client.mutation({
      command: mutations.destroyProfilePhoto,
      args: {
        profileType,
        photoImageId
      }
    });
  }

  public static setPendingProfilePhotoPreview(
    taskId: string,
    previewImageUrl: string
  ): CommandResultPromise<typeof mutations.setPendingProfilePhotoPreview> {
    return this.client.mutation({
      command: mutations.setPendingProfilePhotoPreview,
      args: {
        taskId,
        previewUrl: previewImageUrl
      }
    });
  }

  public static destroyPendingProfilePhoto(taskId: string): CommandResultPromise<typeof mutations.destroyPendingProfilePhoto> {
    return this.client.mutation({
      command: mutations.destroyPendingProfilePhoto,
      args: { taskId }
    });
  }

  public static createOwnerProfile(
    ownerProfileDetails: OwnerProfileCreationDetails
  ): CommandResultPromise<typeof mutations.createOwnerProfile> {
    return this.client.mutation({
      command: mutations.createOwnerProfile,
      args: { ownerProfileDetails: buildOwnerProfileDetails(ownerProfileDetails) }
    });
  }

  public static partialBorrowerProfile(borrowerProfileUuid: string): CommandResultPromise<typeof queries.partialBorrowerProfile> {
    return this.client.query({
      command: queries.partialBorrowerProfile,
      args: { profileId: borrowerProfileUuid }
    });
  }

  public static partialOwnerProfile(ownerProfileUuid: string): CommandResultPromise<typeof queries.partialOwnerProfile> {
    return this.client.query({
      command: queries.partialOwnerProfile,
      args: { profileId: ownerProfileUuid }
    });
  }

  public static createBorrowerLike(ownerProfileUuid: string): CommandResultPromise<typeof mutations.createBorrowerLike> {
    return this.client.mutation({
      command: mutations.createBorrowerLike,
      args: { ownerProfileUuid }
    });
  }

  public static destroyBorrowerLike(ownerProfileUuid: string): CommandResultPromise<typeof mutations.destroyBorrowerLike> {
    return this.client.mutation({
      command: mutations.destroyBorrowerLike,
      args: { ownerProfileUuid }
    });
  }

  public static createOwnerLike(borrowerProfileUuid: string): CommandResultPromise<typeof mutations.createOwnerLike> {
    return this.client.mutation({
      command: mutations.createOwnerLike,
      args: { borrowerProfileUuid }
    });
  }

  public static destroyOwnerLike(borrowerProfileUuid: string): CommandResultPromise<typeof mutations.destroyOwnerLike> {
    return this.client.mutation({
      command: mutations.destroyOwnerLike,
      args: { borrowerProfileUuid }
    });
  }

  public static destroyIncompleteBorrowerProfile(): CommandResultPromise<typeof mutations.destroyIncompleteBorrowerProfile> {
    return this.client.mutation({
      command: mutations.destroyIncompleteBorrowerProfile
    });
  }

  public static destroyIncompleteOwnerProfile(): CommandResultPromise<typeof mutations.destroyIncompleteOwnerProfile> {
    return this.client.mutation({
      command: mutations.destroyIncompleteOwnerProfile
    });
  }

  public static switchToBorrowerProfile(): CommandResultPromise<typeof mutations.switchToBorrowerProfile> {
    return this.client.mutation({
      command: mutations.switchToBorrowerProfile
    });
  }

  public static switchToOwnerProfile(): CommandResultPromise<typeof mutations.switchToOwnerProfile> {
    return this.client.mutation({
      command: mutations.switchToOwnerProfile
    });
  }

  public static updateBorrowerSearchPreferences(
    criteria: ProfileSearchCriteriaArgument,
    filters: DogSearchFiltersArgument,
    searchViewType: SearchViewType
  ): CommandResultPromise<typeof mutations.updateBorrowerSearchPreferences> {
    return this.client.mutation({
      command: mutations.updateBorrowerSearchPreferences,
      args: { preferences: { criteria, filters, searchViewType }}
    });
  }

  public static updateOwnerSearchPreferences(
    criteria: ProfileSearchCriteriaArgument,
    filters: BorrowerSearchFiltersArgument,
    searchViewType: SearchViewType
  ): CommandResultPromise<typeof mutations.updateOwnerSearchPreferences> {
    return this.client.mutation({
      command: mutations.updateOwnerSearchPreferences,
      args: { preferences: { criteria, filters, searchViewType }}
    });
  }

  public static createBorrowerPictureRequest(borrowerProfileUuid: string): CommandResultPromise<typeof mutations.createBorrowerPictureRequest> {
    return this.client.mutation({
      command: mutations.createBorrowerPictureRequest,
      args: { borrowerProfileUuid }
    });
  }

  public static createOwnerPictureRequest(ownerProfileUuid: string): CommandResultPromise<typeof mutations.createOwnerPictureRequest> {
    return this.client.mutation({
      command: mutations.createOwnerPictureRequest,
      args: { ownerProfileUuid }
    });
  }

  public static createBorrowerProfileReport(
    borrowerProfileUuid: string,
    reportType: ProfileReportType,
    explanation?: string
  ): CommandResultPromise<typeof mutations.createBorrowerProfileReport> {
    return this.client.mutation({
      command: mutations.createBorrowerProfileReport,
      args: { borrowerProfileUuid, reportType, explanation }
    });
  }

  public static createOwnerProfileReport(
    ownerProfileUuid: string,
    reportType: ProfileReportType,
    explanation?: string
  ): CommandResultPromise<typeof mutations.createOwnerProfileReport> {
    return this.client.mutation({
      command: mutations.createOwnerProfileReport,
      args: { ownerProfileUuid, reportType, explanation }
    });
  }

  public static disableMessagingForProfile(
    profileType: ProfileType,
    acceptingMessages: boolean
  ): CommandResultPromise<typeof mutations.disableMessagingForProfile> {
    return this.client.mutation({
      command: mutations.disableMessagingForProfile,
      args: { profileType, acceptingMessages }
    });
  }

  public static enableMessagingForProfile(
    profileType: ProfileType,
    lookingForMatch: boolean
  ): CommandResultPromise<typeof mutations.enableMessagingForProfile> {
    return this.client.mutation({
      command: mutations.enableMessagingForProfile,
      args: { profileType, lookingForMatch }
    });
  }

  public static ownerActivityStream(
    page?: number,
    filter?: ActivityStreamFilter
  ): CommandResultPromise<typeof queries.ownerActivityStream> {
    return this.client.query({
      command: queries.ownerActivityStream,
      args: { page, filter }
    });
  }

  public static borrowerActivityStream(
    page?: number,
    filter?: ActivityStreamFilter
  ): CommandResultPromise<typeof queries.borrowerActivityStream> {
    return this.client.query({
      command: queries.borrowerActivityStream,
      args: { page, filter }
    });
  }
}
