import React, { MouseEvent, useContext } from 'react';
import { faHandHeart, faTennisBall } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { SelectionButtons } from '@borrowmydoggy/core-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';
import { BorrowerOffering } from './BorrowerOffering';

const StyledButtons = styled(SelectionButtons)`
  margin: 0 auto;
  margin-bottom: 30px;
` as typeof SelectionButtons;

export const Borrower5: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  function handleChangeGardenAnswer(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void {
    event.preventDefault();
    props.record({ gardenOrOutsideSpaceConfirmed: false });
  }

  function handleSelection(buttonId: BorrowerOffering, event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void {
    event.preventDefault();
    props.record({ offering: buttonId, offeringConfirmed: true });
  }

  return (
    <StepContainer
      stepTitle='It&rsquo;s all about you'
      totalSteps={props.totalStepCount}
      currentStep={5}
      stepImageUrl={context.images['illustrations/img-dog-sleeping-in-house.svg']}
    >
      <StepHeading text='How would you spend time with a dog?' />
      <StandardStepDescription text='Would you like to take a dog for a walk, keep them company or both?' />
      <StyledButtons
        buttons={[
          {
            id: 'exerciseAndCompany',
            text: 'Exercise and company',
            icon: { name: 'exerciseAndCompany' }
          },
          {
            id: 'exercise',
            text: 'Just exercise',
            icon: faTennisBall
          },
          {
            id: 'company',
            text:'Just company',
            icon: faHandHeart
          }
        ]}
        onClick={handleSelection}
      />
      <StepNavigation onBackClick={handleChangeGardenAnswer} backButton={{ text: 'Back' }} />
    </StepContainer>
  );
};
