import { Stripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import React, { FunctionComponent, useEffect } from 'react';

export interface ICardActionFormProps {
  paymentIntentClientSecret: string;
  title: string;
  titleClass?: string;
  confirmationImageUrl: string;
  onActionSuccess?: (stripePaymentIntentId: string) => void;
  onActionFailure?: (error: string) => void;
}

export const CardActionForm: FunctionComponent<ICardActionFormProps> = (props: ICardActionFormProps) => {
  const stripe = useStripe();

  async function handleCardAction(stripeApi: Stripe): Promise<void> {
    try {
      const response = await stripeApi.handleCardAction(props.paymentIntentClientSecret);
      if (response.error && props.onActionFailure) {
        props.onActionFailure(response.error.message || '');
      } else if (props.onActionSuccess) {
        props.onActionSuccess(response.paymentIntent?.id || '');
      }
    } catch (error) {
      console.error(error);
      if (props.onActionFailure && error instanceof Error) {
        props.onActionFailure(error.message);
      }
    }
  }

  useEffect(() => {
    if (stripe) {
      handleCardAction(stripe);
    }
  }, [stripe, props.paymentIntentClientSecret]);

  return (
    <div>
      <h1 className={`page-title ${props.titleClass ? props.titleClass : 'premium'}`}>{props.title}</h1>
      <p className='new-paragraph centred space-above-medium'>Awaiting payment confirmation...</p>
      <img className='illustration' src={props.confirmationImageUrl} alt='Please wait' />
    </div>
  );
};
