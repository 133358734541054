import React, { Component, MouseEvent, ReactNode } from 'react';

import { countries } from '@borrowmydoggy/form-components';

import { ChangePhoneNumberForm, ConfirmPhoneVerificationCode, ISuccessfulTelephoneNumberUpdateResponse } from '../verification';

export interface IPhoneVerificationProps {
  countryIsoCode: string;
  telephoneNumber?: string;
  eligibleForAutomaticVerification: boolean;
  editingPhoneNumber: boolean;
  telephoneNumberValidated: boolean;
  contactPath: string;
  textMessageVerificationPath: string;
  phoneNumberVerificationPath: string;
  phoneNumberVerificationHelpPath: string;
  telephoneNumberUpdatePath: string;
  verificationPath: string;
  dashboardPath: string;
}

interface IPhoneVerificationState {
  csrfToken: string;
  countryIsoCode: string;
  telephoneNumber: string;
  eligibleForAutomaticVerification: boolean;
  editingTelephoneNumber: boolean;
  telephoneNumberValidated: boolean;
  telephoneNumberVerified: boolean;
  verificationCodeRequired: boolean;
  verificationCodeResent: boolean;
  allowResendingOfCode: boolean;
  failedToSendVerificationCode: boolean;
  requiresManualVerification: boolean;
  maxAttemptsExceeded: boolean;
  errorMessage: string;
}

export class PhoneVerification extends Component<IPhoneVerificationProps, IPhoneVerificationState> {
  private countryFlags: Record<string, string> = {};

  constructor(props: IPhoneVerificationProps) {
    super(props);

    let csrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const attribute = csrfTokenElement.attributes.getNamedItem('content');
      if (attribute) {
        csrfToken = attribute.value;
      }
    }

    this.buildCountryFlagLookup();

    this.state = {
      csrfToken,
      countryIsoCode: this.props.countryIsoCode,
      telephoneNumber: this.props.telephoneNumber || '',
      eligibleForAutomaticVerification: this.props.eligibleForAutomaticVerification,
      editingTelephoneNumber: this.props.editingPhoneNumber || !this.props.telephoneNumberValidated,
      telephoneNumberValidated: this.props.telephoneNumberValidated,
      telephoneNumberVerified: false,
      verificationCodeRequired: false,
      verificationCodeResent: false,
      allowResendingOfCode: false,
      failedToSendVerificationCode: false,
      requiresManualVerification: false,
      maxAttemptsExceeded: false,
      errorMessage: ''
    };

    this.countryFlag = this.countryFlag.bind(this);

    this.handleCountryIsoCodeChange = this.handleCountryIsoCodeChange.bind(this);
    this.handleTelephoneNumberChange = this.handleTelephoneNumberChange.bind(this);
    this.handlePhoneNumberChanged = this.handlePhoneNumberChanged.bind(this);

    this.handleChangeNumberClick = this.handleChangeNumberClick.bind(this);
    this.handleSendCodeClick = this.handleSendCodeClick.bind(this);
    this.handleResendCodeClick = this.handleResendCodeClick.bind(this);
    this.handlePhoneNumberVerified = this.handlePhoneNumberVerified.bind(this);
    this.handlePhoneNumberVerificationFailed = this.handlePhoneNumberVerificationFailed.bind(this);
    this.handleContactMeClick = this.handleContactMeClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.startResendTimer = this.startResendTimer.bind(this);
    this.enableResend = this.enableResend.bind(this);
  }

  public render(): ReactNode {
    return (
      <div id='verification-container'>
        {this.renderStep()}
        <div className='element-group centred space-above-large'>
          <p>
            Need help?{' '}
            <a href={this.props.contactPath} className='new-link primary'>
              Get in touch
            </a>
          </p>
        </div>
      </div>
    );
  }

  private buildCountryFlagLookup(): void {
    countries.forEach(country => {
      this.countryFlags[country.isoCode] = country.flag;
    });
  }

  private countryFlag(): string {
    if (this.state.countryIsoCode) {
      return this.countryFlags[this.state.countryIsoCode];
    } else {
      return '';
    }
  }

  private handleCountryIsoCodeChange(countryIsoCode: string): void {
    this.setState({ countryIsoCode });
  }

  private handleTelephoneNumberChange(telephoneNumber: string): void {
    this.setState({ telephoneNumber });
  }

  private async handlePhoneNumberChanged({ countryIsoCode, eligibleForAutomaticVerification }: ISuccessfulTelephoneNumberUpdateResponse): Promise<void> {
    if (eligibleForAutomaticVerification) {
      const result = await this.sendVerificationCode();
      if (result) {
        this.setState({
          editingTelephoneNumber: false,
          telephoneNumberValidated: true,
          countryIsoCode,
          eligibleForAutomaticVerification,
          verificationCodeRequired: true,
          errorMessage: ''
        });
      } else {
        const errorMessage = 'Sorry, we were unable to send a message to the phone number you provided. Please try again.';
        this.setState({
          editingTelephoneNumber: false,
          telephoneNumberValidated: true,
          countryIsoCode,
          eligibleForAutomaticVerification,
          verificationCodeRequired: true,
          failedToSendVerificationCode: true,
          errorMessage
        });
      }
    } else {
      this.setState({
        editingTelephoneNumber: false,
        telephoneNumberValidated: true,
        countryIsoCode,
        eligibleForAutomaticVerification,
        requiresManualVerification: true
      });
      this.requestHelp();
    }
  }

  private handleChangeNumberClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    this.setState({ editingTelephoneNumber: true });
  }

  private async handleSendCodeClick(event: MouseEvent<HTMLAnchorElement>): Promise<void> {
    event.preventDefault();
    const result = await this.sendVerificationCode();
    if (result) {
      this.setState({ verificationCodeRequired: true, errorMessage: '' });
    } else {
      const errorMessage = 'Sorry, we were unable to send a message to the phone number you provided. Please try again.';
      this.setState({ verificationCodeRequired: true, failedToSendVerificationCode: true, errorMessage });
    }
  }

  private async handleResendCodeClick(): Promise<void> {
    this.setState({ errorMessage: '', failedToSendVerificationCode: false });
    const result = await this.sendVerificationCode();
    if (result) {
      this.setState({ errorMessage: '', verificationCodeResent: true });
    } else {
      const errorMessage = 'Sorry, we were unable to send a message to the phone number you provided. Please try again.';
      this.setState({ errorMessage, verificationCodeResent: false });
    }
  }

  private startResendTimer(): void {
    setTimeout(this.enableResend, 10000);
  }

  private enableResend(): void {
    this.setState({ allowResendingOfCode: true });
  }

  private async sendVerificationCode(): Promise<boolean> {
    try {
      const response = await fetch(this.props.textMessageVerificationPath, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken }
      });
      if (response.status === 200) {
        const json = await response.json();
        if (json.status && json.status === 'success') {
          this.setState({ allowResendingOfCode: false });
          this.startResendTimer();
          return true;
        } else {
          if (json.maxAttemptsExceeded) {
            this.setState({ maxAttemptsExceeded: true, requiresManualVerification: true });
            this.requestHelp();
          }
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private handlePhoneNumberVerified(): void {
    this.setState({ telephoneNumberVerified: true });
  }

  private handlePhoneNumberVerificationFailed(errorMessage: string): void {
    this.setState({ telephoneNumberVerified: false, errorMessage });
  }

  private handleContactMeClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    this.setState({ requiresManualVerification: true });
    this.requestHelp();
  }

  private handleCancelClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    this.setState({ requiresManualVerification: true });
    this.requestHelp();
  }

  private async requestHelp(): Promise<boolean> {
    try {
      const response = await fetch(this.props.phoneNumberVerificationHelpPath, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken }
      });
      if (response.status === 200) {
        const json = await response.json();
        if (json.status && json.status === 'success') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private renderChangePhoneNumberForm(): ReactNode {
    const formTitle = this.state.telephoneNumberValidated ? 'Enter your new number' : 'Phone number verification';
    const paragraphText = this.state.telephoneNumberValidated
      ? ''
      : 'First we need a phone number, we’ll check it’s yours. Mobiles are best. We won’t share it with anyone.';
    return (
      <ChangePhoneNumberForm
        countryIsoCode={this.state.countryIsoCode}
        telephoneNumber={this.state.telephoneNumber}
        title={formTitle}
        paragraph={paragraphText}
        onCountryIsoCodeChange={this.handleCountryIsoCodeChange}
        onTelephoneNumberChange={this.handleTelephoneNumberChange}
        onPhoneNumberChanged={this.handlePhoneNumberChanged}
        telephoneNumberUpdatePath={this.props.telephoneNumberUpdatePath}
        csrfToken={this.state.csrfToken}
      />
    );
  }

  private renderEligibleForAutomaticVerificationContent(): ReactNode {
    return (
      <>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>We&rsquo;ll send a code to your phone to make sure that we have the right number for you.</p>
        </div>
        <div className='element-group centred space-above-large'>
          <p className='clarification'>Is this your number?</p>
        </div>
        <div className='element-group centred space-above-small'>
          <p className='highlight-text'>
            {this.countryFlag()} {this.state.telephoneNumber}
          </p>
        </div>
        <div className='element-group centred space-above-medium'>
          <a onClick={this.handleSendCodeClick} href='#' className='new-button primary wide'>
            Yes - send code
          </a>
        </div>
        <div className='element-group centred space-above-medium'>
          <a onClick={this.handleChangeNumberClick} href='#' className='new-link primary'>
            No, change number
          </a>
        </div>
      </>
    );
  }

  private renderIneligibleForAutomaticVerificationContent(): ReactNode {
    return (
      <>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>As your number is not a UK mobile number we need to manually check it.</p>
        </div>
        <div className='element-group centred space-above-large'>
          <p className='clarification'>Is this your number?</p>
        </div>
        <div className='element-group centred space-above-small'>
          <p className='highlight-text'>
            {this.countryFlag()} {this.state.telephoneNumber}
          </p>
        </div>
        <div className='element-group centred space-above-medium'>
          <a onClick={this.handleContactMeClick} href='#' className='new-button primary wide'>
            Yes - contact me
          </a>
        </div>
        <div className='element-group centred space-above-medium'>
          <a onClick={this.handleChangeNumberClick} href='#' className='new-link primary'>
            No, change number
          </a>
        </div>
      </>
    );
  }

  private renderConfirmationStep(): ReactNode {
    let step: ReactNode;
    if (this.state.eligibleForAutomaticVerification) {
      step = this.renderEligibleForAutomaticVerificationContent();
    } else {
      step = this.renderIneligibleForAutomaticVerificationContent();
    }
    return (
      <>
        <h2 className='section-title space-above-medium'>Let&rsquo;s start with your phone</h2>
        {step}
      </>
    );
  }

  private renderVerificationCodeStep(): ReactNode {
    return (
      <ConfirmPhoneVerificationCode
        telephoneNumber={this.state.telephoneNumber}
        verificationCodeResent={this.state.verificationCodeResent}
        errorMessage={this.state.errorMessage}
        failedToSendVerificationCode={this.state.failedToSendVerificationCode}
        phoneNumberVerificationPath={this.props.phoneNumberVerificationPath}
        resendEnabled={this.state.allowResendingOfCode}
        onPhoneNumberVerified={this.handlePhoneNumberVerified}
        onPhoneNumberVerificationFailed={this.handlePhoneNumberVerificationFailed}
        onChangeNumberClicked={this.handleChangeNumberClick}
        onResendCodeClicked={this.handleResendCodeClick}
        onCancelClicked={this.handleCancelClick}
        csrfToken={this.state.csrfToken}
      />
    );
  }

  private renderVerificationSuccess(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>Phone verified</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>Great! Your phone number is verified, next we need to check your ID for the safety of all our members.</p>
        </div>
        <div className='element-group centred space-above-medium'>
          <a href={this.props.verificationPath} className='new-button primary wide'>
            Next
          </a>
        </div>
      </>
    );
  }

  private renderEditingSuccess(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>Phone verified</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>Great! Your new phone number is verified, now you can get back to finding a match.</p>
        </div>
        <div className='element-group centred space-above-medium'>
          <a href={this.props.dashboardPath} className='new-button primary wide'>
            Go to Dashboard
          </a>
        </div>
      </>
    );
  }

  private renderManualVerificationNoticeWhenVerifying(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>We&rsquo;ll get in touch</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>
            {this.state.maxAttemptsExceeded
              ? 'You tried to send the code too many times so we will contact you within 24 hours to check your phone number.'
              : 'We’ll contact you within 24 hours to check your phone number.'}
          </p>
        </div>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>In the meantime we need to check your ID for the safety of all our members.</p>
        </div>
        <div className='element-group centred space-above-medium'>
          <a href={this.props.verificationPath} className='new-button primary wide'>
            Next
          </a>
        </div>
      </>
    );
  }

  private renderManualVerificationNoticeWhenEditing(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>We&rsquo;ll get in touch</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>
            {this.state.maxAttemptsExceeded
              ? 'You tried to send the code too many times so we will contact you within 24 hours to check your phone number.'
              : 'We’ll contact you within 24 hours to check your phone number.'}
          </p>
        </div>
        <div className='element-group centred space-above-medium'>
          <a href={this.props.dashboardPath} className='new-button primary wide'>
            Go to Dashboard
          </a>
        </div>
      </>
    );
  }

  private renderStep(): ReactNode {
    if (this.state.editingTelephoneNumber) {
      return this.renderChangePhoneNumberForm();
    } else if (this.state.telephoneNumberVerified && !this.props.editingPhoneNumber) {
      return this.renderVerificationSuccess();
    } else if (this.state.telephoneNumberVerified && this.props.editingPhoneNumber) {
      return this.renderEditingSuccess();
    } else if (this.state.requiresManualVerification && !this.props.editingPhoneNumber) {
      return this.renderManualVerificationNoticeWhenVerifying();
    } else if (this.state.requiresManualVerification && this.props.editingPhoneNumber) {
      return this.renderManualVerificationNoticeWhenEditing();
    } else if (this.state.verificationCodeRequired) {
      return this.renderVerificationCodeStep();
    } else {
      return this.renderConfirmationStep();
    }
  }
}
