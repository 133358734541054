import React, { FunctionComponent, ReactNode, useState } from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { AccessibilityText, Icon } from '@borrowmydoggy/core-components';
import { ModalBinaryPanel } from '@borrowmydoggy/account-components';

import { ProfileAPI } from '../api';
import { IPhotoToolbarProps, PhotoToolbar } from '../userAccount';

interface IPhoto {
  imageId: string;
  imageUrl: string;
}

interface IPendingPhoto {
  taskId: string;
  imageUrl: string;
  status: 'pendingUpload' | 'pendingImport' | 'processing' | 'ready' | 'fileTooLarge' | 'imageTooLarge' | 'imageTooSmall' | 'error';
}

function isPendingPhoto(photo: IPhoto | IPendingPhoto): photo is IPendingPhoto {
  return (photo as IPendingPhoto).taskId !== undefined;
}

export interface IOwnPhotosProps {
  initialPhotos: Array<IPhoto | IPendingPhoto>;
  slideshowId: string;
  profileType: 'borrower' | 'owner';
  showLastImage?: boolean;
  missingImageUrl: string;
  errorImageUrl: string;
  className?: string;
}

export const OwnPhotos: FunctionComponent<IOwnPhotosProps> = (props: IOwnPhotosProps) => {
  const [photos, setPhotos] = useState<Array<IPhoto | IPendingPhoto>>(props.initialPhotos);
  const [imageIndex, setImageIndex] = useState(props.showLastImage ? Math.max(photos.length - 1, 0) : 0);
  const [inProgress, setInProgress] = useState(false);
  const [deletionConfirmationShown, setDeletionConfirmationShown] = useState(false);

  function handleThumbnailClick(index: number): void {
    setImageIndex(index);
  }

  async function handleMakeMainClick(): Promise<void> {
    const photo = photos[imageIndex];
    if (photo && !isPendingPhoto(photo)) {
      setInProgress(true);
      const response = await ProfileAPI.updateProfilePrimaryPhoto(props.profileType, photo.imageId);
      if (response.error === undefined && response.data) {
        const result = response.data;
        if (result && result.updateProfilePrimaryPhoto?.successful) {
          setPhotos(result.updateProfilePrimaryPhoto.updatedPhotosWithPending.map(p => {
            if (isPendingPhoto(p)) {
              return { taskId: p.taskId, imageUrl: p.imageUrl, status: p.status };
            }
            return { imageId: p.imageId, imageUrl: `${p.imageUrl}/scale/400` };
          }));
          setImageIndex(0);
        }
      }
      setInProgress(false);
    }
  }

  function handleDeleteClick(): void {
    setDeletionConfirmationShown(true);
  }

  function handleDeletionConfirmationNoClick(): void {
    setDeletionConfirmationShown(false);
  }

  async function handleDeletionConfirmationYesClick(): Promise<void> {
    setDeletionConfirmationShown(false);
    const photo = photos[imageIndex];
    if (photo && !isPendingPhoto(photo)) {
      setInProgress(true);
      const response = await ProfileAPI.destroyProfilePhoto(props.profileType, photo.imageId);
      if (response.error === undefined && response.data) {
        const result = response.data;
        if (result && result.destroyProfilePhoto?.successful) {
          // const newPhotos = result.destroyProfilePhoto.updatedPhotos.map(p => ({ ...p, imageUrl: `${p.imageUrl}/scale/400` }));
          const newPhotos = result.destroyProfilePhoto.updatedPhotosWithPending.map(p => {
            if (isPendingPhoto(p)) {
              return { taskId: p.taskId, imageUrl: p.imageUrl, status: p.status };
            }
            return { imageId: p.imageId, imageUrl: `${p.imageUrl}/scale/400` };
          });
          setPhotos(newPhotos);
          setImageIndex(Math.max(Math.min(imageIndex, newPhotos.length - 1), 0));
        }
      }
      setInProgress(false);
    }
  }

  function handleReloadClick(): void {
    window.location.reload();
  }

  async function handleRemoveClick(): Promise<void> {
    try {
      const photo = photos[imageIndex];
      if (photo && isPendingPhoto(photo)) {
        setInProgress(true);
        const response = await ProfileAPI.destroyPendingProfilePhoto(photo.taskId);
        setInProgress(false);
        if (response.error === undefined && response.data) {
          const result = response.data;
          if (result && result.destroyPendingProfilePhoto?.successful) {
            window.location.reload();
          }
        }
      }
    } catch {
      setInProgress(false);
    }
  }

  function pendingPhotoAvailable(photo: IPendingPhoto): boolean {
    switch (photo.status) {
      case 'pendingUpload':
      case 'pendingImport':
      case 'processing':
      case 'ready':
        return true;
      case 'fileTooLarge':
      case 'imageTooLarge':
      case 'imageTooSmall':
      case 'error':
      default:
        return false;
    }
  }

  function renderToolbar(): ReactNode {
    let toolbarStatus: IPhotoToolbarProps['status'];
    const photo = photos[imageIndex];
    if (inProgress) {
      toolbarStatus = 'inProgress'
    } else if (photos.length === 0) {
      toolbarStatus = 'noImages';
    } else if (isPendingPhoto(photo)) {
      toolbarStatus = pendingPhotoAvailable(photo) ? 'processing' : 'error';
    } else if (imageIndex === 0) {
      toolbarStatus = 'mainImage';
    } else {
      toolbarStatus = 'secondaryImage';
    }
    return (
      <PhotoToolbar
        status={toolbarStatus}
        onMakeMainClick={handleMakeMainClick}
        onDeleteClick={handleDeleteClick}
        onReloadClick={handleReloadClick}
        onRemoveClick={handleRemoveClick}
      />
    );
  }

  function renderMainImage(): ReactNode {
    if (photos.length > 0) {
      const photo = photos[imageIndex];
      let photoElement = <></>;
      if (photo && photo.imageUrl && photo.imageUrl.length > 0 && (!isPendingPhoto(photo) || pendingPhotoAvailable(photo))) {
        photoElement = <img src={photos[imageIndex].imageUrl} alt='' className={isPendingPhoto(photos[imageIndex]) ? 'pending' : ''} />;
      }
      return (
        <div className='pv__photos-main-image'>
          <div className={`pv__photos-slideshow${isPendingPhoto(photo) && !pendingPhotoAvailable(photo) ? ' error': ''}`}>
            {photoElement}
          </div>
        </div>
      );
    }
    return (
      <div className='pv__photos-main-image pv__photos-main-image_blank'>
        <div className='pv__photos-blank-main-image'>
          <img src={props.missingImageUrl} id='main-image' alt='' />
        </div>
      </div>
    );
  }

  function renderThumbnails(): ReactNode {
    const plusButtons: ReactNode[] = [];
    for (let i = 0; i < 5 - photos.length; i++) {
      plusButtons.push((
        <div key={i} className='pv__photos-thumbnails-item pe-photos-file-selector'>
          <a className='button_with-loader' href={`/profile/new_photo/${props.profileType}`}>
            <Icon definition={faPlus} />
            <AccessibilityText>Add a photo</AccessibilityText>
          </a>
        </div>
      ));
    }
    return (
      <div className='pv__photos-thumbnails'>
        <>
          {photos.map((p, i) => {
            let thumbnailError = false;
            let photoElement = (
              <img
                src={p.imageUrl}
                alt=''
                data-cycle-cmd='goto'
                data-cycle-arg={i}
                data-cycle-context={`#${props.slideshowId}`}
                className={isPendingPhoto(p) ? 'pending' : ''}
              />
            );
            if (isPendingPhoto(p) && !pendingPhotoAvailable(p)) {
              thumbnailError = true;
              photoElement = <></>;
            }
            return (
              <div
                key={i}
                className={`pv__photos-thumbnails-item pv__photos-thumbnails-item_${i + 1} ${i === imageIndex ? 'pv__photos-thumbnails-item_active' : ''} ${thumbnailError ? 'error' : ''}`}
                onClick={handleThumbnailClick.bind({}, i)}
              >
                {photoElement}
              </div>
            );
          })}
          {plusButtons}
        </>
      </div>
    );
  }

  function renderDeletionConfirmation(): ReactNode {
    if (deletionConfirmationShown) {
      return (
        <ModalBinaryPanel
          title='Are you sure?'
          description='Do you definitely want to delete this image?'
          onNoClick={handleDeletionConfirmationNoClick}
          onYesClick={handleDeletionConfirmationYesClick}
        />
      );
    }
  }

  return (
    <div className='pv__photos'>
      {renderToolbar()}
      {renderMainImage()}
      {renderThumbnails()}
      {renderDeletionConfirmation()}
    </div>
  );
};
