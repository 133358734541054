import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { AssistanceIcon, responsiveQuery } from '@borrowmydoggy/core-components';
import { CheckBox } from './CheckBox';

export interface ICheckBoxGroupItemProps {
	id: string;
	label: string;
	checked?: boolean;
	disabled?: boolean;
	assistanceText?: string;
	className?: string;
	onChange?: (id: string, checked: boolean) => void;
}

interface IStyleProps {
	assistanceIconPresent: boolean;
}

const StyledCheckBoxGroupItem = styled.span<IStyleProps>`
	display: grid;
	grid-gap: 5px;
	grid-auto-flow: column;
	grid-template-columns: max-content ${props => props.assistanceIconPresent ? 'min-content' : ''} 18px;
	justify-content: start;
	align-items: center;

	&>label {
		font-family: Lato, sans-serif;
		color: ${props => props.theme.secondary.mid};

		${responsiveQuery('mobilePortrait')} {
			font-size: 14px;
		}
	}
`;

export const CheckBoxGroupItem: FunctionComponent<ICheckBoxGroupItemProps> = (props: ICheckBoxGroupItemProps) => {
	function handleChange(checked: boolean): void {
		if (props.onChange) {
			props.onChange(props.id, checked);
		}
	}

	function renderAssistanceIcon(): ReactNode {
		if (props.assistanceText && props.assistanceText.length > 0) {
			return (
				<AssistanceIcon>
					<p>{props.assistanceText}</p>
				</AssistanceIcon>
			);
		}
		return <></>;
	}

	return (
		<StyledCheckBoxGroupItem className={props.className} assistanceIconPresent={props.assistanceText && props.assistanceText.length > 0 ? true : false}>
			<label htmlFor={props.id}>{props.label}</label>
			{renderAssistanceIcon()}
			<CheckBox id={props.id} checked={props.checked || false} disabled={props.disabled} onChange={handleChange} />
		</StyledCheckBoxGroupItem>
	);
};
