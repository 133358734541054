import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { responsiveQuery, PageHeading } from '@borrowmydoggy/core-components';

import { WebContext } from '../utils/AppContext';

export interface IMobileAppProps {
}

const StyledMobileAppPage = styled.div`
  display: grid;
  max-width: 100vw;

  & > .explanation {
    grid-area: explanation;
  }
  & > .graphic {
    grid-area: graphic;
  }
  & > .details {
    grid-area: details;
  }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    max-width: 800px;
    margin: 70px auto;
    padding: 0 20px;
    grid-template-columns: 1fr 300px;
    grid-template-areas:
      'explanation graphic'
      'details details';
    grid-gap: 30px 15px;
    & > .explanation {
      align-self: center;
      text-align: center;
      & > p {
        font-size: 1.6rem;
        margin-bottom: 1em;
      }
    }
  }

  ${responsiveQuery('mobilePortrait')} {
    margin: 50px 0;
    grid-template-columns: 1fr;
    grid-template-areas:
      'graphic'
      'explanation'
      'details';
    grid-gap: 25px;
    & > .explanation {
      padding: 0 10px;
      align-self: center;
      text-align: center;
      & > p {
        font-size: 1.6rem;
        margin-bottom: 1em;
      }
    }
    & > .graphic {
      padding: 0 10px;
      width: 218px;
      justify-self: center;
    }
  }
`;

const StyledPageHeading = styled(PageHeading)`
  text-align: center;

  // We want this to look like a SectionHeading.
  ${responsiveQuery('tabletLandscape', 'desktop')} { font-size: 3.2rem; }
	${responsiveQuery('mobileLandscape', 'tabletPortrait')} { font-size: 3rem; }
	${responsiveQuery('mobilePortrait')} { font-size: 2.6rem; }
`;

const HorizontalButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: center;
`;

const StyledLinkButton = styled.img`
  margin: auto;
  max-width: 135px;
`;

export const MobileApp: FunctionComponent<IMobileAppProps> = () => {
  const context = useContext(WebContext);

  return (
    <StyledMobileAppPage>
      <div className='explanation'>
        <StyledPageHeading>BorrowMyDoggy on your phone</StyledPageHeading>
        <p>
          Chatting to members and finding your pawfect match is even easier with our iPhone and Android apps.
          Install today and get your paws on the BorrowMyDoggy community wherever you are, making meeting up pawfectly simple!
        </p>
        <p>
          Our tech pups are working their tails off to release more of the features you love from the BorrowMyDoggy website{' '}
          so make sure that you keep the app up to date.
        </p>
        <HorizontalButtons>
          <a href='https://itunes.apple.com/gb/app/borrowmydoggy/id860721134'>
            <StyledLinkButton src={context.images['pages/mobile-app/apple-download.svg']} alt='Download on the App Store' />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.borrowmydoggy.borrowmydoggymobile'>
            <StyledLinkButton src={context.images['pages/mobile-app/google-download.svg']} alt='Download on the Google Play Store' />
          </a>
        </HorizontalButtons>
      </div>
      <div className='graphic'>
        <img src={context.images['illustrations/img-person-with-giant-phone.svg']} alt='Person with giant phone' />
      </div>
    </StyledMobileAppPage>
  );
};
