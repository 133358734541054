import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';

const COMPONENT_NAME = 'dashboard__approve-recommendation-alert';
const COMPONENT_CLASS_NAME = `${COMPONENT_NAME} notification notification_success`;
const ACTIONS_CLASS_NAME = `${COMPONENT_NAME}-actions`;
const BODY_CLASS_NAME = 'block-quote';
const APPROVE_BUTTON_CLASS_NAME = 'button button_default button_secondary';
const REJECT_BUTON_CLASS_NAME = 'button button_default button_danger';

interface IRecommendation {
  name: string;
  body: string;
  code: string;
}

export interface IRecommendationsApprovalPanelProps {
  fetchURL: string;
  approveURL: string;
  rejectURL: string;
  profileURL: string;
  recommendationApproved?: boolean;
}

export const RecommendationsApprovalPanel: FunctionComponent<IRecommendationsApprovalPanelProps> = (props: IRecommendationsApprovalPanelProps) => {
  function findCsrfToken(): string {
    let foundCsrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const attribute = csrfTokenElement.attributes.getNamedItem('content');
      if (attribute) {
        foundCsrfToken = attribute.value;
      }
    }
    return foundCsrfToken;
  }

  const [recommendations, setRecommendations] = useState<IRecommendation[]>([]);
  const [recommendation, setRecommendation] = useState<IRecommendation>();
  const [hadDataLoaded, setHadDataLoaded] = useState(false);
  const [lastAction, setLastAction] = useState<string>();
  const [csrfToken, setCsrfToken] = useState<string>(findCsrfToken());

  useEffect(() => {
    loadData(props.fetchURL);
  }, []);

  function renderRecommendation(): ReactElement {
    if (recommendation) {
      return (
        <div className={COMPONENT_CLASS_NAME}>
          <div className='notification-content'>
            <div className='notification-icon'></div>
            <div className='notification-text'>
              <p>You have a new recommendation!</p>
              <p>Your friend, <b>{recommendation.name}</b>, has posted the following recommendation.</p>
              <p className={BODY_CLASS_NAME}>{recommendation.body}</p>
              <p>Please approve it if you would like it to appear on your profile.</p>
            </div>
          </div>
          <div className='notification-call-to-action'>
            <div className={ACTIONS_CLASS_NAME}>
              <button className={APPROVE_BUTTON_CLASS_NAME} onClick={handleYes.bind({}, recommendation.code)}>
                <i className='fas fa-check'></i>{' '}
                Yes, show it on my profile
              </button>
              <button className={REJECT_BUTON_CLASS_NAME} onClick={handleNo.bind({}, recommendation.code)}>
                <i className='fas fa-times'></i>{' '}
                No, don’t show it
              </button>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  }

  function renderDone(): ReactElement {
    return (
      <div className={COMPONENT_CLASS_NAME}>
        <div className='notification-content'>
          <div className='notification-icon'></div>
          <div className='notification-text'>
            <p>Nice work. Take a look at your updated <a href={props.profileURL}>profile</a>.</p>
          </div>
        </div>
      </div>
    );
  }

  function resolveCurrent(): void {
    if (recommendations.length) {
      setRecommendation(recommendations[0]);
      setRecommendations(recommendations.slice(1));
    } else {
      setRecommendation(undefined);
    }
  }

  async function handleYes(code: string): Promise<void> {
    try {
      await fetch(props.approveURL, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken },
        body: JSON.stringify({ id: code })
      });
      setLastAction('handleYes');
      resolveCurrent();
    } catch (error) {
      console.error(error);
    }
  }

  async function handleNo(code: string): Promise<void> {
    try {
      await fetch(props.rejectURL, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken },
        body: JSON.stringify({ id: code })
      });
      setLastAction('handleNo');
      resolveCurrent();
    } catch (error) {
      console.error(error);
    }
  }

  async function loadData(fetchUrl: string): Promise<void> {
    try {
      const response = await fetch(fetchUrl, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken }
      });
      const data = await response.json();
      if (data.length) {
        setHadDataLoaded(true);
      }

      setRecommendations(data);
      resolveCurrent();
    } catch (error) {
      console.error(error);
    }
  }

  if (props.recommendationApproved) {
    return renderDone();
  }

  if (recommendation) {
    return renderRecommendation();
  } else if (hadDataLoaded && lastAction !== 'handleNo') {
    return renderDone();
  } else {
    return null;
  }
};
