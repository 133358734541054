import memoize from 'memoize-one';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { DogCompatibilitySelection, DogPropertySelection } from '@borrowmydoggy/profile-components';
import { IDogSearchResult } from '@borrowmydoggy/search-components';

import { ProfileAPI } from '../api';

import { IDogLikeResult } from './IDogLikeResult';
import { IProfileFeedback } from './IProfileFeedback';
import { OwnerProfile } from './OwnerProfile';
import { ReportType } from './ReportType';

export interface ISearchContextOwnerProfileProps {
	searchResults: IDogSearchResult[];
	totalResults: number;
	searchId: string;
	currentUserPremiumBorrower: boolean;
	premiumPath: string;
	onBackLinkClick?: (lastProfileId: string) => void;
	onNavigateToProfile?: (profileId: string) => void;
	onNextProfileRequired?: () => void;
	onProfileLiked?: (profileId: string, searchResultIndex: number) => Promise<IDogLikeResult>;
	onProfileUnliked?: (profileId: string, searchResultIndex: number) => Promise<IDogLikeResult>;
}

export const SearchContextOwnerProfile: FunctionComponent<ISearchContextOwnerProfileProps> = (props: ISearchContextOwnerProfileProps) => {
	// Here, searchId is used to bust the memoized cache if the search results change.
	const memoizedSearchResultIndex = memoize((profileId: string, searchResults: IDogSearchResult[], searchId: string) =>
		searchResults.findIndex(sr => sr.profileId === profileId)
	);

  const profileIdParam = useParams()['profileId'];

  const [breedCode, setBreedCode] = useState('');
  const [focusedImageIndex, setFocusedImageIndex] = useState(0);
  const [liked, setLiked] = useState(false);
  const [dogAge, setDogAge] = useState('');
  const [profileCreatedAtUtc, setProfileCreatedAtUtc] = useState(new Date());
  const [activityLevel, setActivityLevel] = useState<string>();
  const [company, setCompany] = useState(false);
  const [exercise, setExercise] = useState(false);
  const [acceptingMessages, setAcceptingMessages] = useState(true);
  const [conversationInProgress, setConversationInProgress] = useState(false);
  const [conversationPath, setConversationPath] = useState('');
  const [doggyInfoSheetPath, setDoggyInfoSheetPath] = useState<string>();
  const [profileFeedback, setProfileFeedback] = useState<IProfileFeedback[]>([]);
  const [dogProperties, setDogProperties] = useState<DogPropertySelection>({ hypoallergenic: false, neutered: false, rescueDog: false });
  const [dogCompatibility, setDogCompatibility] = useState<DogCompatibilitySelection>({ cats: false, children: false, otherDogs: false });
  const [picsRequested, setPicsRequested] = useState(false);
  const [reportProfileOpen, setReportProfileOpen] = useState(false);

  if (profileIdParam) {
    useEffect(() => {
      loadProfile(profileIdParam);
    }, [profileIdParam]);

    const searchResultIndex = memoizedSearchResultIndex(profileIdParam, props.searchResults, props.searchId);
    const searchResult = props.searchResults[searchResultIndex];
    let previousResultPath: string | undefined;
    if (searchResultIndex > 0) {
      previousResultPath = `/dog_profile/${props.searchResults[searchResultIndex - 1].profileId}`;
    }
    let nextResultPath: string | undefined;
    // NB: We can't retrieve the ID of a profile which hasn't yet been returned from a search.
    if (searchResultIndex < props.searchResults.length - 1) {
      nextResultPath = `/dog_profile/${props.searchResults[searchResultIndex + 1].profileId}`;
    }

    function handleBackLinkClick(profileId: string): void {
      if (props.onBackLinkClick) {
        props.onBackLinkClick(profileId);
      }
    }

    function handlePreviousResultClick(): void {
      if (searchResultIndex > 0 && props.onNavigateToProfile) {
        const previousSearchResultIndex = searchResultIndex - 1;
        const previousProfileId = props.searchResults[previousSearchResultIndex].profileId;
        props.onNavigateToProfile(previousProfileId);
      }
    }

    function handleNextResultClick(): void {
      if (props.onNavigateToProfile) {
        if (searchResultIndex < props.searchResults.length - 1) {
          const nextSearchResultIndex = searchResultIndex + 1;
          const nextProfileId = props.searchResults[nextSearchResultIndex].profileId;
          props.onNavigateToProfile(nextProfileId);
        } else if (props.onNextProfileRequired) {
          props.onNextProfileRequired();
        }
      }
    }

    async function handleProfileLiked(profileId: string): Promise<void> {
      if (props.onProfileLiked) {
        const likeResult = await props.onProfileLiked(profileId, searchResultIndex);
        if (likeResult.successful) {
          setLiked(true);
        }
      }
    }

    async function handleProfileUnliked(profileId: string): Promise<void> {
      if (props.onProfileUnliked) {
        const unlikeResult = await props.onProfileUnliked(profileId, searchResultIndex);
        if (unlikeResult.successful) {
          setLiked(false);
        }
      }
    }

    async function handlePhotoRequested(profileId: string): Promise<void> {
      const result = await requestPics(profileId);
      setPicsRequested(result);
    }

    function handlePhotoSelected(index: number): void {
      setFocusedImageIndex(index);
    }

    function handleReportProfileOpen(): void {
      setReportProfileOpen(true);
    }

    function handleReportProfileClose(): void {
      setReportProfileOpen(false);
    }

    function handleProfileReported(profileId: string, reportType: ReportType, explanation?: string): void {
      reportProfile(profileId, reportType, explanation);
    }

    async function loadProfile(profileId: string): Promise<void> {
      setReportProfileOpen(false); // Do this before issuing the API call.
      const response = await ProfileAPI.partialOwnerProfile(profileId);
      if (response.error === undefined && response.data && response.data.ownerProfile) {
        setBreedCode(response.data.ownerProfile.breedCode);
        setFocusedImageIndex(0);
        setLiked(response.data.ownerProfile.liked);
        setDogAge(response.data.ownerProfile.dogAge || '');
        setProfileCreatedAtUtc(new Date(response.data.ownerProfile.profileCreatedAtUtc));
        setActivityLevel(response.data.ownerProfile.activityLevel || undefined);
        setCompany(response.data.ownerProfile.company);
        setExercise(response.data.ownerProfile.exercise);
        setAcceptingMessages(response.data.ownerProfile.acceptingMessages);
        setConversationInProgress(response.data.ownerProfile.conversationInProgress);
        setPicsRequested(response.data.ownerProfile.picsRequested);
        setConversationPath(response.data.ownerProfile.conversationPath);
        setDoggyInfoSheetPath(response.data.ownerProfile.doggyInfoSheetPath || undefined);
        setProfileFeedback((response.data.ownerProfile.profileFeedback || []).map(pf => ({ ...pf, portraitImageUrl: pf.portraitImageUrl || undefined })));
        setDogProperties(response.data.ownerProfile.dogProperties);
        setDogCompatibility(response.data.ownerProfile.dogCompatibility);
        window.scrollTo(0, 0);
      } else {
        console.warn('API failure when attempting to load profile.', response.error);
      }
    }

    async function requestPics(profileId: string): Promise<boolean> {
      const response = await ProfileAPI.createOwnerPictureRequest(profileId);
      if (response.error === undefined && response.data && response.data.createOwnerPictureRequest) {
        return response.data.createOwnerPictureRequest.pictureRequested;
      } else {
        console.warn('API failure when attempting to request pictures.', response.error);
        return false;
      }
    }

    async function reportProfile(profileId: string, reportType: ReportType, explanation?: string): Promise<boolean> {
      const response = await ProfileAPI.createOwnerProfileReport(profileId, reportType, explanation);
      if (response.error === undefined && response.data && response.data.createOwnerProfileReport) {
        return response.data.createOwnerProfileReport.profileReported;
      } else {
        console.warn('API failure when attempting to report profile.', response.error);
        return false;
      }
    }

    return (
      <OwnerProfile
        profileId={profileIdParam}
        dogName={searchResult.name}
        ownerName={searchResult.ownerName}
        breed={searchResult.breed}
        breedCode={breedCode}
        direction={searchResult.direction}
        distanceInMiles={searchResult.distanceInMiles}
        imageUrls={searchResult.imageUrls}
        focusedImageIndex={focusedImageIndex}
        liked={liked}
        dogAge={dogAge}
        multipleDogs={searchResult.multipleDogs}
        premium={searchResult.premium}
        ambassador={false}
        description={searchResult.description}
        profileCreatedAtUtc={profileCreatedAtUtc}
        activityLevel={activityLevel}
        company={company}
        exercise={exercise}
        acceptingMessages={acceptingMessages}
        conversationInProgress={conversationInProgress}
        conversationPath={conversationPath}
        doggyInfoSheetPath={doggyInfoSheetPath}
        currentUserPremiumBorrower={props.currentUserPremiumBorrower}
        availability={searchResult.availability}
        profileFeedback={profileFeedback}
        dogProperties={dogProperties}
        dogCompatibility={dogCompatibility}
        picsRequested={picsRequested}
        showNavigationLinks
        previousLinkEnabled={searchResultIndex > 0}
        nextLinkEnabled={searchResultIndex < props.totalResults - 1}
        previousProfilePath={previousResultPath}
        nextProfilePath={nextResultPath}
        premiumPath={props.premiumPath}
        reportProfileOpen={reportProfileOpen}
        onProfileLiked={handleProfileLiked.bind({}, profileIdParam)}
        onProfileUnliked={handleProfileUnliked.bind({}, profileIdParam)}
        onBackLinkClick={handleBackLinkClick.bind({}, profileIdParam)}
        onNextLinkClick={handleNextResultClick}
        onPreviousLinkClick={handlePreviousResultClick}
        onPhotoRequested={handlePhotoRequested.bind({}, profileIdParam)}
        onPhotoSelected={handlePhotoSelected}
        onReportProfileOpen={handleReportProfileOpen}
        onReportProfileClose={handleReportProfileClose}
        onProfileReported={handleProfileReported.bind({}, profileIdParam)}
      />
    );
  }
  return <Navigate to='/search/dogs' replace />;
};
