import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { Form, FormSubmissionResult, TextField } from '@borrowmydoggy/form-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { nameTransformer, UserAccount } from '@borrowmydoggy/core-models';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const userAccountSchema = z.object({ firstName: UserAccount.firstName, lastName: UserAccount.lastName });
type UserAccountSchema = z.infer<typeof userAccountSchema>;

export const User7: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  function cleanString(input: string): string {
    return input.trim();
  }

  function handleSubmit(data: UserAccountSchema): FormSubmissionResult<UserAccountSchema> {
    const firstName = cleanString(data.firstName);
    const lastName = cleanString(data.lastName);
    props.record({ firstName, surname: lastName, nameConfirmed: true });
    return { outcome: 'success', formStateAction: 'halt' };
  }

  let title: string;
  if (props.manuscript.variant === 'new') {
    title = 'Welcome!';
  } else {
    title = `Welcome new ${props.manuscript.intendedProfileType == 'owner' ? 'pup' : 'borrower'}!`;
  }

  return (
    <StepContainer
      stepTitle={title}
      totalSteps={props.totalStepCount}
      currentStep={7}
      stepImageUrl={context.images['illustrations/img-person-waving.svg']}
    >
      <StepHeading text='Great! What’s your name?'/>
      <StandardStepDescription text='For safety and security, only your first name will show on your profile.' />
      <Form initialValues={{ firstName: props.manuscript.firstName, lastName: props.manuscript.surname }} schema={userAccountSchema} onSubmit={handleSubmit}>
        {({ fields, handleChange, handleValidate }) => (
          <>
            <TextField
              id='firstName'
              label='First name'
              placeholder='Will show on your profile'
              value={fields.firstName.value}
              validationState={fields.firstName.validationState}
              onValidate={handleValidate}
              onChange={handleChange}
              transformation={nameTransformer}
            />
            <TextField
              id='lastName'
              label='Last name'
              placeholder='Won’t show on your profile'
              value={fields.lastName.value}
              validationState={fields.lastName.validationState}
              onValidate={handleValidate}
              onChange={handleChange}
              transformation={nameTransformer}
            />
            <StepNavigation
              nextButton={{ text: 'Next', icon: faChevronRight, iconAlignment: 'right' }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
