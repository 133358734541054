import { gqlCommand } from '@borrowmydoggy/api-client';
import { Mutation, MutationUpdateRecommendationApprovalStateArgs } from '@borrowmydoggy/bmd-api';

export const mutations = {
  updateRecommendationApprovalState: gqlCommand<
    { updateRecommendationApprovalState: Mutation['updateRecommendationApprovalState']; },
    MutationUpdateRecommendationApprovalStateArgs
  >`
    mutation UpdateRecommendationApprovalState($recommendationCode: String!, $approved: Boolean!) {
      updateRecommendationApprovalState(recommendationCode: $recommendationCode, approved: $approved) {
        recommendationStateUpdated
        errors
      }
    }
  `
};
