import React, { FunctionComponent, ReactNode } from 'react';

export interface IPaymentSuccessfulProps {
  title: string;
  firstParagraph: string;
  secondParagraph?: string;
  forwardPath: string;
  button: string;
  backwardText?: string;
  backwardPath?: string;
}

export const PaymentSuccessful: FunctionComponent<IPaymentSuccessfulProps> = (props: IPaymentSuccessfulProps) => {
  function renderBackwardLink(): ReactNode {
    if (props.backwardText && props.backwardPath) {
      return (
        <div className='element-group centred space-above-medium'>
          <a href={props.backwardPath} className='new-link primary block'>{props.backwardText}</a>
        </div>
      );
    }
  }

  return (
    <div>
      <h1 className='page-title premium'>{props.title}</h1>
      <p className='new-paragraph centred space-above-medium'>{props.firstParagraph}</p>
      <p className='new-paragraph centred space-above-medium'>{props.secondParagraph}</p>
      <div className='element-group space-above-medium-desktop space-above-large-tablet space-above-large-mobile'>
        <a href={props.forwardPath} className='new-button primary large'>{props.button}</a>
      </div>
      {renderBackwardLink()}
    </div>
  );
}
