import React, { ChangeEvent, FormEvent, FunctionComponent, ReactNode, useState } from 'react';

import { ProfileType } from '@borrowmydoggy/core-components';
import { ContactDetailsDetectionValidator, LengthValidator, PresenceValidator } from '@borrowmydoggy/bmd-validators';

export interface IMessageSendBoxProps {
  recipientUuid: string;
  conversationUuid?: string;
  guidePath: string;
  recipientProfileType: ProfileType;
  messageSendRequestsPath: string;
  recipientIsVerified: boolean;
  children?: ReactNode;
}

interface IMessageSendData {
  recipients: string;
  conversation_message_send_request: { message_body: string; };
  id?: string;
}

export const MessageSendBox: FunctionComponent<IMessageSendBoxProps> = (props: IMessageSendBoxProps) => {
  const [messageBody, setMessageBody] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageSending, setMessageSending] = useState(false);

  function handleMessageBodyChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    setMessageBody(event.target.value);
  }

  function performValidation(): boolean {
    setErrorMessage('');

    const presenceValidator = new PresenceValidator('Please type a message before sending');
    presenceValidator.validate(messageBody);
    if (!presenceValidator.isValid) {
      setErrorMessage(presenceValidator.message);
      return false;
    }

    const lengthValidator = new LengthValidator('Your message has exceeded the maximum length (5000 characters).', { max: 5000 });
    lengthValidator.validate(messageBody);
    if (!lengthValidator.isValid) {
      setErrorMessage(lengthValidator.message);
      return false;
    }

    if (!props.recipientIsVerified) {
      const contactDetailsValidator = new ContactDetailsDetectionValidator(
        'This member is unverified. For your own safety please remove any contact details or references to other websites.'
      );
      contactDetailsValidator.validate(messageBody);
      if (!contactDetailsValidator.isValid) {
        setErrorMessage(contactDetailsValidator.message);
      }
      return contactDetailsValidator.isValid;
    } else {
      return true;
    }
  }

  async function handleFormSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    setMessageSending(true);

    event.preventDefault();
    event.stopPropagation();

    const formValid = performValidation();

    if (formValid) {
      let csrfToken = '';
      const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
      if (csrfTokenElement) {
        const attribute = csrfTokenElement.attributes.getNamedItem('content');
        if (attribute) {
          csrfToken = attribute.value;
        }
      }

      setErrorMessage('');
      try {
        const body: IMessageSendData = {
          recipients: props.recipientUuid,
          conversation_message_send_request: { message_body: messageBody }
        };
        if (props.conversationUuid !== undefined) {
          body.id = props.conversationUuid;
        }
        const response = await fetch(props.messageSendRequestsPath, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrfToken },
          body: JSON.stringify(body)
        });
        const json = await response.json();
        if (json.status && json.status === 'success') {
          setMessageBody('');
          setMessageSending(false);
          const conversationUuid = json['conversation_uuid'];
          const showBorrowingInfoPopup = json['show_borrowing_info_popup'] || false;
          document.dispatchEvent(new CustomEvent('conversation:messageAdded', { detail: conversationUuid }));
          if (showBorrowingInfoPopup){
            document.dispatchEvent(new Event('conversation:borrowingInfoPopupRequired'));
          }
        } else {
          if (json.errors && json.errors['message_body']) {
            setErrorMessage(json.errors['message_body']);
          } else {
            setErrorMessage('Sorry, there was a problem sending your message.');
          }
          setMessageSending(false);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('Sorry, there was a problem processing your message.');
        setMessageSending(false);
      }
    } else {
      setMessageSending(false);
    }
  }

  function renderErrorMessage(): ReactNode {
    return <label className='input_error'>{errorMessage}</label>;
  }

  return (
    <form className='new_conversation_message_send_request' onSubmit={handleFormSubmit}>
      <input type='hidden' name='recipients' id='recipients' value={props.recipientUuid} />
      {props.conversationUuid !== undefined && <input type='hidden' name='id' id='id' value={props.conversationUuid} />}
      <div className='form-row'>
        <div className='form-row__field'>
          <textarea
            placeholder='Your message here...'
            className={`legacy${errorMessage.length > 0 ? ' input_error' : ''}`}
            name='conversation_message_send_request[message_body]'
            id='conversation_message_send_request_message_body'
            value={messageBody}
            onChange={handleMessageBodyChange}
          />
          {errorMessage.length > 0 && renderErrorMessage()}
        </div>
      </div>
      <div className='form-row form-row_submit'>
        <span className='conversation__message-form-help-link'>
          Lost for words? Read our{' '}
          <a className='link_standard-green' target='_blank' rel='noreferrer' href={props.guidePath}>
            guide to messaging {props.recipientProfileType}s
          </a>
        </span>
        <button
          className={`button button_primary button_default button_block button_send-message${messageSending ? ' loading' : ''}`}
          disabled={messageSending}
        >
          Send your message
        </button>
      </div>
    </form>
  );
};
