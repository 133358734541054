import { CommandResultPromise } from '@borrowmydoggy/api-client';

import { ClientBase } from '../ClientBase';
import { mutations } from './mutations';

export class RecommendationAPI extends ClientBase {
  public static updateRecommendationApprovalState(
    recommendationCode: string,
    approved: boolean
  ): CommandResultPromise<typeof mutations.updateRecommendationApprovalState> {
    return this.client.mutation({
      command: mutations.updateRecommendationApprovalState,
      args: { recommendationCode, approved }
    });
  }
}
