import React, { useContext } from 'react';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';

import { DecisionStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { navigateWithPost } from '../utils';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

import { UEnhancedButtonProps } from '@borrowmydoggy/product-components';

export const User4A: StepComponent<IUserAccountRegistrationManuscript> = (
	props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
	const context = useContext(WebContext);

	function handleDecisionClick(authenticationType: 'apple' | 'facebook' | 'password'): void {
		props.record({ desiredAuthenticationType: authenticationType });
		if (authenticationType === 'apple' || authenticationType === 'facebook') {
			let destinationUrl: string;
			if (authenticationType === 'facebook') {
				destinationUrl = `/auth/facebook?profile_type=${props.manuscript.intendedProfileType || 'borrower'}`;
			} else {
				destinationUrl = `/auth/apple?profile_type=${props.manuscript.intendedProfileType || 'borrower'}`;
			}
			navigateWithPost(destinationUrl);
		} else {
			props.record({ authenticationProviderConfirmed: true });
		}
	}

	let title: string;
	if (props.manuscript.variant === 'new') {
		title = `Check out local ${props.manuscript.intendedProfileType == 'owner' ? 'borrowers' : 'dogs'} for free`;
	} else {
		title = `Welcome new ${props.manuscript.intendedProfileType == 'owner' ? 'pup' : 'borrower'}!`;
	}

	const buttons: UEnhancedButtonProps<'apple' | 'facebook' | 'password'>[] = [
		{
			id: 'password',
			title: 'Use your email and a password to sign in',
			buttonText: 'Sign up with email',
			buttonIcon: faEnvelope,
			illustrationURL: context.images['illustrations/img-person-holding-envelope.svg']
		},
		{
			id: 'apple',
			title: 'Use your Apple ID to sign in with no extra passwords',
			buttonText: 'Sign up with Apple',
			buttonType: 'apple',
			illustrationURL: context.images['illustrations/img-person-with-iphone.svg']
		}
	];

	// If the user is in the control group, show the Facebook button
	if (props.manuscript.fbAuthVariant === 'control') {
		const facebookButton: UEnhancedButtonProps<'apple' | 'facebook' | 'password'> = {
			id: 'facebook',
			title: 'Use Facebook to sign in with no extra passwords',
			buttonText: 'Sign up with Facebook',
			buttonType: 'facebook',
			illustrationURL: context.images['illustrations/img-person-with-facebook.svg']
		};

		// Insert the Facebook button after the first button to maintain the control order
		buttons.splice(1, 0, facebookButton);
	}

	return (
		<DecisionStep
			stepTitle={title}
			stepHeading='How would you like to sign up?'
			totalSteps={props.totalStepCount}
			currentStep={4}
			buttons={buttons}
			onDecisionClick={handleDecisionClick}
		>
			<StandardStepDescription
				text='This is how you’ll sign in to BorrowMyDoggy'
				inError={props.manuscript.appleSignInStatus === 'failed' || props.manuscript.facebookSignInStatus === 'failed'}
			/>
		</DecisionStep>
	);
};
