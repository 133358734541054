import { DogGender } from './DogGender';

interface IParsedDogGender {
  genderedPronoun: string;
  genderedPossessive: string;
  genderedObjectivePronoun: string;
}

export function parseDogGender(dogGender: DogGender): IParsedDogGender {
  return {
    genderedPronoun: dogGender === 'female' ? 'she' : 'he',
    genderedPossessive: dogGender === 'female' ? 'her' : 'his',
    genderedObjectivePronoun: dogGender === 'female' ? 'her' : 'him'
  };
}
