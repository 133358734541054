import React, { FunctionComponent, ReactNode, useState } from 'react';
import { faApple, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { z } from 'zod';

import { Form, FormSubmissionResult, PasswordField } from '@borrowmydoggy/form-components';
import { PositiveMessageBanner } from '@borrowmydoggy/product-components';
import { Icon, PageHeading, Paragraph, responsiveQuery, SmallText, TextButton } from '@borrowmydoggy/core-components';
import { StepDescription, StepHeading, StepPanel } from '@borrowmydoggy/step-components';

import { AuthenticationProviderType } from '../userAccount';
import { UserAPI } from '../api';

export interface ISecurityProps {
  initialAuthenticationProviderType: AuthenticationProviderType;
  emailAddress: string;
}

const SecurityContainer = styled.div`
`;

const StyledPageHeading = styled(PageHeading)`
  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    padding-left: 20px;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 3rem;
`;

const PasswordIcon = styled(StyledIcon)`
`;

const FacebookIcon = styled(StyledIcon)`
  color: ${props => props.theme.thirdParty.facebook.mid};
`;

const AppleIcon = styled(StyledIcon)`
  color: ${props => props.theme.thirdParty.apple};
`;

const Introduction = styled.p`
  font-size: 1.6rem;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  gap: 10px;

  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    margin-left: 20px;
    grid-template-columns: 30px 1fr;
    align-items: center;
  }

  ${responsiveQuery('mobilePortrait')} {
    grid-template-rows: 30px 1fr;
    justify-items: center;
    &>span { text-align: center };
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Panel = styled(StepPanel)`
  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    margin-left: 20px;
  }
`;

const StyledSuccessBanner = styled(PositiveMessageBanner)`
  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    margin-left: 20px;
  }

  ${responsiveQuery('mobilePortrait')} {
    margin-left: 10px;
  }
`;

const disconnectSchema = z.object({
  newPassword: z.string({ required_error: 'Please type your new password here' }).min(10, 'Keep typing! You need at least 10 characters')
});
type DisconnectFormSchema = z.infer<typeof disconnectSchema>;

const updatePasswordSchema = z.object({
  currentPassword: z.string({ required_error: 'Please type your current password here' }).min(6, 'Keep typing! You need at least 6 characters'),
  newPassword: z.string({ required_error: 'Please type your new password here' }).min(10, 'Keep typing! You need at least 10 characters')
});
type UpdatePasswordFormSchema = z.infer<typeof updatePasswordSchema>;

export const Security: FunctionComponent<ISecurityProps> = (props: ISecurityProps) => {
  const [successBannerMessage, setSuccessBannerMessage] = useState('');
  const [authenticationProviderType, setAuthenticationProviderType] = useState(props.initialAuthenticationProviderType);

  async function handlePasswordChangeSubmit(data: UpdatePasswordFormSchema): Promise<FormSubmissionResult<UpdatePasswordFormSchema>> {
    setSuccessBannerMessage('');
    const currentPassword = data['currentPassword'].toString() || '';
    const newPassword = data['newPassword'].toString() || '';
    const response = await UserAPI.updateUserAccountPassword(currentPassword, newPassword);
    if (response.error === undefined && response.data && response.data.updateUserAccountPassword) {
      if (response.data.updateUserAccountPassword.successful) {
        setSuccessBannerMessage('You have successfully changed your password');
        return { outcome: 'success', formStateAction: 'initialise' };
      } else if (response.data.updateUserAccountPassword.errors && response.data.updateUserAccountPassword.errors.length > 0) {
        return { outcome: 'failure', errors: { currentPassword: 'This does not match the password we have for you, please try again' } };
      } else {
        return { outcome: 'error', errorMessage: 'Hmm, that didn’t work, please try again' };
      }
    } else {
      return { outcome: 'error', errorMessage: 'Hmm, that didn’t work, please try again' };
    }
  }

  async function handleDisconnectFacebookSubmit(data: DisconnectFormSchema): Promise<FormSubmissionResult<DisconnectFormSchema>> {
    setSuccessBannerMessage('');
    const newPassword = data['newPassword'].toString() || '';
    const response = await UserAPI.disconnectUserAccountFromFacebook(newPassword);
    if (
      response.error === undefined
      && response.data
      && response.data.disconnectUserAccountFromFacebook
      && response.data.disconnectUserAccountFromFacebook.successful
    ) {
      setSuccessBannerMessage('You have successfully disconnected your BorrowMyDoggy account from Facebook');
      setAuthenticationProviderType('password');
      return { outcome: 'success', formStateAction: 'initialise' };
    } else {
      return { outcome: 'error', errorMessage: 'Hmm, that didn’t work, please try again' };
    }
  }

  async function handleDisconnectAppleSubmit(data: DisconnectFormSchema): Promise<FormSubmissionResult<DisconnectFormSchema>> {
    setSuccessBannerMessage('');
    const newPassword = data['newPassword'].toString() || '';
    const response = await UserAPI.disconnectUserAccountFromApple(newPassword);
    if (
      response.error === undefined
      && response.data
      && response.data.disconnectUserAccountFromApple
      && response.data.disconnectUserAccountFromApple.successful
    ) {
      setSuccessBannerMessage('You have successfully disconnected your BorrowMyDoggy account from Apple');
      setAuthenticationProviderType('password');
      return { outcome: 'success', formStateAction: 'initialise' };
    } else {
      return { outcome: 'error', errorMessage: 'Hmm, that didn’t work, please try again' };
    }
  }

  function renderSuccessBanner(): ReactNode {
    return <StyledSuccessBanner>{successBannerMessage}</StyledSuccessBanner>;
  }

  function renderIntroduction(): ReactNode {
    let icon: ReactNode, paragraph: string;
    switch (authenticationProviderType) {
      case 'facebook':
        icon = <FacebookIcon definition={faFacebook} />;
        paragraph = 'You sign in to BorrowMyDoggy using your Facebook account';
        break;
      case 'apple':
        icon = <AppleIcon definition={faApple} />;
        paragraph = 'You sign in to BorrowMyDoggy using your Apple account';
        break;
      case 'password':
        icon = <PasswordIcon definition={faEnvelopeOpenText} />;
        paragraph = `You sign in to BorrowMyDoggy using your email address  (${props.emailAddress}) and password`;
    }
    return <Introduction>{icon}<span>{paragraph}</span></Introduction>;
  }

  function renderForm(): ReactNode {
    switch (authenticationProviderType) {
      case 'facebook':
        return (
          <Form
            schema={disconnectSchema}
            onSubmit={handleDisconnectFacebookSubmit}
          >
            {({ fields, handleChange, handleValidate, submitButtonStatus }) => (
              <>
                <StepHeading text='Disconnect from Facebook' />
                <StepDescription>
                  <Paragraph>
                    <SmallText>
                      To disconnect your account from Facebook please enter a password,{' '}
                      which you will use with your email address ({props.emailAddress}) to sign in to BorrowMyDoggy.
                    </SmallText>
                  </Paragraph>
                </StepDescription>
                <PasswordField
                  id='newPassword'
                  label='New password'
                  value={fields.newPassword.value}
                  validationState={fields.newPassword.validationState}
                  onChange={handleChange}
                  onValidate={handleValidate}
                  assistanceContent={<p key=''>The longer the better, minimum 10 characters</p>}
                />
                <TextButton
                  text='Disconnect'
                  disabled={submitButtonStatus === 'inProgress'}
                />
              </>
            )}
          </Form>
        );
      case 'apple':
        return (
          <Form
            schema={disconnectSchema}
            onSubmit={handleDisconnectAppleSubmit}
          >
            {({ fields, handleChange, handleValidate, submitButtonStatus }) => (
              <>
                <StepHeading text='Disconnect from Apple' />
                <StepDescription>
                  <Paragraph>
                    <SmallText>
                      To disconnect your account from Apple please enter a password,{' '}
                      which you will use with your email address ({props.emailAddress}) to sign in to BorrowMyDoggy.
                    </SmallText>
                  </Paragraph>
                </StepDescription>
                <PasswordField
                  id='newPassword'
                  label='New password'
                  value={fields.newPassword.value}
                  validationState={fields.newPassword.validationState}
                  onChange={handleChange}
                  onValidate={handleValidate}
                  assistanceContent={[<p key=''>The longer the better, minimum 10 characters</p>]}
                />
                <TextButton
                  text='Disconnect'
                  disabled={submitButtonStatus === 'inProgress'}
                />
              </>
            )}
          </Form>
        );
      case 'password':
      default:
        return (
          <Form
            schema={updatePasswordSchema}
            onSubmit={handlePasswordChangeSubmit}
          >
            {({ fields, handleChange, handleValidate, submitButtonStatus }) => (
              <>
                <StepHeading text='Change password' />
                <StepDescription>
                  <Paragraph>
                    <SmallText>The longer the better, minimum 10 characters</SmallText>
                  </Paragraph>
                </StepDescription>
                <PasswordField
                  id='currentPassword'
                  label='Current password'
                  value={fields.currentPassword.value}
                  validationState={fields.currentPassword.validationState}
                  onChange={handleChange}
                  onValidate={handleValidate}
                />
                <PasswordField
                  id='newPassword'
                  label='New password'
                  value={fields.newPassword.value}
                  validationState={fields.newPassword.validationState}
                  onChange={handleChange}
                  onValidate={handleValidate}
                />
                <TextButton
                  text='Change password'
                  disabled={submitButtonStatus === 'inProgress'}
                />
              </>
            )}
          </Form>
        );
    }
  }

  return (
    <SecurityContainer>
      <StyledPageHeading>Security</StyledPageHeading>
      {successBannerMessage.length > 0 && renderSuccessBanner()}
      {renderIntroduction()}
      <Panel width='standard'>
        {renderForm()}
      </Panel>
    </SecurityContainer>
  );
};
