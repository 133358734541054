import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Big } from 'big.js';
import { faChevronRight, faCrosshairs } from '@fortawesome/pro-solid-svg-icons';

import { CountryCodes } from '@borrowmydoggy/core-models';
import { LocationMap } from '@borrowmydoggy/product-components';
import { LoadingIndicator, PageHeading, Paragraph, responsiveQuery, SmallText, VerticalIconTextButton } from '@borrowmydoggy/core-components';
import {
  IStepComponentProps,
  StandardStepDescription,
  StepComponent,
  StepHeading,
  StepPanel,
  StepNavigation
} from '@borrowmydoggy/step-components';
import { ModalBinaryPanel } from '@borrowmydoggy/account-components';
import { ResultList, TextField } from '@borrowmydoggy/form-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const Container = styled.main`
  display: grid;
  grid-template-rows: max-content 1fr;
`;

const Contents = styled.div`
  width: 100%;
  max-width: 1110px;
  display: grid;
  grid-template-rows auto 1fr;
  margin: 0 auto;
`;

const StepTitle = styled(PageHeading)`
  &:focus {
    border: none;
    outline: none;
  }

  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait')} {
    padding: 50px 10px 30px 10px;
  }

  ${responsiveQuery('mobileLandscape', 'mobilePortrait')} {
    padding: 30px 0 20px 0;
  }
`;

const InnerContainer = styled.div`
  position: relative;

  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait', 'mobileLandscape')} {
    margin: 0 30px;
    height: 700px;
  }
`;

const Panel = styled(StepPanel)`
  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait', 'mobileLandscape')} {
    position: absolute;
    top: 0;
    left: calc(50% - 450px / 2);
    z-index: 1;
  }

  ${responsiveQuery('mobilePortrait')} {
  }
`;

const InteractionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 9.6rem;
  gap: 20px;
  align-items: start;
`;

const StyledTextField = styled(TextField)`
  &>*:last-child { display: none; }
`;

const OrText = styled(SmallText)`
  align-self: center;
`;

const AutocompleteContainer = styled.div`
  margin-top: 30px;
`;

const ResultLoadingIndicator = styled(LoadingIndicator)`
  width: 80px;
`;

const Map = styled(LocationMap)`
  max-width: 1110px;

  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait', 'mobileLandscape')} {
    height: 550px;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 120px;
    bottom: 30px;
    z-index: 0;
  }

  ${responsiveQuery('mobilePortrait')} {
    height: 400px;
    margin-bottom: 30px;
  }
`;

const ResultContainer = styled.div`
  margin-bottom: 10px;
`;

const BoldText = styled(SmallText)`
  font-weight: bold;
`;

const NoResultContainer = styled.div`
  border-top: 1px solid ${props => props.theme.base};
  padding-top: 10px;
  margin-bottom: 15px;
`;

const NoResultMessage = styled(SmallText)`
  color: ${props => props.theme.negative.mid};
`;

interface ILocation {
  description: string;
  latitude: Big;
  longitude: Big;
  postcode?: string;
  country: string;
}

interface IUnsupportedLocation { // TODO same as IInternationalInterestDetails
  city: string;
  region: string;
  countryIsoCode: typeof CountryCodes[number];
}

const searchPhraseDelayInMs = 500;

type GeolocationStatus = 'pending' | 'successful' | 'notPermitted' | 'error';

export const User9A: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  const titleRef = useRef<HTMLHeadingElement>(null);

  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchPhraseTimer, setSearchPhraseTimer] = useState<number>();
  const [loadingResults, setLoadingResults] = useState(false);
  const [searchAutocompleteResults, setSearchAutocompleteResults] = useState<mapkit.SearchAutocompleteResult[] | undefined>();
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [location, setLocation] = useState<ILocation>();
  const [unsupportedLocation, setUnsupportedLocation] = useState<IUnsupportedLocation>();
  const [geolocationStatus, setGeolocationStatus] = useState<GeolocationStatus>('pending');
  const [abortModalShown, setAbortModalShown] = useState(false);

  function focusTitle(): void {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }

  useEffect(() => {
    focusTitle();
    return () => {
      clearTimeout(searchPhraseTimer);
    };
  }, []);

  async function raiseTextSearchRequest(text: string): Promise<void> {
    setSearchPhraseTimer(undefined);
    const search = new window.mapkit.Search({
      language: 'en-GB',
      includePointsOfInterest: false,
      includeAddresses: true,
      includeQueries: false,
      limitToCountries: 'gb,ie',
      region: new window.mapkit.CoordinateRegion(new mapkit.Coordinate(55, -4), new mapkit.CoordinateSpan(11, 15))
    });
    search.autocomplete(text, (error: Error | null, data: mapkit.SearchAutocompleteResponse) => {
      setSearchPerformed(true);
      if (error) {
        console.warn(error);
      } else {
        setSearchAutocompleteResults(data.results);
        setLoadingResults(false);
      }
    });
  }

  async function raiseCoordinatesSearchRequest(lat: number, long: number): Promise<void> {
    const geocoder = new window.mapkit.Geocoder({ language: 'en-GB' });
    geocoder.reverseLookup(
      new mapkit.Coordinate(lat, long),
      (error: Error | null, data: mapkit.GeocoderResponse) => {
        setLoadingResults(false);
        setSearchPerformed(true);
        if (error || data.results.length < 1) {
          setGeolocationStatus('error');
          if (error) {
            console.warn(error.message);
          }
        } else {
          setGeolocationStatus('successful');
          const firstResult = data.results[0];
          if (firstResult.countryCode === 'GB' || firstResult.countryCode === 'IE') {
            const newLocation: ILocation = {
              latitude: Big(firstResult.coordinate.latitude),
              longitude: Big(firstResult.coordinate.longitude),
              description: firstResult.formattedAddress,
              country: firstResult.countryCode,
              postcode: firstResult.postCode
            };
            setLocation(newLocation);
            setUnsupportedLocation(undefined);
            setSearchPhrase(firstResult.formattedAddress);
            focusTitle();
          } else {
            const newUnsupportedLocation: IUnsupportedLocation = {
              city: firstResult.locality || 'Unknown',
              region: firstResult.subLocality || 'Unknown',
              countryIsoCode: firstResult.countryCode as typeof CountryCodes[number]
            };
            setLocation(undefined);
            setUnsupportedLocation(newUnsupportedLocation);
          }
        }
      }
    );
  }

  function handleChange(_id: string, newValue: string): void {
    clearTimeout(searchPhraseTimer);
    setSearchPhrase(newValue);
    if (newValue.length > 1) {
      setLoadingResults(true);
      const newTimer = window.setTimeout(() => raiseTextSearchRequest(newValue), searchPhraseDelayInMs);
      setSearchPhraseTimer(newTimer);
    } else {
      setLoadingResults(false);
      setSearchAutocompleteResults(undefined);
    }
  }

  function handleResultClick(resultId: string): void {
    const resultIndex = parseInt(resultId, 10);
    if (searchAutocompleteResults && resultIndex >= 0 && resultIndex < searchAutocompleteResults.length) {
      const search = new window.mapkit.Search({ language: 'en-GB', includePointsOfInterest: false });
      search.search(searchAutocompleteResults[resultIndex], (error: Error | null, data) => {
        if (error || data.places.length < 1) {
          console.error(error);
        } else {
          const firstResult = data.places[0];
          setLocation({
            latitude: Big(firstResult.coordinate.latitude),
            longitude: Big(firstResult.coordinate.longitude),
            description: firstResult.formattedAddress,
            country: firstResult.countryCode,
            postcode: firstResult.postCode
          });
          setUnsupportedLocation(undefined);
          focusTitle();
        }
      });
    }
  }

  function handleLocationConfirmation(): void {
    if (location) {
      props.record({
        searchPhrase,
        ...location,
        residentOfEligibleCountryConfirmed: true
      });
    }
  }

  function handleFindMeClick(): void {
    setLoadingResults(true);
    setGeolocationStatus('pending');
    navigator.geolocation.getCurrentPosition(position => {
      raiseCoordinatesSearchRequest(position.coords.latitude, position.coords.longitude);
    }, () => {
      setLoadingResults(false);
      setGeolocationStatus('notPermitted');
    });
  }

  function handleLetMeKnowClick(): void {
    props.record({
      residentOfEligibleCountryConfirmed: false,
      internationalInterestStatus: 'pending',
      internationalInterestDetails: unsupportedLocation
    });
  }

  function handleDeleteDetailsClick(): void {
    setAbortModalShown(true);
  }

  function handleAbortModalNoClick(): void {
    setAbortModalShown(false);
  }

  function clearSearch(): void {
    setSearchPhrase('');
    clearTimeout(searchPhraseTimer);
    setSearchPhraseTimer(undefined);
    setLoadingResults(false);
    setSearchAutocompleteResults(undefined);
    setSearchPerformed(false);
    setLocation(undefined);
    setUnsupportedLocation(undefined);
    setGeolocationStatus('pending');
  }

  function renderResults(): ReactNode {
    if (loadingResults) {
      return <AutocompleteContainer><ResultLoadingIndicator /></AutocompleteContainer>;
    }
    if (searchAutocompleteResults || (searchPerformed && geolocationStatus !== 'error')) {
      if (searchAutocompleteResults && searchAutocompleteResults.length > 0) {
        return (
          <AutocompleteContainer>
            <ResultList
              items={searchAutocompleteResults.slice(0, 5).map((result, index) => ({ id: index.toString(), text: result.displayLines.join(', ') }))}
              onItemSelected={handleResultClick}
            />
          </AutocompleteContainer>
        );
      } else {
        return (
          <>
            <NoResultContainer>
              <Paragraph><NoResultMessage>No results found</NoResultMessage></Paragraph>
              <Paragraph>
                <SmallText>
                  We&rsquo;re only in the UK and Ireland at the moment.{' '}
                  Would you like us to save your contact details so that we can let you know if BorrowMyDoggy comes to your area?
                </SmallText>
              </Paragraph>
            </NoResultContainer>
            <StepNavigation
              nextButton={{
                text: 'Let me know',
                icon: faChevronRight,
                iconAlignment: 'right'
              }}
              backButton={{
                text: 'Delete my details'
              }}
              onNextClick={handleLetMeKnowClick}
              onBackClick={handleDeleteDetailsClick}
            />
          </>
        );
      }
    }
    return <></>;
  }

  function renderPanelContent(): ReactNode {
    if (location) {
      return (
        <>
          <ResultContainer>
            <Paragraph>
              <SmallText>Confirm that this is where you&rsquo;d like to search, don&rsquo;t worry if the door number is not quite right.</SmallText>
            </Paragraph>
            <Paragraph><BoldText>{location.description}</BoldText></Paragraph>
          </ResultContainer>
          <StepNavigation
            nextButton={{
              text: 'Set location',
              icon: faChevronRight,
              iconAlignment: 'right'
            }}
            backButton={{
              text: 'Search again'
            }}
            onNextClick={handleLocationConfirmation}
            onBackClick={clearSearch}
          />
        </>
      );
    }
    let errorText: string | undefined;
    if (geolocationStatus === 'notPermitted') {
      errorText = 'We need your permission to find your location.';
    } else if (geolocationStatus === 'error') {
      errorText = 'Sorry, we were unable to get your location, please try again';
    }
    let description: string;
    if (props.manuscript.variant === 'new') {
      description = 'We won’t share your exact location with other members.';
    } else {
      description = 'This helps us find your local matches. We won’t share your exact location with other members.';
    }
    return (
      <>
        <StandardStepDescription
          text={description}
          inError={geolocationStatus === 'notPermitted' || geolocationStatus === 'error'}
          errorText={errorText}
        />
        <InteractionContainer>
          <StyledTextField
            id='searchPhrase'
            label='Search location'
            placeholder='Postcode is best'
            value={searchPhrase}
            validationState={{ valid: true, status: 'valid' }}
            onChange={handleChange}
          />
          <OrText>OR</OrText>
          <VerticalIconTextButton text='Find me' icon={faCrosshairs} onClick={handleFindMeClick} />
        </InteractionContainer>
        {renderResults()}
      </>
    );
  }

  let title: string;
  if (props.manuscript.variant === 'new') {
    title = 'Set search area';
  } else {
    title = `Welcome new ${props.manuscript.intendedProfileType == 'owner' ? 'pup' : 'borrower'}!`;
  }

  return (
    <Container>
      <Contents>
        <StepTitle tabIndex={-1} ref={titleRef}>{title}</StepTitle>
        <InnerContainer>
          <Panel>
            <StepHeading text='Where are you based?' />
            {renderPanelContent()}
          </Panel>
          <Map
            coordinates={location ? { latitude: location.latitude.toNumber(), longitude: location.longitude.toNumber() } : undefined }
            mapkitJsToken={context.mapkitJsToken}
          />
        </InnerContainer>
      </Contents>
      {abortModalShown && <ModalBinaryPanel
        title='Are you sure?'
        description='This will cancel your BorrowMyDoggy account creation and delete all of the information we have for you.'
        yesHref='/'
        onNoClick={handleAbortModalNoClick}
      />}
    </Container>
  );
};
