import React, { FunctionComponent, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { Icon } from '@borrowmydoggy/core-components';

export interface IToggleInputProps {
	id: string;
	checked: boolean;
	onChange?: (checked: boolean) => void;
}

interface ICheckedProps {
	checked: boolean;
}

const StyledToggleInput = styled.span<ICheckedProps>`
	&>input {
		/* appearance: none; */
		visibility: hidden;
	}

	display: inline-block;
	width: 75px;
	height: 32px;
	position: relative;
	border-radius: 100px;
	padding: 2px;
	cursor: pointer;

	&>.text, &>.icon {
		display: block;
		position: absolute;
	}

	&>.text {
		top: 2px;
		right: 2px;
		bottom: 2px;
		left: 2px;
		background-color: ${props => props.theme.neutral.mid};
		border-radius: 60px;
		text-align: ${props => props.checked ? 'left' : 'right'};
		font-family: Lato, sans-serif;
		font-weight: bold;
		font-size: 16px;
		padding-top: 5px;
		padding-${props => props.checked ? 'left' : 'right'}: 10px;
	}

	&>.icon {
		top: 4px;
		bottom: 4px;
		left: 0;
		margin-left: ${props => props.checked ? '47px' : '4px'};
		width: 28px;
		height: 28px;
		border-radius: 28px;
		background-color: ${props => props.theme.base};
		color: ${props => props.checked ? props.theme.primary.mid : props.theme.negative.mid};
		font-size: 16px;
		display: grid;
		align-content: center;
		justify-content: center;
		transition: margin 0.4s;
	}
`;

export const ToggleInput: FunctionComponent<IToggleInputProps> = (props: IToggleInputProps) => {
	function handleClick(event: MouseEvent<HTMLSpanElement>): void {
		event.preventDefault();
		event.stopPropagation();
		if (props.onChange) {
			props.onChange(!props.checked);
		}
	}

	function renderText(checked: boolean): ReactNode {
		return <span className='text'>{checked ? 'Yes' : 'No'}</span>;
	}

	function renderIcon(checked: boolean): ReactNode {
		return <Icon definition={checked ? faCheck : faTimes} />;
	}

	return (
		<StyledToggleInput checked={props.checked} onClick={handleClick}>
			<input type='checkbox' id={props.id} checked={props.checked} readOnly />
			{renderText(props.checked)}
			<span className='icon'>
				{renderIcon(props.checked)}
			</span>
		</StyledToggleInput>
	);
};
