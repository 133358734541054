import { CommandResultPromise } from '@borrowmydoggy/api-client';

import { ClientBase } from '../ClientBase';
import { mutations } from './mutations';
import { queries } from './queries';

import { buildUserAccountDetails } from './adapters';
import { UserAccountRegistrationDetails } from '../../schemas';

export class RegistrationAPI extends ClientBase {
  public static emailAddressAcceptability(emailAddress: string): CommandResultPromise<typeof queries.emailAddressAcceptability> {
    return this.client.query({
      command: queries.emailAddressAcceptability,
      args: { emailAddress }
    });
  }

  public static createUserAccount(userAccountDetails: UserAccountRegistrationDetails): CommandResultPromise<typeof mutations.createUserAccount> {
    return this.client.mutation({
      command: mutations.createUserAccount,
      args: { userAccountDetails: buildUserAccountDetails(userAccountDetails) }
    });
  }

  public static createInternationalInterest(
    firstName: string,
    surname: string,
    emailAddress: string,
    city: string,
    region: string,
    countryIsoCode: string
  ): CommandResultPromise<typeof mutations.createInternationalInterest> {
    return this.client.mutation({
      command: mutations.createInternationalInterest,
      args: { internationalInterest: { firstName, surname, emailAddress, city, region, countryIsoCode } }
    });
  }
}
