import copy from 'copy-to-clipboard';
import React, { ErrorInfo, FunctionComponent, ReactNode, useState } from 'react';
import styled  from 'styled-components';
import { faClipboard, faRedo } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import { DisclosurePanel, IconButton, IconTextButton, responsiveQuery, SectionHeading } from '@borrowmydoggy/core-components';

import { readReactEnvironment } from './Environment';

export interface IMajorErrorFallbackProps {
  error: Error;
  info: ErrorInfo;
}

const StyledErrorPage = styled.div`
  display: grid;
  max-width: 100vw;

  &>.explanation { grid-area: explanation; }
  &>.graphic { grid-area: graphic; }
  &>.details { grid-area: details; }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    max-width: 600px;
    margin: 70px auto;
    grid-template-columns: 1fr 218px;
    grid-template-areas:    'explanation graphic'
                            'details details';
    grid-gap: 30px 15px;
    &>.explanation {
      align-self: center;
      text-align: center;
      &>p { font-size: 1.6rem; }
    }
  }

  ${responsiveQuery('mobilePortrait')} {
    margin: 50px 0;
    grid-template-columns: 1fr;
    grid-template-areas:    'graphic'
                            'explanation'
                            'details';
    grid-gap: 25px;
    &>.explanation {
      padding: 0 10px;
      align-self: center;
      text-align: center;
      &>p { font-size: 1.6rem; }
    }
    &>.graphic {
      padding: 0 10px;
      width: 218px;
      justify-self: center;
    }
  }
`;

const StyledSectionHeading = styled(SectionHeading)`
  text-align: center;
`;

const StyledButton = styled(IconTextButton)`
  margin: 15px auto 0 auto;
`;

const StyledDisclosurePanel = styled(DisclosurePanel)`
  ${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
    header {
      padding: 0 20px;
    }
  }
  ${responsiveQuery('mobilePortrait')} {
    header {
      padding: 0 10px;
    }
  }
`;

const StyledCode = styled.pre`
  font-size: 1rem;
  line-height: unset;
  overflow: scroll;

  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    max-width: 560px;
  }
  ${responsiveQuery('mobilePortrait')} {
    max-width: calc(100vw - 40px);
  }
`;

export const MajorErrorFallback: FunctionComponent<IMajorErrorFallbackProps> = (props: IMajorErrorFallbackProps) => {
  const [open, setOpen] = useState(false);
  const environment = readReactEnvironment();

  function handleOpen(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleCopyClick(): void {
    const formattedErrorText = `${props.error.message}\n\n${props.error.stack}`;
    copy(formattedErrorText);
  }

  function handleReloadClick(): void {
    window.location.reload();
  }

  function renderLargeError(error: Error): ReactNode {
    return (
      <StyledErrorPage>
        <div className='explanation'>
          <StyledSectionHeading>Hmm, that&rsquo;s not quite right...</StyledSectionHeading>
          <p>If this keeps happening please call the helpdesk on 020 3826 8628.</p>
          <StyledButton
            icon={faRedo}
            text='Reload page'
            onClick={handleReloadClick}
          />
        </div>
        <div className='graphic'>
          <img src={environment.errorImage} />
        </div>
        <StyledDisclosurePanel
          icon={faInfoCircle}
          title='Details'
          openLinkText='more info'
          closeLinkText='close'
          open={open}
          className='details'
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <p>Error: {error.message}</p>
          <StyledCode>{error.stack}</StyledCode>
          <IconButton
            altText='Copy error info'
            type='soft'
            icon={faClipboard}
            onClick={handleCopyClick}
            className='copyButton'
          />
        </StyledDisclosurePanel>
      </StyledErrorPage>
    );
  }

  return <>{renderLargeError(props.error)}</>;
};
