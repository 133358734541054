import { StepComponent } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationStep } from './BorrowerProfileCreationConductor';

import { ExitPrompt } from './ExitPrompt';
import { Borrower2 } from './Borrower2';
import { Borrower3 } from './Borrower3';
import { Borrower4 } from './Borrower4';
import { Borrower5 } from './Borrower5';
import { Borrower6 } from './Borrower6';
import { Borrower7 } from './Borrower7';
import { Borrower8 } from './Borrower8';
import { Borrower9A } from './Borrower9A';
import { Borrower9B } from './Borrower9B';
import { BorrowerError } from './BorrowerError';
import { SwitchPrompt } from './SwitchPrompt';

export * from './IBorrowerProfileCreationManuscript';
export * from './BorrowerProfileCreationConductor';

export const BorrowerProfileCreationStepMap: Record<BorrowerProfileCreationStep, StepComponent<IBorrowerProfileCreationManuscript>> = {
  borrower2: Borrower2,
  borrower3: Borrower3,
  borrower4: Borrower4,
  borrower5: Borrower5,
  borrower6: Borrower6,
  borrower7: Borrower7,
  borrower8: Borrower8,
  borrower9a: Borrower9A,
  borrower9b: Borrower9B,
  borrowerError: BorrowerError,
  exitPrompt: ExitPrompt,
  switchPrompt: SwitchPrompt
};
