import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import {
  FeatureBlockGroup,
  ProfileType,
  SectionHeading,
  SmallText
} from '@borrowmydoggy/core-components';

export interface IPaymentSafetyFirstProps {
  profileType: ProfileType;
  imageUrls: [string, string, string];
}

const Container = styled.div`
  margin: auto;
  padding: 50px 10px 0 10px;
`;

const TextContainer = styled.div`
  max-width: 650px;
  margin: auto;
  padding-bottom: 30px;
`;

const Heading = styled(SectionHeading)`
  text-align: center;
`;

const Paragraph = styled(SmallText)`
  display: block;
  text-align: center;
  margin-top: 10px;
`;

const BoldParagraph = styled(Paragraph)`
  font-weight: bold;
  margin-top: 20px;
`;

const FeatureBlocks = styled(FeatureBlockGroup)`
  margin: auto;
`;

export const PaymentSafetyFirst: FunctionComponent<IPaymentSafetyFirstProps> = (props: IPaymentSafetyFirstProps) => {
  return (
    <Container>
      <TextContainer>
        <Heading>Safety first and foremost</Heading>
        <Paragraph>
          We require members to go Premium before sending messages.{' '}
          <strong>Why?</strong> Because the safety of our members, dogs and humans alike, is the most important thing to us.
        </Paragraph>
        <BoldParagraph>Premium membership provides:</BoldParagraph>
      </TextContainer>
      <FeatureBlocks
        featureBlocks={[
          {
            heading: 'Safety checks',
            bodyContent: {
              text: 'We check the ID and phone number of every Premium member'
            },
            imageUrl: props.imageUrls[0]
          }, {
            heading: '24/7 Vet Line',
            bodyContent: {
              text: 'Around the clock support from veterinary professionals'
            },
            imageUrl: props.imageUrls[1]
          }, {
            heading: 'Insurance',
            bodyContent: {
              text: `Extra insurance for peace of mind when ${props.profileType === 'owner' ? 'your dog is borrowed' : 'you’re borrowing a dog'}`,
              button: {
                text: 't&cs',
                url: '/terms-and-conditions'
              }
            },
            imageUrl: props.imageUrls[2]
          }
        ]}
      />
    </Container>
  );
};
