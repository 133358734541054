import React, { Component, ReactNode } from 'react';

import styled from 'styled-components';

import { CountrySelectField, TelephoneNumberField } from '../forms';
import { lookUpCountryCode } from '../telephone-country-tree';

export interface ITelephoneNumberFormProps {
  countryIsoCode: string;
  telephoneNumber?: string;
  telephoneNumberInvalid?: boolean;
}

interface ITelephoneNumberFormState {
  countryIsoCode: string;
  telephoneNumber: string;
  cleanNumber: string;
  callingCodeFilter?: string;
  telephoneNumberInvalid: boolean;
}

const TelephoneNumberContainer = styled.div`
  width: 100%;
`;

export class TelephoneNumberForm extends Component<ITelephoneNumberFormProps, ITelephoneNumberFormState> {
  constructor(props: ITelephoneNumberFormProps) {
    super(props);

    this.state = {
      countryIsoCode: this.props.countryIsoCode,
      telephoneNumber: this.props.telephoneNumber || '',
      cleanNumber: this.cleanNumber(this.props.telephoneNumber),
      callingCodeFilter: undefined,
      telephoneNumberInvalid: this.props.telephoneNumberInvalid || false
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleTelephoneNumberChange = this.handleTelephoneNumberChange.bind(this);
    this.handleTelephoneNumberBlur = this.handleTelephoneNumberBlur.bind(this);
    this.handleTelephoneNumberValidationFailure = this.handleTelephoneNumberValidationFailure.bind(this);
    this.handleTelephoneNumberValidationSuccess = this.handleTelephoneNumberValidationSuccess.bind(this);
  }

  public componentDidMount(): void {
    document.addEventListener('BMDTelephoneNumberValidationFailure', this.handleTelephoneNumberValidationFailure);
    document.addEventListener('BMDTelephoneNumberValidationSuccess', this.handleTelephoneNumberValidationSuccess);
  }

  public render(): ReactNode {
    return (
      <>
        <label htmlFor='phone_number'>Your phone number</label>
        <div className='element-group'>
          <CountrySelectField
            name='phone_number_country_code'
            countryIsoCode={this.state.countryIsoCode}
            callingCodeFilter={this.state.callingCodeFilter}
            fullNumber={this.state.cleanNumber}
            onChange={this.handleCountryChange}
          />
          <TelephoneNumberContainer>
            <TelephoneNumberField
              name='phone_number'
              value={this.state.telephoneNumber}
              onChange={this.handleTelephoneNumberChange}
              onBlur={this.handleTelephoneNumberBlur}
            />
            {this.renderError()}
          </TelephoneNumberContainer>
        </div>
      </>
    );
  }

  private renderError(): ReactNode | null {
    if (this.state.telephoneNumberInvalid) {
      return <label className='feedback'>Please check the number you have provided</label>;
    } else {
      return null;
    }
  }

  private cleanNumber(dirtyNumber: string | undefined): string {
    if (dirtyNumber === undefined || dirtyNumber == null) {
      return '';
    }

    let cleanedNumber = dirtyNumber.replace(/[^0-9]/g, '');
    if (cleanedNumber.startsWith('00')) {
      cleanedNumber = cleanedNumber.slice(2);
    }
    return cleanedNumber;
  }

  private handleCountryChange(countryIsoCode: string): void {
    this.setState({ countryIsoCode });
  }

  private handleTelephoneNumberChange(telephoneNumber: string): void {
    const cleanNumber = this.cleanNumber(telephoneNumber);
    this.setState({ telephoneNumber, cleanNumber });
    if (telephoneNumber.startsWith('+') || telephoneNumber.startsWith('00')) {
      const countryLookUp = lookUpCountryCode(cleanNumber);
      if (countryLookUp) {
        this.setState({ callingCodeFilter: countryLookUp.callingCode });
      }
    } else {
      this.setState({ callingCodeFilter: undefined });
    }
  }

  private handleTelephoneNumberBlur(): void {
    this.setState({ telephoneNumberInvalid: false });
  }

  private handleTelephoneNumberValidationFailure(): void {
    this.setState({ telephoneNumberInvalid: true });
  }

  private handleTelephoneNumberValidationSuccess(): void {
    this.setState({ telephoneNumberInvalid: false });
  }
}
