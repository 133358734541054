import { ProfileType, ResponsiveBlock, responsiveQuery, SimpleHeader } from '@borrowmydoggy/core-components';
import { StepConductor, StepFooter } from '@borrowmydoggy/step-components';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { userAccountRegistrationOperations } from '../operations';
import { IUserAccountRegistrationManuscript, resolvers, UserAccountRegistrationStep, UserAccountRegistrationStepMap } from '../userRegistration';
import { rudderanalytics } from '../utils';

interface IAppleCallbackDetails {
	appleUid: string;
	refreshToken: string;
	tokenExpiresAt: number;
	firstName?: string;
	surname?: string;
	emailAddress?: string;
}

interface IFacebookCallbackDetails {
	facebookUid: string;
	facebookToken: string;
	firstName?: string;
	surname?: string;
	emailAddress?: string;
}

type UserRegistrationInitiationStatus =
	| 'normal'
	| 'appleAccountAlreadyLinked'
	| 'facebookAccountAlreadyLinked'
	| 'emailAddressAlreadyInUse'
	| 'appleAuthenticationFailed'
	| 'facebookAuthenticationFailed';

export interface IUserRegistrationProps {
	initiationStatus?: UserRegistrationInitiationStatus;
	profileType?: ProfileType;
	appleCallbackDetails?: IAppleCallbackDetails;
	facebookCallbackDetails?: IFacebookCallbackDetails;
	variant: 'control' | 'new';
	authVariant: 'control' | 'new';
	fbAuthVariant: 'control' | 'new';
	exp161Variant: 'control' | 'new';
}

const PageContainer = styled.div`
	display: grid;
	grid-auto-flow: row;
	min-height: 100vh;

	${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait')} {
		grid-template-rows: max-content 1fr max-content;
	}
	${responsiveQuery('mobileLandscape', 'mobilePortrait')} {
		grid-template-rows: 1fr max-content;
	}
`;

const StyledHeader = styled(SimpleHeader)`
	box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 30%);
`;

export const UserRegistration: FunctionComponent<IUserRegistrationProps> = (props: IUserRegistrationProps) => {
	function resolveInitialManuscript(): Partial<IUserAccountRegistrationManuscript> {
		if (props.appleCallbackDetails) {
			return {
				intendedProfileType: props.profileType || 'borrower',
				desiredAuthenticationType: 'apple',
				appleSignInStatus: 'successful',
				authenticationProvider: {
					authenticationType: 'apple',
					appleUid: props.appleCallbackDetails.appleUid,
					refreshToken: props.appleCallbackDetails.refreshToken,
					tokenExpiresAt: props.appleCallbackDetails.tokenExpiresAt
				},
				firstName: props.appleCallbackDetails.firstName,
				surname: props.appleCallbackDetails.surname,
				emailAddress: props.appleCallbackDetails.emailAddress,
				variant: props.variant,
				authVariant: props.authVariant,
				fbAuthVariant: props.fbAuthVariant,
				exp161Variant: props.exp161Variant
			};
		}
		if (props.facebookCallbackDetails) {
			return {
				intendedProfileType: props.profileType || 'borrower',
				desiredAuthenticationType: 'facebook',
				facebookSignInStatus: 'successful',
				authenticationProvider: {
					authenticationType: 'facebook',
					facebookUid: props.facebookCallbackDetails.facebookUid,
					facebookToken: props.facebookCallbackDetails.facebookToken
				},
				firstName: props.facebookCallbackDetails.firstName,
				surname: props.facebookCallbackDetails.surname,
				emailAddress: props.facebookCallbackDetails.emailAddress,
				variant: props.variant,
				authVariant: props.authVariant,
				fbAuthVariant: props.fbAuthVariant,
				exp161Variant: props.exp161Variant
			};
		}
		switch (props.initiationStatus) {
			case 'appleAccountAlreadyLinked':
				return {
					intendedProfileType: props.profileType || 'borrower',
					desiredAuthenticationType: 'apple',
					appleSignInStatus: 'alreadyInUse',
					variant: props.variant,
					authVariant: props.authVariant,
					fbAuthVariant: props.fbAuthVariant,
					exp161Variant: props.exp161Variant
				};
			case 'facebookAccountAlreadyLinked':
				return {
					intendedProfileType: props.profileType || 'borrower',
					desiredAuthenticationType: 'facebook',
					facebookSignInStatus: 'alreadyInUse',
					variant: props.variant,
					authVariant: props.authVariant,
					fbAuthVariant: props.fbAuthVariant,
					exp161Variant: props.exp161Variant
				};
			case 'emailAddressAlreadyInUse':
				return {
					intendedProfileType: props.profileType || 'borrower',
					desiredAuthenticationType: 'password',
					authenticationProviderConfirmed: true,
					emailAddressCheckStatus: 'unacceptableDuplicate',
					variant: props.variant,
					authVariant: props.authVariant,
					fbAuthVariant: props.fbAuthVariant,
					exp161Variant: props.exp161Variant
				};
			case 'appleAuthenticationFailed':
				return {
					intendedProfileType: props.profileType || 'borrower',
					desiredAuthenticationType: 'apple',
					appleSignInStatus: 'failed',
					variant: props.variant,
					authVariant: props.authVariant,
					fbAuthVariant: props.fbAuthVariant,
					exp161Variant: props.exp161Variant
				};
			case 'facebookAuthenticationFailed':
				return {
					intendedProfileType: props.profileType || 'borrower',
					desiredAuthenticationType: 'facebook',
					facebookSignInStatus: 'failed',
					variant: props.variant,
					authVariant: props.authVariant,
					fbAuthVariant: props.fbAuthVariant,
					exp161Variant: props.exp161Variant
				};
			case 'normal':
			default:
				return {
					variant: props.variant,
					authVariant: props.authVariant,
					fbAuthVariant: props.fbAuthVariant,
					exp161Variant: props.exp161Variant
				};
		}
	}

	function handleStepChange(newStep: UserAccountRegistrationStep): void {
		rudderanalytics.track('userAccount.registration.stepChange', { newStep });
	}

	return (
		<PageContainer>
			<ResponsiveBlock categories={['desktop', 'tabletLandscape', 'tabletPortrait']}>
				<StyledHeader />
			</ResponsiveBlock>
			<StepConductor
				processDefinition={{
					initialStep: 'user3',
					fallbackStep: 'user3',
					resolvers,
					totalStepCount: 9,
					stepComponents: UserAccountRegistrationStepMap,
					onStepChange: handleStepChange,
					onCompletion: (manuscript: Partial<IUserAccountRegistrationManuscript>) => console.log('completed!', manuscript)
				}}
				operations={userAccountRegistrationOperations}
				initialManuscript={resolveInitialManuscript()}
				stepFooter={({ record }) => <StepFooter onClick={() => record({ exitPromptShown: true })} />}
			/>
		</PageContainer>
	);
};
