import React, { Component, MouseEvent, ReactElement, ReactNode } from 'react';

import styled from 'styled-components';

import { Form, TextField } from '../forms';

import { IAddressSearchResult } from './IAddressSearchResult';

export interface IAddressSearchFormProps {
  postcode: string;
  validationMessage: string;
  csrfToken: string;
  addressSearchRequestPath: string;
  addressSearchResultPath: string;
  className?: string;
  onPostcodeChange?: (value: string) => void;
  onValidationMessageChange?: (message: string) => void;
  onError?: (error: ReactElement) => void;
  onAddressSearchResultsRetrieved?: (results: IAddressSearchResult[]) => void;
  onManualAddressClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

interface IAddressSearchFormState {
  postcodeValid: boolean;
  searchInProgress: boolean;
}

const StyledTextField = styled(TextField)`
  &.invalid>input {
    border-color: var(--toy-red);
    background-color: var(--toy-red-light);
  }
`;

export class AddressSearchForm extends Component<IAddressSearchFormProps, IAddressSearchFormState> {
  constructor(props: IAddressSearchFormProps) {
    super(props);

    this.state = { postcodeValid: true, searchInProgress: false };

    this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
    //this.handlePostcodeBlur = this.handlePostcodeBlur.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleManualAddressClick = this.handleManualAddressClick.bind(this);
  }

  public render(): ReactNode {
    return (
      <div className={this.props.className}>
        <Form id='verification-form' className='compact' onSubmit={this.handleFormSubmission}>
          <fieldset>
            <legend>Your Address</legend>
            <div className='elements two-col-33-66'>
              <label htmlFor='postcode'>Postcode</label>
              <StyledTextField
                value={this.props.postcode}
                id='postcode'
                name='postcode'
                maxLength={10}
                autoComplete='postal-code'
                inputPattern='^[0-9a-zA-Z ]*$'
                autoFocus
                className={this.state.postcodeValid ? '' : 'invalid'}
                onChange={this.handlePostcodeChange}
              />
              <button type='submit' className='new-button secondary wide' disabled={this.state.searchInProgress}>Find my address</button>
              <span className='feedback'>{this.props.validationMessage}</span>
            </div>
          </fieldset>
        </Form>
        <div className='element-group centred space-above-small'>
          <p>
            <a href='#' onClick={this.handleManualAddressClick} className='new-link primary'>Enter address manually</a>
          </p>
        </div>
      </div>
    );
  }

  private handlePostcodeChange(value: string): void {
    if (this.props.onPostcodeChange) {
      this.props.onPostcodeChange(value.toUpperCase());
    }
  }

  /* private handlePostcodeBlur(validationState: IValidationState) { */
  /*   // Only trigger the blur validation if a postcode has been entered. */
  /*   if (this.props.postcode.length > 0) { */
  /*     this.setState({ postcodeValid: validationState.isValid }); */
  /*     if (this.props.onValidationMessageChange) { */
  /*       this.props.onValidationMessageChange(validationState.messages.join()); */
  /*     } */
  /*   } */
  /* } */

  private handleFormSubmission(): void {
    if (this.props.postcode.length === 0) {
      this.setState({ postcodeValid: false, searchInProgress: false });
      if (this.props.onValidationMessageChange) {
        this.props.onValidationMessageChange('Please tell us your postcode');
      }
      if (this.props.onAddressSearchResultsRetrieved) {
        this.props.onAddressSearchResultsRetrieved([]);
      }
    } else {
      this.setState({ postcodeValid: true, searchInProgress: true });
      if (this.props.onValidationMessageChange) {
        this.props.onValidationMessageChange('');
      }
      const data = { verification_address_verification_id3_search_request: { postcode: this.props.postcode } };
      if (this.props.onError) {
        this.props.onError(<></>); // Clear any error
      }

      const searchRequestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken },
        body: JSON.stringify(data)
      };
      fetch(this.props.addressSearchRequestPath, searchRequestOptions).then(response => {
        return response.json();
      }).then(json => {
        if (json.status && json.status === 'success') {
          const searchResultOptions: RequestInit = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
          };
          fetch(this.props.addressSearchResultPath, searchResultOptions).then(rResponse => {
            return rResponse.json();
          }).then(rJson => {
            if (this.props.onAddressSearchResultsRetrieved) {
              this.props.onAddressSearchResultsRetrieved(rJson);
            }
            if (rJson.length === 0) {
              const errorText = (
                <span>
                  We couldn&rsquo;t find any addresses, please check the postcode or{' '}
                  <a href='#' onClick={this.handleManualAddressClick} className='new-link primary'>enter address manually</a>
                </span>
              );
              if (this.props.onError) {
                this.props.onError(errorText);
              }
            }
            this.setState({ searchInProgress: false });
          }).catch(rError => {
            console.error(rError);
            if (this.props.onError) {
              this.props.onError(<>Sorry, we were unable to search for your address. Please try again.</>);
            }
            this.setState({ searchInProgress: false });
          });
        } else {
          const errorMessage = <>{json.error}</> || <>Sorry, we were unable to search for your address. Please try again.</>;
          if (this.props.onError) {
            this.props.onError(errorMessage);
          }
          this.setState({ searchInProgress: false });
        }
      }).catch(error => {
        console.error(error);
        if (this.props.onError) {
          this.props.onError(<>Sorry, we were unable to search for your address. Please try again.</>);
        }
        this.setState({ searchInProgress: false });
      });
    }
  }

  private handleManualAddressClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    if (this.props.onManualAddressClick) {
      this.props.onManualAddressClick(event);
    }
  }
}
