import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components';

import { ResponsiveBlock, responsiveQuery, SimpleHeader } from '@borrowmydoggy/core-components';
import { IPersistenceAdapter, LocalStorageAdapter, StepConductor, StepFooter } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript, resolvers, BorrowerProfileCreationStepMap, BorrowerProfileCreationStep } from '../borrowerProfileCreation';
import { borrowerProfileCreationOperations } from '../operations';
import { rudderanalytics } from '../utils';

export interface IBorrowerProfileCreationProps {
  firstName: string;
  existingOwnerProfile: boolean;
}

const PageContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  min-height: 100vh;

  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait')} {
    grid-template-rows: max-content 1fr max-content;
  }
  ${responsiveQuery('mobileLandscape', 'mobilePortrait')} {
    grid-template-rows: 1fr max-content;
  }
`;

const StyledHeader = styled(SimpleHeader)`
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 30%);
`;

export const BorrowerProfileCreation: FunctionComponent<IBorrowerProfileCreationProps> = (props: IBorrowerProfileCreationProps) => {
  const localStorageAdapterRef = useRef<IPersistenceAdapter<Partial<IBorrowerProfileCreationManuscript>>>(new LocalStorageAdapter('borrowerProfileCreation'));

  function resolveInitialManuscript(): Partial<IBorrowerProfileCreationManuscript> {
    const persistedManuscript = localStorageAdapterRef.current.load();
    if (persistedManuscript == null) {
      return {
        firstName: props.firstName,
        existingOwnerProfile: props.existingOwnerProfile
      };
    }
    return {
      ...persistedManuscript,
      firstName: props.firstName,
      existingOwnerProfile: props.existingOwnerProfile
    };
  }

  function handleStepChange(newStep: BorrowerProfileCreationStep): void {
    rudderanalytics.track('borrowerProfile.completion.stepChange', { newStep });
  }

  return (
    <PageContainer>
      <ResponsiveBlock categories={['desktop', 'tabletLandscape', 'tabletPortrait']}>
        <StyledHeader />
      </ResponsiveBlock>
      <StepConductor
        processDefinition={{
          initialStep: 'borrower2',
          fallbackStep: 'borrowerError',
          resolvers,
          totalStepCount: 8,
          stepComponents: BorrowerProfileCreationStepMap,
          onStepChange: handleStepChange,
          onCompletion: (manuscript: Partial<IBorrowerProfileCreationManuscript>) => console.log('completed!', manuscript)
        }}
        operations={borrowerProfileCreationOperations}
        initialManuscript={resolveInitialManuscript()}
        stepFooter={({ manuscript, record }) => (
          <StepFooter
            onClick={() => record({ exitPromptShown: true })}
            hideQuitButton={manuscript.borrowerProfileCreationStatus === 'successful'}
          />
        )}
        persistenceAdapter={localStorageAdapterRef.current}
      />
    </PageContainer>
  );
};
