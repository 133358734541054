import React, { useContext } from 'react';
import { z } from 'zod';

import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { nameTransformer, UserAccount } from '@borrowmydoggy/core-models';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';
import { Form, FormSubmissionResult, TextField } from '@borrowmydoggy/form-components';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

const userAccountSchema = z.object({ firstName: UserAccount.firstName });
type UserAccountSchema = z.infer<typeof userAccountSchema>;

export const User11B: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  function cleanString(input: string): string {
    return input.trim();
  }

  async function handleSubmit(data: UserAccountSchema): Promise<FormSubmissionResult<UserAccountSchema>> {
    const firstName = cleanString(data.firstName);
    await props.record({ firstName, nameConfirmed: true, accountCreationStatus: 'pending' });
    props.operations.createUserAccount();
    return { outcome: 'success', formStateAction: 'halt' };
  }

  return (
    <StepContainer
      stepTitle='Some things to check'
      totalSteps={props.totalStepCount}
      currentStep={11}
      stepImageUrl={context.images['illustrations/img-person-waving.svg']}
    >
      <StepHeading text='Please check your first name'/>
      <StandardStepDescription text='You have used a word that we don’t allow, please remove it to continue.' />
      <Form
        initialValues={{ firstName: props.manuscript.firstName }}
        schema={userAccountSchema}
        initiallyExternallyInvalid={{ firstName: 'Please remove the inappropriate word' }}
        mode='editing'
        onSubmit={handleSubmit}
      >
        {({ fields, handleChange, handleValidate }) => (
          <>
            <TextField
              id='firstName'
              label='First name'
              placeholder='Will show on your profile'
              value={fields.firstName.value}
              validationState={fields.firstName.validationState}
              onValidate={handleValidate}
              onChange={handleChange}
              transformation={nameTransformer}
            />
            <StepNavigation
              nextButton={{ text: 'Next', icon: faChevronRight, iconAlignment: 'right' }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
