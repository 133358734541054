import React from 'react';

import { ErrorStep, IStepComponentProps, StepComponent } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';

export const BorrowerError: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  async function handleTryAgainClick(): Promise<void> {
    await props.record({ borrowerProfileCreationStatus: 'pending' });
    props.operations.createBorrowerProfile();
  }

  return (
    <ErrorStep
      stepHeading='We couldn’t create your profile, please try again'
      totalSteps={props.totalStepCount}
      currentStep={9}
      onTryAgainClick={handleTryAgainClick}
    />
  );
};
