import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';

import { ActivityStreamFilterType } from './ActivityStreamFilterType';

export interface IActivityStreamFilterProps {
  onFilterChange: (filterType: ActivityStreamFilterType) => void;
}

const filterLabels: Record<ActivityStreamFilterType, string> = {
  messages: 'Messages',
  likes: 'Likes',
  borrowingInfo: 'Doggy Info Sheet',
  all: 'Show All Activity'
};

export const ActivityStreamFilter: FunctionComponent<IActivityStreamFilterProps> = (props: IActivityStreamFilterProps) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [filteredBy, setFilteredBy] = useState<ActivityStreamFilterType>();

  function toggleOptions(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.nativeEvent && event.nativeEvent.stopImmediatePropagation) {
      event.nativeEvent.stopImmediatePropagation();
    }
    setOptionsVisible(!optionsVisible);
  }

  function hideOptions(): void {
    setOptionsVisible(false);
  }

  function getOptionsClass(): string {
    return optionsVisible ? 'activity-stream__filter-options_visible' : '';
  }

  function getOptionClass(option: string): string {
    return filteredBy === option ? 'activity-stream__filter-option_active' : '';
  }

  function getFilterLabel(): string {
    return filteredBy && filteredBy !== 'all' ? `Filtered by: ${filterLabels[filteredBy]}` : 'Filter by';
  }

  function filterBy(filter: ActivityStreamFilterType): (event: MouseEvent<HTMLAnchorElement>) => void {
    return event => {
      event.preventDefault();
      if (filter !== filteredBy) {
        hideOptions();
        setFilteredBy(filter);
        props.onFilterChange(filter);
      }
    };
  }

  function handleDocumentClick(): void {
    hideOptions();
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div className='activity-stream__filter'>
      <div className='activity-stream__filter-label'>
        <a href='' onClick={toggleOptions}>
          {getFilterLabel()}
        </a>
      </div>
      <div className={`activity-stream__filter-options ${getOptionsClass()}`}>
        <a
          onClick={filterBy('messages')}
          href=''
          className={`activity-stream__filter-option activity-stream__filter-option_messages ${getOptionClass('messages')}`}
        >
          <i className='fas fa-envelope' />
          Messages
        </a>
        <a
          onClick={filterBy('likes')}
          href=''
          className={`activity-stream__filter-option activity-stream__filter-option_likes ${getOptionClass('likes')}`}
        >
          <i className='fas fa-heart' />
          Likes
        </a>
        <a
          onClick={filterBy('borrowingInfo')}
          href=''
          className={`activity-stream__filter-option activity-stream__filter-option_borrowing-info ${getOptionClass('borrowing-info')}`}
        >
          <i className='fas fa-paw' />
          Doggy Info Sheet
        </a>
        {(filteredBy && filteredBy !== 'all') &&
        <a onClick={filterBy('all')} href='' className='activity-stream__filter-option activity-stream__filter-option_all'>
          <i className='fas fa-align-left'/>
          Show All Activity
        </a>
        }
      </div>
    </div>
  );
};
