import { StepResolvers } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';

export type BorrowerProfileCreationStep =
  | 'borrower2'
  | 'borrower3'
  | 'borrower4'
  | 'borrower5'
  | 'borrower6'
  | 'borrower7'
  | 'borrower8'
  | 'borrower9a'
  | 'borrower9b'
  | 'borrowerError'
  | 'exitPrompt'
  | 'switchPrompt';

export const resolvers: StepResolvers<IBorrowerProfileCreationManuscript, BorrowerProfileCreationStep> = [
  manuscript => manuscript.exitPromptShown ? 'exitPrompt' : 'skip',
  manuscript => manuscript.switchPromptShown ? 'switchPrompt' : 'skip',
  manuscript => manuscript.experienceWithDogsConfirmed ? 'skip' : 'borrower2',
  manuscript => manuscript.childrenAtHomeConfirmed ? 'skip' : 'borrower3',
  manuscript => manuscript.gardenOrOutsideSpaceConfirmed ? 'skip' : 'borrower4',
  manuscript => manuscript.offeringConfirmed ? 'skip' : 'borrower5',
  manuscript => manuscript.availability !== undefined ? 'skip' : 'borrower6',
  manuscript => manuscript.descriptionConfirmed ? 'skip' : 'borrower7',
  manuscript => manuscript.photoConfirmed ? 'skip' : 'borrower8',
  manuscript => {
    switch (manuscript.borrowerProfileCreationStatus) {
      case 'pending':
      case 'inProgress':
        return 'borrower9a';
      case 'successful':
        return 'skip';
      case 'failedDueToDescription':
        return 'borrower9b';
      case 'error':
      default:
        return 'borrowerError';
    }
  }
];
