import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { Form, FormSubmissionResult, TextAreaField } from '@borrowmydoggy/form-components';
import { Dog } from '@borrowmydoggy/core-models';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

const descriptionSchema = z.object({ description: Dog.description });
type DescriptionSchema = z.infer<typeof descriptionSchema>;

export const Owner17B: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { multipleDogs, subject } = parseDogNames(props.manuscript.dogNames || []);

  async function handleSubmit(data: DescriptionSchema): Promise<FormSubmissionResult<DescriptionSchema>> {
    await props.record({ description: data.description.trim(), descriptionConfirmed: true, ownerProfileCreationStatus: 'pending' });
    props.operations.createOwnerProfile();
    return { outcome: 'success', formStateAction: 'halt' };
  }

  return (
    <StepContainer
      stepTitle='Some things to check'
      totalSteps={props.totalStepCount}
      currentStep={17}
      stepImageUrl={context.images['illustrations/img-person-holding-massive-pencil.svg']}
      stepPanelWidth='wide'
      hideImageAtMobileScreenWidths
    >
      <StepHeading text='Please check your description' />
      <StandardStepDescription text='You have used a word that we don’t allow, please remove it to continue.' />
      <Form
        schema={descriptionSchema}
        initialValues={{ description: props.manuscript.description }}
        initiallyExternallyInvalid={{ description: 'Please remove the inappropriate word' }}
        mode='editing'
        onSubmit={handleSubmit}
      >
        {({ fields, handleChange, handleValidate }) => (
          <>
            <TextAreaField
              id='description'
              label='Description'
              placeholder={`What ${multipleDogs ? 'are' : 'is'} ${subject} like?`}
              value={fields.description.value}
              validationState={fields.description.validationState}
              onChange={handleChange}
              onValidate={handleValidate}
            />
            <StepNavigation
              nextButton={{
                icon: faChevronRight,
                text: 'Next',
                iconAlignment: 'right'
              }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
