import { BorrowerProfileDetailsArgument, OwnerProfileDetailsArgument, ProfilePhotoArgument } from '@borrowmydoggy/bmd-api';

import { BorrowerProfileCreationDetails, OwnerProfileCreationDetails } from '../../schemas';

export function buildBorrowerProfileDetails(borrowerProfileCreationDetails: BorrowerProfileCreationDetails): BorrowerProfileDetailsArgument {
  let photos: ProfilePhotoArgument[] | undefined;
  if (borrowerProfileCreationDetails.uploadedPhotoKey && borrowerProfileCreationDetails.photoMimeType) {
    photos = [{
      fileName: borrowerProfileCreationDetails.uploadedPhotoKey,
      fileMimeType: borrowerProfileCreationDetails.photoMimeType,
      taskId: borrowerProfileCreationDetails.photoTaskId,
      previewUrl: borrowerProfileCreationDetails.previewImageDataUrl
    }];
  }
  return {
    attributes: {
      experienceWithDogs: borrowerProfileCreationDetails.experienceWithDogs,
      dogHandlingCertificate: false,
      childrenAtHome: borrowerProfileCreationDetails.childrenAtHome,
      gardenOrOutsideSpace: borrowerProfileCreationDetails.gardenOrOutsideSpace
    },
    offering: {
      company: borrowerProfileCreationDetails.offering !== 'exercise',
      exercise: borrowerProfileCreationDetails.offering !== 'company'
    },
    availability: borrowerProfileCreationDetails.availability,
    description: borrowerProfileCreationDetails.description,
    photos
  };
}

export function buildOwnerProfileDetails(ownerProfileCreationDetails: OwnerProfileCreationDetails): OwnerProfileDetailsArgument {
  let photos: ProfilePhotoArgument[] | undefined;
  if (ownerProfileCreationDetails.uploadedPhotoKey && ownerProfileCreationDetails.photoMimeType) {
    photos = [{
      fileName: ownerProfileCreationDetails.uploadedPhotoKey,
      fileMimeType: ownerProfileCreationDetails.photoMimeType,
      taskId: ownerProfileCreationDetails.photoTaskId,
      previewUrl: ownerProfileCreationDetails.previewImageDataUrl
    }];
  }
  return {
    dogNames: ownerProfileCreationDetails.dogNames,
    dogBreedCode: ownerProfileCreationDetails.dogBreedCode,
    dogDateOfBirth: ownerProfileCreationDetails.dogDateOfBirth,
    dogGender: ownerProfileCreationDetails.dogGender,
    compatibility: {
      goodWithChildren: ownerProfileCreationDetails.goodWithChildren,
      tolerantOfCats: ownerProfileCreationDetails.tolerantOfCats,
      okayWithOtherDogs: ownerProfileCreationDetails.okayWithOtherDogs
    },
    properties: {
      hypoallergenicBreed: ownerProfileCreationDetails.hypoallergenicBreed,
      neutered: ownerProfileCreationDetails.neutered,
      rescueDog: ownerProfileCreationDetails.rescueDog
    },
    needs: {
      company: ownerProfileCreationDetails.dogNeeds !== 'exercise',
      exercise: ownerProfileCreationDetails.dogNeeds !== 'company'
    },
    availability: ownerProfileCreationDetails.availability,
    description: ownerProfileCreationDetails.description,
    photos,
    permissionToUse: ownerProfileCreationDetails.permissionToUse
  };
}
