import React, { Component, ReactNode } from 'react';

import { IPureDate } from '@borrowmydoggy/bmd-validators';
import { ConfirmName, IAddress, ProvideAddress, ProvideDob, VerifyAddress } from '../verification';

const VerificationSteps = ['confirm-name', 'provide-dob', 'provide-address', 'verify-address'];

type ServerVerificationStep =
  | 'name_confirmation'
  | 'phone_confirmation'
  | 'sms_code_confirmation'
  | 'details_confirmation'
  | 'success'
  | 'failure'
  | 'choose_address';

type EditableDetailsType = 'name' | 'dob' | 'address';

export interface IVerificationProps {
  verificationDetailsPath: string;
  contactPath: string;
  nameConfirmationPath: string;
  dobRequestPath: string;
  addressSearchRequestPath: string;
  addressSearchResultPath: string;
  addressCreateFromSearchResultPath: string;
  manualAddressCreationPath: string;
  addressVerificationPath: string;
  step: ServerVerificationStep;
}

interface IVerificationState {
  csrfToken: string;
  forename: string;
  surname: string;
  dob?: IPureDate;
  address: IAddress;
  step: number;
  memberOfCompany: boolean;
  currentlyEditing: EditableDetailsType | null;
  errorMessage: string;
}

export class Verification extends Component<IVerificationProps, IVerificationState> {
  constructor(props: IVerificationProps) {
    super(props);

    let csrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const attribute = csrfTokenElement.attributes.getNamedItem('content');
      if (attribute) {
        csrfToken = attribute.value;
      }
    }

    const initialStep = this.resolveStep(props.step);

    this.state = {
      csrfToken,
      forename: '',
      surname: '',
      dob: undefined,
      address: { flat: '', houseNumber: '', lineOne: '', lineTwo: '', lineThree: '', town: '', postcode: '' },
      step: initialStep,
      memberOfCompany: false,
      currentlyEditing: null,
      errorMessage: ''
    };

    this.handleNameConfirmed = this.handleNameConfirmed.bind(this);
    this.handleDobProvided = this.handleDobProvided.bind(this);
    this.handleAddressProvided = this.handleAddressProvided.bind(this);
    this.handleAddressVerified = this.handleAddressVerified.bind(this);

    this.handleForenameChange = this.handleForenameChange.bind(this);
    this.handleSurnameChange = this.handleSurnameChange.bind(this);

    this.handleDobChange = this.handleDobChange.bind(this);

    this.handleAddressChange = this.handleAddressChange.bind(this);

    this.handleNameEditRequest = this.handleNameEditRequest.bind(this);
    this.handleDobEditRequest = this.handleDobEditRequest.bind(this);
    this.handleAddressEditRequest = this.handleAddressEditRequest.bind(this);
  }

  public componentDidMount(): void {
    fetch(this.props.verificationDetailsPath, { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } })
    .then(response => {
      return response.json();
    })
    .then(json => {
      this.setState({ step: this.resolveStep(json.step), memberOfCompany: json.memberOfCompany, forename: json.forename, surname: json.surname });
      if (json.dob) {
        /* let day = json.dob.day.toString(); */
        /* if (day.length === 1) { */
        /*   day = `0${day}`; */
        /* } */
        /* let month = json.dob.month.toString(); */
        /* if (month.length === 1) { */
        /*   month = `0${month}`; */
        /* } */
        /* const year = json.dob.year.toString(); */
        this.setState({ dob: json.dob });
      }
      if (json.addressVerification) {
        const addressVerification = json.addressVerification;
        this.setState({
          address: {
            flat: addressVerification.flat,
            houseNumber: addressVerification.houseNumber,
            lineOne: addressVerification.lineOne,
            lineTwo: addressVerification.lineTwo,
            lineThree: addressVerification.lineThree,
            town: addressVerification.town,
            postcode: addressVerification.postcode
          }
        });
      }
    })
    .catch(error => {
      console.error(error);
      const errorMessage = 'Something went wrong our end. Please try refreshing the page!';
      this.setState({ errorMessage });
    });
  }

  public render(): ReactNode {
    return (
      <div id='verification-container'>
        {this.renderStep()}
        {this.renderFootnote()}
        <div className='element-group centred space-above-large'>
          <p>
            Need help?{' '}
            <a href={this.props.contactPath} className='new-link primary'>
              Get in touch
            </a>
          </p>
        </div>
      </div>
    );
  }

  private handleNameConfirmed(): void {
    if (this.state.currentlyEditing === 'name') {
      this.clearCurrentlyEditingState();
    } else {
      this.nextStep();
    }
  }

  private handleDobProvided(): void {
    if (this.state.currentlyEditing === 'dob') {
      this.clearCurrentlyEditingState();
    } else {
      this.nextStep();
    }
  }

  private handleAddressProvided(): void {
    if (this.state.currentlyEditing === 'address') {
      this.clearCurrentlyEditingState();
    } else {
      this.nextStep();
    }
  }

  private handleAddressVerified(): void {
    // Rely on the server page to render success or failure messages.
    window.location.reload();
  }

  private handleForenameChange(forename: string): void {
    this.setState({ forename });
  }

  private handleSurnameChange(surname: string): void {
    this.setState({ surname });
  }

  private handleDobChange(dateOfBirth: IPureDate): void {
    this.setState({ dob: dateOfBirth });
  }

  private handleAddressChange(address: IAddress): void {
    this.setState({ address });
  }

  private handleNameEditRequest(): void {
    this.setCurrentlyEditingState('name');
  }

  private handleDobEditRequest(): void {
    this.setCurrentlyEditingState('dob');
  }

  private handleAddressEditRequest(): void {
    this.setCurrentlyEditingState('address');
  }

  // Helpers
  private resolveStep(serverStep: ServerVerificationStep): number {
    switch (serverStep) {
      case 'name_confirmation':
        return 0;
      case 'details_confirmation':
        return 3;
      default:
        return 0;
    }
  }

  private setCurrentlyEditingState(details: EditableDetailsType): void {
    this.setState({ currentlyEditing: details });
  }

  private clearCurrentlyEditingState(): void {
    this.setState({ currentlyEditing: null });
  }

  private nextStep(): void {
    this.setState({ step: this.state.step + 1 });
    // Scroll back up to the top of the verification container when changing step.
    const container = document.getElementById('verification-container');
    if (container) {
      container.scrollIntoView(true);
    }
  }

  // Rendering methods
  private renderNameConfirmation(): ReactNode {
    return (
      <ConfirmName
        forename={this.state.forename}
        surname={this.state.surname}
        csrfToken={this.state.csrfToken}
        nameConfirmationPath={this.props.nameConfirmationPath}
        onNameConfirmed={this.handleNameConfirmed}
        onForenameChange={this.handleForenameChange}
        onSurnameChange={this.handleSurnameChange}
      />
    );
  }

  private renderProvideDob(): ReactNode {
    return (
      <ProvideDob
        dateOfBirth={this.state.dob}
        csrfToken={this.state.csrfToken}
        dobRequestPath={this.props.dobRequestPath}
        onDateOfBirthChange={this.handleDobChange}
        onDateOfBirthProvided={this.handleDobProvided}
      />
    );
  }

  private renderProvideAddress(startWithManualEntry = false): ReactNode {
    return (
      <ProvideAddress
        startWithManualEntry={startWithManualEntry}
        address={this.state.address}
        csrfToken={this.state.csrfToken}
        addressSearchRequestPath={this.props.addressSearchRequestPath}
        addressSearchResultPath={this.props.addressSearchResultPath}
        addressCreateFromSearchResultPath={this.props.addressCreateFromSearchResultPath}
        manualAddressCreationPath={this.props.manualAddressCreationPath}
        onAddressProvided={this.handleAddressProvided}
        onAddressChange={this.handleAddressChange}
      />
    );
  }

  private renderVerifyAddress(): ReactNode {
    return (
      <VerifyAddress
        forename={this.state.forename}
        surname={this.state.surname}
        dateOfBirth={this.state.dob}
        address={this.state.address}
        csrfToken={this.state.csrfToken}
        addressVerificationPath={this.props.addressVerificationPath}
        onAddressVerified={this.handleAddressVerified}
        onNameEditRequest={this.handleNameEditRequest}
        onDobEditRequest={this.handleDobEditRequest}
        onAddressEditRequest={this.handleAddressEditRequest}
      />
    );
  }

  private renderDetailsConfirmation(): ReactNode {
    switch (this.state.currentlyEditing) {
      case 'name':
        return this.renderNameConfirmation();
      case 'dob':
        return this.renderProvideDob();
      case 'address':
        return this.renderProvideAddress(true);
      default:
        return this.renderVerifyAddress();
    }
  }

  private renderStep(): ReactNode {
    switch (VerificationSteps[this.state.step]) {
      case 'provide-dob':
        return this.renderProvideDob();
      case 'provide-address':
        return this.renderProvideAddress(false);
      case 'verify-address':
        return this.renderDetailsConfirmation();
      case 'confirm-name':
      default:
        return this.renderNameConfirmation();
    }
  }

  private renderFootnote(): ReactNode | undefined {
    // Do not show the footnote when reviewing the details.
    if (VerificationSteps[this.state.step] !== 'verify-address') {
      return <p className='footnote'>You&rsquo;ll have a chance to review everything before you submit it</p>;
    }
  }
}
