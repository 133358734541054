import React, { FunctionComponent, useContext } from 'react';

import styled from 'styled-components';

import { FeatureAside, HighlightSection, ProfileType, responsiveQuery, TextButton } from '@borrowmydoggy/core-components';
import { MemberFeedbackReviewForm, PortraitHeroHeader, SubmissionResult } from '@borrowmydoggy/profile-components';

import { MemberFeedbackAPI } from '../api';
import { WebContext } from '../utils/AppContext';

export interface IMemberFeedbackProps {
  recipientFirstName: string;
  portraitImageUrl?: string;
  recipientProfileType: ProfileType;
  recipientProfileUuid: string;
  referralPath: string;
  giftVouchersPath: string;
}

const StyledMemberFeedback = styled.div`
  padding-top: 50px;
  display: grid;
  grid-template-areas: 'pageHeader'
                       'review'
                       'onwardJourneys';

  &>.pageHeader {
    grid-area: pageHeader;
  }

  &>.review {
    grid-area: review;
  }

  ${responsiveQuery('tabletLandscape', 'desktop')} {
    &>.onwardJourneys {
      grid-area: onwardJourneys;
      max-width: 1110px;
      margin: 100px auto;
      padding: 0 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      grid-gap: 50px;
    }
  }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
    &>.onwardJourneys {
      margin: 50px auto;
      padding: 0 30px;
      display: grid;
      grid-auto-flow: row;
      grid-gap: 50px;
    }
  }

  ${responsiveQuery('mobilePortrait')} {
    &>.onwardJourneys {
      margin: 30px auto;
      padding: 0 10px;
      display: grid;
      grid-auto-flow: row;
      grid-gap: 40px;
    }
  }

  ${responsiveQuery('tabletPortrait', 'tabletLandscape', 'desktop')} {
    padding-top: 50px;
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape')} {
    padding-top: 30px;
  }
`;

const StyledHighlightSection = styled(HighlightSection)`
  ${responsiveQuery('tabletPortrait', 'tabletLandscape', 'desktop')} {
    &>.wrapper {
      display: grid;
      justify-content: center;
      &>* { min-width: 600px; }
    }
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape')} {
  }
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

export const MemberFeedback: FunctionComponent<IMemberFeedbackProps> = (props: IMemberFeedbackProps) => {
  const context = useContext(WebContext);

  async function createMemberFeedback(
    recipientProfileType: ProfileType,
    recipientProfileUuid: string,
    positiveFeedback: boolean,
    reviewText: string,
    contactRequested: boolean
  ): Promise<SubmissionResult> {
    const response = await MemberFeedbackAPI.createMemberFeedback(
      recipientProfileType,
      recipientProfileUuid,
      positiveFeedback,
      reviewText,
      contactRequested
    );
    if (response.error === undefined && response.data && response.data.createMemberFeedback) {
      switch (response.data.createMemberFeedback.result) {
        case 'successful':
        case 'failedProfanity':
          return response.data?.createMemberFeedback.result;
        case 'failedUnknown':
        case 'failedTooShort':
        case 'failedTooLong':
        default:
          return 'failedUnknown';
      }
    } else {
      console.warn('API failure when attempting to create member feedback.', response.error)
      return 'error';
    }
  }

  async function handleReviewSubmit(positiveFeedback: boolean, reviewText: string, contactRequested: boolean): Promise<SubmissionResult> {
    const result = await createMemberFeedback(
      props.recipientProfileType,
      props.recipientProfileUuid,
      positiveFeedback,
      reviewText,
      contactRequested
    );
    return result;
  }

  return (
    <StyledMemberFeedback>
      <PortraitHeroHeader
        title={`Tell us about ${props.recipientFirstName}`}
        illustrationImageUrl={context.images['illustrations/img-person-with-frame.svg']}
        portraitImageUrl={props.portraitImageUrl}
        profileType={props.recipientProfileType}
        className='pageHeader'
      >
        <Paragraph>
          To help other members find their pawfect match, we&rsquo;d love to know more about your experience with {props.recipientFirstName}.
        </Paragraph>
        <Paragraph>Only positive reviews will be shared with the community.</Paragraph>
      </PortraitHeroHeader>
      <StyledHighlightSection className='review'>
        <MemberFeedbackReviewForm
          recipientFirstName={props.recipientFirstName}
          onSubmit={handleReviewSubmit}
        />
      </StyledHighlightSection>
      <div className='onwardJourneys'>
        <FeatureAside
          title='Share the love'
          text='Help us spread the word about BorrowMyDoggy by sharing with your dog-loving friends and family'
          button={<TextButton text='Refer a friend' link={{ href: props.referralPath }} wide />}
          imageUrl={context.images['illustrations/img-person-holding-dog.svg']}
        />
        <FeatureAside
          title="The gift of a dog's love"
          text='Want to get someone special on BorrowMyDoggy right now? A gift voucher will help them on their way.'
          button={<TextButton text='Gift vouchers' link={{ href: props.giftVouchersPath }} wide />}
          imageUrl={context.images['illustrations/img-dog-gift-envelope.svg']}
          alignment='right'
        />
      </div>
    </StyledMemberFeedback>
  );
};
