import React, { MouseEvent, ReactNode, useContext, useState } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';
import styled from 'styled-components';

import { BorrowerProfile } from '@borrowmydoggy/core-models';
import { generateBorrowerDescription } from '@borrowmydoggy/profile-components';
import { Form, FormSubmissionResult, TextAreaField } from '@borrowmydoggy/form-components';
import { InlineTextButton, SmallText } from '@borrowmydoggy/core-components';
import { IStepComponentProps, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { ModalBinaryPanel } from '@borrowmydoggy/account-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const descriptionSchema = z.object({ description: BorrowerProfile.description });
type DescriptionSchema = z.infer<typeof descriptionSchema>;

const Introduction = styled.div`
  margin-bottom: 20px;
`;

export const Borrower7: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);
  const [confirmationShown, setConfirmationShown] = useState(false);

  function handleSubmit(data: DescriptionSchema): FormSubmissionResult<DescriptionSchema> {
    props.record({ description: data.description.trim(), descriptionConfirmed: true });
    return { outcome: 'success' };
  }

  function handleConfirmationNoClick(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void {
    event.preventDefault();
    setConfirmationShown(false);
  }

  function handleConfirmationYesClick(descriptionGenerationHandler: () => void, event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void {
    event.preventDefault();
    setConfirmationShown(false);
    props.record({ descriptionGenerated: true });
    descriptionGenerationHandler();
  }

  function renderIntroduction(preFillClickHandler: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void): ReactNode {
    if (!props.manuscript.descriptionGenerated) {
      return (
        <Introduction>
          <SmallText>Cat got your tongue? <InlineTextButton size='small' onClick={preFillClickHandler}>Write it for me</InlineTextButton></SmallText>
        </Introduction>
      );
    }
    return (
      <Introduction>
        <SmallText>We&rsquo;ve got you started from your previous answers, now show your personality by writing it in your own words.</SmallText>
      </Introduction>
    );
  }

  return (
    <StepContainer
      stepTitle='It&rsquo;s all about you'
      totalSteps={props.totalStepCount}
      currentStep={7}
      stepImageUrl={context.images['illustrations/img-person-holding-massive-pencil.svg']}
      stepPanelWidth='wide'
      hideImageAtMobileScreenWidths
    >
      <StepHeading text='Now write a bit about you' />
      <Form
        schema={descriptionSchema}
        initialValues={{ description: props.manuscript.description }}
        ignorePropChanges
        onSubmit={handleSubmit}
      >
        {({ fields, handleChange, handleValidate }) => {
          function generateDescription(): void {
            const newDescription = generateBorrowerDescription({
              firstName: props.manuscript.firstName || 'a borrower',
              attributes: {
                ownedDog: props.manuscript.experienceWithDogs || false,
                certified: false,
                garden: props.manuscript.gardenOrOutsideSpace || false,
                childrenAtHome: props.manuscript.childrenAtHome || false
              },
              company: props.manuscript.offering !== 'exercise',
              exercise: props.manuscript.offering !== 'company',
              availability: props.manuscript.availability || { daytime: false, evenings: false, weekends: false, holidays: false }
            });
            handleChange('description', newDescription);
          }

          function handlePreFillClick(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void {
            event.preventDefault();
            if (fields.description.value && fields.description.value.length > 0) {
              setConfirmationShown(true);
            } else {
              props.record({ descriptionGenerated: true });
              generateDescription();
            }
          }

          return (
            <>
              {renderIntroduction(handlePreFillClick)}
              <TextAreaField
                id='description'
                label='Description'
                placeholder='Help owners to get to know and trust you'
                value={fields.description.value}
                validationState={fields.description.validationState}
                onChange={handleChange}
                onValidate={handleValidate}
              />
              <StepNavigation
                nextButton={{
                  icon: faChevronRight,
                  text: 'Next',
                  iconAlignment: 'right'
                }}
              />
              {confirmationShown && <ModalBinaryPanel
                title='Replace your description?'
                description='Should we replace your current description with one based on the questions you’ve already answered? You’ll still be able to edit it.'
                onNoClick={handleConfirmationNoClick}
                onYesClick={handleConfirmationYesClick.bind({}, generateDescription)}
              />}
            </>
          );
        }}
      </Form>
    </StepContainer>
  );
};
