import { checkEmailAddressAcceptability } from './checkEmailAddressAcceptability';
import { createUserAccount } from './createUserAccount';
import { registerInternationalInterest } from './registerInternationalInterest';

export const userAccountRegistrationOperations = {
  checkEmailAddressAcceptability,
  createUserAccount,
  registerInternationalInterest
};

export type UserAccountRegistrationOperations = typeof userAccountRegistrationOperations;
