import { gqlCommand } from '@borrowmydoggy/api-client';
import {
  Query,
  QueryBorrowerSearchArgs,
  QueryDefaultBorrowerSearchArgs,
  QueryDefaultDogSearchArgs,
  QueryDogSearchArgs,
  QueryLocationSearchArgs,
  QueryLocationSearchByCoordinatesArgs
} from '@borrowmydoggy/bmd-api';

export const queries = {
  borrowerSearch: gqlCommand<{ borrowerSearch: Query['borrowerSearch']; }, QueryBorrowerSearchArgs>`
    query BorrowerSearch($parameters: BorrowerSearchParametersArgument!) {
      borrowerSearch(parameters: $parameters) {
        count
        results {
          name
          profileId
          profileCreatedAtUtc
          activityLevel
          liked
          premium
          imageUrls
          distanceInMiles
          direction
          description
          availability{
            daytime
            evenings
            weekends
            holidays
          }
          profileUrl
        }
      }
    }
  `,
  defaultBorrowerSearch: gqlCommand<{ defaultBorrowerSearch: Query['defaultBorrowerSearch']; }, QueryDefaultBorrowerSearchArgs>`
    query DefaultBorrowerSearch($limit: Int!) {
      defaultBorrowerSearch(limit: $limit) {
        count
        results {
          name
          profileId
          profileCreatedAtUtc
          activityLevel
          liked
          premium
          imageUrls
          distanceInMiles
          direction
          description
          availability{
            daytime
            evenings
            weekends
            holidays
          }
          profileUrl
        }
        searchPreferences {
          criteria {
            searchDirections
            latitude
            longitude
            searchDistance
            searchLocation
            searchLocationType
            sortType
            permanentSearchLocation
          }
          filters {
            premium
            attributes {
              ownedDog
              garden
            }
            availability {
              daytime
              evenings
              weekends
              holidays
            }
          }
          searchViewType
        }
      }
    }
  `,
  defaultDogSearch: gqlCommand<{ defaultDogSearch: Query['defaultDogSearch']; }, QueryDefaultDogSearchArgs>`
    query DefaultDogSearch($limit: Int!) {
      defaultDogSearch(limit: $limit) {
        count
        results {
          name
          ownerName
          profileId
          breed
          direction
          distanceInMiles
          imageUrls
          liked
          premium
          multipleDogs
          description
          availability {
            daytime
            evenings
            weekends
            holidays
          }
          profileUrl
        }
        searchPreferences {
          criteria {
            searchDirections
            latitude
            longitude
            searchDistance
            searchLocation
            searchLocationType
            sortType
            permanentSearchLocation
          }
          filters {
            premium
            dogSizes {
              small
              medium
              large
            }
            compatibility {
              cats
              children
              otherDogs
            }
            properties {
              hypoallergenic
              neutered
            }
            availability {
              daytime
              evenings
              weekends
              holidays
            }
          }
          searchViewType
        }
      }
    }
  `,
  dogSearch: gqlCommand<{ dogSearch: Query['dogSearch']; }, QueryDogSearchArgs>`
    query DogSearch($parameters: DogSearchParametersArgument!) {
      dogSearch(parameters: $parameters) {
        count
        results {
          name
          ownerName
          profileId
          breed
          direction
          distanceInMiles
          imageUrls
          liked
          premium
          multipleDogs
          description
          availability {
            daytime
            evenings
            weekends
            holidays
          }
          profileUrl
        }
      }
    }
  `,
  locationSearch: gqlCommand<{ locationSearch: Query['locationSearch']; }, QueryLocationSearchArgs>`
    query LocationSearch($searchText: String!) {
      locationSearch(searchText: $searchText) {
        results {
          id
          description
          latitude
          longitude
          city
          postcode
        }
      }
    }
  `,
  locationSearchByCoordinates: gqlCommand<{ locationSearchByCoordinates: Query['locationSearchByCoordinates']; }, QueryLocationSearchByCoordinatesArgs>`
    query LocationSearchByCoordinates($latitude: Float!, $longitude: Float!) {
      locationSearchByCoordinates(latitude: $latitude, longitude: $longitude) {
        results {
          id
          description
          latitude
          longitude
          city
          postcode
        }
      }
    }
  `
};
