import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { InformationalStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const User4B: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  let title: string;
  if (props.manuscript.variant === 'new') {
    title = `Check out local ${props.manuscript.intendedProfileType == 'owner' ? 'borrowers' : 'dogs'} for free`;
  } else {
    title = `Welcome new ${props.manuscript.intendedProfileType == 'owner' ? 'pup' : 'borrower'}!`;
  }

  return (
    <InformationalStep
      stepTitle={title}
      stepHeading='We’ve got your details from Apple'
      totalSteps={props.totalStepCount}
      currentStep={4}
      stepImageUrl={context.images['illustrations/img-person-with-iphone.svg']}
      nextButton={{
        icon: faChevronRight,
        text: 'Check details',
        iconAlignment: 'right'
      }}
      onNextClick={() => props.record({ authenticationProviderConfirmed: true })}
    >
      <StandardStepDescription text='Please check through them to make sure they’re correct.' />
    </InformationalStep>
  );
};
