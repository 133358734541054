import { IStepProcessOperationOptions } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from '../../userRegistration';
import { navigateWithPost } from '../../utils';
import { RegistrationAPI } from '../../api';

import { userAccountRegistrationDetails } from '../../schemas';

export async function createUserAccount(
  { manuscript, record }: IStepProcessOperationOptions<IUserAccountRegistrationManuscript>
): Promise<Partial<IUserAccountRegistrationManuscript>> {
  const accountCreationAttempts = manuscript.accountCreationAttempts || 0;
  record({ accountCreationStatus: 'inProgress' });
  try {
    const validatedDetails = userAccountRegistrationDetails.parse(manuscript);
    const response = await RegistrationAPI.createUserAccount(validatedDetails);
    if (response.error === undefined && response.data && response.data.createUserAccount) {
      switch (response.data.createUserAccount.result) {
        case 'successful':
          navigateWithPost('/signin/jwt', { jwt: response.data.createUserAccount.authenticationToken || '' });
          return { accountCreationStatus: 'successful', accountCreationAttempts: accountCreationAttempts + 1 };
        case 'failedDueToFirstName':
          return { accountCreationStatus: 'failedDueToFirstName', accountCreationAttempts: accountCreationAttempts + 1 };
        case 'failedDueToPhoneNumber':
          return { accountCreationStatus: 'failedDueToPhoneNumber', accountCreationAttempts: accountCreationAttempts + 1 };
        case 'failedUnknown':
        default:
          return { accountCreationStatus: 'error', accountCreationAttempts: accountCreationAttempts + 1 };
      }
    } else {
      console.warn(response);
      return { accountCreationStatus: 'error', accountCreationAttempts: accountCreationAttempts + 1 };
    }
  } catch (error) {
    console.warn(error);
    return { accountCreationStatus: 'error', accountCreationAttempts: accountCreationAttempts + 1 };
  }
}
