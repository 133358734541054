import React, { FunctionComponent, ReactNode } from 'react';

import { IInboundMessageBorrowerActivityStreamEvent } from './IInboundMessageBorrowerActivityStreamEvent';
import { IInboundMessageOwnerActivityStreamEvent } from './IInboundMessageOwnerActivityStreamEvent';

export interface IMessageEventProps {
  eventData: IInboundMessageBorrowerActivityStreamEvent | IInboundMessageOwnerActivityStreamEvent;
  missingOwnerImageURL: string;
  missingBorrowerImageURL: string;
  children?: ReactNode;
}

export const MessageEvent: FunctionComponent<IMessageEventProps> = (props: IMessageEventProps) => {
  const subscriptionType = props.eventData.userVerified ? 'premium' : 'basic';
  let profileImageUrl = props.eventData.profileImageUrl;
  if (profileImageUrl === undefined || profileImageUrl == null) {
    profileImageUrl = props.eventData.profileType === 'owner' ? props.missingOwnerImageURL : props.missingBorrowerImageURL;
  }

  return (
    <div className='panel__item panel__item_list activity-stream-event'>
      <div className='activity-stream-event__profile-image'>
        <div className={`profile-image profile-image_${subscriptionType}`}>
          <a href={props.eventData.profileUrl}>
            <img src={profileImageUrl} alt=''/>
          </a>
        </div>
        <div className='activity-stream-event__type-icon activity-stream-event__type-icon_message'>
          <i className='fas fa-envelope'/>
        </div>
      </div>
      <div className='activity-stream-event__content activity-stream-event__content_extended'>
        <div className='activity-stream-event__content-heading'>
          {props.children}
          <div className='activity-stream-event__content-heading-date'>
            {props.eventData.eventCreatedAt}
          </div>
        </div>
        <div className='activity-stream-event__content-body'>
          <div className='activity-stream-event__message-body'>
            <a href={props.eventData.conversationUrl}>
              {props.eventData.messageBody}
            </a>
          </div>
          <div className='activity-stream-event__message-link'>
            <a href={props.eventData.conversationUrl}>
              <i className='fas fa-chevron-right'/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
