import React from 'react';

import { ProcessingStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';

export const User11A: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  return (
    <ProcessingStep
      displayCancelButton={false}
      totalSteps={props.totalStepCount}
      currentStep={11}
    >
      <StandardStepDescription text='We’re creating your account' />
    </ProcessingStep>
  );
};
