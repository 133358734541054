import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';
import { WebContext } from '../utils/AppContext';

export const Owner10: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);
  const pronoun = props.manuscript.dogGender ? parseDogGender(props.manuscript.dogGender).genderedPronoun : firstDogName;
  const puppyAvoidanceTechnique = props.manuscript.dogGender === 'female' ? 'spayed' : 'neutered';

  return (
    <BinaryStep
      stepTitle={`Tell us about ${firstDogName}`}
      stepHeading={`Has ${pronoun} been ${puppyAvoidanceTechnique}?`}
      totalSteps={props.totalStepCount}
      currentStep={10}
      stepImageUrl={context.images['illustrations/img-dog-wearing-cone.svg']}
      backButton={{
        text: 'Back'
      }}
      onBinaryButtonClick={polarity => props.record({ neutered: polarity, neuteredConfirmed: true })}
      onBackClick={() => props.record({ hypoallergenicBreedConfirmed: false })}
    >
      <StandardStepDescription text='Just to make sure there’s no risk of puppies if your dog is around the opposite gender!' />
    </BinaryStep>
  );
};
