import { AuthenticationProvider, UserAccountDetailsArgument } from '@borrowmydoggy/bmd-api';

import { UAuthenticationProvider, UserAccountRegistrationDetails } from '../../schemas';

export function buildUserAccountDetails(registrationDetails: UserAccountRegistrationDetails): UserAccountDetailsArgument {
  return {
    intendedProfileType: registrationDetails.intendedProfileType,
    authenticationProvider: mapAuthenticationProvider(registrationDetails.authenticationProvider),
    emailAddress: registrationDetails.emailAddress,
    firstName: registrationDetails.firstName,
    surname: registrationDetails.surname,
    telephoneNumber: registrationDetails.phoneNumber.phoneNumber,
    telephoneNumberCountryIsoCode: registrationDetails.phoneNumber.countryCode,
    latitude: parseFloat(registrationDetails.latitude.toString()),
    longitude: parseFloat(registrationDetails.longitude.toString()),
    locationSearchPhrase: registrationDetails.searchPhrase,
    postcode: registrationDetails.postcode,
    countryIsoCode: registrationDetails.country,
    attributionSource: registrationDetails.attributionSource
  };
}

function mapAuthenticationProvider(authenticationProvider: UAuthenticationProvider): AuthenticationProvider {
  switch (authenticationProvider.authenticationType) {
    case 'apple':
      return {
        type: 'apple',
        appleUid: authenticationProvider.appleUid,
        appleRefreshToken: authenticationProvider.refreshToken,
        appleTokenExpiresAt: authenticationProvider.tokenExpiresAt
      };
    case 'facebook':
      return {
        type: 'facebook',
        facebookUid: authenticationProvider.facebookUid,
        facebookToken: authenticationProvider.facebookToken
      };
    case 'password':
    default:
      return {
        type: 'password',
        password: authenticationProvider.password
      };
  }
}
