import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { InformationalStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

export const Owner12A: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName, compositeNameExcludingFirstDog } = parseDogNames(props.manuscript.dogNames || []);
  const subject = props.manuscript.dogNames && props.manuscript.dogNames.length === 2 ? 'both' : 'all';

  const stepDescriptionText =
      `At the moment you can only add specific details for ${firstDogName}. `
    + `The next questions are regarding ${subject} of your dogs and you can tell us more about ${compositeNameExcludingFirstDog} in the description.`;

  return (
    <InformationalStep
      stepTitle='Tell us about your dogs'
      stepHeading='Impawtent info!'
      totalSteps={props.totalStepCount}
      currentStep={12}
      stepImageUrl={context.images['illustrations/img-person-with-two-dogs.svg']}
      nextButton={{
        icon: faChevronRight,
        text: 'Got it!',
        iconAlignment: 'right'
      }}
      onNextClick={() => props.record({ dogNameLimitationAccepted: true })}
    >
      <StandardStepDescription text={stepDescriptionText} />
    </InformationalStep>
  );
};
