import React from 'react';

import { IWelcomeActivityStreamEvent } from './IWelcomeActivityStreamEvent';
import { IActivityStreamEventComponent, IActivityStreamEventComponentProps } from './IActivityStreamEventComponent';

export const WelcomeEvent: IActivityStreamEventComponent<IWelcomeActivityStreamEvent> = (
  props: IActivityStreamEventComponentProps<IWelcomeActivityStreamEvent>
) => {
  return (
    <div className='panel__item panel__item_list activity-stream-event'>
      <div className='activity-stream-event__profile-image'>
        <div className='profile-image profile-image_basic'>
          <img src={props.borisImageURL} alt='' />
        </div>
      </div>
      <div className='activity-stream-event__content'>
        <div className='activity-stream-event__content-heading-text'>
          <span className='green'>
            Welcome
          </span>
          {' '}
          <span className='gray'>to</span>
          {' '}
          <span className='blue'>BorrowMyDoggy!</span>
        </div>
        <div className='activity-stream-event__content-heading-date'>
          {props.eventData.eventCreatedAt}
        </div>
      </div>
    </div>
  );
};
