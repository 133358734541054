type TelephoneCountryTree = {
  [key: number | string]: TelephoneCountryTree | string[];
};

const telephoneCountryTree: TelephoneCountryTree = {
  4: {
    4: {
      '*': ['GB', 'IM', 'JE'],
      '1': {
        4: {
          8: {
            1: ['GG']
          }
        }
      }
    },
    3: ['AT'],
    7: ['BV', 'NO', 'SJ'],
    2: {
      0: ['CZ'],
      3: ['LI'],
      1: ['SK']
    },
    5: ['DK'],
    9: ['DE'],
    8: ['PL'],
    0: ['RO'],
    6: ['SE'],
    1: ['CH']
  },
  3: {
    5: {
      3: ['IE'],
      5: ['AL'],
      9: ['BG'],
      7: ['CY'],
      8: ['FI', 'AX'],
      0: ['GI'],
      4: ['IS'],
      2: ['LU'],
      6: ['MT'],
      1: ['PT']
    },
    7: {
      6: ['AD'],
      4: ['AM'],
      5: ['BY'],
      2: ['EE'],
      9: ['VA'],
      1: ['LV'],
      0: ['LT'],
      3: ['MD'],
      7: ['MC'],
      8: ['SM']
    },
    2: ['BE'],
    8: {
      7: ['BA'],
      5: ['HR'],
      9: ['MK'],
      2: ['ME'],
      1: ['RS'],
      6: ['SI'],
      0: ['UA']
    },
    4: {
      '5': ['KY'],
      '*': ['ES']
    },
    3: ['FR'],
    0: ['GR'],
    6: ['HU'],
    9: ['IT'],
    1: ['NL']
  },
  9: {
    3: ['AF'],
    9: {
      4: ['AZ'],
      5: ['GE'],
      6: ['KG'],
      2: ['TJ'],
      3: ['TM'],
      8: ['UZ']
    },
    7: {
      3: ['BH'],
      5: ['BT'],
      2: ['IL'],
      6: ['MN'],
      7: ['NP'],
      0: ['PS'],
      4: ['QA'],
      1: ['AE']
    },
    1: ['IN'],
    8: ['IR'],
    6: {
      4: ['IQ'],
      2: ['JO'],
      5: ['KW'],
      1: ['LB'],
      0: ['MV'],
      8: ['OM'],
      6: ['SA'],
      3: ['SY'],
      7: ['YE']
    },
    5: ['MM'],
    2: ['PK'],
    4: ['LK'],
    0: ['TR']
  },
  2: {
    1: {
      3: ['DZ'],
      8: ['LY'],
      2: ['MA', 'EH'],
      1: ['SS'],
      6: ['TN']
    },
    4: {
      4: ['AO'],
      2: ['BS', 'CG'],
      6: ['BB', 'IO'],
      3: ['CD'],
      0: ['GQ'],
      1: ['GA'],
      5: ['GW'],
      8: ['SC'],
      9: ['SD']
    },
    6: {
      4: ['AI', 'NA'],
      8: ['AG', 'SZ'],
      7: ['BW'],
      9: ['KM'],
      6: ['LS'],
      1: ['MG'],
      5: ['MW'],
      2: ['YT', 'RE'],
      0: ['ZM'],
      3: ['ZW']
    },
    9: {
      7: ['AW'],
      1: ['ER'],
      8: ['FO'],
      9: ['GL'],
      0: ['SH']
    },
    2: {
      9: ['BJ'],
      6: ['BF'],
      5: ['CI', 'GN'],
      0: ['GM'],
      3: ['ML'],
      2: ['MR'],
      1: ['SN'],
      8: ['TG']
    },
    5: {
      7: ['BI'],
      3: ['DJ'],
      1: ['ET'],
      4: ['KE'],
      8: ['MZ'],
      0: ['RW'],
      2: ['SO'],
      5: ['TZ'],
      6: ['UG']
    },
    3: {
      8: ['CV'],
      7: ['CM'],
      6: ['CF'],
      5: ['TD'],
      3: ['GH'],
      1: ['LR'],
      0: ['MU'],
      4: ['NG'],
      9: ['ST'],
      2: ['SL']
    },
    0: ['EG'],
    7: {
      '7': ['NE'],
      '*': ['ZA']
    },
    8: {
      4: ['VG']
    }
  },
  6: {
    8: {
      4: ['AS'],
      2: ['CK'],
      9: ['PF', 'TF'],
      6: ['KI'],
      7: ['NC'],
      3: ['NU'],
      0: ['PW'],
      5: ['WS'],
      8: ['TV'],
      1: ['WF']
    },
    7: {
      2: ['AQ', 'NF'],
      3: ['BN'],
      9: ['FJ'],
      1: ['GU'],
      4: ['NR'],
      0: ['MP', 'TL'],
      5: ['PG'],
      7: ['SB'],
      6: ['TO'],
      8: ['VU']
    },
    1: ['AU', 'CX', 'HM'],
    2: ['ID'],
    0: ['MY'],
    9: {
      2: ['MH'],
      1: ['FM'],
      0: ['TK']
    },
    6: {
      '4': ['MS'],
      '*': ['TH']
    },
    4: ['NZ'],
    3: ['PH'],
    5: ['SG']
  },
  5: {
    4: ['AR'],
    0: {
      1: ['BZ'],
      6: ['CR'],
      3: ['SV'],
      0: ['FK', 'GS'],
      2: ['GT'],
      9: ['HT'],
      4: ['HN'],
      5: ['NI'],
      7: ['PA'],
      8: ['PM']
    },
    9: {
      1: ['BO'],
      9: ['BQ', 'CW', 'SX'],
      3: ['EC'],
      4: ['GF'],
      0: ['GP', 'BL', 'MF'],
      2: ['GY'],
      6: ['MQ'],
      5: ['PY'],
      7: ['SR'],
      8: ['UY']
    },
    5: ['BR'],
    6: ['CL'],
    7: ['CO'],
    3: ['CU'],
    2: ['MX'],
    1: ['PE'],
    8: ['VE']
  },
  8: {
    8: {
      0: ['BD'],
      6: ['TW']
    },
    5: {
      5: ['KH'],
      2: ['HK'],
      0: ['KP'],
      6: ['LA'],
      3: ['MO']
    },
    6: ['CN'],
    9: {
      1: ['CC']
    },
    0: {
      9: ['DO']
    },
    7: {
      6: ['JM'],
      2: ['PN']
    },
    1: ['JP'],
    2: ['KR'],
    4: ['VN']
  },
  1: {
    '4': {
      4: {
        1: ['BM']
      },
      7: {
        3: ['GD']
      }
    },
    '*': ['CA', 'UM', 'US'],
    '7': {
      6: {
        7: ['DM']
      },
      8: {
        7: ['PR'],
        4: ['VC']
      },
      5: {
        8: ['LC']
      }
    },
    '8': {
      6: {
        9: ['KN'],
        8: ['TT']
      }
    },
    '6': {
      4: {
        9: ['TC']
      }
    },
    '3': {
      4: {
        0: ['VI']
      }
    }
  },
  7: ['KZ', 'RU']
};

interface ICountryCodeLookUp {
  countryIsoCodes: string[];
  callingCode: string;
}

export function lookUpCountryCode(phoneNumber: string): ICountryCodeLookUp | null {
  let cleanedNumber = phoneNumber.replace(/[^0-9]/g, '');
  if (cleanedNumber.startsWith('00')) {
    cleanedNumber = cleanedNumber.slice(2);
  }
  let charIndex = 0;
  let callingCode = '';
  const history = [];
  let node: TelephoneCountryTree | string[] = telephoneCountryTree;
  while (node[cleanedNumber[charIndex]]) {
    node = node[cleanedNumber[charIndex]];
    callingCode += cleanedNumber[charIndex];
    if (Array.isArray(node)) {
      break;
    }
    history.push(node);
    charIndex++;
  }
  if (Array.isArray(node)) {
    // We have found an exact match.
    return { countryIsoCodes: node, callingCode };
  } else if (node['*'] && Array.isArray(node['*'])) {
    // There are more specific codes, but we can use the generic * ones.
    return { countryIsoCodes: node['*'], callingCode };
  } else {
    // There isn't a result at this depth, so walk back up the tree until we (hopefully) find some results.
    while (history.length > 0) {
      const poppedHistoryItem = history.pop();
      if (poppedHistoryItem) {
        node = poppedHistoryItem;
        if (node && node['*']) {
          break;
        }
        callingCode = callingCode.slice(0, callingCode.length - 1);
      }
    }
    return node && node['*'] && Array.isArray(node['*']) ? { countryIsoCodes: node['*'], callingCode } : null;
  }
}
