import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { BorrowerActivityStream, OwnerActivityStream } from '@borrowmydoggy/bmd-api';
import { LoadingIndicator } from '@borrowmydoggy/core-components';

import { ActivityStreamFilter, ActivityStreamFilterType } from '../activity-stream';
import {
  BorrowingInfoUpdateBorrowerActivityStreamEvent,
  BorrowingInfoUpdateOwnerActivityStreamEvent,
  IActivityStreamEventComponent,
  InboundLikeBorrowerActivityStreamEvent,
  InboundLikeOwnerActivityStreamEvent,
  InboundMessageBorrowerActivityStreamEvent,
  InboundMessageOwnerActivityStreamEvent,
  OutboundLikeBorrowerActivityStreamEvent,
  OutboundLikeOwnerActivityStreamEvent,
  ProfileType,
  UActivityStreamEvent,
  UBorrowerActivityStreamEvent,
  UOwnerActivityStreamEvent,
  WelcomeEvent
} from '../activity-stream/events';
import { ProfileAPI } from '../api';

export interface IActivityStreamProps {
  profileType: ProfileType;
  borisImageURL: string;
  missingBorrowerImageURL: string;
  missingOwnerImageURL: string;
}

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: auto;
  max-width: 80px;
`;

export const ActivityStream: FunctionComponent<IActivityStreamProps> = (props: IActivityStreamProps) => {
  const [initializing, setInitializing] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activityStreamEvents, setActivityStreamEvents] = useState<UActivityStreamEvent[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(true);
  const [filter, setFilter] = useState<ActivityStreamFilterType>('all');

  async function loadData(customFilter: ActivityStreamFilterType = 'all', page = 1, reset = false): Promise<void> {
    try {
      let result: BorrowerActivityStream | OwnerActivityStream | null = null;
      if (props.profileType === 'owner') {
        const ownerActivityStreamResponse = await ProfileAPI.ownerActivityStream(page, customFilter);
        if (ownerActivityStreamResponse.error === undefined && ownerActivityStreamResponse.data && ownerActivityStreamResponse.data.ownerActivityStream) {
          result = ownerActivityStreamResponse.data.ownerActivityStream;
        }
      } else {
        const borrowerActivityStreamResponse = await ProfileAPI.borrowerActivityStream(page, customFilter);
        if (
          borrowerActivityStreamResponse.error === undefined
          && borrowerActivityStreamResponse.data
          && borrowerActivityStreamResponse.data.borrowerActivityStream
        ) {
          result = borrowerActivityStreamResponse.data.borrowerActivityStream;
        }
      }
      if (result) {
        setCurrentPage(result.currentPage);
        setLastPage(result.lastPage);
        setFilter(result.filter);
        if (reset) {
          setActivityStreamEvents(result.activityStreamEvents as UActivityStreamEvent[]);
        } else {
          setActivityStreamEvents(activityStreamEvents.concat(result.activityStreamEvents as UActivityStreamEvent[]));
        }
      } else {
        console.error('Unable to retrieve activity stream.');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInitializing(false);
      setLoadingMore(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  function handleShowMore(): void {
    if (!lastPage) {
      setLoadingMore(true);
      loadData(filter, currentPage + 1);
    }
  }

  function handleFilterChange(newFilterType: ActivityStreamFilterType): void {
    loadData(newFilterType, 1, true);
  }

  function renderLoader(profileType: ProfileType): ReactNode {
    const profileTypeClass = profileType === 'owner' ? 'dog' : 'borrower';

    return (
      <div className='panel__content'>
        <div className={`panel__content-loading panel__content-loading_${profileTypeClass}`}>
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  function renderEventComponent<E extends UActivityStreamEvent>(EventComponent: IActivityStreamEventComponent<E>, eventData: E): ReactNode {
    return (
      <EventComponent
        key={eventData.eventId}
        eventData={eventData}
        borisImageURL={props.borisImageURL}
        missingBorrowerImageURL={props.missingBorrowerImageURL}
        missingOwnerImageURL={props.missingOwnerImageURL}
      />
    );
  }

  function renderBorrowerEvent(eventData: UBorrowerActivityStreamEvent): ReactNode {
    switch (eventData.eventType) {
      case 'welcome':
        return renderEventComponent(WelcomeEvent, eventData);
      case 'borrowingInfoUpdate':
        return renderEventComponent(BorrowingInfoUpdateBorrowerActivityStreamEvent, eventData);
      case 'inboundLike':
        return renderEventComponent(InboundLikeBorrowerActivityStreamEvent, eventData);
      case 'inboundMessage':
        return renderEventComponent(InboundMessageBorrowerActivityStreamEvent, eventData);
      case 'outboundLike':
        return renderEventComponent(OutboundLikeBorrowerActivityStreamEvent, eventData);
    }
  }

  function renderOwnerEvent(eventData: UOwnerActivityStreamEvent): ReactNode {
    switch (eventData.eventType) {
      case 'welcome':
        return renderEventComponent(WelcomeEvent, eventData);
      case 'borrowingInfoUpdate':
        return renderEventComponent(BorrowingInfoUpdateOwnerActivityStreamEvent, eventData);
      case 'inboundLike':
        return renderEventComponent(InboundLikeOwnerActivityStreamEvent, eventData);
      case 'inboundMessage':
        return renderEventComponent(InboundMessageOwnerActivityStreamEvent, eventData);
      case 'outboundLike':
        return renderEventComponent(OutboundLikeOwnerActivityStreamEvent, eventData);
    }
  }

  function renderEvents(): ReactNode {
    let renderedEvents: ReactNode[];
    if (props.profileType === 'owner') {
      renderedEvents = activityStreamEvents.map(e => renderOwnerEvent(e as UOwnerActivityStreamEvent));
    } else {
      renderedEvents = activityStreamEvents.map(e => renderBorrowerEvent(e as UBorrowerActivityStreamEvent));
    }
    return (
      <div className='panel__content'>
        <div className='panel__list'>
          {renderedEvents}
        </div>
      </div>
    );
  }

  function renderEmpty(borisImageURL: string): ReactNode {
    return (
      <div className='panel__content activity-stream__empty'>
        <div className='profile-image profile-image_basic'>
          <img src={borisImageURL} alt='' />
        </div>
        <p>Nothing here yet.</p>
      </div>
    );
  }

  function renderContent(profileType: ProfileType, borisImageURL: string): ReactNode {
    if (initializing) {
      return renderLoader(profileType);
    } else if (activityStreamEvents.length > 0) {
      return renderEvents();
    } else {
      return renderEmpty(borisImageURL);
    }
  }

  function renderFooter(): ReactNode {
    if (loadingMore) {
      return (
        <div className='panel__footer'>
          <StyledLoadingIndicator />
        </div>
      );
    } else if (!lastPage) {
      return (
        <div className='panel__footer'>
          <button onClick={handleShowMore} className='button button_small button_tertiary activity-stream__show-more'>
            Show more
            <i className='fas fa-chevron-down'/>
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div className='panel panel_v2 activity-stream'>
      <div className='panel__heading'>
        <div className='panel__title'>
          Activity
        </div>
        <ActivityStreamFilter onFilterChange={handleFilterChange} />
      </div>
      {renderContent(props.profileType, props.borisImageURL)}
      {renderFooter()}
    </div>
  );
};
