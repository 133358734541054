import { Big } from 'big.js';
import { z } from 'zod';
import { UserAccount } from '@borrowmydoggy/core-models';

import { authenticationProvider } from './authenticationProvider';

export const userAccountRegistrationDetails = z.object({
  intendedProfileType: z.enum(['borrower', 'owner']),
  authenticationProvider,
  emailAddress: UserAccount.emailAddress,
  firstName: UserAccount.firstName,
  surname: UserAccount.lastName,
  phoneNumber: UserAccount.phoneNumber,
  latitude: z.instanceof(Big).refine(b => b.toNumber()),
  longitude: z.instanceof(Big).refine(b => b.toNumber()),
  searchPhrase: z.string(),
  postcode: z.string().optional(),
  country: z.string(),
  attributionSource: z.string().optional()
});

export type UserAccountRegistrationDetails = z.infer<typeof userAccountRegistrationDetails>;
