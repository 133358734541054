import React, { ChangeEvent, FunctionComponent, FormEvent, useState } from 'react';

export interface IVoucherRedemptionFormProps {
  title: string;
  voucherRedemptionPath: string;
  csrfToken: string;
  onSuccessfulRedemption?: (redeemedVoucherType: string, voucherProviderName: string) => void;
}

export const VoucherRedemptionForm: FunctionComponent<IVoucherRedemptionFormProps> = (props: IVoucherRedemptionFormProps) => {
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherCodeFeedback, setVoucherCodeFeedback] = useState('');
  const [voucherCodeRejected, setVoucherCodeRejected] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  function handleCodeChange(event: ChangeEvent<HTMLInputElement>): void {
    setVoucherCode(event.target.value.toUpperCase());
  }

  function handleFormSubmission(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setVoucherCodeFeedback('');
    setVoucherCodeRejected(false);
    setSubmissionInProgress(true);

    const data = {
      voucher_redemption_request: {
        code: voucherCode
      }
    };

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': props.csrfToken },
      body: JSON.stringify(data)
    };
    fetch(props.voucherRedemptionPath, requestOptions).then(response => {
      return response.json();
    }).then(json => {
      if (json.status && json.status === 'success') {
        const redeemedVoucherType = json.voucherType;
        const voucherProviderName = json.providerName;
        setVoucherCodeFeedback('');
        setVoucherCodeRejected(false);
        if (props.onSuccessfulRedemption) {
          props.onSuccessfulRedemption(redeemedVoucherType, voucherProviderName);
        }
      } else {
        const errorMessage = json.errors || 'Sorry, there was a problem redeeming your voucher.';
        setVoucherCodeFeedback(errorMessage);
        setVoucherCodeRejected(true);
        setSubmissionInProgress(false);
      }
    }).catch(error => {
      console.error(error);
      const errorMessage = 'Sorry, there was a problem redeeming your voucher.';
      setVoucherCodeFeedback(errorMessage);
      setSubmissionInProgress(false);
    });
  }

  return (
    <div>
      <h1 className='page-title premium'>{props.title}</h1>
      <form method='post' className='new-form space-above-medium' onSubmit={handleFormSubmission}>
        <fieldset>
          <legend>Voucher Details</legend>
          <div className='field'>
            <label htmlFor='code'>Voucher code</label>
            <input
              type='text'
              name='voucher-code'
              id='code'
              value={voucherCode}
              aria-invalid={voucherCodeRejected}
              onChange={handleCodeChange}
            />
            <span className='feedback'>{voucherCodeFeedback}</span>
          </div>
          <div className='element-group space-above-small'>
            <button
              type='submit'
              className={`new-button primary wide ${submissionInProgress ? 'activity' : ''}`}
              disabled={submissionInProgress}
            >
              Redeem now
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
