import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';
import { WebContext } from '../utils/AppContext';

export const Owner6: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);
  const pronoun = props.manuscript.dogGender ? parseDogGender(props.manuscript.dogGender).genderedPronoun : 'they';

  return (
    <BinaryStep
      stepTitle={`Tell us about ${firstDogName}`}
      stepHeading={`Is ${firstDogName} good with children?`}
      totalSteps={props.totalStepCount}
      currentStep={6}
      stepImageUrl={context.images['illustrations/img-child-with-dog-in-heart.svg']}
      backButton={{
        text: 'Back'
      }}
      onBinaryButtonClick={polarity => props.record({ goodWithChildren: polarity, goodWithChildrenConfirmed: true })}
      onBackClick={() => props.record({ dogAgeConfirmed: false, dogAgeRestrictionsAccepted: false })}
    >
      <StandardStepDescription text={`Could ${pronoun} be borrowed by a family with small children?`} />
    </BinaryStep>
  );
};
