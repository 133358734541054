import React, { ReactNode, useContext, useState } from 'react';
import { z } from 'zod';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { EmailAddressField, Form, FormSubmissionResult } from '@borrowmydoggy/form-components';
import { InlineTextButton, Paragraph, SmallText } from '@borrowmydoggy/core-components';
import { UserAccount } from '@borrowmydoggy/core-models';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const emailAddressSchema = z.object({ emailAddress: UserAccount.emailAddress });
type EmailAddressSchema = z.infer<typeof emailAddressSchema>;

export const User5A: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);
  const [formInError, setFormInError] = useState(false);

  async function handleSubmit(data: EmailAddressSchema): Promise<FormSubmissionResult<EmailAddressSchema>> {
    const updatedManuscript = await props.operations.checkEmailAddressAcceptability(data.emailAddress);
    switch (updatedManuscript.emailAddressCheckStatus) {
      case 'acceptable':
      case 'unacceptableDuplicate':
        return { outcome: 'success', formStateAction: 'halt' }; // Need to move to another step
      case 'unacceptableInvalid':
        const errorMessage =
          updatedManuscript.suggestedEmailAddress ?
            `This doesn’t look quite right, did you mean ${updatedManuscript.suggestedEmailAddress}?` :
            'This doesn’t look quite right, please check and try again';

        return { outcome: 'failure', errors: { emailAddress: errorMessage } };
      case 'error':
      default:
        setFormInError(true);
        return { outcome: 'error' };
    }
  }

  function renderStepDescription(): ReactNode {
    let description: string;
    if (props.manuscript.variant === 'new') {
      description = `We’ll notify you ${props.manuscript.intendedProfileType === 'owner' ? 'of local trusted borrowers' : 'about dogs in your area'}`;
    } else {
      description = 'What email address do you want to use? Make sure it’s one you check regularly.';
    }
    if (props.manuscript.emailAddress) {
      switch (props.manuscript.desiredAuthenticationType) {
        case 'apple':
          description = 'This is the email address that Apple have given us - but you can change it now if you want to.';
          if (props.manuscript.emailAddress.endsWith('@privaterelay.appleid.com')) {
            description = 'You chose to hide your email address with Apple so they’ve given us this address for you. When we send you emails Apple will forward them to the email address they have for you. You can choose to change it now if you prefer.';
          }
          break;
        case 'facebook':
          description = 'This is the email address that Facebook have given us - but you can change it now if you want to.';
          break;
      }
    }
    return <StandardStepDescription inError={formInError} text={description} />;
  }

  let title: string;
  let heading: string;
  if (props.manuscript.variant === 'new') {
    title = `Check out local ${props.manuscript.intendedProfileType == 'owner' ? 'borrowers' : 'dogs'} for free`;
    heading = 'What is your email address?';
  } else {
    title = `Welcome new ${props.manuscript.intendedProfileType == 'owner' ? 'pup' : 'borrower'}!`;
    heading = 'Let’s start with your email address';
  }

  return (
    <StepContainer
      stepTitle={title}
      totalSteps={props.totalStepCount}
      currentStep={5}
      stepImageUrl={context.images['illustrations/img-person-holding-envelope.svg']}
    >
      <StepHeading text={heading} />
      {renderStepDescription()}
      <Form initialValues={{ emailAddress: props.manuscript.emailAddress }} schema={emailAddressSchema} onSubmit={handleSubmit}>
        {({ fields, handleChange, handleValidate, submitButtonStatus, formEnabled }) => (
          <>
            <EmailAddressField
              id='emailAddress'
              value={fields.emailAddress.value}
              validationState={fields.emailAddress.validationState}
              onValidate={handleValidate}
              onChange={handleChange}
              disabled={!formEnabled}
            />
            <Paragraph>
              <SmallText>
                By signing up, you agree to our{' '}
                <InlineTextButton size='small' link={{ href: '/privacy-policy', blankTarget: true }}>privacy policy</InlineTextButton>
                {' '}and{' '}
                <InlineTextButton size='small' link={{ href: '/terms-and-conditions', blankTarget: true }}>
                  terms and conditions
                </InlineTextButton>.
              </SmallText>
            </Paragraph>
            <StepNavigation
              nextButton={{ text: 'Next', icon: faChevronRight, iconAlignment: 'right' }}
              inProgress={submitButtonStatus === 'inProgress'}
              backButton={
                props.manuscript.emailAddressCheckStatus === 'unacceptableInvalid' && props.manuscript.suggestedEmailAddress ?
                  { text: 'Use suggested email' } :
                  undefined
              }
              onBackClick={event => {
                event.preventDefault();
                if (props.manuscript.suggestedEmailAddress) {
                  handleChange('emailAddress', props.manuscript.suggestedEmailAddress);
                }
              }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
