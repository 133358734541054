import React, { useContext } from 'react';
import { faPaw, faShoePrints } from '@fortawesome/pro-solid-svg-icons';

import { DecisionStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';
import { ProfileType } from '@borrowmydoggy/core-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const User3: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  function handleDecisionClick(profileType: ProfileType): void {
    if (props.manuscript.authVariant === 'new') {
      props.record({ intendedProfileType: profileType, desiredAuthenticationType: 'password', authenticationProviderConfirmed: true });
    } else {
      props.record({ intendedProfileType: profileType });
    }
  }

  const context = useContext(WebContext);
  return (
    <DecisionStep
      stepTitle={props.manuscript.variant === 'new' ? 'Owner or borrower?' : 'Can’t wait to meet you'}
      stepHeading={props.manuscript.variant === 'new' ? 'Which describes you?' : 'First tell us which describes you:'}
      totalSteps={props.totalStepCount}
      currentStep={3}
      buttons={[
        {
          id: 'owner',
          title: 'My dog would love extra walks and attention',
          buttonType: 'primary',
          buttonText: 'I’m an owner',
          buttonIcon: faPaw,
          illustrationURL: context.images['illustrations/img-person-with-dog.svg']
        },
        {
          id: 'borrower',
          title: 'I would love to spend time with a dog',
          buttonType: 'primary',
          buttonText: 'I’m a borrower',
          buttonIcon: faShoePrints,
          illustrationURL: context.images['illustrations/img-dog-with-person.svg']
        }
      ]}
      onDecisionClick={handleDecisionClick}
    >
      <StandardStepDescription text='We have two types of member; please choose one.' />
    </DecisionStep>
  );
};
