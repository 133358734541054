export interface IEnvironment {
  railsEnv: string;
  bugsnagApiKey: string;
  bugsnagReleaseStage: string;
  errorImage: string;
  rudderstackDataPlaneUrl: string;
  rudderstackFrontendWriteKey: string;
  userTrackingId: string;
  apiEndpoint: string;
}

export function readReactEnvironment(): IEnvironment {
  let environment = {
    railsEnv: 'development',
    bugsnagApiKey: '',
    bugsnagReleaseStage: '',
    errorImage: '',
    rudderstackDataPlaneUrl: '',
    rudderstackFrontendWriteKey: '',
    userTrackingId: '',
    apiEndpoint: '/graphql'
  };
  try {
    const reactEnvironmentElement = document.querySelector('meta[name=react-environment]');
    if (reactEnvironmentElement) {
      const environmentAttribute = reactEnvironmentElement.attributes.getNamedItem('content');
      if (environmentAttribute) {
        const environmentJson = environmentAttribute.value;
        environment = JSON.parse(environmentJson);
      }
    }
  } catch (e) {
    console.warn('Unable to read React environment details', e);
  }
  return environment;
}
