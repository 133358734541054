import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { QuestionProcessBanner } from '@borrowmydoggy/product-components';

import { UserAPI } from '../api';

const StyledParagraph = styled.p`
  font-size: 1.6rem;
`;

export const ContentUsagePermissionBanner: FunctionComponent = () => {
  async function updateUserAccountPermissionToUse(permissionToUse: boolean): Promise<boolean> {
    const response = await UserAPI.updateUserAccountPermissionToUse(permissionToUse);
    if (response.error === undefined && response.data && response.data.updateUserAccountPermissionToUse) {
      if (response.data.updateUserAccountPermissionToUse.errors == null) {
        return true;
      } else {
        console.warn(response.data.updateUserAccountPermissionToUse.errors);
        return false;
      }
    } else {
      console.warn('API failure when attempting to update user account permission to use flag.', response.error)
      return false;
    }
  }

  async function handleYesChosen(): Promise<boolean> {
    return await updateUserAccountPermissionToUse(true);
  }

  async function handleNoChosen(): Promise<boolean> {
    return await updateUserAccountPermissionToUse(false);
  }

  const initialContent = (
    <StyledParagraph>We love sharing our members&rsquo; photos with our social community. Would you like your doggy to be featured?</StyledParagraph>
  );
  const completePositiveContent = (
    <StyledParagraph>Fantastic, we&rsquo;ll get to showing off your adorable pooch!</StyledParagraph>
  );
  const completeNegativeContent = (
    <StyledParagraph>No problem, we won&rsquo;t feature your pooch.</StyledParagraph>
  );

  return (
    <QuestionProcessBanner
      initialContent={initialContent}
      completePositiveContent={completePositiveContent}
      completeNegativeContent={completeNegativeContent}
      onYesChosen={handleYesChosen}
      onNoChosen={handleNoChosen}
    />
  );
};
