import React, { FunctionComponent, MouseEvent, ReactNode, useContext, useState } from 'react';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';

import {
  FacebookShareButton,
  FBMessengerShareButton,
  FeatureAside,
  HeroHeader,
  HighlightPanel,
  HighlightSection,
  responsiveQuery,
  TextButton,
  TwitterShareButton,
  WhatsAppShareButton
} from '@borrowmydoggy/core-components';

import { IMultipleEmailAddressFormSubmissionResult, MultipleEmailAddressForm } from '../forms';
import { ReferralAPI } from '../api';
import { WebContext } from '../utils/AppContext';

export interface IReferAFriendProps {
  facebookAppId: string;
  facebookLinkUrl: string;
  facebookRedirectUri: string;
  twitterTweetUrl: string;
  facebookMessengerAppId: string;
  facebookMessengerLinkUrl: string;
  facebookMessengerRedirectUri: string;
  whatsAppLinkUrl: string;
  giftVouchersPath: string;
  ambassadorProgrammePath: string;
  referrerUrl: string;
}

const StyledReferAFriend = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-areas: 'pageHeader'
                       'shareActions'
                       'onwardJourneys';

  &>.pageHeader {
    grid-area: pageHeader;
  }

  &>.shareActions {
    grid-area: shareActions;
  }

  &>.onwardJourneys {
    grid-area: onwardJourneys;
    display: grid;
  }

  ul.emailList {
    li {
      font-family: Lato, sans-serif;
      font-weight: bold;
    }
  }

  ${responsiveQuery('tabletLandscape', 'desktop')} {
    &>.onwardJourneys {
      max-width: 1110px;
      margin: 100px auto;
      padding: 0 10px;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      grid-gap: 50px;
    }
  }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
    &>.onwardJourneys {
      margin: 50px auto;
      padding: 0 30px;
      grid-auto-flow: row;
      grid-gap: 50px;
    }
  }

  ${responsiveQuery('mobilePortrait')} {
    &>.onwardJourneys {
      margin: 30px auto;
      padding: 0 10px;
      grid-auto-flow: row;
      grid-gap: 40px;
    }
  }
`;

const StyledHighlightSection = styled(HighlightSection)`
  &>.wrapper {
    display: grid;
  }

  ${responsiveQuery('tabletLandscape', 'desktop')} {
    &>.wrapper {
      margin: auto;
      justify-content: center;
      max-width: 1110px;
      padding: 30px 10px 0 10px;
      grid-template-columns: minmax(100px, 349px) minmax(100px, 349px) minmax(100px, 349px);
      grid-gap: 33px;
    }
  }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
    &>.wrapper {
      grid-auto-flow: row;
      padding: 30px 30px 0 30px;
      grid-gap: 30px;
    }
  }

  ${responsiveQuery('mobilePortrait')} {
    &>.wrapper {
      grid-auto-flow: row;
      padding: 30px 10px 0 10px;
      grid-gap: 30px;
    }
  }
`;

const ShareButtons = styled.div`
  display: grid;
  justify-content: center;

  ${responsiveQuery('tabletLandscape', 'desktop')} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }

  ${responsiveQuery('mobilePortrait')} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;

const StyledMultipleEmailAddressForm = styled(MultipleEmailAddressForm)`
  ${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    &>span {
      grid-column: 1;
    }

    &>input[type='submit'] {
      grid-column: 2;
    }
  }
`;

const CopyButton = styled(TextButton)`
  display: grid;
`;

export const ReferAFriend: FunctionComponent<IReferAFriendProps> = (props: IReferAFriendProps) => {
  const [copied, setCopied] = useState(false);
  const [emailedSuccessfully, setEmailedSuccessfully] = useState(false);
  const [emailedAddresses, setEmailedAddresses] = useState<string[]>([]);

  const context = useContext(WebContext);

  async function createEmailReferrals(emailAddresses: string[]): Promise<IMultipleEmailAddressFormSubmissionResult> {
    const response = await ReferralAPI.createEmailReferrals(emailAddresses);
    if (response.error === undefined && response.data && response.data.createEmailReferrals) {
      if (response.data.createEmailReferrals.successful) {
        setEmailedSuccessfully(true);
        setEmailedAddresses(response.data.createEmailReferrals.emailedAddresses);
        return { successful: true, failedAddresses: [] };
      } else {
        setEmailedSuccessfully(false);
        setEmailedAddresses(response.data.createEmailReferrals.emailedAddresses);
        return { successful: true, failedAddresses: response.data.createEmailReferrals.addressesWithErrors };
      }
    } else {
      console.warn('API failure when attempting to create email referrals.', response.error)
      return { successful: false, failedAddresses: [] };
    }
  }

  function handleCopyButtonClick(): void {
    copy(props.referrerUrl);
    setCopied(true);
  }

  function handleEmailAddressesSubmit(emailAddresses: string[]): Promise<IMultipleEmailAddressFormSubmissionResult> {
    return createEmailReferrals(emailAddresses);
  }

  function handleSendMoreClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    setEmailedSuccessfully(false);
    setEmailedAddresses([]);
  }

  function renderCopyReferrerButton(): ReactNode {
    return (
      <CopyButton
        text={copied ? 'Copied!' : 'Copy'}
        onClick={handleCopyButtonClick}
        size='small'
        className='inlineButton'
      />
    );
  }

  function renderEmailAddressForm(): ReactNode {
    if (emailedSuccessfully) {
      return (
        <HighlightPanel title='Thanks!'>
          <span>We&rsquo;ve emailed your friends at:</span>
          <ul className='emailList'>
            {emailedAddresses.map((emailAddress, index) => <li key={index}>{emailAddress}</li>)}
          </ul>
          <a href='#' className='new-link primary' onClick={handleSendMoreClick}>Send more</a>
        </HighlightPanel>
      );
    } else {
      return (
        <HighlightPanel title='or send an email:'>
          <StyledMultipleEmailAddressForm maxFieldCount={5} onSubmit={handleEmailAddressesSubmit} />
        </HighlightPanel>
      );
    }
  }

  return (
    <StyledReferAFriend>
      <HeroHeader
        title='Share the love of dogs'
        imageNode={<img src={context.images['illustrations/img-person-holding-dog.svg']} alt='Person holding dog' />}
        className='pageHeader'
      >
        <p>
          We&rsquo;re a small but ambitious team on a mission to leave &ldquo;Pawprints of Happiness&rdquo; on the lives of dogs and people and you can help!
          Spread the word to grow your local community and make dogs, owners and borrowers happier.
        </p>
      </HeroHeader>
      <StyledHighlightSection className='shareActions'>
        <HighlightPanel title='Share on:'>
          <ShareButtons>
            <FacebookShareButton
              facebookAppId={props.facebookAppId}
              urlToShare={props.facebookLinkUrl}
              description="BorrowMyDoggy connects dog owners and local borrowers. I'm a member and thought all my dog loving friends might enjoy it too."
              redirectUri={props.facebookRedirectUri}
            />
            <TwitterShareButton
              urlToShare={props.twitterTweetUrl}
              tweetText="Join @borrowmydoggy 🐶It connects dog owners with local dog borrowers for walks, weekends and holidays. Owner and borrowers become good friends and are part of a dog-loving community. I'm a member and thought you might enjoy it too!"
            />
            <FBMessengerShareButton
              facebookAppId={props.facebookMessengerAppId}
              urlToShare={props.facebookMessengerLinkUrl}
              redirectUri={props.facebookMessengerRedirectUri}
            />
            <WhatsAppShareButton
              urlToShare={props.whatsAppLinkUrl}
              messageText="BorrowMyDoggy connects dog owners and local borrowers. I'm a member and thought all my dog loving friends might enjoy it too."
            />
          </ShareButtons>
        </HighlightPanel>
        <HighlightPanel title='or copy your personal link:'>
          <span>{props.referrerUrl}</span>
          {renderCopyReferrerButton()}
        </HighlightPanel>
        {renderEmailAddressForm()}
      </StyledHighlightSection>
      <div className='onwardJourneys'>
        <FeatureAside
          title='The gift of a dog&rsquo;s love'
          text='Want to get someone special on BorrowMyDoggy right now? A gift voucher will help them on their way.'
          button={<TextButton text='Gift vouchers' link={{ href: props.giftVouchersPath }} wide />}
          imageUrl={context.images['illustrations/img-dog-gift-envelope.svg']}
          alignment='left'
        />
        <FeatureAside
          title='Want to help even more?'
          text='Become an ambassador to help us spread the word even further!'
          button={<TextButton text='Ambassador programme' link={{ href: props.ambassadorProgrammePath }} wide />}
          imageUrl={context.images['illustrations/img-person-holding-papers.svg']}
          alignment='right'
        />
      </div>
    </StyledReferAFriend>
  );
};
