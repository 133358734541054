import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { AvailabilityCheckBoxGroup } from '@borrowmydoggy/product-components';
import { Form, FormSubmissionResult } from '@borrowmydoggy/form-components';
import { OwnerProfile } from '@borrowmydoggy/core-models';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

const availabilitySchema = z.object({ availability: OwnerProfile.availability });
type AvailabilitySchema = z.infer<typeof availabilitySchema>;

export const Owner13: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { subject } = parseDogNames(props.manuscript.dogNames || []);

  function handleChangeNeeds(): void {
    props.record({ dogNeedsConfirmed: false });
  }

  function handleSubmit(data: AvailabilitySchema): FormSubmissionResult<AvailabilitySchema> {
    const manuscriptChanges: Partial<IOwnerProfileCreationManuscript> = { availability: data.availability };
    props.record(manuscriptChanges);
    return { outcome: 'success' };
  }

  return (
    <StepContainer
      stepTitle={`Tell us about ${subject}`}
      totalSteps={props.totalStepCount}
      currentStep={13}
      stepImageUrl={context.images['illustrations/img-dog-with-clock.svg']}
    >
      <StepHeading text='When would you like a borrower?' />
      <StandardStepDescription text={`What times do you need a helping paw looking after ${subject}?`} />
      <Form schema={availabilitySchema} onSubmit={handleSubmit}>
        {({ fields, handleChange, handleValidate }) => (
          <>
            <AvailabilityCheckBoxGroup
              id='availability'
              legend='Choose your availability'
              onChange={handleChange}
              onValidate={handleValidate}
              value={fields.availability.value}
              validationState={fields.availability.validationState}
            />
            <StepNavigation
              onBackClick={handleChangeNeeds}
              backButton={{ text: 'Back' }}
              nextButton={{
                icon: faChevronRight,
                text: 'Next',
                iconAlignment: 'right'
              }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
