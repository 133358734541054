import React, { useContext } from 'react';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';
import { DateTime } from 'luxon';

import { DateField, Form, FormSubmissionResult } from '@borrowmydoggy/form-components';
import { Dog } from '@borrowmydoggy/core-models';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

const dogDateOfBirthSchema = z.object({ dogDateOfBirth: Dog.dateOfBirth });
type DogDateOfBirthSchema = z.infer<typeof dogDateOfBirthSchema>;

const DateFieldContainer = styled.div`
  display: grid;
  justify-items: center;
`;

export const Owner5A: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);

  function handleSubmit(data: DogDateOfBirthSchema): FormSubmissionResult<DogDateOfBirthSchema> {
    const newDateOfBirth = DateTime.fromJSDate(data.dogDateOfBirth);
    const youngPup = newDateOfBirth > DateTime.now().minus({ weeks: 8 });
    props.record({ dogDateOfBirth: data.dogDateOfBirth, dogAgeConfirmed: true, dogAgeRestrictionsAccepted: !youngPup });
    return { outcome: 'success' };
  }

  return (
    <StepContainer
      stepTitle={`Tell us about ${firstDogName}`}
      totalSteps={props.totalStepCount}
      currentStep={5}
      stepImageUrl={context.images['illustrations/img-dog-wearing-party-hat.svg']}
    >
      <StepHeading text={`When was ${firstDogName} born?`} />
      <StandardStepDescription text='Don’t worry if you don’t know the exact date, your best guess will be pawfect.' />
      <Form
        schema={dogDateOfBirthSchema}
        initialValues={{ dogDateOfBirth: props.manuscript.dogDateOfBirth }}
        onSubmit={handleSubmit}
      >
        {({ fields, handleChange, handleValidate }) => (
          <>
            <DateFieldContainer>
              <DateField
                id='dogDateOfBirth'
                label='Date of birth'
                value={fields.dogDateOfBirth.value}
                validationState={fields.dogDateOfBirth.validationState}
                onChange={handleChange}
                onValidate={handleValidate}
              />
            </DateFieldContainer>
            <StepNavigation
              nextButton={{
                text: 'Next',
                icon: faChevronRight,
                iconAlignment: 'right'
              }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
