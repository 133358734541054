import React, { FunctionComponent } from 'react';

import { GoPremiumBorrowerMessageBanner } from '@borrowmydoggy/product-components';

export interface IGoPremiumBorrowerBannerProps {
  subscribeUrl: string;
}

export const GoPremiumBorrowerBanner: FunctionComponent<IGoPremiumBorrowerBannerProps> = (props: IGoPremiumBorrowerBannerProps) => {
  return <GoPremiumBorrowerMessageBanner subscribeUrl={props.subscribeUrl} />;
};
