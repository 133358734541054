import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { InformationalStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

export const Owner5B: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);

  return (
    <InformationalStep
      stepTitle={`Tell us about ${firstDogName}`}
      stepHeading='Impawtent…'
      totalSteps={props.totalStepCount}
      currentStep={5}
      stepImageUrl={context.images['illustrations/img-dog-wearing-party-hat.svg']}
      nextButton={{
        icon: faChevronRight,
        text: 'OK, I understand',
        iconAlignment: 'right'
      }}
      onNextClick={() => props.record({ dogAgeRestrictionsAccepted: true })}
    >
      <StandardStepDescription text='Puppies must be at least 8 weeks old to be covered by our insurance.' />
    </InformationalStep>
  );
};
