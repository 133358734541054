import React, { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { faCheck, faExclamationTriangle, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { CheckBoxField, CheckBoxGroup, ICheckBoxGroupItem } from '@borrowmydoggy/form-components';
import { ContactPreferences } from '@borrowmydoggy/bmd-api';
import { Icon, InlineTextButton, PageHeading, ProfileType, responsiveQuery, SubSectionHeading, TextButton, XSmallText } from '@borrowmydoggy/core-components';
import {
  ModalQuestionPanel,
  INewMemberCommsConfiguration,
  NewMemberCommsPreferences,
  UProfileConfiguration,
  UserAccountProfileConfiguration
} from '@borrowmydoggy/account-components';

import { ProfileAPI, UserAPI } from '../api';

export interface INotificationSettingsProps {
  userAccountProfileConfiguration: UserAccountProfileConfiguration;
  borrowerProfileAcceptingMessages?: boolean;
  ownerProfileAcceptingMessages?: boolean;
  borrowerProfileLookingForMatch?: boolean;
  ownerProfileLookingForMatch?: boolean;
  alertMessageEmail: boolean;
  alertMessageSms: boolean;
  alertMessagePush: boolean;
  alertLikeEmail: boolean;
  alertLikePush: boolean;
  profileViewsEmail: boolean;
  newsletterEmail: boolean;
  guidesTipsEmail: boolean;
  promotionsEventsEmail: boolean;
  alertNewMembersEmail: boolean;
  alertNewMembersPush: boolean;
  selfPremium: boolean;
  newMembersBorrowerProfileConfiguration?: INewMemberCommsConfiguration;
  newMembersOwnerProfileConfiguration?: INewMemberCommsConfiguration;
  userAccountSupportsEmail: boolean;
  userAccountSupportsSms: boolean;
  userAccountSupportsPush: boolean;
  premiumPath: string;
}

interface IContactPreferences {
  alertMessageEmail: boolean;
  alertMessageSms: boolean;
  alertMessagePush: boolean;
  alertLikeEmail: boolean;
  alertLikePush: boolean;
  profileViewsEmail: boolean;
  newsletterEmail: boolean;
  guidesTipsEmail: boolean;
  promotionsEventsEmail: boolean;
  alertNewMembersEmail: boolean;
  alertNewMembersPush: boolean;
  newMembersBorrowerProfileConfiguration?: INewMemberCommsConfiguration;
  newMembersOwnerProfileConfiguration?: INewMemberCommsConfiguration;
}

interface IContactCapabilities {
  email: boolean;
  sms: boolean;
  push: boolean;
}

type ModalProfileType = ProfileType | 'none';

const NotificationSettingsContainer = styled.div`
  &>p.addendum { padding-top: 10px; }

  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    &>p.addendum { padding-left: 20px; }
  }

  ${responsiveQuery('mobilePortrait')} {
    &>p.addendum { padding-left: 10px; }
  }
`;

const StyledPageHeading = styled(PageHeading)`
  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    padding-left: 20px;
  }

  ${responsiveQuery('mobilePortrait')} {
    padding-left: 10px;
  }
`;

const NotificationSettingsSection = styled.div`
  max-width: 700px;
  box-sizing: border-box;
  margin-bottom: 30px;

  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    padding-left: 20px;
  }

  ${responsiveQuery('mobilePortrait')} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

interface ITypeProps {
  type?: 'action';
}

const Setting = styled.div<ITypeProps>`
  box-sizing: border-box;
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
  align-items: center;

  .icon {
    &.positive { color: ${props => props.theme.primary.mid}; }
    &.negative { color: ${props => props.theme.negative.mid}; }
  }

  ${responsiveQuery('tabletLandscape', 'desktop')} {
    &>.description {
      &>p { font-size: 1.6rem; }
    }
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape', 'tabletPortrait')} {
    &>.description {
      &>p { font-size: 1.4rem; }
    }
  }

  ${responsiveQuery('tabletPortrait', 'tabletLandscape', 'desktop')} {
    grid-template-columns: ${props => props.type === 'action' ? '1fr 180px' : 'unset'};
    &>.description {
      &>p { margin-bottom: 10px; }
    }
    &>.action {
      align-self: start;
    }
    &>.assistance {
      align-self: start;
      padding-top: 28px;
      justify-self: start;
    }
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape')} {
    grid-template-columns: ${props => props.type === 'action' ? '1fr 1fr' : 'unset'};
  }
`;

const NewMemberPreferencesContainer = styled.div`
  ${responsiveQuery('tabletPortrait', 'tabletLandscape', 'desktop')} {
    box-sizing: border-box;
    max-width: 700px;
    padding-right: 95px;
  }
`;

const StyledClarification = styled(XSmallText)`
  margin-bottom: 10px;
  ${responsiveQuery('mobilePortrait', 'mobileLandscape')} {
    margin-top: 10px;
  }
`;

const DisabledExplanation = styled(XSmallText)`
  margin-bottom: 20px;
`;

type MessageNotificationChannel = 'alertMessageEmail' | 'alertMessageSms' | 'alertMessagePush';
type LikeNotificationChannel = 'alertLikeEmail' | 'alertLikePush';
type AccountUpdateChannel = 'accountUpdatesEmail' | 'accountUpdatesSms';

export const NotificationSettings: FunctionComponent<INotificationSettingsProps> = (props: INotificationSettingsProps) => {
  const [contactPreferences, setContactPreferences] = useState<IContactPreferences>({
    alertMessageEmail: props.alertMessageEmail,
    alertMessageSms: props.alertMessageSms,
    alertMessagePush: props.alertMessagePush,
    alertLikeEmail: props.alertLikeEmail,
    alertLikePush: props.alertLikePush,
    profileViewsEmail: props.profileViewsEmail,
    newsletterEmail: props.newsletterEmail,
    guidesTipsEmail: props.guidesTipsEmail,
    promotionsEventsEmail: props.promotionsEventsEmail,
    alertNewMembersEmail: props.alertNewMembersEmail,
    alertNewMembersPush: props.alertNewMembersPush,
    newMembersBorrowerProfileConfiguration: props.newMembersBorrowerProfileConfiguration,
    newMembersOwnerProfileConfiguration: props.newMembersOwnerProfileConfiguration
  });
  const [contactCapabilities, setContactCapabilities] = useState<IContactCapabilities>({
    email: props.userAccountSupportsEmail,
    sms: props.userAccountSupportsSms,
    push: props.userAccountSupportsPush
  });

  const [profilesAcceptingMessages, setProfilesAcceptingMessages] = useState({
    borrower: props.borrowerProfileAcceptingMessages,
    owner: props.ownerProfileAcceptingMessages
  });

  const [profilesLookingForMatch, setProfilesLookingForMatch] = useState({
    borrower: props.borrowerProfileLookingForMatch,
    owner: props.ownerProfileLookingForMatch
  });

  const [modalProfileType, setModalProfileType] = useState('none' as ModalProfileType);

  function mapContactPreferences(updatedLocalContactPreferences: IContactPreferences, apiContactPreferences: ContactPreferences): IContactPreferences {
    return {
      ...apiContactPreferences,
      newMembersBorrowerProfileConfiguration: apiContactPreferences.newMembersBorrowerProfileConfiguration ? {
        ...updatedLocalContactPreferences.newMembersBorrowerProfileConfiguration,
        ...apiContactPreferences.newMembersBorrowerProfileConfiguration,
        locationSearchText: apiContactPreferences.newMembersBorrowerProfileConfiguration.searchLocation
      } : undefined,
      newMembersOwnerProfileConfiguration: apiContactPreferences.newMembersOwnerProfileConfiguration ? {
        ...updatedLocalContactPreferences.newMembersOwnerProfileConfiguration,
        ...apiContactPreferences.newMembersOwnerProfileConfiguration,
        locationSearchText: apiContactPreferences.newMembersOwnerProfileConfiguration.searchLocation
      } : undefined
    };
  }

  async function persistContactPreferenceChanges(newContactPreferences: IContactPreferences): Promise<void> {
    const preferences = {
      ...newContactPreferences,
      newMembersBorrowerProfileConfiguration: newContactPreferences.newMembersBorrowerProfileConfiguration ? {
        searchDistance: newContactPreferences.newMembersBorrowerProfileConfiguration.searchDistance,
        premium: newContactPreferences.newMembersBorrowerProfileConfiguration.premium,
        availability: newContactPreferences.newMembersBorrowerProfileConfiguration.availability
      } : undefined,
      newMembersOwnerProfileConfiguration: newContactPreferences.newMembersOwnerProfileConfiguration ? {
        searchDistance: newContactPreferences.newMembersOwnerProfileConfiguration.searchDistance,
        premium: newContactPreferences.newMembersOwnerProfileConfiguration.premium,
        availability: newContactPreferences.newMembersOwnerProfileConfiguration.availability
      } : undefined
    };
    const response = await UserAPI.updateUserAccountContactPreferences(preferences);
    if (
      response.error === undefined
      && response.data
      && response.data.updateUserAccountContactPreferences
      && response.data.updateUserAccountContactPreferences.successful
    ) {
      // Ensure the page state reflects the latest API state.
      setContactPreferences(mapContactPreferences(newContactPreferences, response.data.updateUserAccountContactPreferences.contactPreferences));
      setContactCapabilities(response.data.updateUserAccountContactPreferences.contactCapabilities);
      if (response.data.updateUserAccountContactPreferences.errors) {
        console.error(response.data.updateUserAccountContactPreferences.errors);
      }
    } else {
      console.warn('API failure when attempting to persist contact preference changes.', response.error)
    }
  }

  async function disableMessagingForProfile(profileType: ProfileType, acceptingMessages: boolean): Promise<boolean> {
    const response = await ProfileAPI.disableMessagingForProfile(profileType, acceptingMessages);
    if (response.error === undefined && response.data && response.data.disableMessagingForProfile) {
      if (response.data.disableMessagingForProfile.errors) {
        console.error(response.data.disableMessagingForProfile.errors);
        return false;
      } else {
        return true;
      }
    } else {
      console.warn('API failure when attempting to disable messaging for profile.', response.error)
      return false;
    }
  }

  async function enableMessagingForProfile(profileType: ProfileType, lookingForMatch: boolean): Promise<boolean> {
    const response = await ProfileAPI.enableMessagingForProfile(profileType, lookingForMatch);
    if (response.error === undefined && response.data && response.data.enableMessagingForProfile) {
      if (response.data.enableMessagingForProfile.errors) {
        console.error(response.data.enableMessagingForProfile.errors);
        return false;
      } else {
        return true;
      }
    } else {
      console.warn('API failure when attempting to enable messaging for profile.', response.error)
      return false;
    }
  }

  function handleAlertMessageChange(_id: string, checked: Record<MessageNotificationChannel, boolean>): void {
    const updatedContactPreferences = {
      ...contactPreferences,
      alertMessageEmail: checked.alertMessageEmail,
      alertMessageSms: checked.alertMessageSms,
      alertMessagePush: checked.alertMessagePush
    };
    setContactPreferences(updatedContactPreferences);
    persistContactPreferenceChanges(updatedContactPreferences);
  }

  function handleAlertLikeChange(_id: string, checked: Record<LikeNotificationChannel, boolean>): void {
    const updatedContactPreferences = {
      ...contactPreferences,
      alertLikeEmail: checked.alertLikeEmail,
      alertLikePush: checked.alertLikePush
    };
    setContactPreferences(updatedContactPreferences);
    persistContactPreferenceChanges(updatedContactPreferences);
  }

  function handleProfileViewsChange(_id: 'profileViewsEmail', newValue: boolean): void {
    const updatedContactPreferences = { ...contactPreferences, profileViewsEmail: newValue };
    setContactPreferences(updatedContactPreferences);
    persistContactPreferenceChanges(updatedContactPreferences);
  }

  function handleNewsletterChange(_id: 'newsletterEmail', newValue: boolean): void {
    const updatedContactPreferences = { ...contactPreferences, newsletterEmail: newValue };
    setContactPreferences(updatedContactPreferences);
    persistContactPreferenceChanges(updatedContactPreferences);
  }

  function handlePromotionsEventsChange(_id: 'promotionsEventsEmail', newValue: boolean): void {
    const updatedContactPreferences = { ...contactPreferences, promotionsEventsEmail: newValue};
    setContactPreferences(updatedContactPreferences);
    persistContactPreferenceChanges(updatedContactPreferences);
  }

  function handleGuidesTipsChange(_id: 'guidesTipsEmail', newValue: boolean): void {
    const updatedContactPreferences = { ...contactPreferences, guidesTipsEmail: newValue};
    setContactPreferences(updatedContactPreferences);
    persistContactPreferenceChanges(updatedContactPreferences);
  }

  function handleNewMemberCommsChange(
    emailEnabled: boolean,
    pushEnabled: boolean,
    newConfiguration: UProfileConfiguration
  ): void {
    let borrowerProfileConfiguration: INewMemberCommsConfiguration | undefined;
    if (newConfiguration.userAccountProfileConfiguration === 'borrower' || newConfiguration.userAccountProfileConfiguration === 'dual') {
      borrowerProfileConfiguration = newConfiguration.borrowerProfileConfiguration;
    }
    let ownerProfileConfiguration: INewMemberCommsConfiguration | undefined;
    if (newConfiguration.userAccountProfileConfiguration === 'owner' || newConfiguration.userAccountProfileConfiguration === 'dual') {
      ownerProfileConfiguration = newConfiguration.ownerProfileConfiguration;
    }
    const updatedContactPreferences = {
      ...contactPreferences,
      alertNewMembersEmail: emailEnabled,
      alertNewMembersPush: pushEnabled,
      newMembersBorrowerProfileConfiguration: borrowerProfileConfiguration,
      newMembersOwnerProfileConfiguration: ownerProfileConfiguration
    };
    setContactPreferences(updatedContactPreferences);
    persistContactPreferenceChanges(updatedContactPreferences);
  }

  function handleDisableMessagingClick(profileType: ProfileType): void {
    setModalProfileType(profileType);
  }

  function handleEnableMessagingClick(profileType: ProfileType): void {
    setModalProfileType(profileType);
  }

  function dismissModal(): void {
    setModalProfileType('none');
  }

  function dismissModalAfterShortDelay(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
      setTimeout(dismissModal, 5);
    });
  }

  function handleCancelClick(): void {
    dismissModal();
  }

  async function disableMessaging(profileType: ProfileType, acceptingMessages: boolean): Promise<boolean> {
    const success = await disableMessagingForProfile(profileType, acceptingMessages);
    if (success) {
      setProfilesLookingForMatch({ ...profilesLookingForMatch, [profileType]: false });
      setProfilesAcceptingMessages({ ...profilesAcceptingMessages, [profileType]: acceptingMessages });
      await dismissModalAfterShortDelay();
    }
    return success;
  }

  async function enableMessaging(profileType: ProfileType, lookingForMatch: boolean): Promise<boolean> {
    const success = await enableMessagingForProfile(profileType, lookingForMatch);
    if (success) {
      setProfilesAcceptingMessages({ ...profilesAcceptingMessages, [profileType]: true });
      setProfilesLookingForMatch({ ...profilesLookingForMatch, [profileType]: lookingForMatch });
      await dismissModalAfterShortDelay();
    }
    return success;
  }

  function handleLookingForMatchDisableResponseChosen(profileType: ProfileType, id: string): Promise<boolean> {
    switch (id) {
      case 'showWithMessages':
        return dismissModalAfterShortDelay();
      case 'removeWithMessages':
        return disableMessaging(profileType, true);
      case 'removeWithoutMessages':
      default:
        return disableMessaging(profileType, false);
    }
  }

  function handleDisableResponseChosen(profileType: ProfileType, id: string): Promise<boolean> {
    switch (id) {
      case 'disableMessages':
        return disableMessaging(profileType, false);
      case 'keepMessages':
      default:
        return dismissModalAfterShortDelay();
    }
  }

  function handleEnableResponseChosen(profileType: ProfileType, id: string): Promise<boolean> {
    switch (id) {
      case 'showInSearches':
        return enableMessaging(profileType, true);
      case 'remainHidden':
      default:
        return enableMessaging(profileType, false);
    }
  }

  function renderBorrowerProfileMessagesSetting(): ReactNode {
    if (profilesAcceptingMessages.borrower) {
      return (
        <Setting type='action'>
          <p className='description'>
            <Icon definition={faCheck} className='icon positive' /> <strong>Owners</strong> can message you
          </p>
          <TextButton type='negative' text='Turn off messages' className='action' onClick={handleDisableMessagingClick.bind({}, 'borrower')} />
        </Setting>
      );
    } else {
      return (
        <Setting type='action'>
          <p className='description'>
            <Icon definition={faExclamationTriangle} className='icon negative' /> <strong>Owners</strong> cannot message you
          </p>
          <TextButton text='Turn on messages' className='action' onClick={handleEnableMessagingClick.bind({}, 'borrower')} />
        </Setting>
      );
    }
  }

  function renderOwnerProfileMessagesSetting(): ReactNode {
    if (profilesAcceptingMessages.owner) {
      return (
        <Setting type='action'>
          <p className='description'>
            <Icon definition={faCheck} className='icon positive' /> <strong>Borrowers</strong> can message you
          </p>
          <TextButton type='negative' text='Turn off messages' className='action' onClick={handleDisableMessagingClick.bind({}, 'owner')} />
        </Setting>
      );
    } else {
      return (
        <Setting type='action'>
          <p className='description'>
            <Icon definition={faExclamationTriangle} className='icon negative' /> <strong>Borrowers</strong> cannot message you
          </p>
          <TextButton text='Turn on messages' className='action' onClick={handleEnableMessagingClick.bind({}, 'owner')} />
        </Setting>
      );
    }
  }

  function renderMessageNotificationSetting(
    alertMessageEmail: boolean,
    alertMessageSms: boolean,
    alertMessagePush: boolean,
    userAccountSupportsSms: boolean,
    userAccountSupportsPush: boolean
  ): ReactNode {
    if (profilesAcceptingMessages.borrower || profilesAcceptingMessages.owner) {
      const items: ICheckBoxGroupItem<MessageNotificationChannel>[] = [
        { id: 'alertMessageEmail', label: 'Email' },
        {
          id: 'alertMessageSms',
          label: 'SMS text message',
          disabled: !userAccountSupportsSms
        },
        {
          id: 'alertMessagePush',
          label: 'Mobile app',
          disabled: !userAccountSupportsPush
        }
      ];
      const smsDisabledMessage = userAccountSupportsSms ? <></> : (
        <DisabledExplanation>
          Want message alerts by SMS? Update{' '}
          <InlineTextButton size='xSmall' link={{ href: '/your_account/main_details' }}>your phone number</InlineTextButton>{' '}
          to a valid UK mobile number.
        </DisabledExplanation>
      );
      const pushDisabledMessage = userAccountSupportsPush ? <></> : (
        <DisabledExplanation>
          Want message alerts through our{' '}
          <InlineTextButton size='xSmall' link={{ href: 'https://itunes.apple.com/gb/app/borrowmydoggy/id860721134', blankTarget: true }}>
            iPhone app
          </InlineTextButton>?{' '}
          Make sure you have the app installed, are logged in with the same account and have enabled push notifications.
        </DisabledExplanation>
      );
      return (
        <Setting>
          <div className='description'>
            <SubSectionHeading>Messages</SubSectionHeading>
            <p>Choose how to be alerted when a BorrowMyDoggy member sends you a message.</p>
            <StyledClarification>Please note: You must have at least one way of being alerted to new messages or turn messages off</StyledClarification>
          </div>
          <div className='action'>
            <CheckBoxGroup
              id='alertMessage'
              items={items}
              legend='Delivery method'
              value={{ alertMessageEmail, alertMessageSms, alertMessagePush }}
              validationState={{ valid: true, status: 'valid' }}
              onChange={handleAlertMessageChange}
              enforceOneChecked
            />
          </div>
          {smsDisabledMessage}
          {pushDisabledMessage}
        </Setting>
      );
    } else {
      return (
        <Setting>
          <div className='description'>
            <SubSectionHeading>Messages</SubSectionHeading>
          </div>
        </Setting>
      );
    }
  }

  function renderDisableMessagingModal(profileType: ProfileType, lookingForMatch: boolean): ReactNode {
    if (lookingForMatch) {
      return (
        <ModalQuestionPanel
          title='Are you sure?'
          description='This will remove you from searches AND stop you from receiving messages from anyone, including members you have already started a conversation with.'
          responses={[
            {
              id: 'showWithMessages',
              polarity: 'positive',
              text: 'Show me in searches and leave messages on'
            },
            {
              id: 'removeWithMessages',
              polarity: 'negative',
              text: 'Remove me from searches but keep messages on'
            },
            {
              id: 'removeWithoutMessages',
              polarity: 'negative',
              text: 'Remove me from searches and turn messages off'
            }
          ]}
          onResponseChosen={handleLookingForMatchDisableResponseChosen.bind({}, profileType)}
          onCancel={handleCancelClick}
        />
      );
    } else {
      return (
        <ModalQuestionPanel
          title='Are you sure?'
          description='This will stop you from receiving messages from anyone, including members you have already started a conversation with.'
          responses={[
            {
              id: 'keepMessages',
              polarity: 'positive',
              text: 'No, keep messages on'
            },
            {
              id: 'disableMessages',
              polarity: 'negative',
              text: 'Yes, turn messages off'
            }
          ]}
          onResponseChosen={handleDisableResponseChosen.bind({}, profileType)}
          onCancel={handleCancelClick}
        />
      );
    }
  }

  function renderEnableMessagingModal(profileType: ProfileType): ReactNode {
    const lookingText = profileType === 'owner' ? 'Looking for a borrower' : 'Looking for a dog to borrow';
    const descriptionText = `If you'd like to show in searches we'll set you to "${lookingText}".`;
    return (
      <ModalQuestionPanel
        title='Would you like to also show in searches?'
        description={descriptionText}
        responses={[
          {
            id: 'showInSearches',
            text: 'Yes',
            icon: faCheck
          },
          {
            id: 'remainHidden',
            type: 'negative',
            text: 'No',
            icon: faTimes
          }
        ]}
        onResponseChosen={handleEnableResponseChosen.bind({}, profileType)}
        onCancel={handleCancelClick}
      />
    );
  }

  function renderModal(): ReactNode {
    switch (modalProfileType) {
      case 'borrower':
        return profilesAcceptingMessages.borrower ? renderDisableMessagingModal('borrower', profilesLookingForMatch.borrower || false) : renderEnableMessagingModal('borrower');
      case 'owner':
        return profilesAcceptingMessages.owner ? renderDisableMessagingModal('owner', profilesLookingForMatch.owner || false) : renderEnableMessagingModal('owner');
      case 'none':
      default:
        return <></>;
    }
  }

  function renderMessagesSection(
    userAccountProfileConfiguration: UserAccountProfileConfiguration,
    alertMessageEmail: boolean,
    alertMessageSms: boolean,
    alertMessagePush: boolean,
    userAccountSupportsSms: boolean,
    userAccountSupportsPush: boolean
  ): ReactNode {
    const borrowerProfilePresent = userAccountProfileConfiguration === 'dual' || userAccountProfileConfiguration === 'borrower';
    const ownerProfilePresent = userAccountProfileConfiguration === 'dual' || userAccountProfileConfiguration === 'owner';
    return (
      <NotificationSettingsSection>
        {renderMessageNotificationSetting(alertMessageEmail, alertMessageSms, alertMessagePush, userAccountSupportsSms, userAccountSupportsPush)}
        {borrowerProfilePresent && renderBorrowerProfileMessagesSetting()}
        {ownerProfilePresent && renderOwnerProfileMessagesSetting()}
      </NotificationSettingsSection>
    );
  }

  let configuration: UProfileConfiguration = { userAccountProfileConfiguration: 'none' };
  if (
    props.userAccountProfileConfiguration === 'dual'
      && contactPreferences.newMembersBorrowerProfileConfiguration
      && contactPreferences.newMembersOwnerProfileConfiguration
  ) {
    configuration = {
      userAccountProfileConfiguration: 'dual',
      borrowerProfileConfiguration: contactPreferences.newMembersBorrowerProfileConfiguration,
      ownerProfileConfiguration: contactPreferences.newMembersOwnerProfileConfiguration
    };
  } else if (props.userAccountProfileConfiguration === 'borrower' && contactPreferences.newMembersBorrowerProfileConfiguration) {
    configuration = { userAccountProfileConfiguration: 'borrower', borrowerProfileConfiguration: contactPreferences.newMembersBorrowerProfileConfiguration };
  } else if (props.userAccountProfileConfiguration === 'owner' && contactPreferences.newMembersOwnerProfileConfiguration) {
    configuration = { userAccountProfileConfiguration: 'owner', ownerProfileConfiguration: contactPreferences.newMembersOwnerProfileConfiguration };
  }

  const accountUpdateItems: ICheckBoxGroupItem<AccountUpdateChannel>[] = [
    { id: 'accountUpdatesEmail', label: 'Email' }
  ];
  if (props.userAccountSupportsSms) {
    accountUpdateItems.push({ id: 'accountUpdatesSms', label: 'SMS text message' });
  }

  const likeItems: ICheckBoxGroupItem<LikeNotificationChannel>[] = [
    { id: 'alertLikeEmail', label: 'Email' },
    {
      id: 'alertLikePush',
      label: 'Mobile app',
      disabled: !contactCapabilities.push
    }
  ];

  const alertLikeEmail = contactPreferences.alertLikeEmail
  const alertLikePush = contactPreferences.alertLikePush

  return (
    <NotificationSettingsContainer>
      <StyledPageHeading>Notification settings</StyledPageHeading>
      {renderMessagesSection(
        props.userAccountProfileConfiguration,
        contactPreferences.alertMessageEmail,
        contactPreferences.alertMessageSms,
        contactPreferences.alertMessagePush,
        contactCapabilities.sms,
        contactCapabilities.push
      )}
      {renderModal()}
      <NotificationSettingsSection>
        <Setting>
          <div className='description'>
            <SubSectionHeading className='title'>Likes</SubSectionHeading>
            <p>Get alerts about likes from BorrowMyDoggy members</p>
          </div>
          <div className='action'>
            <CheckBoxGroup
              id='alertLike'
              items={likeItems}
              legend='Delivery method'
              value={{ alertLikeEmail, alertLikePush }}
              validationState={{ valid: true, status: 'valid' }}
              onChange={handleAlertLikeChange}
            />
          </div>
        </Setting>
      </NotificationSettingsSection>
      <NotificationSettingsSection>
        <Setting>
          <div className='description'>
            <SubSectionHeading>Views</SubSectionHeading>
            <p>Get updates on how popular your profile is</p>
          </div>
          <CheckBoxField
            id='profileViewsEmail'
            label='Email'
            value={contactPreferences.profileViewsEmail}
            validationState={{ valid: true, status: 'valid' }}
            className='action'
            onChange={handleProfileViewsChange}
          />
        </Setting>
      </NotificationSettingsSection>
      <NotificationSettingsSection>
        <Setting>
          <div className='description'>
            <SubSectionHeading>Community newsletter</SubSectionHeading>
            <p>Get news from BorrowMyDoggy including the Howling Herald.</p>
          </div>
          <CheckBoxField
            id='newsletterEmail'
            label='Email'
            value={contactPreferences.newsletterEmail}
            validationState={{ valid: true, status: 'valid' }}
            className='action'
            onChange={handleNewsletterChange}
          />
        </Setting>
      </NotificationSettingsSection>
      <NotificationSettingsSection>
        <Setting>
          <div className='description'>
            <SubSectionHeading>Promotions and events</SubSectionHeading>
            <p>Hear about the launch of new services, events, promotions, surveys and inspiration.</p>
          </div>
          <CheckBoxField
            id='promotionsEventsEmail'
            label='Email'
            value={contactPreferences.promotionsEventsEmail}
            validationState={{ valid: true, status: 'valid' }}
            className='action'
            onChange={handlePromotionsEventsChange}
          />
        </Setting>
      </NotificationSettingsSection>
      <NotificationSettingsSection>
        <Setting>
          <div className='description'>
            <SubSectionHeading>Guides and tips</SubSectionHeading>
            <p>Get the most out of BorrowMyDoggy with guides, hints and tips to help you along the way.</p>
          </div>
          <CheckBoxField
            id='guidesTipsEmail'
            label='Email'
            value={contactPreferences.guidesTipsEmail}
            validationState={{ valid: true, status: 'valid' }}
            className='action'
            onChange={handleGuidesTipsChange}
          />
        </Setting>
      </NotificationSettingsSection>
      <NotificationSettingsSection>
        <Setting>
          <div className='description'>
            <SubSectionHeading>Account updates</SubSectionHeading>
            <p>We may need to contact you about your account, legal notifications, security and privacy matters, and customer support requests.</p>
            <StyledClarification>Please note: For your security you cannot disable these updates.</StyledClarification>
          </div>
          <CheckBoxGroup
            id='accountUpdates'
            items={accountUpdateItems}
            value={{ accountUpdatesEmail: true, accountUpdatesSms: true }}
            validationState={{ valid: true, status: 'valid' }}
            legend='Delivery method'
            className='action'
            disabled
          />
        </Setting>
      </NotificationSettingsSection>
      <NewMemberPreferencesContainer>
        <NewMemberCommsPreferences
          emailEnabled={contactPreferences.alertNewMembersEmail}
          pushEnabled={contactPreferences.alertNewMembersPush}
          pushNotificationsSupported={props.userAccountSupportsPush}
          configuration={configuration}
          selfPremium={props.selfPremium}
          premiumUrl={props.premiumPath}
          onChange={handleNewMemberCommsChange}
        />
      </NewMemberPreferencesContainer>
      <p className='addendum'>These settings save automatically.</p>
    </NotificationSettingsContainer>
  );
};
