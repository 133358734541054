import React, { Component, FormEvent, ReactNode } from 'react';

import styled from 'styled-components';

import { CountrySelectField, TelephoneNumberField } from '../forms';
import { lookUpCountryCode } from '../telephone-country-tree';

export interface ISuccessfulTelephoneNumberUpdateResponse {
  status: 'success';
  countryIsoCode: string;
  eligibleForAutomaticVerification: boolean;
}

export interface IChangePhoneNumberFormProps {
  telephoneNumber: string;
  title: string;
  paragraph: string;
  countryIsoCode: string;
  csrfToken: string;
  telephoneNumberUpdatePath: string;
  onCountryIsoCodeChange?: (countryIsoCode: string) => void;
  onTelephoneNumberChange?: (telephoneNumber: string) => void;
  onPhoneNumberChanged?: (json: ISuccessfulTelephoneNumberUpdateResponse) => void;
}

interface IChangePhoneNumberFormState {
  cleanNumber: string;
  callingCodeFilter?: string;
  telephoneNumberInvalid: boolean;
  errorMessage: string;
}

const Feedback = styled.label`
  grid-area: feedback;
  color: var(--toy-red);
  font-size: 14px;
  font-family: var(--body-font);

  &:before {
    content: var(--icon-fa-exclamation-circle);
    font-family: var(--fa-family);
    font-weight: var(--fa-solid);
    margin-right: 5px;
  }
`;

export class ChangePhoneNumberForm extends Component<IChangePhoneNumberFormProps, IChangePhoneNumberFormState> {
  constructor(props: IChangePhoneNumberFormProps) {
    super(props);

    this.state = { cleanNumber: this.cleanNumber(this.props.telephoneNumber), callingCodeFilter: undefined, telephoneNumberInvalid: false, errorMessage: '' };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleTelephoneNumberChange = this.handleTelephoneNumberChange.bind(this);
    this.handleTelephoneNumberBlur = this.handleTelephoneNumberBlur.bind(this);
    this.handleTelephoneNumberValidationFailure = this.handleTelephoneNumberValidationFailure.bind(this);
    this.handleTelephoneNumberValidationSuccess = this.handleTelephoneNumberValidationSuccess.bind(this);

    this.handleTelephoneNumberSubmission = this.handleTelephoneNumberSubmission.bind(this);
  }

  public render(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>{this.props.title}</h2>
        <p className='clarification'>{this.props.paragraph}</p>
        <form className='new-form' onSubmit={this.handleTelephoneNumberSubmission}>
          <fieldset>
            <div className='space-above-medium'>
              <label htmlFor='phone_number'>Your phone number</label>
            </div>
            <div className='field space-above-small'>
              <CountrySelectField
                name='phone_number_country_code'
                countryIsoCode={this.props.countryIsoCode}
                callingCodeFilter={this.state.callingCodeFilter}
                fullNumber={this.state.cleanNumber}
                onChange={this.handleCountryChange}
              />
            </div>
            <div>
              <TelephoneNumberField
                id='phone_number'
                name='phone_number'
                value={this.props.telephoneNumber}
                onChange={this.handleTelephoneNumberChange}
                onBlur={this.handleTelephoneNumberBlur}
              />
              {this.renderError()}
            </div>
          </fieldset>
          {this.renderErrorMessage()}
          <div className='action space-above-small'>
            <button type='submit' className='new-button primary wide'>Next</button>
          </div>
        </form>
      </>
    );
  }

  private renderError(): ReactNode | null {
    if (this.state.telephoneNumberInvalid) {
      return <Feedback>Please check the number you have provided</Feedback>;
    } else {
      return null;
    }
  }

  private cleanNumber(dirtyNumber: string | null): string {
    if (dirtyNumber == null) {
      return '';
    }

    let cleanedNumber = dirtyNumber.replace(/[^0-9]/g, '');
    if (cleanedNumber.startsWith('00')) {
      cleanedNumber = cleanedNumber.slice(2);
    }
    return cleanedNumber;
  }

  private handleCountryChange(countryIsoCode: string): void {
    if (this.props.onCountryIsoCodeChange) {
      this.props.onCountryIsoCodeChange(countryIsoCode);
    }
  }

  private handleTelephoneNumberChange(telephoneNumber: string): void {
    const cleanNumber = this.cleanNumber(telephoneNumber);
    this.setState({ cleanNumber });
    if (this.props.onTelephoneNumberChange) {
      this.props.onTelephoneNumberChange(telephoneNumber);
    }
    if (telephoneNumber.startsWith('+') || telephoneNumber.startsWith('00')) {
      const countryLookUp = lookUpCountryCode(cleanNumber);
      if (countryLookUp) {
        this.setState({ callingCodeFilter: countryLookUp.callingCode });
      }
    } else {
      this.setState({ callingCodeFilter: undefined });
    }
  }

  private handleTelephoneNumberBlur(): void {
    this.setState({ telephoneNumberInvalid: false });
  }

  private handleTelephoneNumberValidationFailure(): void {
    this.setState({ telephoneNumberInvalid: true });
  }

  private handleTelephoneNumberValidationSuccess(): void {
    this.setState({ telephoneNumberInvalid: false });
  }

  private async handleTelephoneNumberSubmission(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    this.setState({ errorMessage: '' });

    const data = { telephone_number: { number: this.props.telephoneNumber, country_iso_code: this.props.countryIsoCode } };

    try {
      const response = await fetch(
        this.props.telephoneNumberUpdatePath,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken },
          body: JSON.stringify(data)
        }
      );
      if (response.status === 200) {
        const json: ISuccessfulTelephoneNumberUpdateResponse = await response.json();
        if (this.props.onPhoneNumberChanged) {
          this.props.onPhoneNumberChanged(json);
        }
      } else if (response.status === 422) {
        this.setState({ telephoneNumberInvalid: true });
      } else {
        this.setState({ errorMessage: 'Something went wrong our end, please try again' });
      }
    } catch (error) {
      console.error(error);
      this.setState({ errorMessage: 'Something went wrong our end, please try again' });
    }
  }

  private renderErrorMessage(): ReactNode | undefined {
    if (this.state.errorMessage.length > 0) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{this.state.errorMessage}</p>
          </article>
        </div>
      );
    }
  }
}
