import React, { Component, MouseEvent, ReactNode } from 'react';

import styled from 'styled-components';

import { EmailAddressField, Form, TextArea, TextField, ToggleField } from '../forms';
import { EmailAddressValidator, IValidationState, LengthValidator, PresenceValidator, validateAgainstCollection } from '@borrowmydoggy/bmd-validators';

export interface IRecipientDetailsFormProps {
  title: string;
  formattedPrice: string;
  recipientName: string;
  recipientMessage: string;
  recipientEmailAddress: string;
  onRecipientNameChange?: (value: string) => void;
  onRecipientMessageChange?: (value: string) => void;
  onRecipientEmailAddressChange?: (value: string) => void;
  onRecipientDetailsComplete?: () => void;
}

interface IRecipientDetailsFormState {
  errorMessage: string;
  emailRecipient: boolean;

  recipientNameValid: boolean;
  recipientNameMessage: string;
  recipientMessageValid: boolean;
  recipientMessageMessage: string; // Great name.
  recipientEmailAddressValid: boolean;
  recipientEmailAddressMessage: string;
}

const StyledTextField = styled(TextField)`
  &.invalid>input {
    border-color: var(--toy-red);
    background-color: var(--toy-red-light);
  }
`;

const StyledTextArea = styled(TextArea)`
  &>textarea {
    width: 100%;
  }
  &.invalid>textarea {
    border-color: var(--toy-red);
    background-color: var(--toy-red-light);
  }
`;

export class RecipientDetailsForm extends Component<IRecipientDetailsFormProps, IRecipientDetailsFormState> {
  constructor(props: IRecipientDetailsFormProps) {
    super(props);

    this.state = {
      errorMessage: '',
      emailRecipient: false,
      recipientNameValid: true,
      recipientNameMessage: '',
      recipientMessageValid: true,
      recipientMessageMessage: '',
      recipientEmailAddressValid: true,
      recipientEmailAddressMessage: ''
    };

    this.handleRecipientNameChange = this.handleRecipientNameChange.bind(this);
    this.handleRecipientMessageChange = this.handleRecipientMessageChange.bind(this);
    this.handleRecipientDirectlyEmailChange = this.handleRecipientDirectlyEmailChange.bind(this);
    this.handleRecipientEmailAddressChange = this.handleRecipientEmailAddressChange.bind(this);
    this.handleRecipientNameBlur = this.handleRecipientNameBlur.bind(this);
    this.handleRecipientMessageBlur = this.handleRecipientMessageBlur.bind(this);
    this.handleRecipientEmailAddressBlur = this.handleRecipientEmailAddressBlur.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  public render(): ReactNode {
    return (
      <Form className='new-form' onSubmit={this.handleFormSubmission}>
        <h1 className='page-title gift'>{this.props.title}</h1>
        <span className='highlight-heading space-above-medium'>just {this.props.formattedPrice}</span>
        <fieldset>
          <legend>Your Details</legend>
          <div className='field'>
            <label htmlFor='recipientName'>Recipient name</label>
            <StyledTextField
              value={this.props.recipientName}
              id='recipientName'
              name='recipientName'
              maxLength={30}
              inputPattern='^[^0-9@]*$'
              blurValidators={[
                new PresenceValidator("Please tell us your friend's name"),
                new LengthValidator("We can't accept names that long, is there a shorter version that you use?", { max: 30 })
              ]}
              className={this.state.recipientNameValid ? '' : 'invalid'}
              onChange={this.handleRecipientNameChange}
              onBlur={this.handleRecipientNameBlur}
            />
            <span className='feedback'>{this.state.recipientNameMessage}</span>
          </div>
          <div className='field'>
            <label htmlFor='recipientMessage'>Your message</label>
            <StyledTextArea
              value={this.props.recipientMessage}
              id='recipientMessage'
              name='recipientMessage'
              rows={5}
              maxLength={180}
              showRemainingCharacterCount
              blurValidators={[
                new PresenceValidator('Please write a nice message'),
                new LengthValidator('Your message is too long', { max: 180 })
              ]}
              placeholder='Write a message to the recipient here'
              className={this.state.recipientMessageValid ? '' : 'invalid'}
              onChange={this.handleRecipientMessageChange}
              onBlur={this.handleRecipientMessageBlur}
            />
            <span className='feedback'>{this.state.recipientMessageMessage}</span>
          </div>
          <ToggleField
            checked={this.state.emailRecipient}
            id='recipientDirectlyEmail'
            label='Should we email the gift voucher directly to them?'
            onChange={this.handleRecipientDirectlyEmailChange}
          />
          {this.renderRecipientEmailField()}
          {this.renderErrorMessage()}
          <div className='space-above-medium'>
            <button type='submit' className='new-button primary wide' onClick={this.handleFormSubmission}>Next</button>
          </div>
        </fieldset>
      </Form>
    );
  }

  private handleRecipientNameChange(value: string): void {
    if (this.props.onRecipientNameChange) {
      this.props.onRecipientNameChange(value);
    }
  }

  private handleRecipientMessageChange(value: string): void {
    if (this.props.onRecipientMessageChange) {
      this.props.onRecipientMessageChange(value);
    }
  }

  private handleRecipientDirectlyEmailChange(value: boolean): void {
    this.setState({ emailRecipient: value });
  }

  private handleRecipientEmailAddressChange(value: string): void {
    if (this.props.onRecipientEmailAddressChange) {
      this.props.onRecipientEmailAddressChange(value);
    }
  }

  private handleRecipientNameBlur(validationState: IValidationState): void {
    this.setState({ recipientNameValid: validationState.status === 'valid', recipientNameMessage: validationState.message || '' });
  }

  private handleRecipientMessageBlur(validationState: IValidationState): void {
    this.setState({ recipientMessageValid: validationState.status === 'valid', recipientMessageMessage: validationState.message || '' });
  }

  private handleRecipientEmailAddressBlur(): void {
    const newValidationState = this.validateRecipientEmailAddress();
    this.setState({ recipientEmailAddressValid: newValidationState.status === 'valid', recipientEmailAddressMessage: newValidationState.message || '' });
  }

  private validateRecipientEmailAddress(): IValidationState {
    if (this.state.emailRecipient) {
      const validators = [
        new PresenceValidator('Please tell us where to send the gift voucher'),
        new EmailAddressValidator('Please supply a valid email address')
      ];
      return validateAgainstCollection(this.props.recipientEmailAddress, validators);
    }
    return { status: 'valid' };
  }

  private handleFormSubmission(event?: MouseEvent<HTMLButtonElement>): void {
    if (event) {
      event.preventDefault();
    }
    const recipientNameAcceptable = this.state.recipientNameValid && this.props.recipientName.length > 0;
    const recipientMessageAcceptable = this.state.recipientMessageValid && this.props.recipientMessage.length > 0;
    const recipientEmailValidationState = this.validateRecipientEmailAddress();
    this.setState({
      recipientEmailAddressValid: recipientEmailValidationState.status === 'valid',
      recipientEmailAddressMessage: recipientEmailValidationState.message || ''
    });
    if (recipientNameAcceptable && recipientMessageAcceptable && recipientEmailValidationState.status === 'valid' && this.props.onRecipientDetailsComplete) {
      this.props.onRecipientDetailsComplete();
    }
  }

  private renderRecipientEmailField(): ReactNode | undefined {
    if (this.state.emailRecipient) {
      return (
        <div className='field'>
          <label htmlFor='recipientEmailAddress'>Recipient&rsquo;s email address</label>
          <EmailAddressField
            value={this.props.recipientEmailAddress}
            id='recipientEmailAddress'
            name='recipientEmailAddress'
            validState={this.state.recipientEmailAddressValid}
            onChange={this.handleRecipientEmailAddressChange}
            onBlur={this.handleRecipientEmailAddressBlur}
          />
          <span className='feedback'>{this.state.recipientEmailAddressMessage}</span>
        </div>
      );
    }
  }

  private renderErrorMessage(): ReactNode | undefined {
    if (this.state.errorMessage.length > 0) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{this.state.errorMessage}</p>
          </article>
        </div>
      );
    }
  }
}
