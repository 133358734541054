import React from 'react';

export interface IImageProps {
	path: string;
	alt: string;
	className?: string;
}

export const cloudfrontUrl = '//d1h75wi75kkzcv.cloudfront.net';

export function Image({ path, alt, ...props }: IImageProps): JSX.Element {
	const fullUrl = `${cloudfrontUrl}/${path}`;

	return <img src={fullUrl} alt={alt} {...props} />;
}

export default Image;
