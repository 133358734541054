import { CommandResultPromise } from '@borrowmydoggy/api-client';
import { ContactPreferencesArgument, Profile as ProfileType } from '@borrowmydoggy/bmd-api';

import { ClientBase } from '../ClientBase';
import { mutations } from './mutations';

export class UserAPI extends ClientBase {
  public static disconnectUserAccountFromApple(newPassword: string): CommandResultPromise<typeof mutations.disconnectUserAccountFromApple> {
    return this.client.mutation({
      command: mutations.disconnectUserAccountFromApple,
      args: { newPassword }
    });
  }

  public static disconnectUserAccountFromFacebook(newPassword: string): CommandResultPromise<typeof mutations.disconnectUserAccountFromFacebook> {
    return this.client.mutation({
      command: mutations.disconnectUserAccountFromFacebook,
      args: { newPassword }
    });
  }

  public static updateUserAccountContactPreferences(
    preferences: ContactPreferencesArgument
  ): CommandResultPromise<typeof mutations.updateUserAccountContactPreferences> {
    return this.client.mutation({
      command: mutations.updateUserAccountContactPreferences,
      args: { preferences }
    });
  }

  public static updateUserAccountLocationFromProfile(
    profileType: ProfileType
  ): CommandResultPromise<typeof mutations.updateUserAccountLocationFromProfile> {
    return this.client.mutation({
      command: mutations.updateUserAccountLocationFromProfile,
      args: { profileType }
    });
  }

  public static updateUserAccountPassword(currentPassword: string, newPassword: string): CommandResultPromise<typeof mutations.updateUserAccountPassword> {
    return this.client.mutation({
      command: mutations.updateUserAccountPassword,
      args: { currentPassword, newPassword }
    });
  }

  public static updateUserAccountPermissionToUse(permissionToUse: boolean): CommandResultPromise<typeof mutations.updateUserAccountPermissionToUse> {
    return this.client.mutation({
      command: mutations.updateUserAccountPermissionToUse,
      args: { permissionToUse }
    });
  }
}
