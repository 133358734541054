import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { QuestionProcessBanner } from '@borrowmydoggy/product-components';

import { MemberFeedbackAPI } from '../api';

export interface IMemberFeedbackBannerProps {
  senderName: string;
  feedbackBody: string;
  feedbackUuid: string;
}

const StyledParagraph = styled.p`
  font-size: 1.6rem;
`;

export const MemberFeedbackBanner: FunctionComponent<IMemberFeedbackBannerProps> = (props: IMemberFeedbackBannerProps) => {
  async function updateMemberFeedbackApprovalState(
    memberFeedbackUuid: string,
    approved: boolean
  ): Promise<boolean> {
    const response = await MemberFeedbackAPI.updateMemberFeedbackApprovalState(memberFeedbackUuid, approved);
    if (response.error === undefined && response.data && response.data.updateMemberFeedbackApprovalState) {
      if (response.data.updateMemberFeedbackApprovalState.memberFeedbackStateUpdated) {
        return true;
      } else {
        console.warn(response.data.updateMemberFeedbackApprovalState.errors);
        return false;
      }
    } else {
      console.warn('API failure when attempting to update member feedback approval state.', response.error)
      return false;
    }
  }

  async function handleYesChosen(): Promise<boolean> {
    return await updateMemberFeedbackApprovalState(props.feedbackUuid, true);
  }

  async function handleNoChosen(): Promise<boolean> {
    return await updateMemberFeedbackApprovalState(props.feedbackUuid, false);
  }

  const initialContent = (
    <>
      <StyledParagraph>You have new feedback. <strong>{props.senderName}</strong> says:</StyledParagraph>
      <StyledParagraph><strong>&ldquo;{props.feedbackBody}&rdquo;</strong></StyledParagraph>
      <StyledParagraph>Would you like the feedback to show on your profile?</StyledParagraph>
    </>
  );
  const completePositiveContent = (
    <StyledParagraph>Great, that feedback will now appear on your profile!</StyledParagraph>
  );
  const completeNegativeContent = (
    <StyledParagraph>OK, we won&rsquo;t show it on your profile.</StyledParagraph>
  );

  return (
    <QuestionProcessBanner
      initialContent={initialContent}
      completePositiveContent={completePositiveContent}
      completeNegativeContent={completeNegativeContent}
      onYesChosen={handleYesChosen}
      onNoChosen={handleNoChosen}
    />
  );
};
