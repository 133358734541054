import React, { FunctionComponent, useState } from 'react';

import { PhotoUploadPrompt } from './PhotoUploadPrompt';

export interface IAdditionalProfileInfoProps {
  photoUploadRequestUrl: string;
  showPhotoUpload: boolean;
}

export const AdditionalProfileInfo: FunctionComponent<IAdditionalProfileInfoProps> = (props: IAdditionalProfileInfoProps) => {
  const [dismissed, setDismissed] = useState(false);

  function handlePhotoUploadComplete(): void {
    setDismissed(true);
  }

  if (dismissed || !props.showPhotoUpload) {
    return <div className='component-additional-profile-info component-additional-profile-info_done'></div>;
  }
  return (
    <div className='component-additional-profile-info'>
      <PhotoUploadPrompt
        requestUrl={props.photoUploadRequestUrl}
        onComplete={handlePhotoUploadComplete}
      />
    </div>
  );
};
