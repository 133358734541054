import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { InformationalStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const User5B: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  return (
    <InformationalStep
      stepTitle='You’re already a member'
      stepHeading='Please sign in instead'
      totalSteps={props.totalStepCount}
      currentStep={5}
      stepImageUrl={context.images['illustrations/img-person-holding-envelope.svg']}
      nextButton={{ text: 'Go to sign in', icon: faChevronRight, iconAlignment: 'right', link: { href: '/signin' } }}
    >
      <StandardStepDescription text='Your email address is already registered on BorrowMyDoggy. Please sign in to your existing account now.' />
    </InformationalStep>
  );
};
