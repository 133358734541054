import React, { FunctionComponent, ReactNode } from 'react';

import { PositiveMessageBanner } from '@borrowmydoggy/product-components';

export interface ISuccessBannerProps {
  children?: ReactNode;
}

export const SuccessBanner: FunctionComponent<ISuccessBannerProps> = (props: ISuccessBannerProps) => {
  return <PositiveMessageBanner>{props.children}</PositiveMessageBanner>;
};
