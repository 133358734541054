import React, { Component, ReactNode } from 'react';

import styled from 'styled-components';

import { DateField, Form } from '../forms';
import { AgeValidator, IPureDate, IValidationState, IValidator } from '@borrowmydoggy/bmd-validators';

export interface IProvideDobProps {
  dateOfBirth?: IPureDate;
  csrfToken: string;
  dobRequestPath: string;
  onDateOfBirthChange?: (value: IPureDate) => void;
  onDateOfBirthProvided?: () => void;
}

interface IProvideDobState {
  formValid: boolean;
  errorMessage: string;
}

const StyledLabel = styled.label`
  text-align: center;
  display: block;
`;

const StyledDateField = styled(DateField)`
  &>input { width: unset; }
  &.invalid>input {
    border-color: var(--toy-red);
    background-color: var(--toy-red-light);
  }
`;

export class ProvideDob extends Component<IProvideDobProps, IProvideDobState> {
  private ageValidators: Array<IValidator<IPureDate>> = [
    new AgeValidator({
      minYears: 18,
      maxYears: 100,
      dateTooRecentMessage: 'We can’t check the ID of under 18s. Please sign up with an adult, and ask them to take our safety checks instead.',
      dateTooOldMessage: 'That would make you over 100! Please try again'
    })
  ];

  constructor(props: IProvideDobProps) {
    super(props);

    this.state = { formValid: true, errorMessage: '' };

    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleDateOfBirthChange = this.handleDateOfBirthChange.bind(this);
  }

  public render(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>What is your date of birth?</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>
            We&rsquo;ll keep this a secret, but it helps us to find you much more easily
            in the databases we search and so makes our safety checks much more accurate.
          </p>
        </div>
        <Form className='new-form space-above-medium' onSubmit={this.handleFormSubmission}>
          <fieldset>
            <legend>Your Date of Birth</legend>
            <StyledLabel htmlFor='day'>Date of birth</StyledLabel>
            <StyledDateField
              value={this.props.dateOfBirth}
              validators={this.ageValidators}
              className={this.state.formValid ? '' : 'invalid'}
              onChange={this.handleDateOfBirthChange}
            />
            {this.renderFeedback()}
            <p className='clarification'>e.g. 16 05 1976</p>
            <div className='action space-above-medium'>
              <button type='submit' className='new-button primary wide'>Next</button>
            </div>
          </fieldset>
        </Form>
      </>
    );
  }

  private renderFeedback(): ReactNode | undefined {
    if (this.state.errorMessage) {
      return <p className='feedback'>{this.state.errorMessage}</p>;
    }
  }

  private handleFormSubmission(): void {
    if (this.state.formValid && this.props.dateOfBirth && this.props.dateOfBirth.day && this.props.dateOfBirth.month && this.props.dateOfBirth.year) {
      const data = { verification_dob_request: { year: this.props.dateOfBirth.year, month: this.props.dateOfBirth.month, day: this.props.dateOfBirth.day } };
      const requestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken },
        body: JSON.stringify(data)
      };
      fetch(this.props.dobRequestPath, requestOptions).then(response => {
        return response.json();
      }).then(json => {
        if (json.status && json.status === 'success') {
          if (this.props.onDateOfBirthProvided) {
            this.props.onDateOfBirthProvided();
          }
        } else {
          const errorMessage = json.errors || 'Sorry, there was a problem processing your date of birth.';
          this.setState({ errorMessage });
        }
      }).catch(error => {
        console.error(error);
        const errorMessage = 'Sorry, there was a problem processing your date of birth.';
        this.setState({ errorMessage });
      });
    } else {
      this.setState({ formValid: false, errorMessage: 'Please supply your date of birth' });
    }
  }

  private handleDateOfBirthChange(value: IPureDate, validationState: IValidationState): void {
    // Only set the validation state if we have a complete date.
    if (value.day && value.month && value.year) {
      this.setState({ formValid: validationState.status === 'valid', errorMessage: validationState.message || '' });
    }
    if (this.props.onDateOfBirthChange) {
      this.props.onDateOfBirthChange(value);
    }
  }
}
