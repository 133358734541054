import React, { Component, ReactNode } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { ProfileType } from '@borrowmydoggy/core-components';

import { PurchaserDetailsForm, RecipientDetailsForm } from '../gift-vouchers';
import { CardActionForm, CardPaymentForm, HelpMessage, PaymentSuccessful } from '../payment';

export interface IGiftVoucherFormProps {
  purchaserName: string;
  purchaserEmailAddress: string;
  profileType: ProfileType;
  formattedPrice: string;
  paymentSuccessful: boolean;
  confirmationImageUrl: string;
  stripePublishableKey: string;
  giftVoucherPurchaseRequestsPath: string;
  giftVouchersPath: string;
}

interface IGiftVoucherFormState {
  step: GiftVoucherFormStep;
  purchaserName: string;
  purchaserEmailAddress: string;
  recipientName: string;
  recipientMessage: string;
  recipientEmailAddress: string;
  submissionErrorMessage: string;
  submissionInProgress: boolean;
  paymentSuccessful: boolean;
  paymentRequiresAction: boolean;
  stripePaymentIntentClientSecret: string;
  csrfToken: string;
}

interface IGiftVoucherPurchaseRequest {
  voucher_type: ProfileType;
  purchaser_name: string;
  purchaser_email_address: string;
  recipient_name: string;
  message: string;
  stripe_payment_method_id: string;
  recipient_email_address?: string;
}

interface IGiftVoucherPurchaseConfirmationRequest {
  voucher_type: ProfileType;
  purchaser_name: string;
  purchaser_email_address: string;
  recipient_name: string;
  message: string;
  stripe_payment_intent_id: string;
  recipient_email_address?: string;
}

type GiftVoucherFormStep = 'recipientDetails' | 'purchaserDetails' | 'payment' | 'complete';

type SubmissionData =
  | { gift_voucher_purchase_request: IGiftVoucherPurchaseRequest; }
  | { gift_voucher_purchase_confirmation_request: IGiftVoucherPurchaseConfirmationRequest; };

export class GiftVoucherForm extends Component<IGiftVoucherFormProps, IGiftVoucherFormState> {
  private stripePromise: Promise<Stripe | null>;

  constructor(props: IGiftVoucherFormProps) {
    super(props);
    let csrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const attribute = csrfTokenElement.attributes.getNamedItem('content');
      if (attribute) {
        csrfToken = attribute.value;
      }
    }

    //this.stripe = (window as any).Stripe(props.stripePublishableKey);
    this.stripePromise = loadStripe(props.stripePublishableKey);

    const step = this.props.purchaserName && this.props.purchaserEmailAddress ? 'recipientDetails' : 'purchaserDetails';
    this.state = {
      step,
      purchaserName: this.props.purchaserName || '',
      purchaserEmailAddress: this.props.purchaserEmailAddress || '',
      recipientName: '',
      recipientMessage: '',
      recipientEmailAddress: '',
      submissionErrorMessage: '',
      submissionInProgress: false,
      paymentSuccessful: this.props.paymentSuccessful,
      paymentRequiresAction: false,
      stripePaymentIntentClientSecret: '',
      csrfToken
    };

    this.handlePurchaserNameChange = this.handlePurchaserNameChange.bind(this);
    this.handlePurchaserEmailAddressChange = this.handlePurchaserEmailAddressChange.bind(this);
    this.handlePurchaserDetailsComplete = this.handlePurchaserDetailsComplete.bind(this);
    this.handleRecipientNameChange = this.handleRecipientNameChange.bind(this);
    this.handleRecipientMessageChange = this.handleRecipientMessageChange.bind(this);
    this.handleRecipientEmailAddressChange = this.handleRecipientEmailAddressChange.bind(this);
    this.handleRecipientDetailsComplete = this.handleRecipientDetailsComplete.bind(this);

    this.handlePaymentSubmissionStart = this.handlePaymentSubmissionStart.bind(this);
    this.handlePaymentSubmissionFailure = this.handlePaymentSubmissionFailure.bind(this);
    this.handlePaymentSubmissionSuccess = this.handlePaymentSubmissionSuccess.bind(this);

    this.handleCardActionSuccess = this.handleCardActionSuccess.bind(this);
    this.handleCardActionFailure = this.handleCardActionFailure.bind(this);
  }

  public render(): ReactNode {
    return (
      <article>
        <div>
          <nav className='breadcrumb'>
          </nav>
        </div>
        <Elements stripe={this.stripePromise} options={{
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Lato'
            }
          ]
        }}>
          {this.renderSubForm()}
        </Elements>
        <HelpMessage />
      </article>
    );
  }

  private nicelyFormatted(text: string): string {
    return text.replace(/\b[a-z]/g, match => match.toUpperCase());
  }

  private scrollToTop(): void {
    // Scroll back up to the top of the container when changing step.
    const element = document.getElementById('react-GiftVoucherForm');
    if (element) {
      element.scrollIntoView(true);
    }
  }

  private setStep(step: GiftVoucherFormStep): void {
    this.setState({ step });
    this.scrollToTop();
  }

  private handlePurchaserNameChange(purchaserName: string): void {
    this.setState({ purchaserName: this.nicelyFormatted(purchaserName) });
  }

  private handlePurchaserEmailAddressChange(purchaserEmailAddress: string): void {
    this.setState({ purchaserEmailAddress });
  }

  private handlePurchaserDetailsComplete(): void {
    this.setStep('recipientDetails');
  }

  private handleRecipientNameChange(recipientName: string): void {
    this.setState({ recipientName: this.nicelyFormatted(recipientName) });
  }

  private handleRecipientMessageChange(recipientMessage: string): void {
    this.setState({ recipientMessage });
  }

  private handleRecipientEmailAddressChange(recipientEmailAddress: string): void {
    this.setState({ recipientEmailAddress });
  }

  private handleRecipientDetailsComplete(): void {
    this.setStep('payment');
  }

  private handlePaymentSubmissionStart(): void {
    this.setState({ submissionErrorMessage: '', submissionInProgress: true });
  }

  private handlePaymentSubmissionFailure(): void {
    this.setState({ submissionErrorMessage: '', submissionInProgress: false });
  }

  private async handlePaymentSubmissionSuccess(stripePaymentMethodId: string): Promise<void> {
    const purchaseRequestData: IGiftVoucherPurchaseRequest = {
      voucher_type: this.props.profileType,
      purchaser_name: this.state.purchaserName,
      purchaser_email_address: this.state.purchaserEmailAddress,
      recipient_name: this.state.recipientName,
      message: this.state.recipientMessage,
      stripe_payment_method_id: stripePaymentMethodId
    };
    if (this.state.recipientEmailAddress) {
      purchaseRequestData.recipient_email_address = this.state.recipientEmailAddress;
    }
    this.submitPaymentAttempt({ gift_voucher_purchase_request: purchaseRequestData }, 'POST');
  }

  private async submitPaymentAttempt(data: SubmissionData, method: 'POST' | 'PUT'): Promise<boolean> {
    try {
      const response = await fetch(
        this.props.giftVoucherPurchaseRequestsPath,
        {
          method,
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken },
          body: JSON.stringify(data)
        }
      );
      const json = await response.json();
      if (json.status && json.status === 'success') {
        this.setState({ submissionErrorMessage: '', submissionInProgress: false, paymentRequiresAction: false });
        this.setStep('complete');
        return true;
      } else if (json.status && json.status === 'requires_action') {
        this.setState({ submissionInProgress: false, paymentRequiresAction: true, stripePaymentIntentClientSecret: json.paymentIntentClientSecret });
        return false;
      } else {
        const errorMessage = json.errors || 'Sorry, there was a problem processing your gift voucher purchase.';
        this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
        return false;
      }
    } catch (error) {
      console.error(error);
      const errorMessage = 'Sorry, there was a problem processing your gift voucher purchase.';
      this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
      return false;
    }
  }

  private async handleCardActionSuccess(stripePaymentIntentId: string): Promise<void> {
    const data = {
      gift_voucher_purchase_confirmation_request: {
        voucher_type: this.props.profileType,
        purchaser_name: this.state.purchaserName,
        purchaser_email_address: this.state.purchaserEmailAddress,
        recipient_name: this.state.recipientName,
        message: this.state.recipientMessage,
        stripe_payment_intent_id: stripePaymentIntentId
      }
    };
    this.submitPaymentAttempt(data, 'PUT');
  }

  private async handleCardActionFailure(error: string): Promise<void> {
    const errorMessage = error || 'Sorry, there was a problem processing your gift voucher purchase.';
    this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
  }

  private renderSubForm(): ReactNode {
    switch (this.state.step) {
      case 'purchaserDetails':
        return (
          <PurchaserDetailsForm
            title={`Buy ${this.props.profileType === 'owner' ? 'an owner' : 'a borrower'} gift voucher`}
            formattedPrice={this.props.formattedPrice}
            purchaserName={this.state.purchaserName}
            purchaserEmailAddress={this.state.purchaserEmailAddress}
            onPurchaserNameChange={this.handlePurchaserNameChange}
            onPurchaserEmailAddressChange={this.handlePurchaserEmailAddressChange}
            onPurchaserDetailsComplete={this.handlePurchaserDetailsComplete}
          />
        );
      case 'recipientDetails':
        return (
          <RecipientDetailsForm
            title={`Buy ${this.props.profileType === 'owner' ? 'an owner' : 'a borrower'} gift voucher`}
            formattedPrice={this.props.formattedPrice}
            recipientName={this.state.recipientName}
            recipientMessage={this.state.recipientMessage}
            recipientEmailAddress={this.state.recipientEmailAddress}
            onRecipientNameChange={this.handleRecipientNameChange}
            onRecipientMessageChange={this.handleRecipientMessageChange}
            onRecipientEmailAddressChange={this.handleRecipientEmailAddressChange}
            onRecipientDetailsComplete={this.handleRecipientDetailsComplete}
          />
        );
      case 'payment':
        if (this.state.paymentRequiresAction) {
          return (
            <CardActionForm
              title='Go Premium now'
              paymentIntentClientSecret={this.state.stripePaymentIntentClientSecret}
              confirmationImageUrl={this.props.confirmationImageUrl}
              onActionSuccess={this.handleCardActionSuccess}
              onActionFailure={this.handleCardActionFailure}
            />
          );
        } else {
          return (
            <CardPaymentForm
              title={`Buy ${this.props.profileType === 'owner' ? 'an owner' : 'a borrower'} gift voucher`}
              titleClass='gift'
              buttonText={`Pay ${this.props.formattedPrice}`}
              disclaimerText='Our prices include VAT.'
              stripeErrorMessage='Unfortunately there was an error processing your payment. Please try again.'
              errorMessage={this.state.submissionErrorMessage}
              submissionInProgress={this.state.submissionInProgress}
              onSubmissionStart={this.handlePaymentSubmissionStart}
              onSubmissionFailure={this.handlePaymentSubmissionFailure}
              onSubmissionSuccess={this.handlePaymentSubmissionSuccess}
            />
          );
        }
      case 'complete':
        return (
          <PaymentSuccessful
            title='Payment successful!'
            button='Buy another'
            firstParagraph='Pawsome, your gift voucher and receipt will soon arrive in your inbox.'
            secondParagraph={`${this.state.recipientName} can redeem the voucher any time within the next six months.`}
            forwardPath={this.props.giftVouchersPath}
          />
        );
    }
  }
}
