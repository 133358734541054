import Bugsnag from '@bugsnag/js';
import React, { FunctionComponent, ReactNode } from 'react';

import { AppContext } from './AppContext';
import { DevelopmentErrorBoundary } from './DevelopmentErrorBoundary';
import { readReactEnvironment } from './Environment';
import { ErrorHandlingClass } from './ErrorHandlingClass';
import { MajorErrorFallback } from './MajorErrorFallback';
import { MinorErrorFallback } from './MinorErrorFallback';

export interface IComponentWrapperProps {
  errorHandlingClass: ErrorHandlingClass;
  context: Record<string, unknown>;
  wrapWithRouter?: boolean;
  children?: ReactNode;
}

export const ComponentWrapper: FunctionComponent<IComponentWrapperProps> = (props: IComponentWrapperProps) => {
  // This is mainly about error boundaries. For theme and context wrapping, look at AppContext.

  const environment = readReactEnvironment();

  const wrappedChildren = <AppContext context={props.context} wrapWithRouter={props.wrapWithRouter}>{props.children}</AppContext>;

  if (environment.railsEnv === 'production' && environment.bugsnagApiKey && environment.bugsnagReleaseStage) {
    const BugsnagPlugin = Bugsnag.getPlugin('react');
    if (BugsnagPlugin) {
      const ErrorBoundary = BugsnagPlugin.createErrorBoundary();
      const ErrorFallback = props.errorHandlingClass === 'minor' ? MinorErrorFallback : MajorErrorFallback;

      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {wrappedChildren}
        </ErrorBoundary>
      );
    } else {
      // Fallback not including error boundary.
      return wrappedChildren;
    }
  } else {
    return (
      <DevelopmentErrorBoundary errorHandlingClass={props.errorHandlingClass}>
        {wrappedChildren}
      </DevelopmentErrorBoundary>
    );
  }
};
