import { gqlCommand } from '@borrowmydoggy/api-client';
import { Mutation, MutationResetProfileSearchLocationArgs, MutationUpdateProfileSearchLocationArgs } from '@borrowmydoggy/bmd-api';

export const mutations = {
  updateProfileSearchLocation: gqlCommand<{ updateProfileSearchLocation: Mutation['updateProfileSearchLocation']; }, MutationUpdateProfileSearchLocationArgs>`
    mutation UpdateProfileSearchLocation(
      $profileType: Profile!,
      $searchText: String!,
      $latitude: Float!,
      $longitude: Float!,
      $searchDistance: SearchDistance!,
      $searchDirections: [CompassBearing!]!,
      $locationType: SearchLocation!,
      $postcode: String
    ) {
      updateProfileSearchLocation(
        profileType: $profileType,
        searchText: $searchText,
        latitude: $latitude,
        longitude: $longitude,
        searchDistance: $searchDistance,
        searchDirections: $searchDirections,
        locationType: $locationType,
        postcode: $postcode
      ) {
        errors
      }
    }
  `,
  resetProfileSearchLocation: gqlCommand<{ resetProfileSearchLocation: Mutation['resetProfileSearchLocation']; }, MutationResetProfileSearchLocationArgs>`
    mutation ResetProfileSearchLocation($profileType: Profile!) {
      resetProfileSearchLocation(profileType: $profileType) {
        errors
      }
    }
  `
};
