import { z } from 'zod';

import { PasswordAuthenticationProvider } from '@borrowmydoggy/core-models';

export const passwordAuthenticationProvider = z.object({
  authenticationType: z.literal('password'),
  password: PasswordAuthenticationProvider.password
});

export const facebookAuthenticationProvider = z.object({
  authenticationType: z.literal('facebook'),
  facebookUid: z.string(),
  facebookToken: z.string()
});

export const appleAuthenticationProvider = z.object({
  authenticationType: z.literal('apple'),
  appleUid: z.string(),
  refreshToken: z.string(),
  tokenExpiresAt: z.number()
});

export const authenticationProvider = z.union([passwordAuthenticationProvider, facebookAuthenticationProvider, appleAuthenticationProvider]);
export type UAuthenticationProvider = z.infer<typeof authenticationProvider>;
