import React, { useContext } from 'react';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { DogNamesField } from '@borrowmydoggy/profile-components';
import { OwnerProfile } from '@borrowmydoggy/core-models';
import { Form, FormSubmissionResult } from '@borrowmydoggy/form-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const dogNamesSchema = z.object({ dogNames: OwnerProfile.dogNames });
type DogNamesSchema = z.infer<typeof dogNamesSchema>;

const StyledDogNamesField = styled(DogNamesField)`
  margin-bottom: 30px;
` as typeof DogNamesField;

export const Owner2A: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  function handleSubmit(data: DogNamesSchema): FormSubmissionResult<DogNamesSchema> {
    const cleanedDogNames = data.dogNames.map(dn => dn.trim()).filter(dn => dn.length > 0);
    props.record({ dogNames: cleanedDogNames, dogNamesSubmitted: true });
    return { outcome: 'success' };
  }

  return (
    <StepContainer
      stepTitle='All about your pooch'
      totalSteps={props.totalStepCount}
      currentStep={2}
      stepImageUrl={context.images['illustrations/img-dog-blanket-flat.svg']}
    >
      <StepHeading text='What is your dog called?' />
      <StandardStepDescription text='Add every dog you’d like to be borrowed. For your own safety please don’t include your surname.' />
      <Form
        schema={dogNamesSchema}
        initialValues={{ dogNames: props.manuscript.dogNames }}
        onSubmit={handleSubmit}
      >
      {({ fields, handleChange, handleValidate }) => (
          <>
            <StyledDogNamesField
              id='dogNames'
              value={fields.dogNames.value}
              validationState={fields.dogNames.validationState}
              onChange={handleChange}
              onValidate={handleValidate}
            />
            <StepNavigation
              nextButton={{
                text: 'Next',
                icon: faChevronRight,
                iconAlignment: 'right'
              }}
            />
          </>
      )}
      </Form>
    </StepContainer>
  );
};
