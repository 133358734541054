import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { BorrowerProfile } from '@borrowmydoggy/core-models';
import { Form, FormSubmissionResult, TextAreaField } from '@borrowmydoggy/form-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const descriptionSchema = z.object({ description: BorrowerProfile.description });
type DescriptionSchema = z.infer<typeof descriptionSchema>;

export const Borrower9B: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  async function handleSubmit(data: DescriptionSchema): Promise<FormSubmissionResult<DescriptionSchema>> {
    await props.record({ description: data.description.trim(), descriptionConfirmed: true, borrowerProfileCreationStatus: 'pending' });
    props.operations.createBorrowerProfile();
    return { outcome: 'success', formStateAction: 'halt' };
  }

  return (
    <StepContainer
      stepTitle='Some things to check'
      totalSteps={props.totalStepCount}
      currentStep={9}
      stepImageUrl={context.images['illustrations/img-person-holding-massive-pencil.svg']}
      stepPanelWidth='wide'
      hideImageAtMobileScreenWidths
    >
      <StepHeading text='Please check your description' />
      <StandardStepDescription text='You have used a word that we don’t allow, please remove it to continue.' />
      <Form
        schema={descriptionSchema}
        initialValues={{ description: props.manuscript.description }}
        initiallyExternallyInvalid={{ description: 'Please remove the inappropriate word' }}
        mode='editing'
        onSubmit={handleSubmit}
      >
        {({ fields, handleChange, handleValidate }) => (
          <>
            <TextAreaField
              id='description'
              label='Description'
              placeholder='Help owners to get to know and trust you'
              value={fields.description.value}
              validationState={fields.description.validationState}
              onChange={handleChange}
              onValidate={handleValidate}
            />
            <StepNavigation
              nextButton={{
                icon: faChevronRight,
                text: 'Next',
                iconAlignment: 'right'
              }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
