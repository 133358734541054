import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface ISelectListItem {
	id: string;
	text: string;
}

export interface ISelectListProps {
	id: string;
	items: ISelectListItem[];
	selectedId?: string;
	label: string;
	className?: string;
	onChange?: (id: string) => void;
}

const StyledSelectList = styled.span`
	display: inline-block;

	&>label {
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
	&>select {
		appearance: none;
		font-family: Lato, sans-serif;
		font-size: 16px;
		color: ${props => props.theme.primary.mid};
		padding: 0 21px 0 5px;
		border: none;
		background-color: transparent;
		box-sizing: border-box;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMDBjNzhjO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTIwNywzODEuNSwxMi43LDE4Ny4xYTIzLjksMjMuOSwwLDAsMSwwLTMzLjlsMjIuNy0yMi43YTI0LDI0LDAsMCwxLDMzLjksMEwyMjQsMjg0LjVsMTU0LjctMTU0YTI0LDI0LDAsMCwxLDMzLjksMGwyMi43LDIyLjdhMjMuOSwyMy45LDAsMCwxLDAsMzMuOUwyNDEsMzgxLjVhMjQuMiwyNC4yLDAsMCwxLTM0LDBaIi8+PC9zdmc+Cg==');
		background-repeat: no-repeat;
		background-position: right 2px top 2px;
		background-size: 16px;
	}

	&:hover {
		background-color: ${props => props.theme.primary.mid};
		&>select {
			color: ${props => props.theme.base};
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTIwNywzODEuNSwxMi43LDE4Ny4xYTIzLjksMjMuOSwwLDAsMSwwLTMzLjlsMjIuNy0yMi43YTI0LDI0LDAsMCwxLDMzLjksMEwyMjQsMjg0LjVsMTU0LjctMTU0YTI0LDI0LDAsMCwxLDMzLjksMGwyMi43LDIyLjdhMjMuOSwyMy45LDAsMCwxLDAsMzMuOUwyNDEsMzgxLjVhMjQuMiwyNC4yLDAsMCwxLTM0LDBaIi8+PC9zdmc+Cg==');
		}
	}
`;

export const SelectList: FunctionComponent<ISelectListProps> = (props: ISelectListProps) => {
	function handleChange(event: ChangeEvent<HTMLSelectElement>): void {
		if (props.onChange) {
			props.onChange(event.target.value);
		}
	}

	function renderOptions(items: ISelectListItem[]): ReactNode[] {
		return items.map(item => <option key={item.id} value={item.id}>{item.text}</option>);
	}

	return (
		<StyledSelectList>
			<label htmlFor={props.id}>{props.label}</label>
			<select id={props.id} value={props.selectedId} onChange={handleChange}>
				{renderOptions(props.items)}
			</select>
		</StyledSelectList>
	);
};
