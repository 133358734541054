import { StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationStep } from './OwnerProfileCreationConductor';

import { ExitPrompt } from './ExitPrompt';
import { Owner2A } from './Owner2A';
import { Owner2B } from './Owner2B';
import { Owner3 } from './Owner3';
import { Owner4A } from './Owner4A';
import { Owner4B } from './Owner4B';
import { Owner5A } from './Owner5A';
import { Owner5B } from './Owner5B';
import { Owner6 } from './Owner6';
import { Owner7 } from './Owner7';
import { Owner8 } from './Owner8';
import { Owner9 } from './Owner9';
import { Owner10 } from './Owner10';
import { Owner11 } from './Owner11';
import { Owner12A } from './Owner12A';
import { Owner12B } from './Owner12B';
import { Owner13 } from './Owner13';
import { Owner14 } from './Owner14';
import { Owner15 } from './Owner15';
import { Owner17A } from './Owner17A';
import { Owner17B } from './Owner17B';
import { Owner17C } from './Owner17C';
import { OwnerError } from './OwnerError';
import { SwitchPrompt } from './SwitchPrompt';

export * from './IOwnerProfileCreationManuscript';
export * from './OwnerProfileCreationConductor';

export const OwnerProfileCreationStepMap: Record<OwnerProfileCreationStep, StepComponent<IOwnerProfileCreationManuscript>> = {
  owner2a: Owner2A,
  owner2b: Owner2B,
  owner3: Owner3,
  owner4a: Owner4A,
  owner4b: Owner4B,
  owner5a: Owner5A,
  owner5b: Owner5B,
  owner6: Owner6,
  owner7: Owner7,
  owner8: Owner8,
  owner9: Owner9,
  owner10: Owner10,
  owner11: Owner11,
  owner12a: Owner12A,
  owner12b: Owner12B,
  owner13: Owner13,
  owner14: Owner14,
  owner15: Owner15,
  owner17a: Owner17A,
  owner17b: Owner17B,
  owner17c: Owner17C,
  ownerError: OwnerError,
  exitPrompt: ExitPrompt,
  switchPrompt: SwitchPrompt
};
