import React, { useContext } from 'react';
import { faForward, faRedoAlt } from '@fortawesome/pro-solid-svg-icons';

import { DecisionStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const ExitPrompt: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  function handleActionClick(actionId: 'continue' | 'restart'): void {
    if (actionId === 'continue') {
      props.record({ exitPromptShown: false });
    } else {
      window.location.href = '/signup';
    }
  }

  function handleCancelClick(): void {
    window.location.href = '/';
  }

  return (
    <DecisionStep
      stepTitle='What would you like to do?'
      stepHeading='Want to join or leave the pack?'
      totalSteps={props.totalStepCount}
      currentStep={0}
      buttons={[
        {
          id: 'restart',
          title: 'Delete everything and start again',
          buttonType: 'negative',
          buttonText: 'Start again',
          buttonIcon: faRedoAlt,
          illustrationURL: context.images['illustrations/img-dog-waiting-at-door.svg']
        },
        {
          id: 'continue',
          title: 'Carry on creating my account and profile',
          buttonText: 'Keep going',
          buttonIcon: faForward,
          illustrationURL: context.images['illustrations/img-identity-cards.svg']
        }
      ]}
      cancelButtonText='Quit and delete my data'
      onDecisionClick={handleActionClick}
      onCancelClick={handleCancelClick}
    >
      <StandardStepDescription text='You’ve started creating your BorrowMyDoggy account and profile, let us know if you’d like to continue with it or not.' />
    </DecisionStep>
  );
};
