import React from 'react';

import { IActivityStreamEventComponent, IActivityStreamEventComponentProps } from './IActivityStreamEventComponent';
import { IInboundMessageBorrowerActivityStreamEvent } from './IInboundMessageBorrowerActivityStreamEvent';
import { MessageEvent } from './MessageEvent';

export const InboundMessageBorrowerActivityStreamEvent: IActivityStreamEventComponent<IInboundMessageBorrowerActivityStreamEvent> = (
  props: IActivityStreamEventComponentProps<IInboundMessageBorrowerActivityStreamEvent>
) => {
  return (
    <MessageEvent eventData={props.eventData} missingBorrowerImageURL={props.missingBorrowerImageURL} missingOwnerImageURL={props.missingOwnerImageURL}>
      <div className='activity-stream-event__content-heading-text'>
        <span className='green'>
          <a href={props.eventData.profileUrl} className='link_standard-green'>{props.eventData.dogName}</a>
        </span>
        {' '}
        <span className='gray'>sent you a</span>
        {' '}
        <span className='blue'>
          <a href={props.eventData.conversationUrl}>message</a>
        </span>
      </div>
    </MessageEvent>
  );
};
