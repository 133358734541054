import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import { ResponsiveBlock, responsiveQuery, SimpleHeader } from '@borrowmydoggy/core-components';
import { IPersistenceAdapter, LocalStorageAdapter, StepConductor, StepFooter } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript, resolvers, OwnerProfileCreationStepMap, OwnerProfileCreationStep } from '../ownerProfileCreation';
import { ownerProfileCreationOperations } from '../operations';
import { rudderanalytics } from '../utils';

export interface IOwnerProfileCreationProps {
  existingBorrowerProfile: boolean;
}

const PageContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  min-height: 100vh;

  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait')} {
    grid-template-rows: max-content 1fr max-content;
  }
  ${responsiveQuery('mobileLandscape', 'mobilePortrait')} {
    grid-template-rows: 1fr max-content;
  }
`;

const StyledHeader = styled(SimpleHeader)`
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 30%);
`;

export const OwnerProfileCreation: FunctionComponent<IOwnerProfileCreationProps> = (props: IOwnerProfileCreationProps) => {
  const localStorageAdapterRef = useRef<IPersistenceAdapter<Partial<IOwnerProfileCreationManuscript>>>(new LocalStorageAdapter('ownerProfileCreation'));

  function resolveInitialManuscript(): Partial<IOwnerProfileCreationManuscript> {
    const persistedManuscript = localStorageAdapterRef.current.load();
    if (persistedManuscript == null) {
      return {
        existingBorrowerProfile: props.existingBorrowerProfile
      };
    } else if (persistedManuscript.dogDateOfBirth && typeof persistedManuscript.dogDateOfBirth === 'string') {
      persistedManuscript.dogDateOfBirth = DateTime.fromISO(persistedManuscript.dogDateOfBirth).toJSDate();
    }
    return {
      ...persistedManuscript,
      existingBorrowerProfile: props.existingBorrowerProfile
    };
  }

  function handleStepChange(newStep: OwnerProfileCreationStep): void {
    rudderanalytics.track('ownerProfile.completion.stepChange', { newStep });
  }

  return (
    <PageContainer>
      <ResponsiveBlock categories={['desktop', 'tabletLandscape', 'tabletPortrait']}>
        <StyledHeader />
      </ResponsiveBlock>
      <StepConductor
        processDefinition={{
          initialStep: 'owner2a',
          fallbackStep: 'ownerError',
          resolvers,
          totalStepCount: 18,
          stepComponents: OwnerProfileCreationStepMap,
          onStepChange: handleStepChange,
          onCompletion: (manuscript: Partial<IOwnerProfileCreationManuscript>) => console.log('completed!', manuscript)
        }}
        operations={ownerProfileCreationOperations}
        initialManuscript={resolveInitialManuscript()}
        stepFooter={({ manuscript, record }) => (
          <StepFooter
            onClick={() => record({ exitPromptShown: true })}
            hideQuitButton={manuscript.ownerProfileCreationStatus === 'successful'}
          />
        )}
        persistenceAdapter={localStorageAdapterRef.current}
      />
    </PageContainer>
  );
};
