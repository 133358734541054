import { gqlCommand } from '@borrowmydoggy/api-client';
import {
  BorrowerProfile,
  OwnerProfile,
  Query,
  QueryBorrowerActivityStreamArgs,
  QueryBorrowerProfileArgs,
  QueryOwnerActivityStreamArgs,
  QueryOwnerProfileArgs
} from '@borrowmydoggy/bmd-api';

type PartialBorrowerProfile = Omit<
  BorrowerProfile,
  'activityLevel' | 'availability' | 'description' | 'direction' | 'distanceInMiles' | 'imageUrls' | 'name' | 'premium'
>;
type PartialOwnerProfile = Omit<
  OwnerProfile,
  'availability' | 'breed' | 'description' | 'direction' | 'distanceInMiles' | 'dogDateOfBirth'
| 'dogName' | 'dogNames' | 'imageUrls' | 'multipleDogs' | 'ownerName' | 'premium'
>;

export const queries = {
  borrowerActivityStream: gqlCommand<{ borrowerActivityStream: Query['borrowerActivityStream']; }, QueryBorrowerActivityStreamArgs>`
    query BorrowerActivityStream($page: Int, $filter: ActivityStreamFilter) {
      borrowerActivityStream(page: $page, filter: $filter) {
        activityStreamEvents {
          ...on BorrowingInfoUpdateActivityStreamEvent {
            borrowingInfoUrl
            dogName
            profileImageUrl
            profileType
            profileUrl
          }
          ...on InboundLikeBorrowerActivityStreamEvent {
            dogName
            profileImageUrl
            profileType
            profileUrl
          }
          ...on InboundMessageBorrowerActivityStreamEvent {
            conversationUrl
            dogName
            messageBody
            profileImageUrl
            profileType
            profileUrl
          }
          ...on OutboundLikeBorrowerActivityStreamEvent {
            dogName
            profileImageUrl
            profileType
            profileUrl
          }
          eventCreatedAt
          eventId
          eventType
          userVerified
        }
        currentPage
        filter
        lastPage
      }
    }
  `,
  ownerActivityStream: gqlCommand<{ ownerActivityStream: Query['ownerActivityStream']; }, QueryOwnerActivityStreamArgs>`
    query OwnerActivityStream($page: Int, $filter: ActivityStreamFilter) {
      ownerActivityStream(page: $page, filter: $filter) {
        activityStreamEvents {
          ...on BorrowingInfoUpdateActivityStreamEvent {
            borrowingInfoUrl
            dogName
            profileImageUrl
            profileType
            profileUrl
          }
          ...on InboundLikeOwnerActivityStreamEvent {
            borrowerName
            dogName
            profileImageUrl
            profileType
            profileUrl
          }
          ...on InboundMessageOwnerActivityStreamEvent {
            borrowerName
            conversationUrl
            messageBody
            profileImageUrl
            profileType
            profileUrl
          }
          ...on OutboundLikeOwnerActivityStreamEvent {
            borrowerName
            profileImageUrl
            profileType
            profileUrl
          }
          eventCreatedAt
          eventId
          eventType
          userVerified
        }
        currentPage
        filter
        lastPage
      }
    }
  `,
  partialBorrowerProfile: gqlCommand<{ borrowerProfile: PartialBorrowerProfile; }, QueryBorrowerProfileArgs>`
    query BorrowerProfile($profileId: String!) {
      borrowerProfile(profileId: $profileId) {
        liked
        likeCount
        profileCreatedAtUtc
        company
        exercise
        acceptingMessages
        conversationInProgress
        picsRequested
        conversationPath
        profileFeedback {
          name
          dateText
          text
          portraitImageUrl
        }
        borrowerAttributes {
          ownedDog
          garden
          childrenAtHome
        }
      }
    }
  `,
  partialOwnerProfile: gqlCommand<{ ownerProfile: PartialOwnerProfile; }, QueryOwnerProfileArgs>`
    query PartialOwnerProfile($profileId: String!) {
      ownerProfile(profileId: $profileId) {
        breedCode
        liked
        dogAge
        profileCreatedAtUtc
        activityLevel
        company
        exercise
        acceptingMessages
        conversationInProgress
        picsRequested
        conversationPath
        doggyInfoSheetPath
        profileFeedback {
          name
          dateText
          text
          portraitImageUrl
        }
        dogProperties {
          hypoallergenic
          neutered
          rescueDog
        }
        dogCompatibility {
          cats
          children
          otherDogs
        }
      }
    }
  `
};
