import React from 'react';

import { IStepComponentProps, ProcessingStep, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';

export const User11D: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  return (
    <ProcessingStep
      displayCancelButton={false}
      totalSteps={props.totalStepCount}
      currentStep={11}
    >
      <StandardStepDescription text='We’ve made your account and we’re signing you in' />
    </ProcessingStep>
  );
};
