import * as rudderanalytics from 'rudder-sdk-js';

import { readReactEnvironment } from './Environment';

export function loadAnalytics(): void {
  const environment = readReactEnvironment();
  if (environment.railsEnv !== 'test') {
    rudderanalytics.load(environment.rudderstackFrontendWriteKey, environment.rudderstackDataPlaneUrl);
  }
}

interface IUserIdentity {
  userTrackingId: string;
  userUuid: string;
  userEmailAddress: string;
}

interface IAnonymousIdentity {
  userTrackingId: string;
}

type ServerIdentity = IUserIdentity | IAnonymousIdentity;

interface IServerEvent {
  eventName: string;
  identity: ServerIdentity;
  metadata: Record<string, string>;
  createdAt: string;
}

// This function is exclusively for Bing and Google Ads, due to the fact that they only support device mode in RudderStack,
// meaning that a proper RudderStack server-side transformation function is not available.
export function trackPageEventsForRudderStack(): void {
  try {
    const trackingEventsElement = document.querySelector('meta[name=rs-tracking-events]');
    if (trackingEventsElement) {
      const trackingEventsAttribute = trackingEventsElement.attributes.getNamedItem('content');
      if (trackingEventsAttribute) {
        const parsedEvents: IServerEvent[] = JSON.parse(trackingEventsAttribute.value);
        parsedEvents.forEach(e => {
          switch (e.eventName) {
            case 'userAccount.register':
              rudderanalytics.track(
                e.metadata.initialProfileType === 'borrower' ? 'Borrower Sign Up' : 'Owner Sign Up',
                {
                  category: 'Signups'
                },
                {
                  integrations: {
                    All: false,
                    'Bing Ads': true
                  }
                }
              );
              rudderanalytics.track(
                `${e.eventName}.${e.metadata.initialProfileType}`,
                {
                  integrations: {
                    All: false,
                    'Google Ads': true
                  }
                }
              );
              break;
            case 'subscription.purchase':
              rudderanalytics.track(
                e.metadata.subscriptionPlanProfileType === 'borrower' ? 'Borrower Subscribed' : 'Owner Subscribed',
                {
                  category: 'Subscription'
                },
                {
                  integrations: {
                    All: false,
                    'Bing Ads': true
                  }
                }
              );
              rudderanalytics.track(
                `${e.eventName}.${e.metadata.subscriptionPlanProfileType}`,
                {
                  integrations: {
                    All: false,
                    'Google Ads': true
                  }
                }
              );
              break;
          }
        });
      }
    }
  } catch (error: unknown) {
    console.warn('Failed to raise server tracking events with RudderStack.', error);
  }
}

export { rudderanalytics };
