import React from 'react';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { dogBreeds } from '@borrowmydoggy/core-models';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { OwnerPortrait } from '@borrowmydoggy/profile-components';
import { responsiveQuery } from '@borrowmydoggy/core-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';

const ResponsiveOwnerPortrait = styled(OwnerPortrait)`
  display: block;
  margin: 0 auto 30px auto;
  ${responsiveQuery('desktop')} {
    height: 350px;
  }
  ${responsiveQuery('tabletLandscape', 'tabletPortrait')} {
    height: 300px;
  }
  ${responsiveQuery('mobileLandscape', 'mobilePortrait')} {
    height: 250px;
  }
`;

export const Owner4B: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);
  const possessive = props.manuscript.dogGender ? parseDogGender(props.manuscript.dogGender).genderedPossessive : 'their';
  const breedInformalPlural = dogBreeds.find(db => db.id === props.manuscript.dogBreedCode)?.informalPlural || 'dogs';

  function handleBackClick(): void {
    props.record({ dogBreedCode: undefined });
  }

  function handleNextClick(): void {
    props.record({ dogBreedConfirmed: true });
  }

  return (
    <StepContainer
      stepTitle={`Tell us about ${firstDogName}`}
      totalSteps={props.totalStepCount}
      currentStep={4}
    >
      <StepHeading text={`We love ${breedInformalPlural}`} />
      <StandardStepDescription text={`We’ll show this image on ${firstDogName}’s profile to give borrowers an idea of ${possessive} size.`} />
      <ResponsiveOwnerPortrait dogs={[props.manuscript.dogBreedCode || 'crossBreedMedium']} />
      <StepNavigation
        backButton={{
          text: 'Change breed'
        }}
        nextButton={{
          text: 'Next',
          icon: faChevronRight,
          iconAlignment: 'right'
        }}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
      />
    </StepContainer>
  );
};
