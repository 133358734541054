import React, { useContext, useState } from 'react';
import { faCircleXmark, faForward, faRedoAlt } from '@fortawesome/pro-solid-svg-icons';

import { DecisionStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';
import { UEnhancedButtonProps } from '@borrowmydoggy/product-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';
import { ProfileAPI } from '../api';
import { WebContext } from '../utils/AppContext';

export const ExitPrompt: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const [inProgress, setInProgress] = useState(false);
  const [inError, setInError] = useState(false);

  async function handleActionClick(actionId: 'continue' | 'restart' | 'delete'): Promise<void> {
    try {
      if (actionId === 'continue') {
        props.record({ exitPromptShown: false });
      } else {
        let requestSuccess = true;
        if (props.manuscript.existingOwnerProfile) {
          setInProgress(true);
          setInError(false);
          const response = await ProfileAPI.destroyIncompleteBorrowerProfile();
          if (response.error !== undefined || response?.data?.destroyIncompleteBorrowerProfile?.successful !== true) {
            setInError(true);
            setInProgress(false);
            requestSuccess = false;
          }
          // Note that if this worked, we don't leave the in-progress state (as we're about to navigate away).
        }
        if (requestSuccess) {
          if (props.persistenceAdapter) {
            props.persistenceAdapter.clear();
          }
          window.location.href = '/home';
        }
      }
    } catch {
      setInError(true);
      setInProgress(false);
    }
  }

  function handleCancelClick(): void {
    if (props.persistenceAdapter) {
      props.persistenceAdapter.clear();
    }
    window.location.href = '/signout';
  }

  let firstButton: UEnhancedButtonProps<'delete' | 'restart'>;
  let stepDescriptionText: string;

  if (props.manuscript.existingOwnerProfile) {
    firstButton = {
      id: 'delete',
      title: 'Delete my borrower profile (I just want to be an owner)',
      buttonType: 'negative',
      buttonText: 'Delete profile',
      buttonIcon: faCircleXmark,
      illustrationURL: context.images['illustrations/img-dog-waiting-at-door.svg']
    };
    stepDescriptionText = 'You’ve already got an owner profile and you’ve started making a borrower profile. Do you want to complete your borrower profile or delete it?';
  } else {
    firstButton = {
      id: 'restart',
      title: 'Delete my profile and start again',
      buttonType: 'negative',
      buttonText: 'Start again',
      buttonIcon: faRedoAlt,
      illustrationURL: context.images['illustrations/img-dog-waiting-at-door.svg']
    };
    stepDescriptionText = 'You have a BorrowMyDoggy account but you haven’t completed your profile yet. Let us know what you want to do.';
  }

  const buttons: Array<UEnhancedButtonProps<'delete' | 'restart' | 'continue'>> = [
    firstButton,
    {
      id: 'continue',
      title: 'Carry on creating my profile',
      buttonText: 'Keep going',
      buttonIcon: faForward,
      illustrationURL: context.images['illustrations/img-identity-cards.svg']
    }
  ];

  return (
    <DecisionStep
      stepTitle='What would you like to do?'
      stepHeading='Want to finish your profile or delete it?'
      totalSteps={props.totalStepCount}
      currentStep={0}
      buttons={buttons}
      cancelButtonText={props.manuscript.existingOwnerProfile ? undefined : 'Sign out and delete my profile'}
      inProgress={inProgress}
      onDecisionClick={handleActionClick}
      onCancelClick={props.manuscript.existingOwnerProfile ? undefined : handleCancelClick}
    >
      <StandardStepDescription
        text={inProgress ? 'We’re changing your account' : stepDescriptionText}
        inError={inError}
        errorText='Something went wrong, please try again. If it keeps happening please let us know using the contact details in the footer.'
      />
    </DecisionStep>
  );
};
