import { Routes, Route, useNavigate } from 'react-router-dom';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CompassBearing, SearchDistance } from '@borrowmydoggy/product-components';
import { IDogSearchFilters, IDogSearchResult, ISearchCriteria, SearchLocationType, SearchViewType } from '@borrowmydoggy/search-components';

import { ProfileAPI, SearchAPI, UserAPI } from '../api';

import { IDogLikeResult, SearchContextOwnerProfile } from '../profile';
import { DogSearch } from '../search';

export interface IDogSearchContainerProps {
  currentUserPremiumBorrower: boolean;
  sharePath: string;
  premiumPath: string;
  premiumUrl: string;
}

interface ISearchResponse {
  results: IDogSearchResult[];
  total: number;
}

const clearFiltersState = {
  premium: false,
  dogSizes: { small: false, medium: false, large: false },
  compatibility: { cats: false, children: false, otherDogs: false },
  properties: { hypoallergenic: false, neutered: false, rescueDog: false },
  availability: { daytime: false, evenings: false, weekends: false, holidays: false }
};

const initialLimit = 24;
const moreResultsLimit = 12;

interface IDogSearchResultDetails {
  results: IDogSearchResult[];
  currentOffset: number;
  total: number;
}

export const DogSearchContainer: FunctionComponent<IDogSearchContainerProps> = (props: IDogSearchContainerProps) => {
  const [returnProfileId, setReturnProfileId] = useState('');
  const [searchViewType, setSearchViewType] = useState<SearchViewType>('grid');
  const [searchId, setSearchId] = useState('');
  const [resultDetails, setResultDetails] = useState<IDogSearchResultDetails>({ results: [], currentOffset: 0, total: 0 });
  const [criteria, setCriteria] = useState<ISearchCriteria>({
    sortType: 'lastUpdated',
    latitude: 0,
    longitude: 0,
    searchDistance: 'threeMiles',
    searchDirections: [],
    searchLocation: '',
    searchLocationType: 'permanent'
  });
  const [filters, setFilters] = useState<IDogSearchFilters>({ ...clearFiltersState });
  const [permanentSearchLocationText, setPermanentSearchLocationText] = useState('');
  const [allResultsLoading, setAllResultsLoading] = useState(false);
  const [furtherResultsLoading, setFurtherResultsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    loadInitialResults();
  }, []);

  function renderSearch(): ReactNode {
    return (
      <DogSearch
        searchViewType={searchViewType}
        searchId={searchId}
        results={resultDetails.results}
        offset={resultDetails.currentOffset}
        total={resultDetails.total}
        criteria={criteria}
        filters={filters}
        currentUserPremiumBorrower={props.currentUserPremiumBorrower}
        premiumUrl={props.premiumUrl}
        permanentSearchLocationText={permanentSearchLocationText}
        selectedProfileId={returnProfileId}
        allResultsLoading={allResultsLoading}
        furtherResultsLoading={furtherResultsLoading}
        sharePath={props.sharePath}
        onProfileSelected={handleProfileSelected}
        onSearchPreferencesChange={handleSearchPreferencesChange}
        onMoreResultsRequired={handleMoreResultsRequired}
        onSearchViewTypeToggle={handleSearchViewTypeToggle}
        onProfileLiked={handleProfileLiked}
        onProfileUnliked={handleProfileUnliked}
        onLocationChosen={handleLocationChosen}
        onRevertSearchLocation={handleRevertSearchLocation}
        onUpdateUserAccountLocation={handleUpdateUserAccountLocation}
      />
    );
  }

  function renderProfile(): ReactNode {
    return (
      <SearchContextOwnerProfile
        searchResults={resultDetails.results}
        totalResults={resultDetails.total}
        searchId={searchId}
        currentUserPremiumBorrower={props.currentUserPremiumBorrower}
        premiumPath={props.premiumPath}
        onBackLinkClick={handleReturnToSearch}
        onNavigateToProfile={handleNavigateToProfile}
        onNextProfileRequired={handleNextProfileRequired}
        onProfileLiked={handleProfileLiked}
        onProfileUnliked={handleProfileUnliked}
      />
    );
  }

  function handleProfileSelected(profileId: string): void {
    navigate(`/dog_profile/${profileId}`);
  }

  function handleSearchPreferencesChange(newCriteria: ISearchCriteria, newFilters: IDogSearchFilters): void {
    setCriteria(newCriteria);
    setFilters(newFilters);
    loadNewResults(newCriteria, newFilters);
    updateSearchPreferences(newCriteria, newFilters, searchViewType);
  }

  async function handleMoreResultsRequired(): Promise<void> {
    setFurtherResultsLoading(true);
    await loadNextResults();
    setFurtherResultsLoading(false);
  }

  function handleSearchViewTypeToggle(newSearchViewType: SearchViewType): void {
    setSearchViewType(newSearchViewType);
    updateSearchPreferences(criteria, filters, newSearchViewType);
  }

  async function handleProfileLiked(profileId: string, searchResultIndex: number): Promise<IDogLikeResult> {
    const likeResult = await recordProfileLike(profileId);
    if (likeResult.successful) {
      updateSearchResultLikeStatus(searchResultIndex, true);
    }
    return likeResult;
  }

  async function handleProfileUnliked(profileId: string, searchResultIndex: number): Promise<IDogLikeResult> {
    const unlikeResult = await recordProfileUnlike(profileId);
    if (unlikeResult.successful) {
      updateSearchResultLikeStatus(searchResultIndex, false);
    }
    return unlikeResult;
  }

  function updateSearchResultLikeStatus(searchResultIndex: number, liked: boolean): void {
    setResultDetails(currentDetails => ({
      ...currentDetails,
      results: [
        ...currentDetails.results.slice(0, searchResultIndex),
        { ...currentDetails.results[searchResultIndex], liked },
        ...currentDetails.results.slice(searchResultIndex + 1)
      ]
    }));
  }

  function handleReturnToSearch(lastProfileId: string): void {
    setReturnProfileId(lastProfileId);
    navigate('/search/dogs');
  }

  function handleNavigateToProfile(profileId: string): void {
    navigate(`/dog_profile/${profileId}`);
  }

  async function handleNextProfileRequired(): Promise<void> {
    const newResults = await loadNextResults();
    if (newResults.length > 0) {
      const firstNewProfileId = newResults[0].profileId;
      navigate(`/dog_profile/${firstNewProfileId}`);
    }
  }

  async function handleLocationChosen(
    searchText: string,
    latitude: number,
    longitude: number,
    searchDistance: SearchDistance,
    searchDirections: CompassBearing[],
    locationType: SearchLocationType,
    postcode?: string
  ): Promise<boolean> {
    const success = await updateSearchLocation(searchText, latitude, longitude, searchDistance, searchDirections, locationType, postcode);
    if (success) {
      const newCriteria = {
        ...criteria,
        latitude,
        longitude,
        searchDistance,
        searchDirections,
        searchLocation: searchText,
        searchLocationType: locationType
      };
      setCriteria(newCriteria);
      await loadNewResults(newCriteria, filters);
    }
    return success;
  }

  async function handleRevertSearchLocation(): Promise<boolean> {
    const success = await resetSearchLocation();
    if (success) {
      setTimeout(loadInitialResults, 2000);
    }
    return success;
  }

  async function handleUpdateUserAccountLocation(): Promise<boolean> {
    const success = await updateUserAccountLocationFromProfile();
    if (success) {
      setTimeout(loadInitialResults, 2000);
    }
    return success;
  }

  // API calls
  async function loadInitialResults(): Promise<void> {
    setAllResultsLoading(true);
    const response = await SearchAPI.defaultDogSearch(initialLimit);
    if (response.error === undefined && response.data && response.data.defaultDogSearch) {
      setSearchId(uuidv4());
      setFilters(response.data.defaultDogSearch.searchPreferences.filters);
      setCriteria(response.data.defaultDogSearch.searchPreferences.criteria);
      setPermanentSearchLocationText(response.data.defaultDogSearch.searchPreferences.criteria.permanentSearchLocation);
      setSearchViewType(response.data.defaultDogSearch.searchPreferences.searchViewType);
      setResultDetails({
        results: response.data.defaultDogSearch.results,
        currentOffset: response.data.defaultDogSearch.results.length,
        total: response.data.defaultDogSearch.count
      });
    } else {
      console.warn('API failure when attempting to load initial results.', response.error)
    }
    setAllResultsLoading(false);
  }

  async function loadNewResults(newCriteria: ISearchCriteria, newFilters: IDogSearchFilters): Promise<void> {
    setAllResultsLoading(true);
    const response = await loadResults(initialLimit, 0, newCriteria, newFilters);
    setSearchId(uuidv4());
    setResultDetails({
      results: response.results,
      currentOffset: response.results.length,
      total: response.total
    });
    setAllResultsLoading(false);
  }

  async function loadNextResults(): Promise<IDogSearchResult[]> {
    // Note: This would be better all in the setResultDetails state update function, but we need async.
    let nextResults: IDogSearchResult[] = [];
    if (resultDetails.results.length < resultDetails.total) {
      const response = await loadResults(moreResultsLimit, resultDetails.currentOffset, criteria, filters);
      if (response.total > 0) {
        setResultDetails(currentDetails => {
          const appendedResults = [...currentDetails.results, ...response.results];
          return {
            results: appendedResults,
            currentOffset: appendedResults.length,
            total: response.total
          };
        });
      }
      nextResults = response.results;
    }
    return nextResults;
  }

  async function loadResults(newLimit: number, newOffset: number, newCriteria: ISearchCriteria, newFilters: IDogSearchFilters): Promise<ISearchResponse> {
    const relevantCriteria = {
      sortType: newCriteria.sortType,
      searchDistance: newCriteria.searchDistance,
      searchDirections: newCriteria.searchDirections
    };
    const response = await SearchAPI.dogSearch(newLimit, newOffset, relevantCriteria, newFilters);
    if (response.error === undefined && response.data && response.data.dogSearch) {
      return { results: response.data.dogSearch.results, total: response.data.dogSearch.count };
    } else {
      console.warn('API failure when attempting to load results.', response.error)
      return { results: [], total: 0 };
    }
  }

  async function updateSearchPreferences(
    updatedCriteria: ISearchCriteria,
    updatedFilters: IDogSearchFilters,
    searchViewType: SearchViewType
  ): Promise<void> {
    const criteria = {
      sortType: updatedCriteria.sortType,
      searchDistance: updatedCriteria.searchDistance,
      searchDirections: updatedCriteria.searchDirections
    };
    const response = await ProfileAPI.updateBorrowerSearchPreferences(criteria, updatedFilters, searchViewType);
    if (response.error === undefined && response.data && response.data.updateBorrowerSearchPreferences) {
      if (response.data.updateBorrowerSearchPreferences.errors) {
        console.error(response.data.updateBorrowerSearchPreferences.errors);
      }
    } else {
      console.warn('API failure when attempting to update search preferences.', response.error)
    }
  }

  async function recordProfileLike(profileId: string): Promise<IDogLikeResult> {
    const response = await ProfileAPI.createBorrowerLike(profileId);
    if (response.error === undefined && response.data && response.data.createBorrowerLike) {
      return { successful: response.data.createBorrowerLike.liked, dogLikeCount: response.data.createBorrowerLike.dogLikeCount };
    } else {
      console.warn('API failure when attempting to record profile like.', response.error)
      return { successful: false, dogLikeCount: 0 };
    }
  }

  async function recordProfileUnlike(profileId: string): Promise<IDogLikeResult> {
    const response = await ProfileAPI.destroyBorrowerLike(profileId);
    if (response.error === undefined && response.data && response.data.destroyBorrowerLike) {
      return { successful: !response.data.destroyBorrowerLike.liked, dogLikeCount: response.data.destroyBorrowerLike.dogLikeCount };
    } else {
      console.warn('API failure when attempting to record profile unlike.', response.error)
      return { successful: false, dogLikeCount: 0 };
    }
  }

  async function updateSearchLocation(
    searchText: string,
    latitude: number,
    longitude: number,
    searchDistance: SearchDistance,
    searchDirections: CompassBearing[],
    locationType: SearchLocationType,
    postcode?: string
  ): Promise<boolean> {
    const response = await SearchAPI.updateProfileSearchLocation(
      'borrower',
      searchText,
      latitude,
      longitude,
      searchDistance,
      searchDirections,
      locationType,
      postcode
    );
    if (response.error === undefined && response.data && response.data.updateProfileSearchLocation) {
      const successful = !(response.data.updateProfileSearchLocation.errors && response.data.updateProfileSearchLocation.errors.length > 0);
      return successful;
    } else {
      console.warn('API failure when attempting to update profile search location.', response.error)
      return false;
    }
  }

  async function resetSearchLocation(): Promise<boolean> {
    const response = await SearchAPI.resetProfileSearchLocation('borrower');
    if (response.error === undefined && response.data && response.data.resetProfileSearchLocation) {
      return !(response.data.resetProfileSearchLocation.errors && response.data.resetProfileSearchLocation.errors.length > 0);
    } else {
      console.warn('API failure when attempting to reset profile search location.', response.error)
      return false;
    }
  }

  async function updateUserAccountLocationFromProfile(): Promise<boolean> {
    const response = await UserAPI.updateUserAccountLocationFromProfile('borrower');
    if (response.error === undefined && response.data && response.data.updateUserAccountLocationFromProfile) {
      return !(response.data.updateUserAccountLocationFromProfile.errors && response.data.updateUserAccountLocationFromProfile.errors.length > 0);
    } else {
      console.warn('API failure when attempting to update user account location from profile.', response.error)
      return false;
    }
  }

  return (
    <Routes>
      <Route path='/search/dogs' element={renderSearch()} />
      <Route path='/dog_profile/:profileId' element={renderProfile()} />
    </Routes>
  );
};
