import React, { Component, MouseEvent, ReactElement, ReactNode } from 'react';

import { IAddressSearchResult } from './IAddressSearchResult';

export interface ISuccessfulCreateFromSearchResultResponse {
  status: 'success';
  flat?: string;
  houseNumber?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  town: string;
  postcode?: string;
}

interface IFailedCreateFromSearchResultResponse {
  status: 'failure';
  error?: string;
}

interface ICreateFromSearchResultErrorResponse {
  status: 'error';
  error?: string;
}

type CreateFromSearchResultResponse = ISuccessfulCreateFromSearchResultResponse | IFailedCreateFromSearchResultResponse | ICreateFromSearchResultErrorResponse;

export interface IAddressSearchResultsProps {
  addressSearchResults: IAddressSearchResult[];
  csrfToken: string;
  addressCreateFromSearchResultPath: string;
  onManualAddressClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  onAddressResultChosen?: (json: ISuccessfulCreateFromSearchResultResponse) => void;
  onError?: (error: ReactElement) => void;
}

interface IAddressSearchResultsState {
  resultSelectionInProgress: boolean;
}

export class AddressSearchResults extends Component<IAddressSearchResultsProps, IAddressSearchResultsState> {
  constructor(props: IAddressSearchResultsProps) {
    super(props);
    this.state = { resultSelectionInProgress: false };

    this.handleAddressResultClick = this.handleAddressResultClick.bind(this);
  }

  public render(): ReactNode {
    const addressItems = this.props.addressSearchResults.map(r => {
      return (
        <li key={r.id}>
          <a href='#' onClick={this.handleAddressResultClick} key={r.id} id={`result-${r.id}`} data-id={r.id} className='new-link primary'>
            {r.addressString}
          </a>
        </li>
      );
    });
    return (
      <div>
        <div className='verification-address-search-results space-above-medium'>
          <p className='clarification'>Choose your address:</p>
          <ul className='selection-list space-above-small'>
            {addressItems}
          </ul>
        </div>
        <div className='element-group centred space-above-large'>
          <p>
            <a href='#' onClick={this.props.onManualAddressClick} className='new-link primary'>Enter address manually</a>
          </p>
        </div>
      </div>
    );
  }

  private handleAddressResultClick(event: MouseEvent<HTMLAnchorElement>): void {
    if (!this.state.resultSelectionInProgress) {
      this.setState({ resultSelectionInProgress: true });
      event.preventDefault();
      const data = { verification_address_verification_create_from_search_result_request: { search_result_id: event.currentTarget.dataset.id } };
      const requestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken },
        body: JSON.stringify(data)
      };
      fetch(this.props.addressCreateFromSearchResultPath, requestOptions).then(response => {
        return response.json();
      }).then((json: CreateFromSearchResultResponse) => {
        if (json.status === 'success') {
          if (this.props.onAddressResultChosen) {
            this.props.onAddressResultChosen(json);
          }
        } else {
          const errorMessage = json.error || 'Sorry, we were unable to use your chosen address. Please try again.';
          if (this.props.onError) {
            this.props.onError(<>{errorMessage}</>);
          }
        }
        this.setState({ resultSelectionInProgress: false });
      }).catch(error => {
        console.error(error);
        if (this.props.onError) {
          this.props.onError(<>Sorry, we were unable to use your chosen address. Please try again.</>);
        }
        this.setState({ resultSelectionInProgress: false });
      });
    }
  }
}
