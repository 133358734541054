import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { DogNamesField } from '@borrowmydoggy/profile-components';
import { OwnerProfile } from '@borrowmydoggy/core-models';
import { Form, FormSubmissionResult } from '@borrowmydoggy/form-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

const dogNamesSchema = z.object({ dogNames: OwnerProfile.dogNames });
type DogNamesSchema = z.infer<typeof dogNamesSchema>;

export const Owner17C: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  async function handleSubmit(data: DogNamesSchema): Promise<FormSubmissionResult<DogNamesSchema>> {
    const cleanedDogNames = data.dogNames.map(dn => dn.trim()).filter(dn => dn.length > 0);
    await props.record({ dogNames: cleanedDogNames, dogNamesSubmitted: true, ownerProfileCreationStatus: 'pending' });
    props.operations.createOwnerProfile();
    return { outcome: 'success', formStateAction: 'halt' };
  }

  const { multipleDogs } = parseDogNames(props.manuscript.dogNames || []);

  return (
    <StepContainer
      stepTitle='Some things to check'
      totalSteps={props.totalStepCount}
      currentStep={17}
      stepImageUrl={context.images['illustrations/img-dog-blanket-flat.svg']}
    >
      <StepHeading text={`Please check your ${multipleDogs ? 'dogs’ names' : 'dog’s name'}`} />
      <StandardStepDescription text='You have used a word that we don’t allow, please remove it to continue.' />
      <Form
        schema={dogNamesSchema}
        initialValues={{ dogNames: props.manuscript.dogNames }}
        initiallyExternallyInvalid={{ dogNames: 'Please remove the inappropriate word' }}
        mode='editing'
        onSubmit={handleSubmit}
      >
      {({ fields, handleChange, handleValidate }) => (
          <>
            <DogNamesField
              id='dogNames'
              value={fields.dogNames.value}
              validationState={fields.dogNames.validationState}
              onChange={handleChange}
              onValidate={handleValidate}
            />
            <StepNavigation
              nextButton={{
                text: 'Next',
                icon: faChevronRight,
                iconAlignment: 'right'
              }}
            />
          </>
      )}
      </Form>
    </StepContainer>
  );
};
