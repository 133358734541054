import React, { ChangeEvent, FunctionComponent } from 'react';
import styled from 'styled-components';

import { stringMatchesPattern } from '@borrowmydoggy/bmd-validators';

export interface ITelephoneNumberFieldProps {
	name?: string;
	id?: string;
	className?: string;
	value: string;
	placeholder?: string;
	onChange?: (value: string) => void;
	onBlur?: () => void;
}

const StyledTelephoneNumberField = styled.div`
	&>input[type=tel] {
		box-sizing: border-box;
		grid-area: input;
		border: 1px solid ${props => props.theme.neutral.xDark};
		border-radius: 5px;
		background-color: ${props => props.theme.neutral.xxLight};
		font-family: Lato, sans-serif;
		color: ${props => props.theme.secondary.mid};
		font-size: 16px;
		padding: 10px 5px;
		width: 100%;
		margin: 0;
		height: 42px;
	}
`;

const inputPattern = '^[0-9 \\-()[\\]+]*$';

export const TelephoneNumberField: FunctionComponent<ITelephoneNumberFieldProps> = (props: ITelephoneNumberFieldProps) => {
	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		const newValue = event.target.value;
		if (stringMatchesPattern(newValue, inputPattern) || newValue.length === 0) {
			if (props.onChange) {
				props.onChange(newValue);
			}
		}
	}

	function handleBlur(): void {
		if (props.onBlur) {
			props.onBlur();
		}
	}

	return (
		<StyledTelephoneNumberField className={props.className}>
			<input
				id={props.id}
				name={props.name}
				type='tel'
				value={props.value}
				autoComplete='tel'
				placeholder={props.placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		</StyledTelephoneNumberField>
	);
};
