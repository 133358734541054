import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import React, { ChangeEvent, FormEvent, FunctionComponent, ReactNode, useState } from 'react';

import styled from 'styled-components';

export interface ICardPaymentFormProps {
  stripeErrorMessage: string;
  title: string;
  titleClass?: string;
  buttonText: string;
  submissionInProgress: boolean;
  errorMessage?: string;
  disclaimerText?: string;
  onSubmissionStart?: () => void;
  onSubmissionSuccess?: (stripePaymentMethodId: string) => void;
  onSubmissionFailure?: () => void;
}

const Container = styled.div`
  width: 100%;
`;

export const CardPaymentForm: FunctionComponent<ICardPaymentFormProps> = (props: ICardPaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardName, setCardName] = useState('');
  const [cardNameInvalid, setCardNameInvalid] = useState(false);
  const [cardNameMessage, setCardNameMessage] = useState('');
  const [cardComplete, setCardComplete] = useState(false);
  const [cardInvalid, setCardInvalid] = useState(false);
  const [cardInitiallyFocused, setCardInitiallyFocused] = useState(false);
  const [cardMessage, setCardMessage] = useState('');
  const [stripeError, setStripeError] = useState('');

  function performCardNameValidation(): boolean {
    let fieldValid = true;
    if (cardName.length < 1) {
      fieldValid = false;
      setCardNameInvalid(true);
      setCardNameMessage('Please enter the name as it appears on your credit/debit card');
    } else {
      setCardNameInvalid(false);
      setCardNameMessage('');
    }
    return fieldValid;
  }

  function performValidation(): boolean {
    let formCurrentlyValid = true;

    formCurrentlyValid = performCardNameValidation() && cardComplete && formCurrentlyValid;

    return formCurrentlyValid;
  }

  function updateCardFieldState(): void {
    if (cardInvalid || !cardComplete) {
      setCardMessage('Please check your card details');
      // Stripe has a rather relaxed idea of 'invalid'.
      const cardElement = document.getElementById('card-element');
      if (cardElement) {
        cardElement.className += ' StripeElement--invalid';
      }
    } else {
      setCardMessage('');
    }
  }

  function handleCardNameChange(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value.match(/^[^0-9]*$/)) {
      setCardName(event.target.value);
    }
  }

  function handleCardNameBlur(): void {
    performCardNameValidation();
  }

  function handleCardChange(event: StripeCardElementChangeEvent): void {
    setCardComplete(event.complete);
    if (event.error) {
      setCardInvalid(true);
    } else {
      setCardInvalid(false);
      setCardMessage('');
    }
  }

  function handleCardBlur(): void {
    updateCardFieldState();
  }

  function handleCardFocus(): void {
    if (cardInitiallyFocused) {
      updateCardFieldState();
    } else {
      setCardInitiallyFocused(true);
    }
  }

  function handleFormSubmission(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setStripeError('');
    if (props.onSubmissionStart) {
      props.onSubmissionStart();
    }

    const formCurrentlyValid = performValidation();

    if (formCurrentlyValid && elements && stripe) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        stripe.createPaymentMethod({ type: 'card', card: cardElement, billing_details: { name: cardName } }).then(result => {
          if (result.error) {
            console.error(result.error);
            setStripeError(props.stripeErrorMessage);
            if (props.onSubmissionFailure) {
              props.onSubmissionFailure();
            }
          } else {
            const paymentMethod = result.paymentMethod;
            if (props.onSubmissionSuccess) {
              props.onSubmissionSuccess(paymentMethod.id);
            }
          }
        });
      }
    } else if (props.onSubmissionFailure) {
      props.onSubmissionFailure();
    }
  }

  function renderErrorMessage(errorMessage?: string): ReactNode {
    if (stripeError.length > 0 || (errorMessage && errorMessage.length > 0)) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{stripeError.length > 0 ? stripeError : errorMessage}</p>
          </article>
        </div>
      );
    }
  }

  function renderDisclaimer(disclaimerText: string): ReactNode {
    return <p>{disclaimerText}</p>;
  }

  function submissionDisabled(submissionInProgress: boolean): boolean {
    return cardNameInvalid || !cardComplete || cardInvalid || submissionInProgress;
  }

  return (
    <Container>
      <h1 className={`page-title ${props.titleClass ? props.titleClass : 'premium'}`}>{props.title}</h1>
      <form method='post' id='stripe-card-form' className='new-form space-above-medium' onSubmit={handleFormSubmission}>
        <fieldset>
          <legend>Card Details</legend>
          <div className='field'>
            <label htmlFor='name'>Name on card</label>
            <input
              type='text'
              name='card-name'
              id='name'
              value={cardName}
              aria-invalid={cardNameInvalid}
              onChange={handleCardNameChange}
              onBlur={handleCardNameBlur}
            />
            <span className='feedback'>{cardNameMessage}</span>
          </div>
          <div className='field'>
            <label htmlFor='card-element'>Card details</label>
            <CardElement id='card-element' options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#7a8595',
                  fontFamily: 'Lato'
                }
              }
            }} onChange={handleCardChange} onBlur={handleCardBlur} onFocus={handleCardFocus} />
            <span id='card-errors' role='alert' className='feedback'>{cardMessage}</span>
          </div>
          {props.disclaimerText && renderDisclaimer(props.disclaimerText)}
          {renderErrorMessage(props.errorMessage)}
          <div className='element-group space-above-medium'>
            <button
              type='submit'
              className={`new-button icon primary wide ${props.submissionInProgress ? 'activity' : ''}`}
              disabled={submissionDisabled(props.submissionInProgress)}
            >
              <i className='fas fa-lock'></i> {props.buttonText}
            </button>
          </div>
        </fieldset>
      </form>
    </Container>
  );
};
