import React, { Component, MouseEvent, ReactNode } from 'react';

import { IPureDate } from '@borrowmydoggy/bmd-validators';
import { IAddress } from './index';

export interface IVerifyAddressProps {
  forename: string;
  surname: string;
  dateOfBirth?: IPureDate;
  address: IAddress;
  csrfToken: string;
  addressVerificationPath: string;
  onAddressVerified?: (result: boolean) => void;
  onNameEditRequest?: () => void;
  onDobEditRequest?: () => void;
  onAddressEditRequest?: () => void;
}

interface IVerifyAddressState {
  errorMessage: string;
  submissionInProgress: boolean;
}

export class VerifyAddress extends Component<IVerifyAddressProps, IVerifyAddressState> {
  constructor(props: IVerifyAddressProps) {
    super(props);

    this.state = { errorMessage: '', submissionInProgress: false  };

    this.handleConfirmationClick = this.handleConfirmationClick.bind(this);

    this.handleNameEditClick = this.handleNameEditClick.bind(this);
    this.handleDobEditClick = this.handleDobEditClick.bind(this);
    this.handleAddressEditClick = this.handleAddressEditClick.bind(this);
  }

  public render(): ReactNode {
    let dateOfBirth = new Date();
    if (this.props.dateOfBirth && this.props.dateOfBirth.year && this.props.dateOfBirth.month && this.props.dateOfBirth.day) {
      dateOfBirth = new Date(this.props.dateOfBirth.year, this.props.dateOfBirth.month - 1, this.props.dateOfBirth.day);
    }

    return (
      <>
        <h2 className='section-title space-above-medium'>Review your details</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>Will we find these details on databases such as the electoral roll?</p>
        </div>
        <div className='summary-table space-above-medium'>
          <div className='element'>
            <div className='content'>
              <p>{this.props.forename} {this.props.surname}</p>
            </div>
            <div className='action'>
              <a href='#' className='new-link primary' onClick={this.handleNameEditClick}>edit <i className='fas fa-pencil'></i></a>
            </div>
          </div>
          <div className='element'>
            <div className='content'>
              <p>Date of birth:
                {dateOfBirth.toLocaleString('en-gb', { day: 'numeric', month: 'short', year: 'numeric' })}
              </p>
            </div>
            <div className='action'>
              <a href='#' className='new-link primary' onClick={this.handleDobEditClick}>edit <i className='fas fa-pencil'></i></a>
            </div>
          </div>
          <div className='element'>
            <div className='content'>
              {this.renderAddress()}
            </div>
            <div className='action'>
              <a href='#' className='new-link primary' onClick={this.handleAddressEditClick}>edit <i className='fas fa-pencil'></i></a>
            </div>
          </div>
          {this.renderErrorMessage()}
          <div className='action space-above-medium'>
            <button
              type='submit'
              className={`new-button primary wide icon ${this.state.submissionInProgress ? 'activity' : ''}`}
              disabled={this.state.submissionInProgress}
              onClick={this.handleConfirmationClick}
            >
              <i className='fas fa-check'></i> Yes, these details are correct
            </button>
          </div>
        </div>
      </>
    );
  }

  private handleConfirmationClick(event: MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    this.setState({ submissionInProgress: true });

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken }
    };
    fetch(this.props.addressVerificationPath, requestOptions).then(response => {
      return response.json();
    }).then(json => {
      if (json.status && json.status === 'success') {
        if (this.props.onAddressVerified) {
          this.props.onAddressVerified(json.verification_result || false);
        }
      } else {
        const errorMessage = json.errors || 'Sorry, we were unable to confirm the details you provided. Please try again.';
        this.setState({ errorMessage, submissionInProgress: false });
      }
    }).catch(error => {
      console.error(error);
      this.setState({ errorMessage: 'Sorry, we were unable to confirm the details you provided. Please try again.', submissionInProgress: false });
    });
  }

  private handleNameEditClick(): void {
    if (this.props.onNameEditRequest) {
      this.props.onNameEditRequest();
    }
  }

  private handleDobEditClick(): void {
    if (this.props.onDobEditRequest) {
      this.props.onDobEditRequest();
    }
  }

  private handleAddressEditClick(): void {
    if (this.props.onAddressEditRequest) {
      this.props.onAddressEditRequest();
    }
  }

  private renderAddress(): ReactNode {
    return (
      <p>
        {this.props.address.flat}{this.props.address.flat ? <br /> : ''}
        {this.props.address.houseNumber}{this.props.address.houseNumber ? ' ' : ''}
        {this.props.address.lineOne}<br />
        {this.props.address.lineTwo}{this.props.address.lineTwo ? <br /> : ''}
        {this.props.address.lineThree}{this.props.address.lineThree ? <br /> : ''}
        {this.props.address.town}<br />
        {this.props.address.postcode}
      </p>
    );
  }

  private renderErrorMessage(): ReactNode | undefined {
    if (this.state.errorMessage.length > 0) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{this.state.errorMessage}</p>
          </article>
        </div>
      );
    }
  }
}
