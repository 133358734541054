import { IStepProcessOperationOptions } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from '../../userRegistration';
import { RegistrationAPI } from '../../api';

export async function checkEmailAddressAcceptability(
  _options: IStepProcessOperationOptions<IUserAccountRegistrationManuscript>,
  emailAddress: string
): Promise<Partial<IUserAccountRegistrationManuscript>> {
  try {
    const response = await RegistrationAPI.emailAddressAcceptability(emailAddress);
    if (response.error === undefined && response.data && response.data.emailAddressAcceptability) {
      switch (response.data.emailAddressAcceptability.acceptability) {
        case 'acceptable': {
          return { emailAddress, emailAddressCheckStatus: 'acceptable' };
        }
        case 'unacceptableDuplicate': {
          return { emailAddressCheckStatus: 'unacceptableDuplicate' };
        }
        case 'unacceptableInvalid': {
          return {
            suggestedEmailAddress: response.data.emailAddressAcceptability.suggestedEmailAddress || undefined,
            emailAddressCheckStatus: 'unacceptableInvalid'
          };
        }
        case 'error':
        default:
          return { emailAddressCheckStatus: 'error' };
      }
    } else {
      console.warn(response);
      return { emailAddressCheckStatus: 'error' };
    }
  } catch (error) {
    console.warn(error);
    return { emailAddressCheckStatus: 'error' };
  }
}
