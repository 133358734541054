import copy from 'copy-to-clipboard';
import React, { ErrorInfo, FunctionComponent } from 'react';
import styled from 'styled-components';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle, faRedo } from '@fortawesome/pro-solid-svg-icons';

import { Icon, IconButton } from '@borrowmydoggy/core-components';

export interface IMinorErrorFallbackProps {
  error: Error;
  info: ErrorInfo;
}

const StyledError = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  align-items: center;
  font-size: 1.4rem;
  border: 1px dotted ${props => props.theme.neutral.xDark};
  padding: 5px;

  &>.icon {
  }
  &>span>a {
    font-size: 1.4rem;
    color: ${props => props.theme.primary.mid};
  }
  &>.copyButton {
    font-size: 1.4rem;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.negative.mid};
`;

export const MinorErrorFallback: FunctionComponent<IMinorErrorFallbackProps> = (props: IMinorErrorFallbackProps) => {
  function handleCopyClick(): void {
    const formattedErrorText = `${props.error.message}\n\n${props.error.stack}`;
    copy(formattedErrorText);
  }

  function handleReloadClick(): void {
    window.location.reload();
  }

  return (
    <StyledError>
      <StyledIcon definition={faExclamationTriangle} />
      <span>
        This bit didn&rsquo;t work properly.{' '}
        <a href='#' onClick={handleReloadClick}><Icon definition={faRedo} /> Reload</a>
      </span>
      <IconButton
        altText='Copy error info'
        type='soft'
        icon={faClipboard}
        onClick={handleCopyClick}
        className='copyButton'
      />
    </StyledError>
  );
};
