import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const Borrower3: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  function handleButtonClick(polarity: boolean): void {
    props.record({ childrenAtHome: polarity, childrenAtHomeConfirmed: true });
  }

  function handleBackClick(): void {
    props.record({ experienceWithDogsConfirmed: false });
  }

  return (
    <BinaryStep
      stepTitle='It&rsquo;s all about you'
      stepHeading='Have you got children at home?'
      totalSteps={props.totalStepCount}
      currentStep={3}
      stepImageUrl={context.images['illustrations/img-child-with-dog-in-heart.svg']}
      backButton={{ text: 'Back' }}
      onBinaryButtonClick={handleButtonClick}
      onBackClick={handleBackClick}
    >
      <StandardStepDescription text='Some dogs love children, while others aren’t too sure, so it’s helpful for owners to know.' />
    </BinaryStep>
  );
};
