import React, { Component, MouseEvent, ReactElement, ReactNode } from 'react';

import styled from 'styled-components';

import { Form, TextField } from '../forms';
import { IValidationState, PresenceValidator } from '@borrowmydoggy/bmd-validators';

import { IAddress } from './IAddress';

export interface IManualAddressEntryProps {
  address?: IAddress;
  csrfToken: string;
  manualAddressCreationPath: string;
  onAddressChange?: (address: IAddress) => void;
  onAddressSearchClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  onManualAddressSubmitted?: () => void;
  onError?: (error: ReactElement) => void;
}

interface IManualAddressEntryState {
  lineOneValid: boolean;
  lineOneMessage: string;
  townValid: boolean;
  townMessage: string;
  postcodeValid: boolean;
  postcodeMessage: string;
}

const StyledTextFieldWithAssistance = styled(TextField)`
  grid-area: input;
`;

const StyledTextField = styled(TextField)`
  &.invalid>input {
    border-color: var(--toy-red);
    background-color: var(--toy-red-light);
  }
`;

const emptyAddress: IAddress = {
  lineOne: '',
  town: '',
  postcode: ''
};

export class ManualAddressEntry extends Component<IManualAddressEntryProps, IManualAddressEntryState> {
  constructor(props: IManualAddressEntryProps) {
    super(props);

    this.state = {
      lineOneValid: true,
      lineOneMessage: '',
      townValid: true,
      townMessage: '',
      postcodeValid: true,
      postcodeMessage: ''
    };

    this.handleFlatChange = this.handleFlatChange.bind(this);
    this.handleHouseNumberChange = this.handleHouseNumberChange.bind(this);
    this.handleLineOneChange = this.handleLineOneChange.bind(this);
    this.handleLineTwoChange = this.handleLineTwoChange.bind(this);
    this.handleLineThreeChange = this.handleLineThreeChange.bind(this);
    this.handleTownChange = this.handleTownChange.bind(this);
    this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleLineOneBlur = this.handleLineOneBlur.bind(this);
    this.handleTownBlur = this.handleTownBlur.bind(this);
    this.handlePostcodeBlur = this.handlePostcodeBlur.bind(this);
  }

  public render(): ReactNode {
    const lineTwoInput = (
      <div className='field'>
        <TextField
          value={this.props.address?.lineTwo || ''}
          id='lineTwo'
          name='lineTwo'
          maxLength={50}
          autoComplete='address-line2'
          onChange={this.handleLineTwoChange}
        />
      </div>
    );
    const lineThreeInput = (
      <div className='field'>
        <TextField
          value={this.props.address?.lineThree || ''}
          id='lineThree'
          name='lineThree'
          maxLength={50}
          onChange={this.handleLineThreeChange}
        />
      </div>
    );

    return (
      <Form className='new-form' onSubmit={this.handleFormSubmission}>
        <fieldset>
          <legend>Your Full Address</legend>
          <p><a href='#' className='new-link primary' onClick={this.props.onAddressSearchClick}>Search by postcode</a></p>
          <div className='field with-assistance space-above-medium'>
            <label htmlFor='flat'>Flat</label>
            <span className='assistance'>Optional</span>
            <StyledTextFieldWithAssistance
              value={this.props.address?.flat || ''}
              id='flat'
              name='flat'
              maxLength={50}
              onChange={this.handleFlatChange}
            />
          </div>
          <div className='field with-assistance'>
            <label htmlFor='houseNumber'>House number / building name</label>
            <span className='assistance'>Optional</span>
            <StyledTextFieldWithAssistance
              value={this.props.address?.houseNumber || ''}
              id='houseNumber'
              name='houseNumber'
              maxLength={50}
              onChange={this.handleHouseNumberChange}
            />
          </div>
          <div className='field'>
            <label htmlFor='lineOne'>{this.props.address && ((this.props.address.lineTwo && this.props.address.lineTwo.length > 0) || (this.props.address.lineThree && this.props.address.lineThree.length > 0)) ? 'Address' : 'Street'}</label>
            <StyledTextField
              value={this.props.address?.lineOne || ''}
              id='lineOne'
              name='lineOne'
              maxLength={50}
              autoComplete='address-line1'
              blurValidators={[
                new PresenceValidator('Please tell us the first line of your address')
              ]}
              className={this.state.lineOneValid ? '' : 'invalid'}
              onChange={this.handleLineOneChange}
              onBlur={this.handleLineOneBlur}
            />
            <span className='feedback'>{this.state.lineOneMessage}</span>
          </div>
          {this.props.address && this.props.address.lineTwo && this.props.address.lineTwo.length > 0 ? lineTwoInput : ''}
          {this.props.address && this.props.address.lineThree && this.props.address.lineThree.length > 0 ? lineThreeInput : ''}
          <div className='field half-width'>
            <label htmlFor='town'>City / town</label>
            <StyledTextField
              value={this.props.address?.town || ''}
              id='town'
              name='town'
              maxLength={50}
              autoComplete='locality'
              blurValidators={[
                new PresenceValidator('Please tell us your postal town')
              ]}
              className={this.state.townValid ? '' : 'invalid'}
              onChange={this.handleTownChange}
              onBlur={this.handleTownBlur}
            />
            <span className='feedback'>{this.state.townMessage}</span>
          </div>
          <div className='field third-width'>
            <label htmlFor='postcode'>Postcode</label>
            <StyledTextField
              value={this.props.address?.postcode || ''}
              id='postcode'
              name='postcode'
              maxLength={10}
              autoComplete='postal-code'
              inputPattern='[0-9a-zA-Z ]'
              blurValidators={[
                new PresenceValidator('Please tell us your postcode')
              ]}
              className={this.state.postcodeValid ? '' : 'invalid'}
              onChange={this.handlePostcodeChange}
              onBlur={this.handlePostcodeBlur}
            />
            <span className='feedback'>{this.state.postcodeMessage}</span>
          </div>
          <div className='action'>
            <button type='submit' className='new-button primary wide'>Next</button>
          </div>
        </fieldset>
      </Form>
    );
  }

  private handleFlatChange(value: string): void {
    if (this.props.onAddressChange) {
      const updatedAddress = { ...emptyAddress, ...this.props.address, flat: value };
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handleHouseNumberChange(value: string): void {
    if (this.props.onAddressChange) {
      const updatedAddress = { ...emptyAddress, ...this.props.address, houseNumber: value };
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handleLineOneChange(value: string): void {
    if (this.props.onAddressChange) {
      const updatedAddress = { ...emptyAddress, ...this.props.address, lineOne: value };
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handleLineTwoChange(value: string): void {
    if (this.props.onAddressChange) {
      const updatedAddress = { ...emptyAddress, ...this.props.address, lineTwo: value };
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handleLineThreeChange(value: string): void {
    if (this.props.onAddressChange) {
      const updatedAddress = { ...emptyAddress, ...this.props.address, lineThree: value };
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handleTownChange(value: string): void {
    if (this.props.onAddressChange) {
      const updatedAddress = { ...emptyAddress, ...this.props.address, town: value };
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handlePostcodeChange(value: string): void {
    if (this.props.onAddressChange) {
      const updatedAddress = { ...emptyAddress, ...this.props.address, postcode: value };
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handleLineOneBlur(validationState: IValidationState): void {
    this.setState({ lineOneValid: validationState.status === 'valid', lineOneMessage: validationState.message || '' });
  }

  private handleTownBlur(validationState: IValidationState): void {
    this.setState({ townValid: validationState.status === 'valid', townMessage: validationState.message || '' });
  }

  private handlePostcodeBlur(validationState: IValidationState): void {
    this.setState({ postcodeValid: validationState.status === 'valid', postcodeMessage: validationState.message || '' });
  }

  private handleFormSubmission(): void {
    const lineOneAcceptable = this.props.address && this.props.address.lineOne.length > 0;
    const townAcceptable = this.props.address && this.props.address.town.length > 0;
    const postcodeAcceptable = this.props.address && this.props.address.postcode.length > 0;

    if (lineOneAcceptable && townAcceptable && postcodeAcceptable && this.props.address) {
      const data = {
        verification_address_verification_manual_address_request: {
          flat: this.props.address.flat,
          house_number: this.props.address.houseNumber,
          address1: this.props.address.lineOne,
          address2: this.props.address.lineTwo,
          address3: this.props.address.lineThree,
          town: this.props.address.town,
          postcode: this.props.address.postcode
        }
      };
      const requestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken },
        body: JSON.stringify(data)
      };
      fetch(this.props.manualAddressCreationPath, requestOptions).then(response => {
        return response.json();
      }).then(json => {
        if (json.status && json.status === 'success') {
          if (this.props.onManualAddressSubmitted) {
            this.props.onManualAddressSubmitted();
          }
        } else {
          const errorMessage = json.error || 'Sorry, we were unable to store your address. Please try again.';
          if (this.props.onError) {
            this.props.onError(<>{errorMessage}</>);
          }
        }
      }).catch(error => {
        console.error(error);
        if (this.props.onError) {
          this.props.onError(<>Sorry, we were unable to store your address. Please try again.</>);
        }
      });
    } else {
      if (!lineOneAcceptable) {
        this.setState({ lineOneValid: false, lineOneMessage: 'Please tell us the first line of your address' });
      }
      if (!townAcceptable) {
        this.setState({ townValid: false, townMessage: 'Please tell us your postal town' });
      }
      if (!postcodeAcceptable) {
        this.setState({ postcodeValid: false, postcodeMessage: 'Please tell us your postcode' });
      }
    }
  }
}
