import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { faArrowsRotate, faSquareUser, faTrashCan, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

import { Icon, IconTextButton, InlineTextButton, LoadingIndicator, SmallText, SmallTextLink } from '@borrowmydoggy/core-components';

export interface IPhotoToolbarProps {
  status: 'noImages' | 'mainImage' | 'secondaryImage' | 'inProgress' | 'processing' | 'error';
  className?: string;
  onDeleteClick?: () => void;
  onMakeMainClick?: () => void;
  onReloadClick?: () => void;
  onRemoveClick?: () => void;
}

interface IContainerProps {
  centre?: boolean;
}

const Container = styled.div<IContainerProps>`
  background-color: ${props => props.theme.neutral.xLight};
  min-height: 43px;
  box-sizing: border-box;
  display: grid;
  grid-auto-flow: column;
  justify-content: ${props => props.centre ? 'center' : 'space-between'};
  align-items: center;
  padding: 5px;
`;

const MainImageLabel = styled(SmallText)`
  text-align: right;
  font-size: 14px;
`;

const ImageIcon = styled(Icon)`
  padding-right: 5px;
`;

const WarningIcon = styled(ImageIcon)`
  font-color: ${props => props.theme.negative.mid};
`;

const Label = styled(SmallText)`
  font-size: 14px;
  padding-left: 10px;
  line-height: 1;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  justify-self: center;
  width: 80px;
`;

export const PhotoToolbar: FunctionComponent<IPhotoToolbarProps> = (props: IPhotoToolbarProps) => {
  function renderMainImageSection(): ReactNode {
    if (props.status === 'mainImage') {
      return <MainImageLabel><ImageIcon definition={faSquareUser} />Main image</MainImageLabel>;
    }
    return <IconTextButton type='primary' icon={faSquareUser} text='Make main image' size='small' onClick={props.onMakeMainClick} />;
  }

  function renderContent(): ReactNode {
    switch (props.status) {
      case 'noImages':
        return <Label><ImageIcon definition={faSquareUser} />Add photos using the <strong>+</strong> button</Label>;
      case 'processing':
        return (
          <Label>
            <ImageIcon definition={faArrowsRotate} />
            We&rsquo;re still processing this image.{' '}
            <InlineTextButton size='xSmall' onClick={props.onReloadClick}>Is it ready?</InlineTextButton>
          </Label>
        );
      case 'inProgress':
        return <StyledLoadingIndicator />;
      case 'error':
        return (
          <Label>
            <WarningIcon definition={faTriangleExclamation} />
            Something went wrong with this image.{' '}
            <InlineTextButton size='xSmall' onClick={props.onRemoveClick}>Remove</InlineTextButton>
          </Label>
        );

      case 'mainImage':
      case 'secondaryImage':
      default:
        return (
          <>
            <IconTextButton type='negative' icon={faTrashCan} text='Delete image' size='small' onClick={props.onDeleteClick} />
            {renderMainImageSection()}
          </>
        );
    }
  }

  return (
    <Container className={props.className} centre={props.status === 'inProgress'}>
      {renderContent()}
    </Container>
  );
};
