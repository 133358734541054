import React, { FunctionComponent, MouseEvent, ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  BorrowerResultsGrid,
  BorrowerResultsList,
  BorrowerSearchPreferences,
  BorrowerSearchSummaryText,
  IBorrowerSearchFilters,
  IBorrowerSearchResult,
  ISearchCriteria,
  SearchCriteriaPanel,
  SearchLocationType,
  SearchViewToggle,
  SearchViewType
} from '@borrowmydoggy/search-components';
import { CompassBearing, GoPremiumOwnerMessageBanner, SearchDistance } from '@borrowmydoggy/product-components';
import { Desktop, inCategory, LoadingIndicator, ResponsiveBlock, responsiveQuery, slideDown } from '@borrowmydoggy/core-components';

import { WebContext } from '../utils/AppContext';

interface IBorrowerSearchProps {
  searchViewType: SearchViewType;
  searchId: string;
  results: IBorrowerSearchResult[];
  offset: number;
  total: number;
  criteria: ISearchCriteria;
  filters: IBorrowerSearchFilters;
  currentUserPremiumOwner: boolean;
  currentUserPremiumBorrower: boolean;
  ownerDogName: string;
  ownerMultipleDogs: boolean;
  permanentSearchLocationText: string;
  allResultsLoading: boolean;
  furtherResultsLoading: boolean;
  selectedProfileId?: string;
  sharePath: string;
  premiumUrl: string;
  onProfileSelected?: (profileId: string) => void;
  onSearchPreferencesChange?: (criteria: ISearchCriteria, filters: IBorrowerSearchFilters) => void;
  onMoreResultsRequired?: () => void;
  onSearchViewTypeToggle?: (searchViewType: SearchViewType) => void;
  onProfileLiked?: (profileId: string, searchResultIndex: number) => void;
  onProfileUnliked?: (profileId: string, searchResultIndex: number) => void;
  onLocationChosen?: (
    searchText: string,
    latitude: number,
    longitude: number,
    searchDistance: SearchDistance,
    searchDirections: CompassBearing[],
    locationType: SearchLocationType,
    postcode?: string
  ) => Promise<boolean>;
  onRevertSearchLocation?: () => Promise<boolean>;
  onUpdateUserAccountLocation?: () => Promise<boolean>;
}

interface ISearchViewTypeProps {
  searchViewType: SearchViewType;
}

const StyledBorrowerSearch = styled.div<ISearchViewTypeProps>`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  margin: 20px auto;

  &>.searchPreferences {
    grid-area: searchPreferences;
  }
  &>.summary {
    grid-area: summary;
    box-sizing: border-box;
  }
  &>.bar {
    grid-area: bar;
    &>.barContent {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 200px;
      align-items: center;
      box-sizing: border-box;

      &>.loadingIndicator {
        width: 80px;
      }
    }
  }

  &>.results {
    grid-area: results;
    align-self: start;
    &>.additionalLoadingIndicator {
      margin-top: 20px;
      width: 100%;
      display: grid;
      justify-items: center;
    }
  }

  ${responsiveQuery('desktop')} {
    max-width: 1110px;
    grid-template-areas:  'searchPreferences bar'
                          'searchPreferences results';
    grid-template-columns: 165px 1fr;
    grid-template-rows: 30px 1fr;
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape', 'tabletPortrait', 'tabletLandscape')} {
    grid-template-areas:  'summary'
                          'bar'
                          'results';
    &>.summary {
      justify-self: center;
      cursor: pointer;
      &>.title {
        display: grid;
        grid-auto-flow: column;
        &>p { font-size: 1.4rem; }
        &>a {
          font-size: 1.4rem;
          text-align: right;
          color: ${props => props.theme.primary.mid};
        }
      }
      &>p { font-size: 1.4rem; }
    }
    &>.bar {
      background-color: ${props => props.theme.neutral.xLight};
      &>.barContent {
        margin: auto;
        &>p { font-size: 1.4rem; }
      }
    }
    &>.results {
      justify-self: center;
    }
    &>.searchPreferences {
      grid-column: 1 / -1;
      grid-row: 1;
      display: grid;
      justify-content: center;
      align-content: start;
      background-color: white;
      animation: 0.5s ${slideDown};
    }
  }

  ${responsiveQuery('tabletLandscape')} {
    &>.summary, &>.bar>.barContent {
      ${props => props.searchViewType === 'grid' ? 'width: 920px;' : 'width: 100%; padding: 0 20px;'}
    }
    &>.results {
      padding 0 20px;
    }
  }

  ${responsiveQuery('tabletPortrait')} {
    &>.summary, &>.bar>.barContent {
      ${props => props.searchViewType === 'grid' ? 'width: 615px;' : 'width: 100%; padding: 0 20px;'}
    }
    &>.results {
      padding 0 20px;
    }
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape')} {
    &>.summary, &>.bar>.barContent {
      width: 300px;
    }
    &>.bar {
      &>.barContent {
        display: block;
        &>.searchViewToggle {
          display: none;
        }
      }
    }
    &>.results {
      padding 0 20px;
    }
  }

  ${responsiveQuery('mobilePortrait')} {
    &>.searchPreferences {
      display: block;
      padding: 10px;
    }
    &>.results {
      padding: 0 10px 0 5px;
    }
  }
`;

const StyledBorrowerResultsGrid = styled(BorrowerResultsGrid)`
  ${responsiveQuery('tabletPortrait', 'tabletLandscape')} {
    justify-content: center;
  }

  ${responsiveQuery('tabletLandscape')} {
    max-width: 925px;
  }

  ${responsiveQuery('tabletPortrait')} {
    max-width: 615px;
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape')} {
    max-width: 320px;
  }
`;

const StyledGoPremiumOwnerMessageBanner = styled(GoPremiumOwnerMessageBanner)`
  max-width: var(--site-width);

  ${responsiveQuery('tabletPortrait', 'tabletLandscape')} {
    width: unset;
    margin: 20px;
  }

  ${responsiveQuery('mobilePortrait', 'mobileLandscape')} {
    width: unset;
    margin: 10px;
  }
`;

export const BorrowerSearch: FunctionComponent<IBorrowerSearchProps> = (props: IBorrowerSearchProps) => {
  const context = useContext(WebContext);

  const [searchPreferencesShown, setSearchPreferencesShown] = useState(inCategory('desktop'));
  const [searchCriteriaPanelShown, setSearchCriteriaPanelShown] = useState(false);

  useEffect(() => {
    if (props.selectedProfileId) {
      const selectedProfileElement = document.querySelector(`[data-profileid='${props.selectedProfileId}']`);
      if (selectedProfileElement) {
        selectedProfileElement.scrollIntoView(true);
      }
    }
  }, []);

  function renderPageContent(): ReactNode {
    if (searchCriteriaPanelShown) {
      return (
        <SearchCriteriaPanel
          initialSearchDirections={props.criteria.searchDirections}
          initialSearchDistance={props.criteria.searchDistance}
          latitude={props.criteria.latitude}
          longitude={props.criteria.longitude}
          searchLocation={props.criteria.searchLocation}
          searchSpecificLocation={props.criteria.searchLocationType === 'justLooking'}
          userAccountLocation={props.permanentSearchLocationText}
          profileType='owner'
          onCancel={handleSearchCriteriaPanelCancel}
          onUpdate={handleSearchCriteriaPanelUpdate}
          onRevertSearchLocation={props.onRevertSearchLocation ? props.onRevertSearchLocation : undefined}
          onUpdateUserAccountLocation={props.onUpdateUserAccountLocation ? props.onUpdateUserAccountLocation : undefined}
          onLocationChosen={props.onLocationChosen}
          onLocationChangeComplete={handleLocationChangeComplete}
          mapkitJsToken={context.mapkitJsToken}
        />
      );
    } else {
      return (
        <StyledBorrowerSearch searchViewType={props.searchViewType}>
          <Desktop onChange={handleSearchPreferencesDesktopMediaQueryChange}>{null}</Desktop>
          {renderSearchPreferences()}
          {renderSummary()}
          <div className='bar'>
            {renderBar()}
          </div>
          <div className='results'>
            {renderResults()}
            {renderAdditionalLoadingIndicator()}
          </div>
        </StyledBorrowerSearch>
      );
    }
  }

  function renderGoPremiumBanner(): ReactNode {
    if (!props.currentUserPremiumOwner) {
      return (
        <StyledGoPremiumOwnerMessageBanner
          subscribeUrl={props.premiumUrl}
          dogName={props.ownerDogName}
          multipleDogs={props.ownerMultipleDogs}
          subscriptionUpgrade={props.currentUserPremiumBorrower}
        />
      );
    }
  }

  function renderSearchPreferences(): ReactNode {
    if (searchPreferencesShown) {
      return (
        <div className='searchPreferences'>
          <BorrowerSearchPreferences
            criteria={props.criteria}
            filters={props.filters}
            selfPremium={props.currentUserPremiumOwner}
            premiumUrl={props.premiumUrl}
            onChange={handleSearchPreferencesChange}
            onLocationPanelClick={handleLocationPanelClick}
          />
        </div>
      );
    }
  }

  function renderSummary(): ReactNode {
    if (!searchPreferencesShown) {
      return (
        <ResponsiveBlock categories={['mobilePortrait', 'mobileLandscape', 'tabletPortrait', 'tabletLandscape']}>
          <div className='summary' onClick={handleSearchPreferencesClick}>
            <div className='title'>
              <p>Searching for:</p>
              <a href='#'>Change search</a>
            </div>
            <BorrowerSearchSummaryText borrowerSearchFilters={props.filters} searchCriteria={props.criteria} />
          </div>
        </ResponsiveBlock>
      );
    }
  }

  function renderBar(): ReactNode {
    let bar = (
      <div className='barContent'>
        <p>{props.total} borrower{props.total === 1 ? '' : 's'} found</p>
        <SearchViewToggle searchViewType={props.searchViewType} onToggle={handleSearchViewTypeToggle} className='searchViewToggle' />
      </div>
    );

    if (props.allResultsLoading) {
      bar = <div className='barContent'><LoadingIndicator className='loadingIndicator' /></div>;
    }

    if (searchPreferencesShown) {
      return <Desktop>{bar}</Desktop>;
    } else {
      return bar;
    }
  }

  function renderResults(): ReactNode {
    const desktopResults = <Desktop>{props.searchViewType === 'list' ? renderResultsList() : renderResultsGrid()}</Desktop>;
    if (searchPreferencesShown) {
      return desktopResults;
    } else {
      return (
        <>
          {desktopResults}
          <ResponsiveBlock categories={['tabletPortrait', 'tabletLandscape']}>
            {props.searchViewType === 'list' ? renderResultsList() : renderResultsGrid()}
          </ResponsiveBlock>
          <ResponsiveBlock categories={['mobilePortrait', 'mobileLandscape']}>
            {renderResultsGrid()}
          </ResponsiveBlock>
        </>
      );
    }
  }

  function renderResultsList(): ReactNode {
    return (
      <BorrowerResultsList
        results={props.results}
        searchId={props.searchId}
        showBanners={!props.currentUserPremiumOwner}
        noMoreResults={!props.allResultsLoading && props.results.length >= props.total}
        shareUrl={props.sharePath}
        subscribeUrl={props.premiumUrl}
        subscriptionUpgrade={props.currentUserPremiumBorrower}
        onLike={handleLike}
        onUnlike={handleUnlike}
        onLastResultViewed={handleLastResultViewed}
        onClick={handleProfileClick}
      />
    );
  }

  function renderResultsGrid(): ReactNode {
    return (
      <StyledBorrowerResultsGrid
        results={props.results}
        searchId={props.searchId}
        showBanners={!props.currentUserPremiumOwner}
        noMoreResults={!props.allResultsLoading && props.results.length >= props.total}
        shareUrl={props.sharePath}
        subscribeUrl={props.premiumUrl}
        subscriptionUpgrade={props.currentUserPremiumBorrower}
        onLike={handleLike}
        onUnlike={handleUnlike}
        onLastResultViewed={handleLastResultViewed}
        onClick={handleProfileClick}
      />
    );
  }

  function renderAdditionalLoadingIndicator(): ReactNode {
    if (props.furtherResultsLoading) {
      return (
        <div className='additionalLoadingIndicator'>
          <LoadingIndicator />
        </div>
      );
    }
  }

  function handleSearchPreferencesDesktopMediaQueryChange(matches: boolean): void {
    setSearchPreferencesShown(matches);
  }

  function handleLastResultViewed(): void {
    if (props.onMoreResultsRequired) {
      props.onMoreResultsRequired();
    }
  }

  function handleSearchViewTypeToggle(searchViewType: SearchViewType): void {
    if (props.onSearchViewTypeToggle) {
      props.onSearchViewTypeToggle(searchViewType);
    }
  }

  function handleSearchPreferencesClick(event: MouseEvent<HTMLDivElement>): void {
    event.preventDefault();
    setSearchPreferencesShown(true);
  }

  function handleLike(profileId: string, searchResultIndex: number): void {
    if (props.onProfileLiked) {
      props.onProfileLiked(profileId, searchResultIndex);
    }
  }

  function handleUnlike(profileId: string, searchResultIndex: number): void {
    if (props.onProfileUnliked) {
      props.onProfileUnliked(profileId, searchResultIndex);
    }
  }

  function handleProfileClick(profileId: string): void {
    if (props.onProfileSelected) {
      props.onProfileSelected(profileId);
    }
  }

  function handleSearchPreferencesChange(criteria: ISearchCriteria, filters: IBorrowerSearchFilters): void {
    const desktop = inCategory('desktop');
    setSearchPreferencesShown(desktop);
    if (props.onSearchPreferencesChange) {
      props.onSearchPreferencesChange(criteria, filters);
    }
    if (!desktop) {
      window.scrollTo(0, 0);
    }
  }

  function handleLocationPanelClick(): void {
    setSearchCriteriaPanelShown(true);
  }

  function handleSearchCriteriaPanelCancel(): void {
    setSearchCriteriaPanelShown(false);
  }

  function handleSearchCriteriaPanelUpdate(searchDistance: SearchDistance, searchDirections: CompassBearing[]): void {
    const desktop = inCategory('desktop');
    setSearchPreferencesShown(desktop);
    setSearchCriteriaPanelShown(false);
    if (props.onSearchPreferencesChange) {
      const updatedCriteria = { ...props.criteria, searchDistance, searchDirections };
      props.onSearchPreferencesChange(updatedCriteria, props.filters);
    }
  }

  function handleLocationChangeComplete(): void {
    const desktop = inCategory('desktop');
    setSearchPreferencesShown(desktop);
    setSearchCriteriaPanelShown(false);
  }

  return (
    <>
      {renderGoPremiumBanner()}
      {renderPageContent()}
    </>
  );
}
