import { Routes, Route } from 'react-router-dom';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { AvailabilitySelection, CompassBearing } from '@borrowmydoggy/product-components';
import { DogCompatibilitySelection, DogPropertySelection } from '@borrowmydoggy/profile-components';

import { ProfileAPI } from '../api';
import { IDogLikeResult, IProfileFeedback, OwnerProfile, PageReloader, ReportType } from '../profile';

export interface IOwnerProfileContainerProps {
  profileId: string;
  dogName: string;
  ownerName: string;
  breed: string;
  breedCode: string;
  direction: CompassBearing;
  distanceInMiles: number;
  imageUrls: string[];
  liked: boolean;
  dogAge: string;
  multipleDogs: boolean;
  premium: boolean;
  ambassador: boolean;
  description: string;
  profileCreatedAtUtc: string;
  activityLevel?: string;
  company: boolean;
  exercise: boolean;
  acceptingMessages: boolean;
  conversationInProgress: boolean;
  picsRequested: boolean;
  currentUserPremiumBorrower: boolean;
  availability: AvailabilitySelection;
  profileFeedback: IProfileFeedback[];
  dogProperties: DogPropertySelection;
  dogCompatibility: DogCompatibilitySelection;
  showNavigationLinks?: boolean;
  premiumPath: string;
  conversationPath: string;
  doggyInfoSheetPath?: string;
}

export const OwnerProfileContainer: FunctionComponent<IOwnerProfileContainerProps> = (props: IOwnerProfileContainerProps) => {
  const [liked, setLiked] = useState(props.liked);
  const [picsRequested, setPicsRequested] = useState(props.picsRequested);
  const [reportProfileOpen, setReportProfileOpen] = useState(false);
  const [focusedImageIndex, setFocusedImageIndex] = useState(0);

  function renderProfile(): ReactNode {
    return (
      <OwnerProfile
        profileId={props.profileId}
        dogName={props.dogName}
        ownerName={props.ownerName}
        breed={props.breed}
        breedCode={props.breedCode}
        direction={props.direction}
        distanceInMiles={props.distanceInMiles}
        imageUrls={props.imageUrls}
        focusedImageIndex={focusedImageIndex}
        liked={liked}
        dogAge={props.dogAge}
        multipleDogs={props.multipleDogs}
        premium={props.premium}
        ambassador={props.ambassador}
        description={props.description}
        profileCreatedAtUtc={new Date(props.profileCreatedAtUtc)}
        activityLevel={props.activityLevel}
        company={props.company}
        exercise={props.exercise}
        acceptingMessages={props.acceptingMessages}
        conversationInProgress={props.conversationInProgress}
        conversationPath={props.conversationPath}
        doggyInfoSheetPath={props.doggyInfoSheetPath}
        currentUserPremiumBorrower={props.currentUserPremiumBorrower}
        availability={props.availability}
        profileFeedback={props.profileFeedback}
        dogProperties={props.dogProperties}
        dogCompatibility={props.dogCompatibility}
        picsRequested={picsRequested}
        showNavigationLinks={false}
        premiumPath={props.premiumPath}
        reportProfileOpen={reportProfileOpen}
        onProfileLiked={handleProfileLiked}
        onProfileUnliked={handleProfileUnliked}
        onPhotoRequested={handlePhotoRequested}
        onPhotoSelected={handlePhotoSelected}
        onReportProfileOpen={handleReportProfileOpen}
        onReportProfileClose={handleReportProfileClose}
        onProfileReported={handleProfileReported}
      />
    );
  }

  async function handleProfileLiked(): Promise<void> {
    const likeResult = await recordProfileLike(props.profileId);
    if (likeResult.successful) {
      setLiked(true);
    }
  }

  async function handleProfileUnliked(): Promise<void> {
    const unlikeResult = await recordProfileUnlike(props.profileId);
    if (unlikeResult.successful) {
      setLiked(false);
    }
  }

  async function handlePhotoRequested(): Promise<void> {
    const result = await requestPics(props.profileId);
    setPicsRequested(result);
  }

  function handlePhotoSelected(index: number): void {
    setFocusedImageIndex(index);
  }

  function handleReportProfileOpen(): void {
    setReportProfileOpen(true);
  }

  function handleReportProfileClose(): void {
    setReportProfileOpen(false);
  }

  async function handleProfileReported(reportType: ReportType, explanation?: string): Promise<void> {
    await reportProfile(props.profileId, reportType, explanation);
  }

  async function recordProfileLike(profileId: string): Promise<IDogLikeResult> {
    const response = await ProfileAPI.createBorrowerLike(profileId);
    if (response.error === undefined && response.data && response.data.createBorrowerLike) {
      return { successful: response.data.createBorrowerLike.liked, dogLikeCount: response.data.createBorrowerLike.dogLikeCount };
    } else {
      console.warn('API failure when attempting to record profile like.', response.error)
      return { successful: false, dogLikeCount: 0 };
    }
  }

  async function recordProfileUnlike(profileId: string): Promise<IDogLikeResult> {
    const response = await ProfileAPI.destroyBorrowerLike(profileId);
    if (response.error === undefined && response.data && response.data.destroyBorrowerLike) {
      return { successful: !response.data.destroyBorrowerLike.liked, dogLikeCount: response.data.destroyBorrowerLike.dogLikeCount };
    } else {
      console.warn('API failure when attempting to record profile unlike.', response.error)
      return { successful: false, dogLikeCount: 0 };
    }
  }

  async function requestPics(profileId: string): Promise<boolean> {
    const response = await ProfileAPI.createOwnerPictureRequest(profileId);
    if (response.error === undefined && response.data && response.data.createOwnerPictureRequest) {
      return response.data.createOwnerPictureRequest.pictureRequested;
    } else {
      console.warn('API failure when attempting to request pictures.', response.error)
      return false;
    }
  }

  async function reportProfile(profileId: string, reportType: ReportType, explanation?: string): Promise<boolean> {
    const response = await ProfileAPI.createOwnerProfileReport(profileId, reportType, explanation);
    if (response.error === undefined && response.data && response.data.createOwnerProfileReport) {
      return response.data.createOwnerProfileReport.profileReported;
    } else {
      console.warn('API failure when attempting to report profile.', response.error)
      return false;
    }
  }

  // PageReloader below is used to reload the search page from the server when navigating back through the browser history.
  return (
    <Routes>
      <Route path='/search/dogs' element={<PageReloader />} />
      <Route path='/dog_profile/:profileId' element={renderProfile()} />
    </Routes>
  );
};
