import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const Borrower2: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  function handleButtonClick(polarity: boolean): void {
    if (polarity) {
      props.record({ experienceWithDogs: true, experienceWithDogsConfirmed: true });
    } else {
      props.record({ experienceWithDogs: false, experienceWithDogsConfirmed: true });
    }
  }

  const paragraph = [
    'Have you owned, lived with or looked after a dog before? ',
    'Don’t worry if you haven’t, we all start somewhere, it’s just helpful for owners to know.'
  ].join('');

  return (
    <BinaryStep
      stepTitle='It&rsquo;s all about you'
      stepHeading='Have you got experience with dogs?'
      totalSteps={props.totalStepCount}
      currentStep={2}
      stepImageUrl={context.images['illustrations/img-fluffy-white-dog.svg']}
      onBinaryButtonClick={handleButtonClick}
    >
      <StandardStepDescription
        text={paragraph}
      />
    </BinaryStep>
  );
};
