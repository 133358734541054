import React, { FunctionComponent, MouseEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { BackLink, CircularIconButton, FormattedTextBlock, ForwardLink, inCategory, ResponsiveBlock, responsiveQuery } from '@borrowmydoggy/core-components';
import { AvailabilitySelection, CompassBearing, CompassHorizontal } from '@borrowmydoggy/product-components';
import {
  AmbassadorBadge,
  DogCompatibilitySelection,
  DogPropertySelection,
  DogLikeBanner,
  ModalLightBox,
  OwnerPortrait,
  OwnerProfileFeedbackPanel,
  OwnerProfilePhotoDisplay,
  OwnerProfileSideBar,
  OwnerProfileSummary,
  OwnerProfileWideBar,
  ProfileMessageBar,
  ReportProfileAccordion
} from '@borrowmydoggy/profile-components';

import { IProfileFeedback } from './IProfileFeedback';
import { ReportType } from './ReportType';

export interface IOwnerProfileProps {
  profileId: string;
  dogName: string;
  ownerName: string;
  breed: string;
  breedCode: string;
  direction: CompassBearing;
  distanceInMiles: number;
  imageUrls: string[];
  focusedImageIndex: number;
  liked: boolean;
  dogAge: string;
  multipleDogs: boolean;
  premium: boolean;
  ambassador: boolean;
  description: string;
  profileCreatedAtUtc: Date;
  activityLevel?: string;
  company: boolean;
  exercise: boolean;
  acceptingMessages: boolean;
  conversationInProgress: boolean;
  currentUserPremiumBorrower: boolean;
  availability: AvailabilitySelection;
  profileFeedback: IProfileFeedback[];
  dogProperties: DogPropertySelection;
  dogCompatibility: DogCompatibilitySelection;
  picsRequested: boolean;
  showNavigationLinks?: boolean;
  previousLinkEnabled?: boolean;
  nextLinkEnabled?: boolean;
  previousProfilePath?: string;
  nextProfilePath?: string;
  premiumPath: string;
  conversationPath: string;
  doggyInfoSheetPath?: string;
  reportProfileOpen: boolean;
  onProfileLiked?: () => void;
  onProfileUnliked?: () => void;
  onBackLinkClick?: () => void;
  onNextLinkClick?: () => void;
  onPreviousLinkClick?: () => void;
  onPhotoRequested?: () => void;
  onPhotoSelected?: (index: number) => void;
  onReportProfileOpen?: () => void;
  onReportProfileClose?: () => void;
  onProfileReported?: (reportType: ReportType, explanation?: string) => void;
}

interface IProfileProps {
  showNavigationLinks: boolean;
  imageCount: number;
  premium: boolean;
}

const StyledOwnerProfile = styled.div<IProfileProps>`
margin: auto;
display: grid;
&>.topnav {
  grid-area: topnav;
  ${props => props.showNavigationLinks ? 'margin: 10px 0' : ''};
  padding-left: 10px;
  padding-right: 10px;
}
&>.photos { grid-area: photos; box-sizing: border-box; }
&>.content {
  grid-area: content;
  &>.contentHeader {
    display: grid;
    grid-template-areas: 'compass portrait'
                         'title portrait'
                         'summary summary';
    grid-template-columns: minmax(50px, 492px) minmax(min-content, 1fr);
    justify-content: center;
    &>.compass {
      grid-area: compass;
      border-bottom: 1px solid ${props => props.theme.neutral.light};
    }
    &>.portrait {
      grid-area: portrait;
      padding-bottom: 10px;
      border-bottom: 1px solid ${props => props.theme.neutral.light};
      align-self: end;
      justify-self: end;
    }
    &>.title {
      grid-area: title;
      border-bottom: 1px solid ${props => props.theme.neutral.light};
      padding-bottom: 10px;
      justify-self: stretch;
      align-self: end;
      &>h1 {
        font-size: 3rem;
        overflow-wrap: break-word;
        hyphens: auto;
      }
      &>a {
        display: block;
        margin-bottom: 15px;
        color: ${props => props.theme.primary.mid};
      }
    }
    &>.summary {
      grid-area: summary;
      border-bottom: 1px solid ${props => props.theme.neutral.light};
    }
  }
  &>h3 { font-size: 2.2rem; }
  &>.description {
    padding-top: 10px;
    padding-bottom: 20px;
    &>p {
      padding-bottom: 10px;
      word-break: break-word;
    }
  }
  &>nav {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    &>.forwardLink { text-align: right; }
  }
}
&>.profileSidebar {
  grid-area: sidebar;
  display: grid;
  grid-gap: 10px;
  align-items: start;
  align-content: start;
  height: min-content;
}

${responsiveQuery('tabletLandscape', 'desktop')} {
  max-width: 1110px;
  grid-template-areas: 'topnav topnav'
                       'photos ${props => props.imageCount === 1 ? 'sidebar' : 'photos'}'
                       'content sidebar';
  grid-template-columns: 1fr 347px;

  &>.photos {
    ${props => props.imageCount === 1 ? 'padding: 0 90px' : ''};
  }

  &>.content {
    padding: 10px 90px;
    &>.contentHeader {
      grid-template-rows: 80px min-content 1fr;
      &>.portrait {
        height: 200px;
      }
      &>.title>a {
        font-size: 1.6rem;
      }
    }
    &>.description {
      &>p {
        font-size: 1.6rem;
      }
    }
    h3 {
      font-size: 2.2rem;
    }
  }

  &>.profileSidebar {
    position: sticky;
    top: 0;
  }

  .previousButtonFixed, .nextButtonFixed {
    position: fixed;
    top: calc(50% - 20px);
    z-index: 1;
  }
  .previousButtonFixed {
    left: 20px;
  }
  .nextButtonFixed {
    right: 20px;
  }
}

${responsiveQuery('mobilePortrait', 'mobileLandscape', 'tabletPortrait')} {
  grid-template-areas: 'topnav'
                       'photos'
                       'content';
  grid-template-columns: 100%;

  &>.content {
    &>.contentHeader {
      grid-template-rows: 50px min-content 1fr;
      &>.compass {
        padding: 5px 0;
      }
      &>.portrait {
        padding-top: 10px;
      }
      &>.title>a {
        font-size: 1.5rem;
      }
    }
    &>.description {
      &>p {
        font-size: 1.5rem;
        line-height: 1.6;
      }
    }
    h3 {
      font-size: 1.8rem;
    }
    &>nav {
      padding: 0 10px;
    }
  }
  &>.bottomBar {
    position: sticky;
    bottom: 0;
  }
}

${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
  &>.content {
    &>.contentHeader {
      width: 553px;
      margin: auto;
      &>.portrait {
        height: 170px;
      }
    }
    &>h3, &>.description {
      width: 553px;
      margin: auto;
    }
    &>.middleBar {
      margin-bottom: 10px;
      background-color: ${props => props.premium ? props.theme.premium.xLight : props.theme.neutral.xLight};
      &>aside {
        margin: auto;
        width: 553px;
      }
    }
    &>.reportProfileContainer {
      margin-bottom: 10px;
      width: 100%;
      background-color: ${props => props.premium ? props.theme.premium.light : props.theme.neutral.light};
      &>div header {
        margin: auto;
        width: 553px;
      }
    }
  }
}

${responsiveQuery('mobilePortrait')} {
  &>.content {
    &>.contentHeader, &>h3, &>.description, &>.middleBar {
      padding-left: 10px;
      padding-right: 10px;
      &>.portrait {
        height: 150px;
      }
    }
    &>.middleBar {
      margin-bottom: 10px;
      background-color: ${props => props.premium ? props.theme.premium.xLight : props.theme.neutral.xLight};
      &>aside {
        margin: auto;
      }
    }
    &>.reportProfileContainer {
      margin-bottom: 10px;
      background-color: ${props => props.premium ? props.theme.premium.light : props.theme.neutral.light};

      &>div {
        margin: auto;
      }

      &>div header {
        padding: 0 10px;
      }
    }
  }
}
`;

const StyledDogLikeBanner = styled(DogLikeBanner)`
  ${responsiveQuery('mobileLandscape', 'tabletPortrait', 'tabletLandscape', 'desktop')} {
    margin-left: -90px;
  }

  ${responsiveQuery('mobilePortrait')} {
    margin-left: -20px;
  }
`;

const StyledOwnerProfileFeedbackPanel = styled(OwnerProfileFeedbackPanel)`
  margin: 20px auto;

  ${responsiveQuery('mobileLandscape', 'tabletPortrait')} {
    width: 553px;
  }

  ${responsiveQuery('mobilePortrait')} {
    padding: 0 10px;
  }
`;

const StyledReportProfileAccordion = styled(ReportProfileAccordion)`
  ${responsiveQuery('tabletLandscape', 'desktop')} {
    max-width: 347px;
  }
`;

const AmbassadorBadgeContainer = styled.div`
  grid-area: compass;
  display: grid;
  border-bottom: 1px solid ${props => props.theme.neutral.light};
`;

const StyledAmbassadorBadge = styled(AmbassadorBadge)`
  align-self: center;
`;

export const OwnerProfile: FunctionComponent<IOwnerProfileProps> = (props: IOwnerProfileProps) => {
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [currentlySelectedPhotoIndex, setCurrentlySelectedPhotoIndex] = useState(0);

  function renderBackLink(): ReactNode | undefined {
    if (props.showNavigationLinks) {
      return <BackLink label='Back to search' href='/search/dogs' onClick={handleBackLinkClick} />;
    }
  }

  function renderDoggyInfoSheetLink(): ReactNode | undefined {
    if (props.doggyInfoSheetPath) {
      return <a href={props.doggyInfoSheetPath}>Doggy info sheet</a>;
    }
  }

  function renderFeedback(): ReactNode | undefined {
    if (props.profileFeedback.length > 0) {
      const feedback = props.profileFeedback.map((pf, i) => {
        return (
          <StyledOwnerProfileFeedbackPanel
            key={i}
            name={pf.name}
            dateText={pf.dateText}
            text={pf.text}
            portraitImageUrl={pf.portraitImageUrl}
          />
        );
      });
      return (
        <>
          <h3>Feedback from borrowers</h3>
          {feedback}
        </>
      );
    }
  }

  function renderNavLinks(): ReactNode | undefined {
    if (props.showNavigationLinks) {
      return (
        <nav>
          <BackLink
            label='Previous dog'
            href={props.previousProfilePath}
            disabled={!props.previousLinkEnabled}
            onClick={handlePreviousLinkClick}
          />
          <ForwardLink
            label='Next dog'
            href={props.nextProfilePath}
            disabled={!props.nextLinkEnabled}
            className='forwardLink'
            onClick={handleNextLinkClick}
          />
          <ResponsiveBlock categories={['tabletLandscape', 'desktop']}>
            <CircularIconButton
              altText='Previous profile'
              type='soft'
              icon={faChevronLeft}
              link={props.previousProfilePath ? { href: props.previousProfilePath } : undefined}
              onClick={handlePreviousLinkClick}
              disabled={!props.previousLinkEnabled}
              className='previousButtonFixed'
              inverted
            />
            <CircularIconButton
              altText='Next profile'
              type='soft'
              icon={faChevronRight}
              link={props.nextProfilePath ? { href: props.nextProfilePath } : undefined}
              onClick={handleNextLinkClick}
              disabled={!props.nextLinkEnabled}
              className='nextButtonFixed'
              inverted
            />
          </ResponsiveBlock>
        </nav>
      );
    }
  }

  function renderMiddleBar(): ReactNode {
    return (
      <ResponsiveBlock categories={['mobilePortrait', 'mobileLandscape', 'tabletPortrait']}>
        <div className='middleBar'>
          <OwnerProfileWideBar
            ownerName={props.ownerName}
            dogName={props.dogName}
            multipleDogs={props.multipleDogs}
            profileCreatedAtUtc={props.profileCreatedAtUtc}
            activityLevel={props.activityLevel}
            premium={props.premium}
            selfPremium={props.currentUserPremiumBorrower}
            availability={props.availability}
            company={props.company}
            exercise={props.exercise}
            premiumUrl={props.premiumPath}
          />
        </div>
      </ResponsiveBlock>
    );
  }

  function renderLowerBar(): ReactNode {
    return (
      <ResponsiveBlock categories={['mobilePortrait', 'mobileLandscape', 'tabletPortrait']}>
        <div className='reportProfileContainer'>
          <ReportProfileAccordion
            profileType='owner'
            profileUuid={props.profileId}
            open={props.reportProfileOpen}
            premium={props.premium}
            onOpen={handleReportProfileOpen}
            onClose={handleReportProfileClose}
            onPictureReported={handlePictureReported}
            onTextReported={handleTextReported}
            onSomethingElseReported={handleSomethingElseReported}
          />
        </div>
      </ResponsiveBlock>
    );
  }

  function renderLightBox(): ReactNode | undefined {
    if (lightBoxOpen) {
      return (
        <ModalLightBox photos={props.imageUrls} initialPhotoIndex={currentlySelectedPhotoIndex} onCloseClick={handleLightBoxCloseClick} />
      );
    }
  }

  function renderBadge(): ReactNode {
    if (props.ambassador) {
      return <AmbassadorBadgeContainer><StyledAmbassadorBadge /></AmbassadorBadgeContainer>;
    }
    return (
      <CompassHorizontal
        direction={props.direction}
        distanceInMiles={props.distanceInMiles}
        alignment='left'
        premium={props.premium}
        className='compass'
      />
    );
  }

  async function handleLikeToggle(): Promise<void> {
    if (!props.liked && props.onProfileLiked) {
      props.onProfileLiked();
    } else if (props.liked && props.onProfileUnliked) {
      props.onProfileUnliked();
    }
  }

  function handleBackLinkClick(event: MouseEvent<HTMLAnchorElement>): void {
    if (props.onBackLinkClick) {
      event.preventDefault();
      props.onBackLinkClick();
    }
  }

  function handleNextButtonClick(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
    if (props.onNextLinkClick) {
      event.preventDefault();
      props.onNextLinkClick();
    }
  }

  function handleNextLinkClick(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
    if (props.onNextLinkClick) {
      event.preventDefault();
      props.onNextLinkClick();
    }
  }

  function handlePreviousButtonClick(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
    if (props.onPreviousLinkClick) {
      event.preventDefault();
      props.onPreviousLinkClick();
    }
  }

  function handlePreviousLinkClick(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
    if (props.onPreviousLinkClick) {
      event.preventDefault();
      props.onPreviousLinkClick();
    }
  }

  function handleReportProfileOpen(): void {
    if (props.onReportProfileOpen) {
      props.onReportProfileOpen();
    }
  }

  function handleReportProfileClose(): void {
    if (props.onReportProfileClose) {
      props.onReportProfileClose();
    }
  }

  function handlePictureReported(): void {
    if (props.onProfileReported) {
      props.onProfileReported('picture');
    }
  }

  function handleTextReported(): void {
    if (props.onProfileReported) {
      props.onProfileReported('text');
    }
  }

  async function handleSomethingElseReported(description: string): Promise<boolean> {
    if (props.onProfileReported) {
      props.onProfileReported('somethingElse', description);
    }
    return true;
  }

  function handlePhotoSelected(index: number): void {
    if (inCategory('mobilePortrait') && props.onPhotoSelected) {
      props.onPhotoSelected(index);
    } else {
      setLightBoxOpen(true);
      setCurrentlySelectedPhotoIndex(index);
    }
  }

  function handleLightBoxCloseClick(): void {
    setLightBoxOpen(false);
    setCurrentlySelectedPhotoIndex(0);
  }

  return (
    <StyledOwnerProfile
      imageCount={props.imageUrls.length}
      premium={props.premium}
      showNavigationLinks={props.showNavigationLinks || false}
    >
      <div className='topnav'>
        {renderBackLink()}
      </div>
      <OwnerProfilePhotoDisplay
        dogName={props.dogName}
        ownerName={props.ownerName}
        multipleDogs={props.multipleDogs}
        picsRequested={props.picsRequested}
        photos={props.imageUrls}
        focusedPhotoIndex={props.focusedImageIndex}
        onPhotoRequested={props.onPhotoRequested}
        onPhotoSelected={handlePhotoSelected}
        className='photos'
      />
      {renderLightBox()}
      <div className='content'>
        <div className='contentHeader'>
          {renderBadge()}
          <div className='title'>
            <h1 lang='en-gb'>{props.dogName}</h1>
            {renderDoggyInfoSheetLink()}
            <StyledDogLikeBanner
              dogName={props.dogName}
              ownerName={props.ownerName}
              multipleDogs={props.multipleDogs}
              liked={props.liked}
              premium={props.premium}
              onToggle={handleLikeToggle}
            />
          </div>
          <OwnerPortrait dogs={[props.breedCode]} className='portrait' />
          <OwnerProfileSummary
            dogName={props.dogName}
            dogAge={props.dogAge}
            breed={props.breed}
            properties={props.dogProperties}
            compatibility={props.dogCompatibility}
            className='summary'
          />
        </div>
        <div className='description'><FormattedTextBlock text={props.description} /></div>
        {renderMiddleBar()}
        {renderFeedback()}
        {renderLowerBar()}
        {renderNavLinks()}
      </div>
      <ResponsiveBlock categories={['desktop', 'tabletLandscape']}>
        <div className='profileSidebar'>
          <OwnerProfileSideBar
            ownerName={props.ownerName}
            dogName={props.dogName}
            multipleDogs={props.multipleDogs}
            profileCreatedAtUtc={props.profileCreatedAtUtc}
            activityLevel={props.activityLevel}
            premium={props.premium}
            ambassador={props.ambassador}
            selfPremium={props.currentUserPremiumBorrower}
            availability={props.availability}
            company={props.company}
            exercise={props.exercise}
            acceptingMessages={props.acceptingMessages}
            conversationInProgress={props.conversationInProgress}
            premiumUrl={props.premiumPath}
            messageUrl={props.conversationPath}
          />
          <StyledReportProfileAccordion
            profileType='owner'
            profileUuid={props.profileId}
            open={props.reportProfileOpen}
            premium={props.premium}
            onOpen={handleReportProfileOpen}
            onClose={handleReportProfileClose}
            onPictureReported={handlePictureReported}
            onTextReported={handleTextReported}
            onSomethingElseReported={handleSomethingElseReported}
          />
        </div>
      </ResponsiveBlock>
      <ResponsiveBlock categories={['mobilePortrait', 'mobileLandscape', 'tabletPortrait']}>
        <div className='bottomBar'>
          <ProfileMessageBar
            recipientName={`${props.dogName} and ${props.ownerName}`}
            pluralRecipient={true}
            ambassador={props.ambassador}
            selfPremium={props.currentUserPremiumBorrower}
            acceptingMessages={props.acceptingMessages}
            conversationInProgress={props.conversationInProgress}
            premiumUrl={props.premiumPath}
            messageUrl={props.conversationPath}
            showNavigationLinks={props.showNavigationLinks}
            previousLinkEnabled={props.previousLinkEnabled}
            nextLinkEnabled={props.nextLinkEnabled}
            previousUrl={props.previousProfilePath}
            nextUrl={props.nextProfilePath}
            onNextClick={handleNextButtonClick}
            onPreviousClick={handlePreviousButtonClick}
          />
        </div>
      </ResponsiveBlock>
    </StyledOwnerProfile>
  );
};

OwnerProfile.defaultProps = {
  previousLinkEnabled: true,
  nextLinkEnabled: true
};
