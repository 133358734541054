import React, { useContext } from 'react';
import { faHandHeart, faTennisBall } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading } from '@borrowmydoggy/step-components';
import { SelectionButtons } from '@borrowmydoggy/core-components';

import { DogNeeds } from './DogNeeds';
import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';
import { WebContext } from '../utils/AppContext';

const StyledButtons = styled(SelectionButtons)`
  margin: 0 auto;
` as typeof SelectionButtons;

export const Owner12B: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { multipleDogs, subject } = parseDogNames(props.manuscript.dogNames || []);
  const pronoun = props.manuscript.dogGender && !multipleDogs ? parseDogGender(props.manuscript.dogGender).genderedPronoun : 'they';

  function handleSelection(buttonId: DogNeeds): void {
    props.record({ dogNeeds: buttonId, dogNeedsConfirmed: true });
  }

  return (
    <StepContainer
      stepTitle={`Tell us about ${subject}`}
      totalSteps={props.totalStepCount}
      currentStep={12}
      stepImageUrl={context.images['illustrations/img-dog-sleeping-in-house.svg']}
    >
      <StepHeading text={`What ${multipleDogs ? 'do' : 'does'} ${subject} need?`} />
      <StandardStepDescription text={`Would ${pronoun} love more walks, less time alone or both?`} />
      <StyledButtons
        buttons={[
          {
            id: 'exerciseAndCompany',
            text: 'Exercise and company',
            icon: { name: 'exerciseAndCompany' }
          },
          {
            id: 'exercise',
            text: 'Just exercise',
            icon: faTennisBall
          },
          {
            id: 'company',
            text:'Just company',
            icon: faHandHeart
          }
        ]}
        onClick={handleSelection}
      />
    </StepContainer>
  );
};
