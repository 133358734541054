import { IStepProcessOperationOptions } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from '../../borrowerProfileCreation';
import { IProfilePhotoUploadRequest } from '../../profile';
import { ProfileAPI } from '../../api';

export async function createBorrowerProfilePhoto(
  { manuscript, record }: IStepProcessOperationOptions<IBorrowerProfileCreationManuscript>,
  photoFileData: Blob,
  previewImageData: Blob
): Promise<Partial<IBorrowerProfileCreationManuscript>> {
  record({ borrowerProfilePhotoStatus: 'inProgress' });
  if (manuscript.photoFileName === undefined) {
    return { borrowerProfilePhotoStatus: 'error' };
  }
  try {
    const response = await ProfileAPI.createProfilePhotoUploadRequest('borrower');
    if (response.error === undefined && response.data && response.data.createProfilePhotoUploadRequest) {
      if (response.data.createProfilePhotoUploadRequest.successful && response.data.createProfilePhotoUploadRequest.profilePhotoUploadRequest) {
        const profilePhotoUploadRequest: IProfilePhotoUploadRequest = response.data.createProfilePhotoUploadRequest.profilePhotoUploadRequest;
        const taskId = response.data.createProfilePhotoUploadRequest.taskId || '';
        const photoUploadResult = await uploadProfilePhoto(profilePhotoUploadRequest, manuscript.photoFileName, photoFileData);
        if (photoUploadResult) {
          const previewImageDataUrl = await blob2DataUrl(previewImageData);
          return {
            borrowerProfilePhotoStatus: 'successful',
            borrowerProfileCreationStatus: 'pending',
            photoConfirmed: true,
            uploadedPhotoKey: profilePhotoUploadRequest.destinationKey,
            photoTaskId: taskId,
            previewImageDataUrl
          };
        } else {
          return { borrowerProfilePhotoStatus: 'uploadFailed' };
        }
      } else {
        return { borrowerProfilePhotoStatus: 'requestFailed' };
      }
    } else {
      console.warn(response);
      return { borrowerProfilePhotoStatus: 'requestFailed' };
    }
  } catch (error) {
    console.warn(error);
    return { borrowerProfilePhotoStatus: 'error' };
  }
}

async function uploadProfilePhoto(uploadRequest: IProfilePhotoUploadRequest, fileName: string, fileData: Blob): Promise<boolean> {
  try {
    const filePathParts = fileName.split('/');
    const leafName = filePathParts[filePathParts.length - 1];

    const formData = new FormData();
    formData.append('key', uploadRequest.destinationKey);
    formData.append('success_action_status', uploadRequest.successActionStatus);
    formData.append('policy', uploadRequest.policy);
    formData.append('x-amz-credential', uploadRequest.credential);
    formData.append('x-amz-algorithm', uploadRequest.algorithm);
    formData.append('x-amz-date', uploadRequest.requestDate);
    formData.append('x-amz-signature', uploadRequest.signature);
    // formData.append('file', { uri: fileName, name: leafName, type: fileMimeType });
    formData.append('file', fileData, leafName);

    const uploadResult = await fetch(uploadRequest.requestUrl, {
      method: 'POST',
      body: formData
    });
    // Below parseInt is a little dodgy - TODO
    if (uploadResult.status === parseInt(uploadRequest.successActionStatus, 10)) {
      return true;
    } else {
      const resultText = await uploadResult.text();
      console.warn('S3 profile photo upload failed', uploadResult.status, resultText);
      return false;
    }
  } catch (error) {
    console.warn('S3 profile photo upload resulted in error', error);
    return false;
  }
}

function blob2DataUrl(blobData: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const dataUrl = reader.result.toString();
          resolve(dataUrl);
        }
        reject(new Error('FileReader had no result!'));
      };
      reader.readAsDataURL(blobData);
    } catch (error: unknown) {
      reject(error);
    }
  });
}
