import React, { ReactElement, useContext } from 'react'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { Form, FormSubmissionResult, IRadioButtonGroupItem, IRadioButtonGroupProps, RadioButtonGroup } from '@borrowmydoggy/form-components';
import { StepContainer, IStepComponentProps, StepComponent, StepHeading, StandardStepDescription, StepNavigation } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const attributionSources = [
  'facebook',
  'flyers',
  'google',
  'instagram',
  'nextdoor',
  'snapchat',
  'spotify',
  'media',
  'wordOfMouth',
  'other'
] as const;
type AttributionSource = (typeof attributionSources)[number];

const attributionSchema = z.object({ attributionSource: z.enum(attributionSources).optional() });
type AttributionSchema = z.infer<typeof attributionSchema>;

// Help TS with typing (see below).
type AttributionRadioButtonGroup = (props: IRadioButtonGroupProps<'attributionSource', AttributionSource>) => ReactElement;

const items: Array<IRadioButtonGroupItem<AttributionSource>> = [
  { id: 'facebook', label: 'Facebook' },
  { id: 'flyers', label: 'Flyers' },
  { id: 'google', label: 'Google' },
  { id: 'instagram', label: 'Instagram' },
  { id: 'nextdoor', label: 'Nextdoor' },
  { id: 'snapchat', label: 'Snapchat' },
  { id: 'spotify', label: 'Spotify' },
  { id: 'media', label: 'TV / Radio / Newspaper / Magazine' },
  { id: 'wordOfMouth', label: 'Word of mouth' },
  { id: 'other', label: 'Other' }
];

export const User10: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  async function handleSubmit(data: AttributionSchema): Promise<FormSubmissionResult<AttributionSchema>> {
    await props.record({ attributionSource: data.attributionSource, attributionConfirmed: true, accountCreationStatus: 'pending' });
    props.operations.createUserAccount();
    return { outcome: 'success', formStateAction: 'halt' };
  }

  // TS doesn't properly infer this type.
  const AttributionOptions: AttributionRadioButtonGroup = RadioButtonGroup;

  return (
    <StepContainer
      stepTitle='We’d like to know'
      totalSteps={props.totalStepCount}
      currentStep={10}
      stepImageUrl={context.images['illustrations/img-dog-with-heart-in-speech-bubble.svg']}
    >
      <StepHeading text='How did you hear about us?'/>
      <StandardStepDescription text='This helps us to understand where we can find more great members like you!' />
      <Form schema={attributionSchema} onSubmit={handleSubmit}>
        {({ fields, handleChange, handleValidate }) => (
          <>
            <AttributionOptions
              id='attributionSource'
              items={items}
              legend='Choose how you heard about us'
              value={fields.attributionSource.value}
              validationState={fields.attributionSource.validationState}
              onValidate={handleValidate}
              onChange={handleChange}
            />
            <StepNavigation
              nextButton={{ text: 'Next', icon: faChevronRight, iconAlignment: 'right' }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
