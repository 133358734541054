import { gqlCommand } from '@borrowmydoggy/api-client';
import {
  Mutation,
  MutationDisconnectUserAccountFromAppleArgs,
  MutationDisconnectUserAccountFromFacebookArgs,
  MutationUpdateUserAccountContactPreferencesArgs,
  MutationUpdateUserAccountLocationFromProfileArgs,
  MutationUpdateUserAccountPasswordArgs,
  MutationUpdateUserAccountPermissionToUseArgs
} from '@borrowmydoggy/bmd-api';

export const mutations = {
  disconnectUserAccountFromApple: gqlCommand<
    { disconnectUserAccountFromApple: Mutation['disconnectUserAccountFromApple']; },
    MutationDisconnectUserAccountFromAppleArgs
  >`
    mutation DisconnectUserAccountFromApple($newPassword: String!) {
      disconnectUserAccountFromApple(newPassword: $newPassword) {
        successful
        errors
      }
    }
  `,
  disconnectUserAccountFromFacebook: gqlCommand<
    { disconnectUserAccountFromFacebook: Mutation['disconnectUserAccountFromFacebook']; },
    MutationDisconnectUserAccountFromFacebookArgs
  >`
    mutation DisconnectUserAccountFromFacebook($newPassword: String!) {
      disconnectUserAccountFromFacebook(newPassword: $newPassword) {
        successful
        errors
      }
    }
  `,
  updateUserAccountContactPreferences: gqlCommand<
    { updateUserAccountContactPreferences: Mutation['updateUserAccountContactPreferences']; },
    MutationUpdateUserAccountContactPreferencesArgs
  >`
    mutation UpdateUserAccountContactPreferences($preferences: ContactPreferencesArgument!) {
      updateUserAccountContactPreferences(preferences: $preferences) {
        successful
        contactPreferences {
          alertMessageEmail
          alertMessageSms
          alertMessagePush

          alertLikeEmail
          alertLikePush
          profileViewsEmail
          newsletterEmail

          guidesTipsEmail
          promotionsEventsEmail

          alertNewMembersEmail
          alertNewMembersPush

          newMembersBorrowerProfileConfiguration {
            searchDistance
            premium
            availability {
              daytime
              evenings
              weekends
              holidays
            }
            searchLocation
          }
          newMembersOwnerProfileConfiguration {
            searchDistance
            premium
            availability {
              daytime
              evenings
              weekends
              holidays
            }
            searchLocation
          }
        }
        contactCapabilities {
          email
          sms
          push
        }
        errors
      }
    }
  `,
  updateUserAccountLocationFromProfile: gqlCommand<
    { updateUserAccountLocationFromProfile: Mutation['updateUserAccountLocationFromProfile']; },
    MutationUpdateUserAccountLocationFromProfileArgs
  >`
    mutation UpdateUserAccountLocationFromProfile($profileType: Profile!) {
      updateUserAccountLocationFromProfile(profileType: $profileType) {
        errors
      }
    }
  `,
  updateUserAccountPassword: gqlCommand<
    { updateUserAccountPassword: Mutation['updateUserAccountPassword']; },
    MutationUpdateUserAccountPasswordArgs
  >`
    mutation UpdateUserAccountPassword($currentPassword: String!, $newPassword: String!) {
      updateUserAccountPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
        successful
        errors
      }
    }
  `,
  updateUserAccountPermissionToUse: gqlCommand<
    { updateUserAccountPermissionToUse: Mutation['updateUserAccountPermissionToUse']; },
    MutationUpdateUserAccountPermissionToUseArgs
  >`
    mutation UpdateUserAccountPermissionToUse($permissionToUse: Boolean!) {
      updateUserAccountPermissionToUse(permissionToUse: $permissionToUse) {
        errors
      }
    }
  `
};
