import { CommandResultPromise } from '@borrowmydoggy/api-client';

import { ClientBase } from '../ClientBase';
import { mutations } from './mutations';

export class ReferralAPI extends ClientBase {
  public static createEmailReferrals(emailAddresses: string[]): CommandResultPromise<typeof mutations.createEmailReferrals> {
    return this.client.mutation({
      command: mutations.createEmailReferrals,
      args: { emailAddresses }
    });
  }
}
