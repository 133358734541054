import React, { Component, MouseEvent, ReactElement, ReactNode } from 'react';

import {
  AddressSearchForm,
  AddressSearchResults,
  IAddress,
  IAddressSearchResult,
  ISuccessfulCreateFromSearchResultResponse,
  ManualAddressEntry
} from './index';

export interface IProvideAddressProps {
  address: IAddress;
  startWithManualEntry?: boolean;
  csrfToken: string;
  manualAddressCreationPath: string;
  addressSearchRequestPath: string;
  addressSearchResultPath: string;
  addressCreateFromSearchResultPath: string;
  onAddressChange?: (newAddress: IAddress) => void;
  onAddressProvided?: () => void;
}

interface IProvideAddressState {
  errorMessage?: ReactElement;
  addressSearchResults: IAddressSearchResult[];
  postcodeValidationMessage: string;
  manualAddressEntry: boolean;
}

export class ProvideAddress extends Component<IProvideAddressProps, IProvideAddressState> {
  constructor(props: IProvideAddressProps) {
    super(props);

    this.state = { errorMessage: <></>, addressSearchResults: [], postcodeValidationMessage: '', manualAddressEntry: props.startWithManualEntry || false };

    this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
    this.handleValidationMessageChange = this.handleValidationMessageChange.bind(this);
    this.handleManualAddressClick = this.handleManualAddressClick.bind(this);
    this.handleAddressSearchClick = this.handleAddressSearchClick.bind(this);

    this.handleError = this.handleError.bind(this);
    this.handleAddressSearchResultsRetrieved = this.handleAddressSearchResultsRetrieved.bind(this);
    this.handleAddressResultChosen = this.handleAddressResultChosen.bind(this);
    this.handleManualAddressChange = this.handleManualAddressChange.bind(this);
    this.handleManualAddressSubmitted = this.handleManualAddressSubmitted.bind(this);
  }

  public render(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>What is your legal address?</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>
            Please choose the address where you are most likely to be currently registered - often the billing address your bank holds for you.
          </p>
        </div>
        <div className='element-group centred space-above-small'>
          <p className='clarification'><strong>Students:</strong> Please enter your home address, not your university address.</p>
        </div>
        {this.renderErrorMessage()}
        {this.renderAddressForm()}
      </>
    );
  }

  private handlePostcodeChange(value: string): void {
    const updatedAddress = { ...this.props.address, postcode: value };
    if (this.props.onAddressChange) {
      this.props.onAddressChange(updatedAddress);
    }
  }

  private handleValidationMessageChange(message: string): void {
    this.setState({ postcodeValidationMessage: message });
  }

  private handleAddressSearchClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    this.setState({ manualAddressEntry: false, addressSearchResults: [], errorMessage: <></> });
  }

  private handleManualAddressClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    this.setState({ manualAddressEntry: true, addressSearchResults: [], errorMessage: <></> });
    // Scroll back up to the top.
    const element = document.getElementById('verification-container');
    if (element) {
      element.scrollIntoView(true);
    }
  }

  private handleError(errorMessage: ReactElement): void {
    this.setState({ errorMessage });
  }

  private handleAddressSearchResultsRetrieved(results: IAddressSearchResult[]): void {
    // TODO could have a separate 'addressSearchResultsCleared' event
    if (results.length > 0) {
      this.setState({ addressSearchResults: results, errorMessage: <></> });
      // Scroll down to the results.
      const element = document.getElementById('verification-form');
      if (element) {
        element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }
    } else {
      this.setState({ addressSearchResults: results });
    }
  }

  private handleAddressResultChosen(addressResult: ISuccessfulCreateFromSearchResultResponse): void {
    const newAddress = {
      flat: addressResult.flat || '',
      houseNumber: addressResult.houseNumber || '',
      lineOne: addressResult.addressLine1,
      lineTwo: addressResult.addressLine2 || '',
      lineThree: addressResult.addressLine3 || '',
      town: addressResult.town,
      postcode: addressResult.postcode || ''
    };

    if (this.props.onAddressChange) {
      this.props.onAddressChange(newAddress);
    }
    this.setState({ errorMessage: <></> });
    if (this.props.onAddressProvided) {
      this.props.onAddressProvided();
    }
  }

  private handleManualAddressChange(value: IAddress): void {
    if (this.props.onAddressChange) {
      this.props.onAddressChange(value);
    }
  }

  private handleManualAddressSubmitted(): void {
    this.setState({ errorMessage: <></>, manualAddressEntry: false });
    if (this.props.onAddressProvided) {
      this.props.onAddressProvided();
    }
  }

  private renderErrorMessage(): ReactNode | null {
    if (this.state.errorMessage && this.state.errorMessage.props.children && this.state.errorMessage.props.children.length > 0) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{this.state.errorMessage}</p>
          </article>
        </div>
      );
    }
  }

  private renderAddressForm(): ReactNode {
    if (this.state.manualAddressEntry) {
      return (
        <ManualAddressEntry
          address={this.props.address}
          manualAddressCreationPath={this.props.manualAddressCreationPath}
          onAddressChange={this.handleManualAddressChange}
          onManualAddressSubmitted={this.handleManualAddressSubmitted}
          onAddressSearchClick={this.handleAddressSearchClick}
          onError={this.handleError}
          csrfToken={this.props.csrfToken}
        />
      );
    } else if (this.state.addressSearchResults.length > 0) {
      return (
        <div className='space-above-medium'>
          <AddressSearchForm
            postcode={this.props.address.postcode}
            validationMessage={this.state.postcodeValidationMessage}
            addressSearchRequestPath={this.props.addressSearchRequestPath}
            addressSearchResultPath={this.props.addressSearchResultPath}
            onPostcodeChange={this.handlePostcodeChange}
            onManualAddressClick={this.handleManualAddressClick}
            onAddressSearchResultsRetrieved={this.handleAddressSearchResultsRetrieved}
            onValidationMessageChange={this.handleValidationMessageChange}
            onError={this.handleError}
            csrfToken={this.props.csrfToken}
          />
          <AddressSearchResults
            addressSearchResults={this.state.addressSearchResults}
            addressCreateFromSearchResultPath={this.props.addressCreateFromSearchResultPath}
            onAddressResultChosen={this.handleAddressResultChosen}
            onManualAddressClick={this.handleManualAddressClick}
            onError={this.handleError}
            csrfToken={this.props.csrfToken}
          />
        </div>
      );
    } else {
      return (
        <AddressSearchForm
          postcode={this.props.address.postcode}
          validationMessage={this.state.postcodeValidationMessage}
          addressSearchRequestPath={this.props.addressSearchRequestPath}
          addressSearchResultPath={this.props.addressSearchResultPath}
          className='space-above-medium'
          onPostcodeChange={this.handlePostcodeChange}
          onManualAddressClick={this.handleManualAddressClick}
          onAddressSearchResultsRetrieved={this.handleAddressSearchResultsRetrieved}
          onValidationMessageChange={this.handleValidationMessageChange}
          onError={this.handleError}
          csrfToken={this.props.csrfToken}
        />
      );
    }
  }
}
