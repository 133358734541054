import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { Component, ReactNode } from 'react';

import { CardPaymentForm, CardSetupForm, HelpMessage, PaymentSuccessful } from '../payment';

export interface IUpdateCardDetailsFormProps {
  formattedPrice: string;
  stripePublishableKey: string;
  confirmationImageUrl: string;
  stripeCardDetailsPath: string;
  dashboardPath: string;
}

interface IUpdateCardDetailsFormState {
  csrfToken: string;
  updateSuccessful: boolean;
  updateRequiresAction: boolean;
  stripeSetupIntentClientSecret: string;
  submissionErrorMessage: string;
  submissionInProgress: boolean;
}

type UPaymentData =
  | { new_subscription_stripe_create_card_details_request: { stripe_payment_method_id: string; }; }
  | { subscription_stripe_confirm_card_details_request: { stripe_setup_intent_id: string; }; };

export class UpdateCardDetailsForm extends Component<IUpdateCardDetailsFormProps, IUpdateCardDetailsFormState> {
  private stripePromise: Promise<Stripe | null>;

  constructor(props: IUpdateCardDetailsFormProps) {
    super(props);

    let csrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const attribute = csrfTokenElement.attributes.getNamedItem('content');
      if (attribute) {
        csrfToken = attribute.value;
      }
    }

    // this.stripe = (window as any).Stripe(props.stripePublishableKey);
    this.stripePromise = loadStripe(props.stripePublishableKey);

    this.state = {
      csrfToken,
      updateSuccessful: false,
      updateRequiresAction: false,
      stripeSetupIntentClientSecret: '',
      submissionErrorMessage: '',
      submissionInProgress: false
    };

    this.handleCardUpdateSubmissionStart = this.handleCardUpdateSubmissionStart.bind(this);
    this.handleCardUpdateSubmissionFailure = this.handleCardUpdateSubmissionFailure.bind(this);
    this.handleCardUpdateSubmissionSuccess = this.handleCardUpdateSubmissionSuccess.bind(this);

    this.handleCardSetupSuccess = this.handleCardSetupSuccess.bind(this);
    this.handleCardSetupFailure = this.handleCardSetupFailure.bind(this);
  }

  public render(): ReactNode {
    return (
      <article>
        <div>
          <nav className='breadcrumb'>
          </nav>
        </div>
        <Elements stripe={this.stripePromise} options={{
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Lato'
            }
          ]
        }}>
          {this.renderSubForm()}
        </Elements>
        <HelpMessage />
      </article>
    );
  }

  private handleCardUpdateSubmissionStart(): void {
    this.setState({ submissionErrorMessage: '', submissionInProgress: true });
  }

  private handleCardUpdateSubmissionFailure(): void {
    this.setState({ submissionErrorMessage: '', submissionInProgress: false });
  }

  private async handleCardUpdateSubmissionSuccess(stripePaymentMethodId: string): Promise<void> {
    const data = {
      new_subscription_stripe_create_card_details_request: {
        stripe_payment_method_id: stripePaymentMethodId
      }
    };
    this.submitCardUpdateAttempt(data, 'POST');
  }

  private async submitCardUpdateAttempt(data: UPaymentData, method: 'POST' | 'PUT'): Promise<boolean> {
    try {
      const response = await fetch(
        this.props.stripeCardDetailsPath,
        {
          method,
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken },
          body: JSON.stringify(data)
        }
      );
      const json = await response.json();
      if (json.status && json.status === 'success') {
        this.setState({ updateSuccessful: true, submissionErrorMessage: '', submissionInProgress: false, updateRequiresAction: false });
        return true;
      } else if (json.status && json.status === 'requires_action') {
        this.setState({ submissionInProgress: false, updateRequiresAction: true, stripeSetupIntentClientSecret: json.setupIntentClientSecret });
        return false;
      } else {
        const errorMessage = json.errors || 'Sorry, there was a problem processing your card update.';
        this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, updateRequiresAction: false });
        return false;
      }
    } catch (error) {
      console.error(error);
      const errorMessage = 'Sorry, there was a problem processing your card update.';
      this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, updateRequiresAction: false });
      return false;
    }
  }

  private async handleCardSetupSuccess(stripeSetupIntentId: string): Promise<void> {
    const data = {
      subscription_stripe_confirm_card_details_request: {
        stripe_setup_intent_id: stripeSetupIntentId
      }
    };
    this.submitCardUpdateAttempt(data, 'PUT');
  }

  private async handleCardSetupFailure(error: string): Promise<void> {
    const errorMessage = error || 'Sorry, there was a problem processing your card update.';
    this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, updateRequiresAction: false });
  }

  private renderSubForm(): ReactNode {
    if (this.state.updateRequiresAction) {
      return (
        <CardSetupForm
          title='Update card details'
          setupIntentClientSecret={this.state.stripeSetupIntentClientSecret}
          confirmationImageUrl={this.props.confirmationImageUrl}
          onSetupSuccess={this.handleCardSetupSuccess}
          onSetupFailure={this.handleCardSetupFailure}
        />
      );
    } else if (this.state.updateSuccessful) {
      return (
        <PaymentSuccessful
          title='Update card details'
          button='Back to dashboard'
          firstParagraph='Update successful!'
          secondParagraph="You're all set for your next payment."
          forwardPath={this.props.dashboardPath}
        />
      );
    } else {
      return (
        <CardPaymentForm
          title='Update card details'
          buttonText='Update now'
          stripeErrorMessage='Unfortunately there was a problem updating your card'
          errorMessage={this.state.submissionErrorMessage}
          submissionInProgress={this.state.submissionInProgress}
          onSubmissionStart={this.handleCardUpdateSubmissionStart}
          onSubmissionFailure={this.handleCardUpdateSubmissionFailure}
          onSubmissionSuccess={this.handleCardUpdateSubmissionSuccess}
        />
      );
    }
  }
}
