import React, { ChangeEvent, FocusEvent, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { EmailAddressValidator, IValidationState, IValidator, validateAgainstCollection } from '@borrowmydoggy/bmd-validators';

export interface IEmailAddressFieldProps {
	name?: string;
	id?: string;
	className?: string;
	value: string;
	placeholder?: string;
	validateOnBlur?: boolean;
	validState?: boolean;
	onChange?: (value: string, validationState: IValidationState) => void;
	onBlur?: (validationState: IValidationState) => void;
}

interface IValidStateProps {
	validState: boolean;
}

const StyledEmailAddressField = styled.div<IValidStateProps>`
	&>input[type=email] {
		box-sizing: border-box;
		grid-area: input;
		border: 1px solid ${props => props.validState ? props.theme.neutral.xDark : props.theme.negative.mid};
		border-radius: 5px;
		background-color: ${props => props.validState ? props.theme.neutral.xxLight : props.theme.negative.xLight};
		font-family: Lato, sans-serif;
		color: ${props => props.theme.secondary.mid};
		font-size: 16px;
		padding: 10px 5px;
		width: 100%;
		margin: 0;
		height: 42px;
	}

	&>.validationMessage {
		display: block;
		font-family: Lato, sans-serif;
		font-size: 14px;
		color: ${props => props.theme.negative.mid};
	}
`;

export const EmailAddressField: FunctionComponent<IEmailAddressFieldProps> = (props: IEmailAddressFieldProps) => {
	const validators: Array<IValidator<string>> = [
		new EmailAddressValidator('Please supply a valid email address')
	];

	function performValidation(value: string): IValidationState {
		return validateAgainstCollection(value, validators);
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		const newValue = event.target.value;
		if (props.onChange) {
			const validationState = performValidation(newValue);
			props.onChange(newValue, validationState);
		}
	}

	function handleBlur(event: FocusEvent<HTMLInputElement>): void {
		if (props.validateOnBlur && props.onBlur) {
			const validationState = performValidation(event.target.value);
			props.onBlur(validationState);
		}
	}

	function renderValidationMessage(): ReactNode {
		return <span className='validationMessage'>Please supply a valid email address</span>;
	}

	return (
		<StyledEmailAddressField className={props.className} validState={props.validState === undefined ? true : props.validState}>
			<input
				id={props.id}
				name={props.name}
				type='email'
				value={props.value}
				autoComplete='email'
				placeholder={props.placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			{!props.validState && props.validateOnBlur && renderValidationMessage()}
		</StyledEmailAddressField>
	);
};

EmailAddressField.defaultProps = {
	validateOnBlur: false,
	validState: true
};
