import React, { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { faPlusCircle, faRedo } from '@fortawesome/pro-solid-svg-icons';

import { IconButton, IconTextButton, LoadingIndicator, TextButton } from '@borrowmydoggy/core-components';
import { IValidationState } from '@borrowmydoggy/bmd-validators';

import { EmailAddressField } from './EmailAddressField';
import { Form } from './Form';

export interface IMultipleEmailAddressFormSubmissionResult {
	successful: boolean;
	failedAddresses: string[];
}

export interface IMultipleEmailAddressFormProps {
	maxFieldCount: number;
	className?: string;
	onSubmit?: (emailAddresses: string[]) => Promise<IMultipleEmailAddressFormSubmissionResult>;
}

type FormState = 'initial' | 'inProgress' | 'error' | 'success';

const StyledMultipleEmailAddressForm = styled(Form)`
	&>span {
		display: grid;
		grid-template-columns: 1fr 30px;
		margin-bottom: 10px;
		&>button {
			margin-left: 8px;
			margin-top: 8px;
		}
	}
`;

const ErrorMessage = styled.p`
	color: ${props => props.theme.negative.mid};
	font-family: Lato, sans-serif;
	font-size: 14px;
	margin: 5px 0;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
	width: 80px;
`;

export const MultipleEmailAddressForm: FunctionComponent<IMultipleEmailAddressFormProps> = (props: IMultipleEmailAddressFormProps) => {
	const [emailAddresses, setEmailAddresses] = useState(['']);
	const [validationStates, setValidationStates] = useState([true]);
	const [formState, setFormState] = useState('initial' as FormState);
	const [failureCount, setFailureCount] = useState(0);

	function handleChange(index: number, value: string): void {
		const updatedEmailAddresses = [...emailAddresses];
		updatedEmailAddresses[index] = value;
		setEmailAddresses(updatedEmailAddresses);
	}

	function handleBlur(index: number, validationState: IValidationState): void {
		const updatedValidationStates = [...validationStates];
		updatedValidationStates[index] = validationState.status === 'valid';
		setValidationStates(updatedValidationStates);
	}

	function handleAddFieldButtonClick(): void {
		if (emailAddresses.length < props.maxFieldCount) {
			const updatedEmailAddresses = [...emailAddresses, ''];
			setEmailAddresses(updatedEmailAddresses);
		}
	}

	async function handleSubmit(): Promise<void> {
		if (validationStates.reduce((allValid, fieldValid) => allValid && fieldValid)) {
			if (props.onSubmit) {
				setFormState('inProgress');
				const submissionResult = await props.onSubmit(emailAddresses);
				if (submissionResult.failedAddresses.length > 0) {
					setFormState('initial');
					const failedAddresses = submissionResult.failedAddresses;
					const updatedValidationStates = emailAddresses.map(emailAddress => !failedAddresses.includes(emailAddress));
					setValidationStates(updatedValidationStates);
				} else if (!submissionResult.successful) {
					setFormState('error');
					setFailureCount(failureCount + 1);
				} else {
					setFormState('success');
				}
			}
		}
	}

	function renderAddFieldButton(): ReactNode {
		return (
			<IconButton type='soft' icon={faPlusCircle} altText='Add another email address' onClick={handleAddFieldButtonClick} />
		);
	}

	function renderFields(maxFieldCount: number): ReactNode[] {
		const emailAddressFields = [];
		for (let fieldIndex = 0; fieldIndex < emailAddresses.length; fieldIndex++) {
			emailAddressFields.push(
				<span key={fieldIndex}>
					<EmailAddressField
						value={emailAddresses[fieldIndex]}
						placeholder="Your friend's email address"
						validateOnBlur
						validState={validationStates[fieldIndex]}
						onChange={handleChange.bind({}, fieldIndex)}
						onBlur={handleBlur.bind({}, fieldIndex)}
					/>
					{fieldIndex === emailAddresses.length - 1 && fieldIndex < maxFieldCount - 1 ? renderAddFieldButton() : ''}
				</span>
			);
		}
		return emailAddressFields;
	}

	function renderSubmitButton(): ReactNode {
		const fieldsValidForSubmission = /@/g.test(emailAddresses.join());
		switch (formState) {
			case 'error':
				return (
					<IconTextButton
            icon={faRedo}
						text='Try again'
						wide
						disabled={!fieldsValidForSubmission}
						onClick={handleSubmit}
					/>
				);
			case 'inProgress':
				return <StyledLoadingIndicator />;
			case 'initial':
			default:
				return <TextButton text='Send invite' wide disabled={!fieldsValidForSubmission} />;
		}
	}

	function renderErrorMessage(): ReactNode {
		let errorMessage = "Something didn't quite work, please check your connection and try again.";
		if (failureCount > 2) {
			errorMessage += ' If you keep getting this please call the helpdesk on 020 3826 8628.';
		}
		return <ErrorMessage>{errorMessage}</ErrorMessage>;
	}

	return (
		<StyledMultipleEmailAddressForm onSubmit={handleSubmit} className={props.className}>
			{renderFields(props.maxFieldCount)}
			{renderSubmitButton()}
			{formState === 'error' && renderErrorMessage()}
		</StyledMultipleEmailAddressForm>
	);
};
