import React, { ChangeEvent, FocusEvent, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

import { IValidationState, IValidator, IValidatorGroup, stringMatchesPattern, validateAgainstCollection } from '@borrowmydoggy/bmd-validators';

export interface ITextFieldProps {
	name?: string;
	id?: string;
	className?: string;
	value: string;
	placeholder?: string;
	maxLength?: number;
	autoComplete?: string;
	inputPattern?: string;
	autoFocus?: boolean;
	changeValidators?: Array<IValidator<string>>;
	blurValidators?: Array<IValidator<string>>;
	group?: IValidatorGroup;
	onChange?: (value: string, validationState: IValidationState) => void;
	onBlur?: (validationState: IValidationState) => void;
}

const StyledTextField = styled.div`
	&>input[type=text] {
		box-sizing: border-box;
		grid-area: input;
		border: 1px solid ${props => props.theme.neutral.xDark};
		border-radius: 5px;
		background-color: ${props => props.theme.neutral.xxLight};
		font-family: Lato, sans-serif;
		color: ${props => props.theme.secondary.mid};
		font-size: 16px;
		padding: 10px 5px;
		width: 100%;
		margin: 0;
		height: 42px;
	}
`;

export const TextField: FunctionComponent<ITextFieldProps> = (props: ITextFieldProps) => {
	useEffect(() => {
		if (props.group && props.id) {
			props.group.register(props.id);
		}
	}, [props.group, props.id]);

	function performValidation(value: string, validators: Array<IValidator<string>> | undefined): IValidationState {
    if (validators) {
      return validateAgainstCollection(value, validators);
    }
    return { status: 'pending' };
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		const newValue = event.target.value;
		if ((props.inputPattern && stringMatchesPattern(newValue, props.inputPattern)) || props.inputPattern === undefined) {
			const validationState = performValidation(newValue, props.changeValidators);
			if (props.onChange) {
				props.onChange(newValue, validationState);
			}
			if (props.group && props.id) {
				props.group.handleValidationStateChange(props.id, validationState.status === 'valid');
			}
		}
	}

	function handleBlur(event: FocusEvent<HTMLInputElement>): void {
		const validationState = performValidation(event.target.value, props.blurValidators);
		if (props.onBlur) {
			props.onBlur(validationState);
		}
		if (props.group && props.id) {
			props.group.handleValidationStateChange(props.id, validationState.status === 'valid');
		}
	}
	return (
		<StyledTextField className={props.className}>
			<input
				id={props.id}
				name={props.name}
				type='text'
				value={props.value}
				placeholder={props.placeholder}
				maxLength={props.maxLength}
				autoComplete={props.autoComplete}
				autoFocus={props.autoFocus}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		</StyledTextField>
	);
};
