import React from 'react';

import { IActivityStreamEventComponent, IActivityStreamEventComponentProps } from './IActivityStreamEventComponent';
import { LikeEvent } from './LikeEvent';

import { IInboundLikeOwnerActivityStreamEvent } from './IInboundLikeOwnerActivityStreamEvent';

export const InboundLikeOwnerActivityStreamEvent: IActivityStreamEventComponent<IInboundLikeOwnerActivityStreamEvent> = (
  props: IActivityStreamEventComponentProps<IInboundLikeOwnerActivityStreamEvent>) => {
  return (
    <LikeEvent eventData={props.eventData} missingBorrowerImageURL={props.missingBorrowerImageURL} missingOwnerImageURL={props.missingOwnerImageURL}>
      <div className='activity-stream-event__content-heading-text'>
        <span className='green'>
          <a href={props.eventData.profileUrl} className='link_standard-green'>{props.eventData.borrowerName}</a>
        </span>
        {' '}
        <span className='blue'>liked</span>
        {' '}
        <span className='gray'>{props.eventData.dogName}</span>
      </div>
    </LikeEvent>
  );
};
