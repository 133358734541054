import React, { FormEvent, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

export interface IFormProps {
	id?: string;
	className?: string;
	children?: ReactNode;
	onSubmit?: () => void;
}

const StyledForm = styled.form`
`;

export const Form: FunctionComponent<IFormProps> = (props: IFormProps) => {
	function handleSubmit(event: FormEvent<HTMLFormElement>): void {
		if (props.onSubmit) {
			event.preventDefault();
			props.onSubmit();
		}
	}

	return (
		<StyledForm id={props.id} className={props.className} onSubmit={handleSubmit}>
			{props.children}
		</StyledForm>
	);
};
