import { z } from 'zod';
import { UserAccount } from '@borrowmydoggy/core-models';

export const internationalInterestDetails = z.object({
  firstName: z.string(),
  surname: z.string(),
  emailAddress: UserAccount.emailAddress,
  city: z.string(),
  region: z.string(),
  countryIsoCode: z.string()
});

export type InternationalInterestDetails = z.infer<typeof internationalInterestDetails>;
