// import Bugsnag, { Client } from '@bugsnag/js';
import type { Client } from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';

// import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import React, { StrictMode } from 'react';
import type { FunctionComponent, ReactElement, ReactNode } from 'react';
// import { createRoot } from 'react-dom/client';
// import convert from 'react-from-dom';

// import { ComponentWrapper, ErrorHandlingClass, loadAnalytics, readReactEnvironment, rudderanalytics } from '../utils';
import type { ErrorHandlingClass, IEnvironment } from '../utils';
// import * as Elements from '../elements';
// import * as Pages from '../pages';
import type * as Elements from '../elements';
import type * as Pages from '../pages';
// const { createRoot } = await import('react-dom/client');

let bugsnagClient: Client;

type ComponentType = 'page' | 'element';

interface IComponentDetails {
	component: FunctionComponent;
	componentType: ComponentType;
	errorHandlingClass: ErrorHandlingClass;
}

async function initialiseBugsnagIfRequired(environment: IEnvironment): Promise<void> {
	// const { readReactEnvironment } = await import('../utils');
	// const environment = readReactEnvironment();
	if (bugsnagClient === undefined && environment.railsEnv === 'production' && environment.bugsnagApiKey && environment.bugsnagReleaseStage) {
		const { default: Bugsnag } = await import('@bugsnag/js');
		const { default: BugsnagPluginReact } = await import('@bugsnag/plugin-react');
		bugsnagClient = Bugsnag.start({
			apiKey: environment.bugsnagApiKey,
			releaseStage: environment.bugsnagReleaseStage,
			plugins: [new BugsnagPluginReact(React)]
		});
	}
}

function mapImport(
	importedLibrary: typeof Elements | typeof Pages,
	errorHandlingClass: ErrorHandlingClass,
	componentType: ComponentType
): Record<string, IComponentDetails> {
	const map: Record<string, IComponentDetails> = {};
	const ownPropertyNames = Object.getOwnPropertyNames(importedLibrary);
	ownPropertyNames
		.filter(k => k !== '__esModule')
		.forEach(k => {
			map[k] = {
				component: importedLibrary[k as keyof (typeof Elements | typeof Pages)],
				componentType,
				errorHandlingClass
			};
		});
	return map;
}

async function buildComponentRegistry(): Promise<Record<string, IComponentDetails>> {
	const Elements = await import('../elements');
	const Pages = await import('../pages');
	return {
		...mapImport(Elements, 'minor', 'element'),
		...mapImport(Pages, 'major', 'page')
	};
}

function renderReactComponent<P extends Record<string, unknown>>(component: FunctionComponent<P>, props: P, children: ReactNode[]): ReactElement {
	return React.createElement(component, props, children);
}

async function renderReactComponents(environment?: IEnvironment): Promise<void> {
	const { readReactEnvironment } = await import('../utils');
	initialiseBugsnagIfRequired(environment || readReactEnvironment());
	const componentRegistry: Record<string, IComponentDetails> = await buildComponentRegistry();
	const reactComponentContainers = document.querySelectorAll('.reactComponent');

	const { createRoot } = await import('react-dom/client');
	const { default: convert } = await import('react-from-dom');
	const { ComponentWrapper } = await import('../utils');

	reactComponentContainers.forEach(rcc => {
		const componentName = rcc.getAttribute('data-component');
		let componentProps: Record<string, unknown> = {};
		const propsAttribute = rcc.getAttribute('data-props');
		if (propsAttribute && propsAttribute.length > 0) {
			componentProps = JSON.parse(propsAttribute);
		}
		let componentContext: Record<string, unknown> = {};
		const contextAttribute = rcc.getAttribute('data-context');
		if (contextAttribute && contextAttribute.length > 0) {
			componentContext = JSON.parse(contextAttribute);
		}
		let componentChildren: ReactNode[] = [];
		if (rcc.childNodes.length > 0) {
			componentChildren = Array.prototype.slice.call(rcc.childNodes).map((cn: ChildNode, index: number) => convert(cn, { index }) as ReactNode);
		}

		if (componentName && componentRegistry[componentName]) {
			const componentDetails = componentRegistry[componentName];
			const reactComponent = renderReactComponent(componentDetails.component, componentProps, componentChildren);
			const wrappedComponent = (
				<StrictMode>
					<ComponentWrapper
						context={componentContext}
						errorHandlingClass={componentDetails.errorHandlingClass}
						wrapWithRouter={componentDetails.componentType === 'page'}
					>
						{reactComponent}
					</ComponentWrapper>
				</StrictMode>
			);
			const root = createRoot(rcc);
			root.render(wrappedComponent);
			rcc.classList.remove('reactComponent'); // This is done to prevent double-rendering if this function is called again.
		} else {
			console.warn('Found React DOM container for unrecognised component', componentName);
		}
	});
}

// Remove deprecated JWT from localStorage.
window.localStorage.removeItem('jwt');

async function loadApp(): Promise<void> {
	const { loadAnalytics, readReactEnvironment, rudderanalytics, trackPageEventsForRudderStack } = await import('../utils');
	const environment = readReactEnvironment();
	renderReactComponents(environment);
	loadAnalytics();

	// Identify user with rudderstack
	rudderanalytics.identify(environment.userTrackingId);

	// Raise page track event with rudderstack
	rudderanalytics.page('page');

	// Raise other page events with RudderStack (supplied by server as part of page rendering)
	trackPageEventsForRudderStack();
}

// Automatically render any React components on the page once the DOM has fully loaded.
document.addEventListener('DOMContentLoaded', loadApp);

// The below global is to allow legacy JS code to trigger React component rendering.
(window as any).renderReactComponents = renderReactComponents;
