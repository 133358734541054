import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';
import { WebContext } from '../utils/AppContext';

export const Owner7: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);
  let pronoun: string;
  let objectivePronoun: string;
  if (props.manuscript.dogGender) {
    const parsedGender = parseDogGender(props.manuscript.dogGender);
    pronoun = parsedGender.genderedPronoun;
    objectivePronoun = parsedGender.genderedObjectivePronoun;
  } else {
    pronoun = 'they';
    objectivePronoun = 'them';
  }

  return (
    <BinaryStep
      stepTitle={`Tell us about ${firstDogName}`}
      stepHeading={`Does ${pronoun} get on with cats?`}
      totalSteps={props.totalStepCount}
      currentStep={7}
      stepImageUrl={context.images['illustrations/img-dog-with-cat.svg']}
      backButton={{
        text: 'Back'
      }}
      onBinaryButtonClick={polarity => props.record({ tolerantOfCats: polarity, tolerantOfCatsConfirmed: true })}
      onBackClick={() => props.record({ goodWithChildrenConfirmed: false })}
    >
      <StandardStepDescription text={`Is it safe for ${objectivePronoun} to be borrowed by someone with a cat?`} />
    </BinaryStep>
  );
};
