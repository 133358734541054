import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';
import { WebContext } from '../utils/AppContext';

export const Owner8: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);
  const pronoun = props.manuscript.dogGender ? parseDogGender(props.manuscript.dogGender).genderedPronoun : firstDogName;

  return (
    <BinaryStep
      stepTitle={`Tell us about ${firstDogName}`}
      stepHeading={`Is ${pronoun} OK with other dogs?`}
      totalSteps={props.totalStepCount}
      currentStep={8}
      stepImageUrl={context.images['illustrations/img-two-dogs-with-bone.svg']}
      backButton={{
        text: 'Back'
      }}
      onBinaryButtonClick={polarity => props.record({ okayWithOtherDogs: polarity, okayWithOtherDogsConfirmed: true })}
      onBackClick={() => props.record({ tolerantOfCatsConfirmed: false })}
    >
      <StandardStepDescription text={`Would ${pronoun} be happy being borrowed by someone with another dog?`} />
    </BinaryStep>
  );
};
