export function navigateWithPost(destinationUrl: string, fields?: Record<string, string>): void {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = destinationUrl;

  const formFields: Record<string, string> = { ...fields };

  const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
  if (csrfTokenElement) {
    const contentAttribute = csrfTokenElement.attributes.getNamedItem('content');
    if (contentAttribute) {
      formFields['authenticity_token'] = contentAttribute.value;
    }
  }

  const formFieldKeys = Object.keys(formFields);
  formFieldKeys.forEach(k => {
    const formField = document.createElement('input');
    formField.type = 'hidden';
    formField.name = k;
    formField.value = formFields[k];
    form.appendChild(formField);
  });

  document.body.appendChild(form);
  form.submit();
}
