import React, { FunctionComponent, ReactNode } from 'react';

import { IBorrowingInfoUpdateActivityStreamEvent } from './IBorrowingInfoUpdateActivityStreamEvent';

export interface IBorrowingInfoUpdateEventProps {
  eventData: IBorrowingInfoUpdateActivityStreamEvent;
  missingOwnerImageURL: string;
  missingBorrowerImageURL: string;
  children?: ReactNode;
}

export const BorrowingInfoUpdateEvent: FunctionComponent<IBorrowingInfoUpdateEventProps> = (props: IBorrowingInfoUpdateEventProps) => {
  const subscriptionType = props.eventData.userVerified ? 'premium' : 'basic';
  let profileImageUrl = props.eventData.profileImageUrl;
  if (profileImageUrl === undefined || profileImageUrl == null) {
    profileImageUrl = props.eventData.profileType === 'owner' ? props.missingOwnerImageURL : props.missingBorrowerImageURL;
  }

  return (
    <div className='panel__item panel__item_list activity-stream-event'>
      <div className='activity-stream-event__profile-image'>
        <div className={`profile-image profile-image_${subscriptionType}`}>
          <a href={props.eventData.profileUrl}>
            <img src={profileImageUrl} alt='' />
          </a>
        </div>
        <div className='activity-stream-event__type-icon activity-stream-event__type-icon_borrowing-info-update'>
          <span className='fas fa-paw' />
        </div>
      </div>
      <div className='activity-stream-event__content'>
        {props.children}
        <div className='activity-stream-event__content-heading-date'>
          {props.eventData.eventCreatedAt}
        </div>
      </div>
    </div>
  );
};
