import { Stripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import React, { FunctionComponent, useEffect } from 'react';

export interface ICardSetupFormProps {
  setupIntentClientSecret: string;
  title: string;
  titleClass?: string;
  confirmationImageUrl: string;
  onSetupSuccess?: (stripeSetupIntentId: string) => void;
  onSetupFailure?: (error: string) => void;
}

export const CardSetupForm: FunctionComponent<ICardSetupFormProps> = (props: ICardSetupFormProps) => {
  const stripe = useStripe();

  async function handleCardSetup(stripeApi: Stripe): Promise<void> {
    const response = await stripeApi.confirmCardSetup(props.setupIntentClientSecret);
    if (response.error && props.onSetupFailure) {
      props.onSetupFailure(response.error.message || '');
    } else if (props.onSetupSuccess) {
      props.onSetupSuccess(response.setupIntent?.id || '');
    }
  }

  useEffect(() => {
    if (stripe) {
      handleCardSetup(stripe);
    }
  }, [stripe, props.setupIntentClientSecret]);

  return (
    <div>
      <h1 className={`page-title ${props.titleClass ? props.titleClass : 'premium'}`}>{props.title}</h1>
      <p className='new-paragraph centred space-above-medium'>Awaiting card confirmation...</p>
      <img className='illustration' src={props.confirmationImageUrl} alt='Please wait' />
    </div>
  );
};
