import React from 'react';

import { IStepComponentProps, ProcessingStep, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';

export const Borrower9A: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {

  return (
    <ProcessingStep
      totalSteps={props.totalStepCount}
      currentStep={9}
      displayCancelButton={false}
    >
      <StandardStepDescription text='We’re creating your profile' />
    </ProcessingStep>
  );
};
