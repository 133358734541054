import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import React, { Component, MouseEvent, ReactNode } from 'react';

import { CardActionForm, HelpMessage, PaymentSuccessful } from '../payment';

export interface IRenewalPaymentConfirmationFormProps {
  stripePaymentIntentClientSecret: string;
  stripePublishableKey: string;
  confirmationImageUrl: string;
  successParagraph: string;
  stripeRenewalPaymentRequestsPath: string;
  stripeRenewalPaymentConfirmationRequestsPath: string;
  dashboardPath: string;
}

interface IRenewalPaymentConfirmationFormState {
  csrfToken: string;
  stripePaymentIntentClientSecret: string;
  confirmationSuccessful: boolean;
  submissionErrorMessage: string;
  submissionInProgress: boolean;
  paymentRequiresAction: boolean;
}

export class RenewalPaymentConfirmationForm extends Component<IRenewalPaymentConfirmationFormProps, IRenewalPaymentConfirmationFormState> {
  private stripePromise: Promise<Stripe | null>;

  constructor(props: IRenewalPaymentConfirmationFormProps) {
    super(props);

    let csrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const attribute = csrfTokenElement.attributes.getNamedItem('content');
      if (attribute) {
        csrfToken = attribute.value;
      }
    }

    // this.stripe = (window as any).Stripe(props.stripePublishableKey);
    this.stripePromise = loadStripe(props.stripePublishableKey);

    this.state = {
      csrfToken,
      stripePaymentIntentClientSecret: this.props.stripePaymentIntentClientSecret,
      confirmationSuccessful: false,
      submissionErrorMessage: '',
      submissionInProgress: false,
      paymentRequiresAction: false
    };

    this.handleCardActionSuccess = this.handleCardActionSuccess.bind(this);
    this.handleCardActionFailure = this.handleCardActionFailure.bind(this);

    this.handleRetryClick = this.handleRetryClick.bind(this);
  }

  public render(): ReactNode {
    return (
      <article>
        <div>
          <nav className='breadcrumb'>
          </nav>
        </div>
        {this.renderSubForm()}
        <HelpMessage />
      </article>
    );
  }

  private async handleCardActionSuccess(stripePaymentIntentId: string): Promise<boolean> {
    const data = {
      subscription_stripe_renewal_payment_confirmation_request: {
        stripe_payment_intent_id: stripePaymentIntentId
      }
    };
    try {
      const response = await fetch(
        this.props.stripeRenewalPaymentConfirmationRequestsPath,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken },
          body: JSON.stringify(data)
        }
      );
      const json = await response.json();
      if (json.status && json.status === 'success') {
        this.setState({ confirmationSuccessful: true, submissionErrorMessage: '', paymentRequiresAction: false });
        return true;
      } else if (json.status && json.status === 'requires_action') {
        this.setState({ paymentRequiresAction: true, stripePaymentIntentClientSecret: json.paymentIntentClientSecret });
        return false;
      } else {
        const errorMessage = json.errors || 'Sorry, there was a problem processing your renewal confirmation.';
        this.setState({ submissionErrorMessage: errorMessage, paymentRequiresAction: false });
        return false;
      }
    } catch (error) {
      console.error(error);
      const errorMessage = 'Sorry, there was a problem processing your renewal confirmation.';
      this.setState({ submissionErrorMessage: errorMessage, paymentRequiresAction: false });
      return false;
    }
  }

  private async handleCardActionFailure(error: string): Promise<void> {
    const errorMessage = error || 'Sorry, there was a problem processing your renewal confirmation.';
    this.setState({ submissionErrorMessage: errorMessage, paymentRequiresAction: false });
  }

  private async createNewRenewalPayment(): Promise<boolean> {
    try {
      const response = await fetch(
        this.props.stripeRenewalPaymentRequestsPath,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken }
        }
      );
      const json = await response.json();
      if (json.status && json.status === 'success') {
        this.setState({ confirmationSuccessful: true, submissionErrorMessage: '', submissionInProgress: false, paymentRequiresAction: false });
        return true;
      } else if (json.status && json.status === 'requires_action') {
        this.setState({ paymentRequiresAction: true, stripePaymentIntentClientSecret: json.paymentIntentClientSecret, submissionInProgress: false });
        return false;
      } else {
        const errorMessage = json.errors || 'Sorry, there was a problem processing your renewal payment.';
        this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
        return false;
      }
    } catch (error) {
      console.error(error);
      const errorMessage = 'Sorry, there was a problem processing your renewal payment.';
      this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
      return false;
    }
  }

  private handleRetryClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    this.setState({ submissionErrorMessage: '', submissionInProgress: true });
    this.createNewRenewalPayment();
  }

  private renderSubForm(): ReactNode {
    if (this.state.submissionErrorMessage.length > 0) {
      return (
        <div>
          <h1 className='page-title premium'>Renewal failed</h1>
          <p className='new-paragraph centred space-above-medium'>Please retry or contact us</p>
          <div className='element-group space-above-medium-desktop space-above-large-tablet space-above-large-mobile'>
            <a onClick={this.handleRetryClick} href='#' className='new-button primary large'>Retry</a>
          </div>
          <div className='element-group centred space-above-medium'>
            <a href='https://help.borrowmydoggy.com/hc/en-gb/requests/new' className='new-link primary block'>Contact us</a>
          </div>
        </div>
      );
    } else if (this.state.submissionInProgress) {
      return (
        <div>
          <h1 className='page-title premium'>Confirm renewal payment</h1>
          <p className='new-paragraph centred space-above-medium'>Re-attempting payment...</p>
          <img className='illustration' src={this.props.confirmationImageUrl} alt='Please wait' />
        </div>
      );
    } else if (this.state.confirmationSuccessful) {
      return (
        <PaymentSuccessful
          title='Renewal successful!'
          button='Back to dashboard'
          firstParagraph={this.props.successParagraph}
          forwardPath={this.props.dashboardPath}
        />
      );
    } else {
      return (
        <Elements stripe={this.stripePromise} options={{
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Lato'
            }
          ]
        }}>
          <CardActionForm
            title='Confirm renewal payment'
            paymentIntentClientSecret={this.state.stripePaymentIntentClientSecret}
            confirmationImageUrl={this.props.confirmationImageUrl}
            onActionSuccess={this.handleCardActionSuccess}
            onActionFailure={this.handleCardActionFailure}
          />
        </Elements>
      );
    }
  }
}
