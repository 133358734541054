import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { AvailabilityCheckBoxGroup } from '@borrowmydoggy/product-components';
import { BorrowerProfile } from '@borrowmydoggy/core-models';
import { Form, FormSubmissionResult } from '@borrowmydoggy/form-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';

import { BorrowerProfileCreationOperations } from '../operations';
import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { WebContext } from '../utils/AppContext';

const availabilitySchema = z.object({ availability: BorrowerProfile.availability });
type AvailabilitySchema = z.infer<typeof availabilitySchema>;

export const Borrower6: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  function handleChangeActivities(): void {
    props.record({ offeringConfirmed: false });
  }

  function handleSubmit(data: AvailabilitySchema): FormSubmissionResult<AvailabilitySchema> {
    const manuscriptChanges: Partial<IBorrowerProfileCreationManuscript> = { availability: data.availability };
    props.record(manuscriptChanges);
    return { outcome: 'success', formStateAction: 'halt' };
  }

  return (
    <StepContainer
      stepTitle='It&rsquo;s all about you'
      totalSteps={props.totalStepCount}
      currentStep={6}
      stepImageUrl={context.images['illustrations/img-dog-with-clock.svg']}
    >
      <StepHeading text='When are you available?' />
      <StandardStepDescription text='What times are you usually free to borrow a dog? You can update your availability at any time.' />
      <Form schema={availabilitySchema} onSubmit={handleSubmit}>
        {({ fields, handleChange, handleValidate }) => (
          <>
            <AvailabilityCheckBoxGroup
              id='availability'
              legend='Choose your availability'
              onChange={handleChange}
              onValidate={handleValidate}
              value={fields.availability.value}
              validationState={fields.availability.validationState}
            />
            <StepNavigation
              onBackClick={handleChangeActivities}
              backButton={{ text: 'Back' }}
              nextButton={{
                icon: faChevronRight,
                text: 'Next',
                iconAlignment: 'right'
              }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
