import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from './IBorrowerProfileCreationManuscript';
import { BorrowerProfileCreationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const Borrower4: StepComponent<IBorrowerProfileCreationManuscript> = (
  props: IStepComponentProps<IBorrowerProfileCreationManuscript, BorrowerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  function handleButtonClick(polarity: boolean): void {
    props.record({ gardenOrOutsideSpace: polarity, gardenOrOutsideSpaceConfirmed: true });
  }

  function handleBackClick(): void {
    props.record({ childrenAtHomeConfirmed: false });
  }

  return (
    <BinaryStep
      stepTitle='It&rsquo;s all about you'
      stepHeading='Have you got a garden / outside space?'
      totalSteps={props.totalStepCount}
      currentStep={4}
      stepImageUrl={context.images['illustrations/img-dog-hiding-in-bushes.svg']}
      backButton={{ text: 'Back' }}
      onBinaryButtonClick={handleButtonClick}
      onBackClick={handleBackClick}
    >
      <StandardStepDescription text='It’s helpful for owners to know if you have a safe outside space for their dog at your home.' />
    </BinaryStep>
  );
};
