import { APIClient } from '@borrowmydoggy/api-client';

interface IAuthState {} // Currently empty.

export abstract class ClientBase {
  private static apiClient: APIClient<IAuthState>;

  protected static get client(): APIClient<IAuthState> {
    if (this.apiClient == null) {
      this.apiClient = new APIClient<IAuthState>(this.endpointPath, () => {
        return {
          headers: {
            'X-CSRF-Token': this.getCSRFToken()
          }
        };
      },
      undefined, error => {
          const isAuthError = error.response?.status === 403 || error.graphQLErrors.some(e => e.extensions?.code === 'unauthorisedAccess');
          if (isAuthError) {
            const currentLocation = new URL(window.location.href).pathname;
            window.location.replace(`/signin?return_to=${currentLocation}`);
          }
        }
      );
    }
    return this.apiClient;
  }

  protected static get endpointPath(): string {
    return '/graphql';
  }

  private static getCSRFToken(): string {
    let csrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const contentAttribute = csrfTokenElement.attributes.getNamedItem('content');
      if (contentAttribute) {
        csrfToken = contentAttribute.value;
      }
    }
    return csrfToken;
  }
}
