import { CommandResultPromise } from '@borrowmydoggy/api-client';
import {
  BorrowerSearchFiltersArgument,
  CompassBearing,
  DogSearchFiltersArgument,
  Profile as ProfileType,
  ProfileSearchCriteriaArgument,
  SearchDistance,
  SearchLocation as SearchLocationType
} from '@borrowmydoggy/bmd-api';

import { ClientBase } from '../ClientBase';
import { mutations } from './mutations';
import { queries } from './queries';

export class SearchAPI extends ClientBase {
  public static defaultDogSearch(limit: number): CommandResultPromise<typeof queries.defaultDogSearch> {
    return this.client.query({
      command: queries.defaultDogSearch,
      args: { limit }
    });
  }

  public static dogSearch(
    limit: number,
    offset: number,
    criteria: ProfileSearchCriteriaArgument,
    filters?: DogSearchFiltersArgument
  ): CommandResultPromise<typeof queries.dogSearch> {
    return this.client.query({
      command: queries.dogSearch,
      args: { parameters: { limit, offset, criteria, filters }},
      requestPolicy: 'network-only'
    });
  }

  public static defaultBorrowerSearch(limit: number): CommandResultPromise<typeof queries.defaultBorrowerSearch> {
    return this.client.query({
      command: queries.defaultBorrowerSearch,
      args: { limit }
    });
  }

  public static borrowerSearch(
    limit: number,
    offset: number,
    criteria: ProfileSearchCriteriaArgument,
    filters?: BorrowerSearchFiltersArgument
  ): CommandResultPromise<typeof queries.borrowerSearch> {
    return this.client.query({
      command: queries.borrowerSearch,
      args: { parameters: { limit, offset, criteria, filters } },
      requestPolicy: 'network-only'
    });
  }

  public static locationSearch(searchText: string): CommandResultPromise<typeof queries.locationSearch> {
    return this.client.query({
      command: queries.locationSearch,
      args: { searchText }
    });
  }

  public static locationSearchByCoordinates(latitude: number, longitude: number): CommandResultPromise<typeof queries.locationSearchByCoordinates> {
    return this.client.query({
      command: queries.locationSearchByCoordinates,
      args: { latitude, longitude }
    });
  }

  public static updateProfileSearchLocation(
    profileType: ProfileType,
    searchText: string,
    latitude: number,
    longitude: number,
    searchDistance: SearchDistance,
    searchDirections: CompassBearing[],
    locationType: SearchLocationType,
    postcode?: string
  ): CommandResultPromise<typeof mutations.updateProfileSearchLocation> {
    return this.client.mutation({
      command: mutations.updateProfileSearchLocation,
      args: { profileType, searchText, latitude, longitude, searchDistance, searchDirections, locationType, postcode }
    });
  }

  public static resetProfileSearchLocation(profileType: ProfileType): CommandResultPromise<typeof mutations.resetProfileSearchLocation> {
    return this.client.mutation({
      command: mutations.resetProfileSearchLocation,
      args: { profileType }
    });
  }
}
