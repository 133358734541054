import React, { useContext, useState } from 'react';
import { faPaw, faShoePrints } from '@fortawesome/pro-solid-svg-icons';

import { DecisionStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { ProfileAPI } from '../api';
import { WebContext } from '../utils/AppContext';

export const SwitchPrompt: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const [inProgress, setInProgress] = useState(false);
  const [inError, setInError] = useState(false);

  async function handleActionClick(actionId: 'owner' | 'borrower'): Promise<void> {
    try {
      if (actionId === 'owner') {
        props.record({ switchPromptShown: false });
      } else {
        setInProgress(true);
        setInError(false);
        const response = await ProfileAPI.switchToBorrowerProfile();
        if (response.error !== undefined || response?.data?.switchToBorrowerProfile?.successful !== true) {
          setInError(true);
          setInProgress(false);
        } else {
          // Note that if this worked, we don't leave the in-progress state (as we're about to navigate away).
          if (props.persistenceAdapter) {
            props.persistenceAdapter.clear();
          }
          window.location.href = '/home';
        }
      }
    } catch {
      setInError(true);
      setInProgress(false);
    }
  }

  return (
    <DecisionStep
      stepTitle='Owner or borrower?'
      stepHeading='Which of these sounds like you?'
      totalSteps={props.totalStepCount}
      currentStep={1}
      buttons={[
        {
          id: 'owner',
          title: 'My dog would love extra walks and attention',
          buttonText: 'I’m an owner',
          buttonIcon: faPaw,
          illustrationURL: context.images['illustrations/img-person-with-dog.svg']
        },
        {
          id: 'borrower',
          title: 'I would love to spend time with a dog',
          buttonText: 'I’m a borrower',
          buttonIcon: faShoePrints,
          illustrationURL: context.images['illustrations/img-dog-with-person.svg']
        }
      ]}
      inProgress={inProgress}
      onDecisionClick={handleActionClick}
    >
      <StandardStepDescription
        text={inProgress ? 'We’re changing your account' : 'You’re currently signed up as an owner, but if you want to spend time with dogs that are not your own then choose “I’m a borrower”.'}
        inError={inError}
        errorText='Something went wrong, please try again. If it keeps happening please let us know using the contact details in the footer.'
      />
    </DecisionStep>
  );
};
