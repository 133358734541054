import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { AppleAuthButton, FacebookAuthButton, ProfileType } from '@borrowmydoggy/core-components';

interface IRegistrationAuthenticationButtonsProps {
  facebookEnabled: boolean;
  appleEnabled: boolean;
  profileType: ProfileType;
}

const ButtonContainer = styled.div`
  display: grid;
  gap: 20px;
  justify-content: center;
  form { padding: 0; }
  button { width: 300px; }
`;

export const RegistrationAuthenticationButtons: FunctionComponent<IRegistrationAuthenticationButtonsProps> = (
  props: IRegistrationAuthenticationButtonsProps
) => {
  function renderFacebookButton(): ReactNode {
    return (
      <form method='post' action={`/auth/facebook?profile_type=${props.profileType}`}>
        <input type='hidden' name='authenticity_token' />
        <FacebookAuthButton text='Continue with Facebook' wide />
      </form>
    );
  }

  function renderAppleButton(): ReactNode {
    return (
      <form method='post' action={`/auth/apple?profile_type=${props.profileType}`}>
        <input type='hidden' name='authenticity_token' />
        <AppleAuthButton text='Sign up with Apple' wide />
      </form>
    );
  }

  return (
    <ButtonContainer>
      {props.facebookEnabled && renderFacebookButton()}
      {props.appleEnabled && renderAppleButton()}
    </ButtonContainer>
  );
};
