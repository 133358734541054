import React, { ChangeEvent, FocusEvent, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { IValidationState, IValidator, stringMatchesPattern, validateAgainstCollection } from '@borrowmydoggy/bmd-validators';

export interface ITextAreaProps {
	name?: string;
	id?: string;
	className?: string;
	value: string;
	placeholder?: string;
	rows?: number;
	cols?: number;
	autoFocus?: boolean;
	maxLength?: number;
	showRemainingCharacterCount?: boolean;
	inputPattern?: string;
	changeValidators?: Array<IValidator<string>>;
	blurValidators?: Array<IValidator<string>>;
	onChange?: (value: string, validationState: IValidationState) => void;
	onBlur?: (validationState: IValidationState) => void;
}

const StyledTextArea = styled.div`
	&>textarea {
		box-sizing: border-box;
		border: 1px solid ${props => props.theme.neutral.xDark};
		border-radius: 5px;
		background-color: ${props => props.theme.neutral.xxLight};
		color: ${props => props.theme.secondary.mid};
		font-family: Lato, sans-serif;
		font-size: 16px;
		padding: 10px;
		margin: 0;

		// These revert weird global resets used in the website.
		width: unset;
		min-height: unset;
	}
	&>.remainingCharacters {
		display: block;
		text-align: right;
		font-family: Lato, sans-serif;
		font-size: 12px;
		color: ${props => props.theme.neutral.mid};
	}
`;

export const TextArea: FunctionComponent<ITextAreaProps> = (props: ITextAreaProps) => {
	function performValidation(value: string, validators: Array<IValidator<string>> | undefined): IValidationState {
    if (validators) {
      return validateAgainstCollection(value, validators);
    }
    return { status: 'pending' };
	}

	function handleChange(event: ChangeEvent<HTMLTextAreaElement>): void {
		const newValue = event.target.value;
		if ((props.inputPattern && stringMatchesPattern(newValue, props.inputPattern)) || !props.inputPattern) {
			if (props.onChange) {
				const validationState = performValidation(newValue, props.changeValidators);
				props.onChange(newValue, validationState);
			}
		}
	}

	function handleBlur(event: FocusEvent<HTMLTextAreaElement>): void {
		if (props.onBlur) {
			const validationState = performValidation(event.target.value, props.blurValidators);
			props.onBlur(validationState);
		}
	}

	function renderRemainingCharacterCount(maxLength: number | undefined, value: string): ReactNode {
		if (maxLength && maxLength > 0) {
			const remainingCharacters = maxLength - value.length;
			return <span className='remainingCharacters'>{remainingCharacters} {remainingCharacters === 1 ? 'character' : 'characters'} remaining</span>;
		}
	}

	return (
		<StyledTextArea className={props.className}>
			<textarea
				id={props.id}
				name={props.name}
				maxLength={props.maxLength}
				placeholder={props.placeholder}
				rows={props.rows}
				cols={props.cols}
				onChange={handleChange}
				onBlur={handleBlur}
				value={props.value}
				autoFocus={props.autoFocus}
			>
			</textarea>
			{props.showRemainingCharacterCount && renderRemainingCharacterCount(props.maxLength, props.value)}
		</StyledTextArea>
	);
};
