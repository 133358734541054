import { useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';

import { ApplePayButton, GooglePayButton, MicrosoftPayButton } from '@borrowmydoggy/product-components';

export interface IPaymentRequestButtonProps {
  country: string;
  currency: string;
  label: string;
  amountInSubUnits: number;
  onPaymentRequested?: (stripePaymentMethodId: string, event: PaymentRequestPaymentMethodEvent) => void;
}

type ButtonType = 'applePay' | 'msPay' | 'googlePay';

export const PaymentRequestButton: FunctionComponent<IPaymentRequestButtonProps> = (props: IPaymentRequestButtonProps) => {
  const [paymentRequestEnabled, setPaymentRequestEnabled] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [buttonType, setButtonType] = useState<ButtonType>();

  const stripe = useStripe();
  let paymentCheckerCancelled = false;

  function handlePaymentMethod(event: PaymentRequestPaymentMethodEvent): void {
    const paymentMethodId = event.paymentMethod.id;
    if (props.onPaymentRequested) {
      props.onPaymentRequested(paymentMethodId, event);
    }
  }

  async function establishPaymentRequestSupport(stripeApi: Stripe): Promise<void> {
    const potentialPaymentRequest = stripeApi.paymentRequest({
      country: props.country,
      currency: props.currency,
      total: {
        label: props.label,
        amount: props.amountInSubUnits
      },
      requestPayerName: true,
      requestPayerEmail: false
    });
    try {
      const canMakePayment = potentialPaymentRequest && await potentialPaymentRequest.canMakePayment();
      if (!paymentCheckerCancelled) {
        if (canMakePayment) {
          potentialPaymentRequest.on('paymentmethod', handlePaymentMethod);
          if (canMakePayment.applePay) {
            setButtonType('applePay');
          } else {
            const parser = new UAParser();
            const uaResult = parser.getResult();
            setButtonType(uaResult.browser.name === 'Edge' ? 'msPay' : 'googlePay');
          }
        }
        setPaymentRequestEnabled(canMakePayment !== undefined && canMakePayment !== null);
        setPaymentRequest(potentialPaymentRequest);
      }
    } catch (e) {
      console.error(e);
      setPaymentRequestEnabled(false);
    }
  }

  useEffect(() => {
    if (stripe) {
      establishPaymentRequestSupport(stripe);
      return () => {
        paymentCheckerCancelled = true;
      };
    }
  }, [stripe]);

  function handlePaymentRequestButtonClicked(): void {
    if (paymentRequest) {
      paymentRequest.show();
    }
  }

  function renderButton(): ReactElement {
    switch (buttonType) {
      case 'applePay':
        return (
          <a lang='en_GB' title='Start Apple Pay' className='new-button large primary icon apple-pay' onClick={handlePaymentRequestButtonClicked}></a>
        );
      case 'googlePay':
        return <a title='Start Google Pay' className='new-button large primary icon google-pay' onClick={handlePaymentRequestButtonClicked}></a>;
      case 'msPay':
        return <a title='Start Microsoft Pay' className='new-button large primary icon ms-pay' onClick={handlePaymentRequestButtonClicked}></a>;
    }
    return <></>;
  }

  return paymentRequestEnabled ? renderButton() : <></>;
};
