import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js';
import React, { Component, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import {
  CardActionForm,
  CardPaymentForm,
  HelpMessage,
  PaymentSuccessful,
  SelectPaymentMethod,
  VoucherRedemptionForm
} from '../payment';

export interface IPaymentFormProps {
	formattedPrice: string;
	formattedMonthlyPrice: string;
	formattedSubTotal: string;
	formattedDiscount?: string;
	eligibleForVoucherRedemption: boolean;
	country: string;
	currency: string;
	productDescription: string;
	amountInSubUnits: number;
	enablePaymentRequestButton: boolean;
	paymentSuccessful?: boolean;
	paypalPaymentError: boolean;
	profileType: string;
	stripePublishableKey: string;
	stripePurchaseRequestsPath: string;
	confirmationImageUrl: string;
	showModal?: boolean;
	modalText?: string;
	showForwardNav?: boolean;
	forwardPath?: string;
	verificationPath: string;
	voucherRedemptionPath: string;
	paypalRequestPath: string;
}

const DiscountBanner = styled.div`
  background-color: ${props => props.theme.negative.mid};
  padding: 10px;
  color: ${props => props.theme.base};
  font-size: 1.4rem;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

interface IPaymentFormState {
  paymentMethod?: PaymentMethodType;
  submissionErrorMessage: string;
  submissionInProgress: boolean;
  paymentSuccessful: boolean;
  paypalPaymentError: boolean;
  paymentRequiresAction: boolean;
  stripePaymentIntentClientSecret?: string;
  redeemedVoucherType?: string;
  redeemedVoucherProviderName?: string;
  showModal: boolean;
  modalShown: boolean;
  csrfToken: string;
}

type PaymentMethodType = 'card' | 'voucher';

type UPaymentData =
  | { subscription_stripe_purchase_request: { stripe_payment_method_id: string; }; }
  | { subscription_stripe_purchase_confirmation_request: { stripe_payment_intent_id: string; }; };

export class PaymentForm extends Component<IPaymentFormProps, IPaymentFormState> {
	private stripePromise: Promise<Stripe | null>;

	constructor(props: IPaymentFormProps) {
		super(props);

		let csrfToken = '';
		const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
		if (csrfTokenElement) {
			const attribute = csrfTokenElement.attributes.getNamedItem('content');
			if (attribute) {
				csrfToken = attribute.value;
			}
		}

		//this.stripe = (window as any).Stripe(props.stripePublishableKey);
		this.stripePromise = loadStripe(props.stripePublishableKey);

		this.state = {
			paymentMethod: undefined,
			submissionErrorMessage: '',
			submissionInProgress: false,
			paymentSuccessful: this.props.paymentSuccessful || false,
			paypalPaymentError: this.props.paypalPaymentError,
			paymentRequiresAction: false,
			stripePaymentIntentClientSecret: undefined,
			redeemedVoucherType: undefined,
			redeemedVoucherProviderName: undefined,
			showModal: false,
			modalShown: false,
			csrfToken
		};

		this.setPaymentMethod = this.setPaymentMethod.bind(this);
		this.setCardPayment = this.setCardPayment.bind(this);
		this.setVoucherPayment = this.setVoucherPayment.bind(this);
		this.clearPaymentMethod = this.clearPaymentMethod.bind(this);
		this.handleSuccessfulVoucherRedemption = this.handleSuccessfulVoucherRedemption.bind(this);

		this.handlePaymentSubmissionStart = this.handlePaymentSubmissionStart.bind(this);
		this.handlePaymentSubmissionFailure = this.handlePaymentSubmissionFailure.bind(this);
		this.handlePaymentSubmissionSuccess = this.handlePaymentSubmissionSuccess.bind(this);

		this.handlePaymentRequest = this.handlePaymentRequest.bind(this);

		this.handleCardActionSuccess = this.handleCardActionSuccess.bind(this);
		this.handleCardActionFailure = this.handleCardActionFailure.bind(this);

		this.handleForwardNavClick = this.handleForwardNavClick.bind(this);
		this.handleModalPremiumClick = this.handleModalPremiumClick.bind(this);
	}

	public componentDidMount(): void {
		switch (window.location.hash) {
			case '#voucher':
				this.setState({ paymentMethod: 'voucher' });
				break;
			case '#card':
				this.setState({ paymentMethod: 'card' });
				break;
			default:
				this.setState({ paymentMethod: undefined });
		}
	}

	public render(): ReactNode {
		return (
			<>
				{this.renderDiscountBanner()}
				{this.renderPaypalPaymentError()}
				<article>
					<nav className='breadcrumb'>
						{this.renderBackLink()}
						{this.renderForwardLink()}
					</nav>
					{this.renderBoxContent()}
					<HelpMessage />
				</article>
				{this.renderSkipButton()}
				{this.renderModal()}
			</>
		);
	}

	private setPaymentMethod(paymentMethod: 'card' | 'voucher' | undefined): void {
		this.setState({ paymentMethod, paypalPaymentError: false });
	}

	private setCardPayment(): void {
		this.setPaymentMethod('card');
	}

	private setVoucherPayment(): void {
		this.setPaymentMethod('voucher');
	}

	private clearPaymentMethod(): void {
		this.setPaymentMethod(undefined);
	}

	private handleSuccessfulVoucherRedemption(voucherType: string, voucherProviderName: string): void {
		this.setState({ paymentSuccessful: true, redeemedVoucherType: voucherType, redeemedVoucherProviderName: voucherProviderName });
	}

	private handlePaymentSubmissionStart(): void {
		this.setState({ submissionErrorMessage: '', submissionInProgress: true });
	}

	private handlePaymentSubmissionFailure(): void {
		this.setState({ submissionErrorMessage: '', submissionInProgress: false });
	}

	private async handlePaymentSubmissionSuccess(stripePaymentMethodId: string): Promise<boolean> {
		const data = {
			subscription_stripe_purchase_request: {
				stripe_payment_method_id: stripePaymentMethodId
			}
		};
		return this.submitPaymentAttempt(data, 'POST');
	}

	private async submitPaymentAttempt(data: UPaymentData, method: 'POST' | 'PUT'): Promise<boolean> {
		try {
			const response = await fetch(this.props.stripePurchaseRequestsPath, {
				method,
				credentials: 'include',
				headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken },
				body: JSON.stringify(data)
			});
			const json = await response.json();
			if (json.status && json.status === 'success') {
				this.setState({ paymentSuccessful: true, submissionErrorMessage: '', submissionInProgress: false, paymentRequiresAction: false });
				return true;
			} else if (json.status && json.status === 'requires_action') {
				this.setState({ submissionInProgress: false, paymentRequiresAction: true, stripePaymentIntentClientSecret: json.paymentIntentClientSecret });
				return false;
			} else {
				const errorMessage = json.errors || 'Sorry, there was a problem processing your new subscription.';
				this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
				return false;
			}
		} catch (error) {
			console.error(error);
			const errorMessage = 'Sorry, there was a problem processing your new subscription.';
			this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
			return false;
		}
	}

	private async handlePaymentRequest(stripePaymentMethodId: string, event: PaymentRequestPaymentMethodEvent): Promise<void> {
		const result = await this.handlePaymentSubmissionSuccess(stripePaymentMethodId);
		if (event) {
			event.complete(result ? 'success' : 'fail');
		}
	}

	private async handleCardActionSuccess(stripePaymentIntentId: string): Promise<void> {
		const data = {
			subscription_stripe_purchase_confirmation_request: {
				stripe_payment_intent_id: stripePaymentIntentId
			}
		};
		this.submitPaymentAttempt(data, 'PUT');
	}

	private async handleCardActionFailure(error: string): Promise<void> {
		const errorMessage = error || 'Sorry, there was a problem processing your new subscription.';
		this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
	}

	private handleForwardNavClick(event: MouseEvent<HTMLAnchorElement>): void {
		if (this.props.showModal && !this.state.modalShown) {
			event.preventDefault();
			this.setState({ showModal: true, modalShown: true });
		}
	}

	private handleModalPremiumClick(event: MouseEvent<HTMLAnchorElement>): void {
		event.preventDefault();
		this.setState({ showModal: false });
	}

	private renderProfileSpecificSkipButton(): ReactNode | undefined {
		switch (this.props.profileType) {
			case 'owner':
				return 'Find local people first';
			case 'borrower':
				return 'Find local dogs first';
			default:
				return 'Not right now';
		}
	}

	private renderSkipButton(): ReactNode | undefined {
		if (!this.state.paymentSuccessful && this.props.showForwardNav && this.state.paymentMethod == undefined) {
			return (
				<nav className='desktop tablet'>
					<a className='new-button wide soft upper-square' href={this.props.forwardPath} onClick={this.handleForwardNavClick}>
						{this.renderProfileSpecificSkipButton()} <i className='fas fa-chevron-right'></i>
					</a>
				</nav>
			);
		}
	}

	private renderModal(): ReactNode | undefined {
		if (this.state.showModal) {
			return (
				<div className='new-modal'>
					<div>
						<h2>Are you sure?</h2>
						<p>{this.props.modalText}</p>
						<div className='element-group space-above-medium'>
							<a href='#' className='new-button premium icon wide' onClick={this.handleModalPremiumClick}>
								<i className='bmd-i-crown-border'></i> Go Premium now
							</a>
							<a href={this.props.forwardPath} className='new-button primary wide'>
								Stay Basic for now
							</a>
						</div>
					</div>
				</div>
			);
		}
	}

	private renderBackLink(): ReactNode | undefined {
		if (!this.state.paymentSuccessful && this.state.paymentMethod !== undefined) {
			return (
				<div className='back-link'>
					<a onClick={this.clearPaymentMethod} className='new-link primary'>
						{' '}
						Change payment
					</a>
				</div>
			);
		}
	}

	private renderForwardLink(): ReactNode | undefined {
		if (!this.state.paymentSuccessful && this.props.showForwardNav) {
			return (
				<div className='forward-link mobile'>
					<a className='new-link primary' href={this.props.forwardPath} onClick={this.handleForwardNavClick}>
						{this.renderProfileSpecificSkipButton()}{' '}
					</a>
				</div>
			);
		}
	}

	private renderPaymentSuccessful(): ReactNode {
		let successfulTitle = 'Payment successful!';
		if (this.state.paymentMethod === 'voucher') {
			// Yes, this is hard-coded.
			successfulTitle =
				this.state.redeemedVoucherType === 'gift_voucher' ? 'Gift voucher redeemed!' : `${this.state.redeemedVoucherProviderName} voucher redeemed!`;
		}
		return (
			<PaymentSuccessful
				title={successfulTitle}
				button='Start safety checks'
				firstParagraph='Welcome to the pack! We’re so excited to have you here and we’re sure you just can’t wait to start messaging other members.'
				secondParagraph='Before you do, we just need to do some safety checks.'
				forwardPath={this.props.verificationPath}
			/>
		);
	}

	private renderDiscountBanner(): ReactNode {
		if (!this.state.paymentSuccessful && this.props.formattedDiscount) {
			return <DiscountBanner>Discount applied: {this.props.formattedDiscount} off!</DiscountBanner>;
		}
	}

	private renderPaypalPaymentError(): ReactNode | undefined {
		if (this.state.paypalPaymentError) {
			return (
				<div className='message-banner error'>
					<div className='icon'>
						<i className='fas fa-times'></i>
					</div>
					<article>
						<p>Unfortunately, there was a problem processing your PayPal payment.</p>
					</article>
				</div>
			);
		}
	}

	private renderSubForm(): ReactNode {
		if (this.state.paymentRequiresAction && this.state.stripePaymentIntentClientSecret) {
			return (
				<CardActionForm
					title='Go Premium now'
					paymentIntentClientSecret={this.state.stripePaymentIntentClientSecret}
					confirmationImageUrl={this.props.confirmationImageUrl}
					onActionSuccess={this.handleCardActionSuccess}
					onActionFailure={this.handleCardActionFailure}
				/>
			);
		} else if (this.state.paymentMethod === 'card') {
			return (
				<CardPaymentForm
					title='Go Premium now'
					buttonText={`Pay ${this.props.formattedPrice}`}
					disclaimerText='You can manage your payments at any time via your Account Settings. Our prices include VAT.'
					stripeErrorMessage='Unfortunately there was an error processing your payment. Please try again.'
					errorMessage={this.state.submissionErrorMessage}
					submissionInProgress={this.state.submissionInProgress}
					onSubmissionStart={this.handlePaymentSubmissionStart}
					onSubmissionFailure={this.handlePaymentSubmissionFailure}
					onSubmissionSuccess={this.handlePaymentSubmissionSuccess}
				/>
			);
		} else if (this.state.paymentMethod === 'voucher') {
			return (
				<VoucherRedemptionForm
					title='Go Premium now'
					voucherRedemptionPath={this.props.voucherRedemptionPath}
					csrfToken={this.state.csrfToken}
					onSuccessfulRedemption={this.handleSuccessfulVoucherRedemption}
				/>
			);
		} else {
			return (
				<SelectPaymentMethod
					formattedPrice={this.props.formattedPrice}
					formattedMonthlyPrice={this.props.formattedMonthlyPrice}
					formattedSubTotal={this.props.formattedSubTotal}
					formattedDiscount={this.props.formattedDiscount}
					title='Go Premium now'
					eligibleForVoucherRedemption={this.props.eligibleForVoucherRedemption}
					country={this.props.country}
					currency={this.props.currency}
					productDescription={this.props.productDescription}
					amountInSubUnits={this.props.amountInSubUnits}
					enablePaymentRequestButton={this.props.enablePaymentRequestButton}
					enablePaypal={true}
					paypalRequestPath={this.props.paypalRequestPath}
					onSetCardPayment={this.setCardPayment}
					onSetVoucherPayment={this.setVoucherPayment}
					onPaymentRequested={this.handlePaymentRequest}
				/>
			);
		}
	}

	private renderBoxContent(): ReactNode {
		if (this.state.paymentSuccessful) {
			return this.renderPaymentSuccessful();
		} else {
			return (
				<Elements
					stripe={this.stripePromise}
					options={{
						fonts: [
							{
								cssSrc: 'https://fonts.googleapis.com/css?family=Lato'
							}
						]
					}}
				>
					{this.renderSubForm()}
				</Elements>
			);
		}
	}
}
