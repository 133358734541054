import { IStepProcessOperationOptions } from '@borrowmydoggy/step-components';

import { internationalInterestDetails } from '../../schemas';
import { IUserAccountRegistrationManuscript } from '../../userRegistration';
import { RegistrationAPI } from '../../api';

export async function registerInternationalInterest(
  options: IStepProcessOperationOptions<IUserAccountRegistrationManuscript>
): Promise<Partial<IUserAccountRegistrationManuscript>> {
  const attemptsCount = options.manuscript.internationalInterestAttempts || 0;
  options.record({ internationalInterestStatus: 'inProgress' });
  try {
    const validatedDetails = internationalInterestDetails.parse({
      firstName: options.manuscript.firstName,
      surname: options.manuscript.surname,
      emailAddress: options.manuscript.emailAddress,
      city: options.manuscript.internationalInterestDetails?.city,
      region: options.manuscript.internationalInterestDetails?.region,
      countryIsoCode: options.manuscript.internationalInterestDetails?.countryIsoCode
    });
    const response = await RegistrationAPI.createInternationalInterest(
      validatedDetails.firstName,
      validatedDetails.surname,
      validatedDetails.emailAddress,
      validatedDetails.city,
      validatedDetails.region,
      validatedDetails.countryIsoCode
    );
    if (response.error === undefined && response.data && response.data.createInternationalInterest && response.data.createInternationalInterest.successful) {
      return { internationalInterestStatus: 'successful', internationalInterestAttempts: attemptsCount + 1 };
    } else {
      console.warn(response);
      return { internationalInterestStatus: 'error', internationalInterestAttempts: attemptsCount + 1 };
    }
  } catch (error) {
    console.warn(error);
    return { internationalInterestStatus: 'error', internationalInterestAttempts: attemptsCount + 1 };
  }
}
