import React from 'react';

import { ErrorStep, IStepComponentProps, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';

export const UserError: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {

  function handleTryAgainClick(): void {
    props.operations.createUserAccount();
  }

  return (
    <ErrorStep
      stepHeading='We couldn’t create your account, please try again'
      totalSteps={props.totalStepCount}
      currentStep={11}
      onTryAgainClick={handleTryAgainClick}
    />
  );
};
