import { CommandResultPromise } from '@borrowmydoggy/api-client';
import { Profile as ProfileType } from '@borrowmydoggy/bmd-api';

import { ClientBase } from '../ClientBase';
import { mutations } from './mutations';

export class MemberFeedbackAPI extends ClientBase {
  public static createMemberFeedback(
    recipientProfileType: ProfileType,
    recipientProfileUuid: string,
    positiveFeedback: boolean,
    reviewText: string,
    contactRequested: boolean
  ): CommandResultPromise<typeof mutations.createMemberFeedback> {
    return this.client.mutation({
      command: mutations.createMemberFeedback,
      args: { recipientProfileType, recipientProfileUuid, positiveFeedback, reviewText, contactRequested }
    });
  }

  public static updateMemberFeedbackApprovalState(
    memberFeedbackUuid: string,
    approved: boolean
  ): CommandResultPromise<typeof mutations.updateMemberFeedbackApprovalState> {
    return this.client.mutation({
      command: mutations.updateMemberFeedbackApprovalState,
      args: { memberFeedbackUuid, approved }
    });
  }
}
