import React, { useContext } from 'react';
import styled from 'styled-components';
import { faMars, faVenus } from '@fortawesome/pro-solid-svg-icons';

import { IconTextButton } from '@borrowmydoggy/core-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

const ButtonContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  gap: 15px;
`;

export const Owner3: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);

  function handleGirlClick(): void {
    props.record({ dogGender: 'female', dogGenderConfirmed: true });
  }

  function handleBoyClick(): void {
    props.record({ dogGender: 'male', dogGenderConfirmed: true });
  }

  return (
    <StepContainer
      stepTitle={`Tell us about ${firstDogName}`}
      totalSteps={props.totalStepCount}
      currentStep={3}
      stepImageUrl={context.images['illustrations/img-dog-sitting-upright.svg']}
    >
      <StepHeading text={`What gender is ${firstDogName}?`} />
      <StandardStepDescription text={`Is ${firstDogName} male or female?`} />
      <ButtonContainer>
        <IconTextButton
          text='She’s a girl'
          icon={faVenus}
          onClick={handleGirlClick}
        />
        <IconTextButton
          text='He’s a boy'
          icon={faMars}
          onClick={handleBoyClick}
        />
      </ButtonContainer>
    </StepContainer>
  );
};
