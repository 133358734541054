import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { CheckBoxGroupItem } from './CheckBoxGroupItem';

interface ICheckBoxGroupItem {
	id: string;
	label: string;
	assistanceText?: string;
	disabled?: boolean;
}

export interface ICheckBoxGroupProps {
	name: string;
	items: ICheckBoxGroupItem[];
	checked?: Record<string, boolean>;
	enforceOneChecked?: boolean;
	disabled?: boolean;
	className?: string;
	onChange?: (checked: Record<string, boolean>) => void;
}

const StyledCheckBoxGroup = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-gap: 20px;
	justify-content: start;
`;

export const CheckBoxGroup: FunctionComponent<ICheckBoxGroupProps> = (props: ICheckBoxGroupProps) => {
	function handleChange(id: string, checked: boolean): void {
		if (props.onChange) {
			const newCheckedState = { ...props.checked, [id]: checked };
			props.onChange(newCheckedState);
		}
	}

	function renderItems(
		items: ICheckBoxGroupItem[],
		enforceOneChecked: boolean,
		allDisabled: boolean,
		checked?: Record<string, boolean>
	): ReactNode[] {
		return items.map(item => {
			return (
				<CheckBoxGroupItem
					id={item.id}
					key={item.id}
					label={item.label}
					checked={checked ? checked[item.id] : undefined}
					disabled={item.disabled || allDisabled || (enforceOneChecked && checked && checked[item.id] && Object.values(checked).filter(c => c === true).length === 1)}
					assistanceText={item.assistanceText}
					onChange={handleChange}
				/>
			);
		});
	}

	return (
		<StyledCheckBoxGroup className={props.className}>
			{renderItems(props.items, props.enforceOneChecked || false, props.disabled || false, props.checked)}
		</StyledCheckBoxGroup>
	);
};

CheckBoxGroup.defaultProps = {
	enforceOneChecked: false,
	disabled: false
};
