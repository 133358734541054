import React, { ChangeEvent, FocusEvent, FunctionComponent, KeyboardEvent, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IPureDate, IValidationState, IValidator, PureDateValidator, stringMatchesPattern, validateAgainstCollection } from '@borrowmydoggy/bmd-validators';

export interface IDateFieldProps {
	name?: string;
	id?: string;
	className?: string;
	value?: IPureDate;
	validators?: Array<IValidator<IPureDate>>;
	onChange?: (value: IPureDate, validationState: IValidationState) => void;
}

const StyledDateField = styled.div`
	display: grid;
	align-items: start;
	justify-content: center;
	grid-template-columns: 40px 40px 64px;
	grid-gap: 5px;

	&>input[type=text] {
		box-sizing: border-box;
		border: 1px solid ${props => props.theme.neutral.xDark};
		border-radius: 5px;
		background-color: ${props => props.theme.neutral.xxLight};
		font-family: Lato, sans-serif;
		color: ${props => props.theme.secondary.mid};
		font-size: 16px;
		padding: 10px 5px;
		width: 100%;
		margin: 0;
		height: 42px;
	}
`;

const inputPattern = '^[0-9]*$';

export const DateField: FunctionComponent<IDateFieldProps> = (props: IDateFieldProps) => {
	const dayInputRef: RefObject<HTMLInputElement> = React.createRef();
	const monthInputRef: RefObject<HTMLInputElement> = React.createRef();
	const yearInputRef: RefObject<HTMLInputElement> = React.createRef();

	function padNumberIfRequired(value?: number): string {
		if (value == null || value === undefined) {
			return '';
		}
		const numberAsString = value.toString();
		if (numberAsString.length === 1 && numberAsString !== '0') {
			return `0${numberAsString}`;
		} else {
			return numberAsString;
		}
	}

	function performValidation(value: IPureDate, validators: Array<IValidator<IPureDate>>): IValidationState {
		return validateAgainstCollection(value, validators);
	}

	function raiseChange(day?: number, month?: number, year?: number): void {
		if (props.onChange) {
			const newValue = { day, month, year };
			const validators: IValidator<IPureDate>[] = [new PureDateValidator('Please enter a valid date')];
      if (props.validators) {
        validators.push(...props.validators);
      }
			const validationState = performValidation(newValue, validators);
			props.onChange(newValue, validationState);
		}
	}

	const [dayString, setDayString] = useState(props.value == null ? '' : padNumberIfRequired(props.value.day));
	const [monthString, setMonthString] = useState(props.value == null ? '' : padNumberIfRequired(props.value.month));
	const [yearString, setYearString] = useState(props.value == null || props.value.year == null ? '' : props.value.year.toString());

	useEffect(() => {
		// const noDateSpecified = props.value === undefined || props.value == null;
		// setDayString(noDateSpecified || props.value.day == null ? '' : padNumberIfRequired(props.value.day));
    let newDayString = '';
    if (props.value) {
      newDayString = padNumberIfRequired(props.value.day);
    }
    setDayString(newDayString);
	}, [props.value]);

	useEffect(() => {
		// const noDateSpecified = props.value === undefined || props.value == null;
		// setMonthString(noDateSpecified || props.value.month == null ? '' : padNumberIfRequired(props.value.month));
    let newMonthString = '';
    if (props.value) {
      newMonthString = padNumberIfRequired(props.value.month);
    }
    setMonthString(newMonthString);
	}, [props.value]);

	useEffect(() => {
		// const noDateSpecified = props.value === undefined || props.value == null;
		// setYearString(noDateSpecified || props.value.year == null ? '' : props.value.year.toString());
    let newYearString = '';
    if (props.value && props.value.year) {
      newYearString = props.value.year.toString();
    }
    setYearString(newYearString);
	}, [props.value]);


	function handleDayChange(event: ChangeEvent<HTMLInputElement>): void {
		const newDayString = event.target.value;
		if (stringMatchesPattern(newDayString, inputPattern)) {
			setDayString(newDayString);
			const existingDayStringLength = dayString.length;
			const newDayStringLength = newDayString.length;
			if (monthInputRef.current && existingDayStringLength === 1 && newDayStringLength === 2) {
				monthInputRef.current.focus();
				if (monthString.length > 0) {
					monthInputRef.current.select();
				}
			}
		}
	}

	function handleMonthChange(event: ChangeEvent<HTMLInputElement>): void {
		const newMonthString = event.target.value;
		if (stringMatchesPattern(newMonthString, inputPattern)) {
			setMonthString(newMonthString);
			const existingMonthStringLength = monthString.length;
			const newMonthStringLength = newMonthString.length;
			if (yearInputRef.current && existingMonthStringLength === 1 && newMonthStringLength === 2) {
				yearInputRef.current.focus();
				if (yearString.length > 0) {
					yearInputRef.current.select();
				}
			}
		}
	}

	function handleYearChange(event: ChangeEvent<HTMLInputElement>): void {
		const newYearString = event.target.value;
		if (stringMatchesPattern(newYearString, inputPattern)) {
			setYearString(newYearString);
		}
	}

	function handleDayBlur(event: FocusEvent<HTMLInputElement>): void {
		if (event.target.value.length === 1 && event.target.value !== '0') {
			const adjustedDay = `0${event.target.value}`;
			setDayString(adjustedDay);
		}
		const dayNumber = event.target.value.length === 0 ? undefined : parseInt(event.target.value, 10);
		raiseChange(dayNumber, props.value ? props.value.month : undefined, props.value ? props.value.year : undefined);
	}

	function handleMonthBlur(event: FocusEvent<HTMLInputElement>): void {
		if (event.target.value.length === 1 && event.target.value !== '0') {
			const adjustedMonth = `0${event.target.value}`;
			setMonthString(adjustedMonth);
		}
		const monthNumber = event.target.value.length === 0 ? undefined : parseInt(event.target.value, 10);
		raiseChange(props.value ? props.value.day : undefined, monthNumber, props.value ? props.value.year : undefined);
	}

	function handleYearBlur(event: FocusEvent<HTMLInputElement>): void {
		const yearNumber = event.target.value.length === 0 ? undefined : parseInt(event.target.value, 10);
		raiseChange(props.value ? props.value.day : undefined, props.value ? props.value.month : undefined, yearNumber);
	}

	function handleMonthKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
		if (dayInputRef.current && monthString.length === 0 && event.key === 'Backspace') {
			dayInputRef.current.focus();
			dayInputRef.current.setSelectionRange(3, 3); // Set cursor to end of field value (mainly for Firefox)
		}
	}

	function handleYearKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
		if (monthInputRef.current && yearString.length === 0 && event.key === 'Backspace') {
			monthInputRef.current.focus();
			monthInputRef.current.setSelectionRange(3, 3); // Set cursor to end of field value (mainly for Firefox)
		}
	}

	return (
		<StyledDateField className={props.className}>
			<input
				value={dayString}
				type='text'
				pattern='[0-9]*'
				inputMode='numeric'
				placeholder='DD'
				maxLength={2}
				name='day'
				onChange={handleDayChange}
				onBlur={handleDayBlur}
				ref={dayInputRef}
			/>
			<input
				value={monthString}
				type='text'
				pattern='[0-9]*'
				inputMode='numeric'
				placeholder='MM'
				maxLength={2}
				name='month'
				onChange={handleMonthChange}
				onBlur={handleMonthBlur}
				onKeyDown={handleMonthKeyDown}
				ref={monthInputRef}
			/>
			<input
				value={yearString}
				type='text'
				pattern='[0-9]*'
				inputMode='numeric'
				placeholder='YYYY'
				maxLength={4}
				name='year'
				onChange={handleYearChange}
				onBlur={handleYearBlur}
				onKeyDown={handleYearKeyDown}
				ref={yearInputRef}
			/>
		</StyledDateField>
	);
};

DateField.defaultProps = {
	validators: []
};
