import { IStepProcessOperationOptions } from '@borrowmydoggy/step-components';

import { IBorrowerProfileCreationManuscript } from '../../borrowerProfileCreation';
import { ProfileAPI } from '../../api';

import { borrowerProfileCreationDetails } from '../../schemas';

export async function createBorrowerProfile(
  { manuscript, persistenceAdapter, record }: IStepProcessOperationOptions<IBorrowerProfileCreationManuscript>
): Promise<Partial<IBorrowerProfileCreationManuscript>> {
  record({ borrowerProfileCreationStatus: 'inProgress' });
  try {
    const validatedDetails = borrowerProfileCreationDetails.parse(manuscript);
    const response = await ProfileAPI.createBorrowerProfile(validatedDetails);
    if (response.error === undefined && response.data && response.data.createBorrowerProfile) {
      switch (response.data.createBorrowerProfile.result) {
        case 'successful':
          if (persistenceAdapter) {
            // Note that this has to be done in this way, possibly due to browser security restrictions on localStorage.
            setTimeout(() => persistenceAdapter.clear(), 10);
          }
          if (response.data.createBorrowerProfile.currentSubscriptionProfileType === 'owner') {
            if (response.data.createBorrowerProfile.verified) {
              window.location.assign('/search/dogs');
            } else {
              window.location.assign('/verify');
            }
          } else {
            window.location.assign('/payments/new_payment?from=signup');
          }
          // As we're redirecting, we want to maintain the 'in progress' state rather than update the step shown.
          return {
            borrowerProfileCreationStatus: 'inProgress',
            currentSubscriptionProfileType: response.data.createBorrowerProfile.currentSubscriptionProfileType || undefined,
            verified: response.data.createBorrowerProfile.verified
          };
        case 'failedDueToDescription':
          return {
            borrowerProfileCreationStatus: 'failedDueToDescription',
            currentSubscriptionProfileType: response.data.createBorrowerProfile.currentSubscriptionProfileType || undefined,
            verified: response.data.createBorrowerProfile.verified
          };
        case 'failedUnknown':
        default:
          return {
            borrowerProfileCreationStatus: 'error',
            currentSubscriptionProfileType: response.data.createBorrowerProfile.currentSubscriptionProfileType || undefined,
            verified: response.data.createBorrowerProfile.verified
          };
      }
    } else {
      console.warn(response);
      return { borrowerProfileCreationStatus: 'error' };
    }
  } catch (error) {
    console.warn(error);
    return { borrowerProfileCreationStatus: 'error' };
  }
}
