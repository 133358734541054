import React from 'react';

import { DogBreed } from '@borrowmydoggy/core-models';
import { DogBreedList } from '@borrowmydoggy/profile-components';
import { IStepComponentProps, StepComponent, StepContainer, StepHeading } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogGender } from './parseDogGender';
import { parseDogNames } from './parseDogNames';

export const Owner4A: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);
  const pronoun = props.manuscript.dogGender ? parseDogGender(props.manuscript.dogGender).genderedPronoun : firstDogName;

  function handleBreedSelected(dogBreed: DogBreed): void {
    props.record({ dogBreedCode: dogBreed });
  }

  return (
    <StepContainer
      stepTitle={`Tell us about ${firstDogName}`}
      totalSteps={props.totalStepCount}
      currentStep={4}
    >
      <StepHeading text={`That’s pawsome, what breed is ${pronoun}?`} />
      <DogBreedList onItemSelected={handleBreedSelected} />
    </StepContainer>
  );
};
