import React, { MouseEvent, useContext, useState } from 'react';
import { z } from 'zod';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { CountryCodes, CountryCodesEnum } from '@borrowmydoggy/core-models';
import { Form, FormSubmissionResult, SelectField } from '@borrowmydoggy/form-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { ModalBinaryPanel } from '@borrowmydoggy/account-components';

import { InternationalCountryList } from './CountryList';
import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';
import { IInternationalInterestDetails } from '../userRegistration';

const countrySelectionSchema = z.object({ countryCode: z.enum(CountryCodes).optional() });
type CountrySelectionSchema = z.infer<typeof countrySelectionSchema>;

export const User9B: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  const [abortModalShown, setAbortModalShown] = useState(false);

  async function handleSubmit(data: CountrySelectionSchema): Promise<FormSubmissionResult<CountrySelectionSchema>> {
    await props.record({ internationalInterestDetails: {
      city: props.manuscript.internationalInterestDetails?.city || 'Unknown',
      region: props.manuscript.internationalInterestDetails?.region || 'Unknown',
      countryIsoCode: data.countryCode || 'US'
    } });
    const updatedManuscript = await props.operations.registerInternationalInterest();
    if (updatedManuscript.internationalInterestStatus === 'successful') {
      return { outcome: 'success', formStateAction: 'halt' };
    }
    return { outcome: 'error' };
  }

  function handleDeleteDetailsClick(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
    event.preventDefault();
    setAbortModalShown(true);
  }

  function handleAbortModalNoClick(): void {
    setAbortModalShown(false);
  }

  const details: IInternationalInterestDetails = props.manuscript.internationalInterestDetails || { city: 'Unknown', region: 'Unknown', countryIsoCode: 'US' };

  const CountrySelectField: typeof SelectField<'countryCode', keyof typeof CountryCodesEnum> = SelectField;

  return (
    <StepContainer
      stepTitle='Thanks for your interest'
      totalSteps={props.totalStepCount}
      currentStep={9}
      stepImageUrl={context.images['illustrations/img-dog-of-the-world.svg']}
    >
      <StepHeading text='Which country are you in?' />
      <StandardStepDescription
        text='Please choose which country we should notify you about.'
        inError={props.manuscript.internationalInterestStatus === 'error'}
      />
      <Form initialValues={{ countryCode: details.countryIsoCode }} schema={countrySelectionSchema} onSubmit={handleSubmit}>
        {({ fields, handleChange, handleValidate, formEnabled, submitButtonStatus }) => (
          <>
            <CountrySelectField
              id='countryCode'
              label='Country'
              value={fields.countryCode.value}
              validationState={fields.countryCode.validationState}
              options={InternationalCountryList}
              disabled={!formEnabled}
              onChange={handleChange}
              onValidate={handleValidate}
            />
            <StepNavigation
              backButton={{ text: 'Delete my details' }}
              nextButton={{ text: 'Save details', icon: faChevronRight, iconAlignment: 'right' }}
              inProgress={submitButtonStatus === 'inProgress'}
              onBackClick={handleDeleteDetailsClick}
            />
          </>
        )}
      </Form>
      {abortModalShown && <ModalBinaryPanel
        title='Are you sure?'
        description='This will cancel your BorrowMyDoggy account creation and delete all of the information we have for you.'
        yesHref='/'
        onNoClick={handleAbortModalNoClick}
      />}
    </StepContainer>
  );
};
