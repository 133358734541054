import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { Paragraph, SmallText } from '@borrowmydoggy/core-components';
import { InformationalStep, IStepComponentProps, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

export const Owner2B: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { quantityText, multipleDogs, compositeName } = parseDogNames(props.manuscript.dogNames || []);

  return (
    <InformationalStep
      stepTitle='All about your pooch'
      stepHeading='Is this correct?'
      totalSteps={props.totalStepCount}
      currentStep={2}
      stepImageUrl={context.images['illustrations/img-dog-blanket-flat.svg']}
      backButton={{
        text: 'Add or edit dogs'
      }}
      nextButton={{
        icon: faChevronRight,
        text: 'Yes, that’s right',
        iconAlignment: 'right'
      }}
      onBackClick={() => props.record({ dogNamesSubmitted: false })}
      onNextClick={() => props.record({ dogNamesConfirmed: true })}
    >
      <Paragraph>
        <SmallText>
          You&rsquo;d like borrowers for <strong>{quantityText}</strong>, who {multipleDogs ? 'are' : 'is'} called <strong>{compositeName}</strong>.
        </SmallText>
      </Paragraph>
    </InformationalStep>
  );
};
