import { gqlCommand } from '@borrowmydoggy/api-client';
import { Query, QueryEmailAddressAcceptabilityArgs } from '@borrowmydoggy/bmd-api';

export const queries = {
  emailAddressAcceptability: gqlCommand<{ emailAddressAcceptability: Query['emailAddressAcceptability']; }, QueryEmailAddressAcceptabilityArgs>`
    query emailAddressAcceptability($emailAddress: String!) {
      emailAddressAcceptability(emailAddress: $emailAddress) {
        acceptability
        suggestedEmailAddress
      }
    }
  `
};
