import { z } from 'zod';
import { BorrowerProfile } from '@borrowmydoggy/core-models';

export const borrowerProfileCreationDetails = z.object({
  experienceWithDogs: BorrowerProfile.ownedDog,
  childrenAtHome: BorrowerProfile.childrenAtHome,
  gardenOrOutsideSpace: BorrowerProfile.garden,
  offering: BorrowerProfile.offering,
  availability: BorrowerProfile.availability,
  description: BorrowerProfile.description,
  uploadedPhotoKey: z.string().optional(),
  photoMimeType: z.string().optional(),
  photoTaskId: z.string().optional(),
  previewImageDataUrl: z.string().optional()
});

export type BorrowerProfileCreationDetails = z.infer<typeof borrowerProfileCreationDetails>;
