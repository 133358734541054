import React, { useContext } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';

import { Form, FormSubmissionResult, PhoneNumberField } from '@borrowmydoggy/form-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { UserAccount } from '@borrowmydoggy/core-models';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

const phoneNumberSchema = z.object({ phoneNumber: UserAccount.phoneNumber });
type PhoneNumberSchema = z.infer<typeof phoneNumberSchema>;

export const User11C: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  async function handleSubmit(data: PhoneNumberSchema): Promise<FormSubmissionResult<PhoneNumberSchema>> {
    await props.record({ phoneNumber: data.phoneNumber, phoneNumberConfirmed: true, accountCreationStatus: 'pending' });
    props.operations.createUserAccount();
    return { outcome: 'success', formStateAction: 'halt' };
  }

  return (
    <StepContainer
      stepTitle='Some things to check'
      totalSteps={props.totalStepCount}
      currentStep={11}
      stepImageUrl={context.images['illustrations/img-person-holding-phone.svg']}
    >
      <StepHeading text='Please check your phone number'/>
      <StandardStepDescription text='Your phone number doesn’t look quite right - please check and amend it now.' />
      <Form
        initialValues={{ phoneNumber: props.manuscript.phoneNumber }}
        schema={phoneNumberSchema}
        initiallyExternallyInvalid={{ phoneNumber: 'Please correct your phone number' }}
        mode='editing'
        onSubmit={handleSubmit}
      >
        {({ fields, handleChange, handleValidate }) => (
          <>
            <PhoneNumberField
              id='phoneNumber'
              value={fields.phoneNumber.value}
              validationState={fields.phoneNumber.validationState}
              onValidate={handleValidate}
              onChange={handleChange}
            />
            <StepNavigation
              nextButton={{ text: 'Next', icon: faChevronRight, iconAlignment: 'right' }}
            />
          </>
        )}
      </Form>
    </StepContainer>
  );
};
