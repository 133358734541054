import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js';
import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';

import { CardActionForm, CardPaymentForm, HelpMessage, PaymentSuccessful, SelectPaymentMethod } from '../payment';

export interface IUpgradeFormProps {
  formattedPrice: string;
  formattedMonthlyPrice: string;
  formattedSubTotal: string;
  formattedDiscount?: string;
  country: string;
  currency: string;
  productDescription: string;
  amountInSubUnits: number;
  enablePaymentRequestButton: boolean;
  dogName: string;
  paymentSuccessful?: boolean;
  paypalPaymentError: boolean;
  stripePublishableKey: string;
  confirmationImageUrl: string;
  stripeUpgradeRequestsPath: string;
  paypalRequestPath: string;
  borrowersSearchPath: string;
}

const DiscountBanner = styled.div`
  background-color: ${props => props.theme.negative.mid};
  padding: 10px;
  color: ${props => props.theme.base};
  font-size: 1.4rem;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

interface IUpgradeFormState {
  paymentMethod?: PaymentMethodType;
  submissionErrorMessage: string;
  submissionInProgress: boolean;
  paymentSuccessful: boolean;
  paypalPaymentError: boolean;
  paymentRequiresAction: boolean;
  stripePaymentIntentClientSecret?: string;
  csrfToken: string;
}

type PaymentMethodType = 'card' | 'voucher';

type UPaymentData =
  | { subscription_stripe_upgrade_request: { stripe_payment_method_id: string; }; }
  | { subscription_stripe_upgrade_confirmation_request: { stripe_payment_intent_id: string; }; };

export class UpgradeForm extends Component<IUpgradeFormProps, IUpgradeFormState> {
  private stripePromise: Promise<Stripe | null>;

  constructor(props: IUpgradeFormProps) {
    super(props);

    let csrfToken = '';
    const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
    if (csrfTokenElement) {
      const attribute = csrfTokenElement.attributes.getNamedItem('content');
      if (attribute) {
        csrfToken = attribute.value;
      }
    }

    //this.stripe = (window as any).Stripe(props.stripePublishableKey);
    this.stripePromise = loadStripe(props.stripePublishableKey);

    this.state = {
      paymentMethod: undefined,
      submissionErrorMessage: '',
      submissionInProgress: false,
      paymentSuccessful: this.props.paymentSuccessful || false,
      paypalPaymentError: this.props.paypalPaymentError,
      paymentRequiresAction: false,
      stripePaymentIntentClientSecret: undefined,
      csrfToken
    };

    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.setCardPayment = this.setCardPayment.bind(this);
    this.clearPaymentMethod = this.clearPaymentMethod.bind(this);

    this.handlePaymentSubmissionStart = this.handlePaymentSubmissionStart.bind(this);
    this.handlePaymentSubmissionFailure = this.handlePaymentSubmissionFailure.bind(this);
    this.handlePaymentSubmissionSuccess = this.handlePaymentSubmissionSuccess.bind(this);

    this.handlePaymentRequest = this.handlePaymentRequest.bind(this);

    this.handleCardActionSuccess = this.handleCardActionSuccess.bind(this);
    this.handleCardActionFailure = this.handleCardActionFailure.bind(this);
  }

  public render(): ReactNode {
    return (
      <>
        {this.renderDiscountBanner()}
        {this.renderPaypalPaymentError()}
        <article>
          <nav className='breadcrumb'>
            {this.renderBackLink()}
          </nav>
          {this.renderBoxContent()}
          <HelpMessage />
        </article>
      </>
    );
  }

  private setPaymentMethod(paymentMethod: 'card' | undefined): void {
    this.setState({ paymentMethod, paypalPaymentError: false });
  }

  private setCardPayment(): void {
    this.setPaymentMethod('card');
  }

  private clearPaymentMethod(): void {
    this.setPaymentMethod(undefined);
  }

  private handlePaymentSubmissionStart(): void {
    this.setState({ submissionErrorMessage: '', submissionInProgress: true });
  }

  private handlePaymentSubmissionFailure(): void {
    this.setState({ submissionErrorMessage: '', submissionInProgress: false });
  }

  private async handlePaymentSubmissionSuccess(stripePaymentMethodId: string): Promise<boolean> {
    const data = {
      subscription_stripe_upgrade_request: {
        stripe_payment_method_id: stripePaymentMethodId
      }
    };
    return this.submitPaymentAttempt(data, 'POST');
  }

  private async submitPaymentAttempt(data: UPaymentData, method: 'POST' | 'PUT'): Promise<boolean> {
    try {
      const response = await fetch(
        this.props.stripeUpgradeRequestsPath,
        {
          method,
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.state.csrfToken },
          body: JSON.stringify(data)
        }
      );
      const json = await response.json();
      if (json.status && json.status === 'success') {
        this.setState({ paymentSuccessful: true, submissionErrorMessage: '', submissionInProgress: false, paymentRequiresAction: false });
        return true;
      } else if (json.status && json.status === 'requires_action') {
        this.setState({ submissionInProgress: false, paymentRequiresAction: true, stripePaymentIntentClientSecret: json.paymentIntentClientSecret });
        return false;
      } else {
        const errorMessage = json.errors || 'Sorry, there was a problem processing your upgrade.';
        this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
        return false;
      }
    } catch (error) {
      console.error(error);
      const errorMessage = 'Sorry, there was a problem processing your upgrade.';
      this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
      return false;
    }
  }

  private async handlePaymentRequest(stripePaymentMethodId: string, event: PaymentRequestPaymentMethodEvent): Promise<void> {
    const result = await this.handlePaymentSubmissionSuccess(stripePaymentMethodId);
    if (event) {
      event.complete(result ? 'success' : 'fail');
    }
  }

  private async handleCardActionSuccess(stripePaymentIntentId: string): Promise<void> {
    const data = {
      subscription_stripe_upgrade_confirmation_request: {
        stripe_payment_intent_id: stripePaymentIntentId
      }
    };
    this.submitPaymentAttempt(data, 'PUT');
  }

  private async handleCardActionFailure(error: string): Promise<void> {
    const errorMessage = error || 'Sorry, there was a problem processing your upgrade.';
    this.setState({ submissionErrorMessage: errorMessage, submissionInProgress: false, paymentRequiresAction: false });
  }

  private renderBackLink(): ReactNode | undefined {
    if (!this.state.paymentSuccessful && this.state.paymentMethod !== undefined) {
      return (
        <div className='back-link'>
          <a onClick={this.clearPaymentMethod} className='new-link primary'> Change payment</a>
        </div>
      );
    }
  }

  private renderPaymentSuccessful(): ReactNode {
    return (
      <PaymentSuccessful
        title='Upgrade successful!'
        button='Find a borrower'
        firstParagraph={`You can now start messaging borrowers to find some friends for ${this.props.dogName}!`}
        secondParagraph=''
        forwardPath={this.props.borrowersSearchPath}
      />
    );
  }

  private renderDiscountBanner(): ReactNode {
    if (!this.state.paymentSuccessful && this.props.formattedDiscount) {
      return (
        <DiscountBanner>
          Discount applied: {this.props.formattedDiscount} off!
        </DiscountBanner>
      );
    }
  }

  private renderPaypalPaymentError(): ReactNode | undefined {
    if (this.state.paypalPaymentError) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>Unfortunately, there was a problem processing your PayPal payment.</p>
          </article>
        </div>
      );
    }
  }

  private renderSubForm(): ReactNode {
    if (this.state.paymentRequiresAction && this.state.stripePaymentIntentClientSecret) {
      return (
        <CardActionForm
          title='Upgrade your membership'
          paymentIntentClientSecret={this.state.stripePaymentIntentClientSecret}
          confirmationImageUrl={this.props.confirmationImageUrl}
          onActionSuccess={this.handleCardActionSuccess}
          onActionFailure={this.handleCardActionFailure}
        />
      );
    } else if (this.state.paymentMethod === 'card') {
      return (
        <CardPaymentForm
          title='Upgrade your membership'
          buttonText={`Pay ${this.props.formattedPrice}`}
          disclaimerText='You can manage your payments at any time via your Account Settings. Our prices include VAT.'
          stripeErrorMessage='Unfortunately there was an error processing your payment. Please try again.'
          errorMessage={this.state.submissionErrorMessage}
          submissionInProgress={this.state.submissionInProgress}
          onSubmissionStart={this.handlePaymentSubmissionStart}
          onSubmissionFailure={this.handlePaymentSubmissionFailure}
          onSubmissionSuccess={this.handlePaymentSubmissionSuccess}
        />
      );
    } else {
      return (
        <SelectPaymentMethod
          formattedPrice={this.props.formattedPrice}
          formattedMonthlyPrice={this.props.formattedMonthlyPrice}
          formattedSubTotal={this.props.formattedSubTotal}
          formattedDiscount={this.props.formattedDiscount}
          title='Upgrade your membership'
          eligibleForVoucherRedemption={false}
          country={this.props.country}
          currency={this.props.currency}
          productDescription={this.props.productDescription}
          amountInSubUnits={this.props.amountInSubUnits}
          enablePaymentRequestButton={this.props.enablePaymentRequestButton}
          enablePaypal={true}
          paypalRequestPath={this.props.paypalRequestPath}
          onSetCardPayment={this.setCardPayment}
          onPaymentRequested={this.handlePaymentRequest}
        />
      );
    }
  }

  private renderBoxContent(): ReactNode {
    if (this.state.paymentSuccessful) {
      return this.renderPaymentSuccessful();
    } else {
      return (
        <Elements stripe={this.stripePromise} options={{
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css?family=Lato'
            }
          ]
        }}>
          {this.renderSubForm()}
        </Elements>
      );
    }
  }
}
