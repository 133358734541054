import { FeatureList, IFeatureListItemProps, StandardText } from '@borrowmydoggy/core-components';
import React from 'react';

export interface FeaturesProps {
	profileType: 'owner' | 'borrower';
}

export const Features = ({ profileType }: FeaturesProps) => {
	const crownIcon =
		'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGc+CjxwYXRoIGQ9Ik0yNy44ODQsMTIuMTdBMTQsMTQsMCwxLDAsMTIuMTcsMjcuODg0LDE0LjAyNywxNC4wMjcsMCwwLDAsMjcuODg0LDEyLjE3Wm0tNy41NDYsNy41MzdBNDIuMTM5LDQyLjEzOSwwLDAsMCwxNCwxOC43NzdhMzguNTM0LDM4LjUzNCwwLDAsMC02LjE1NS45M0w1LjcsOC4yOTNTNy44MDYsMTQsOS41ODgsMTQsMTQsOC4yOTMsMTQsOC4yOTNzMi42MTksNS43LDQuNDM4LDUuN1MyMi4zLDguMjkzLDIyLjMsOC4yOTNaIiBmaWxsPSIjRjJCODMwIi8+CjwvZz4KPC9zdmc+Cg==';

	const ownerFeatures = [
		{
			body: <StandardText>Covers our safety checks to help build trust in our community.</StandardText>,
			heading: 'Verified profile',
			iconDataUrl: crownIcon
		},
		{
			body: <StandardText>Access to around-the-clock veterinary professionals & insurance coverage whilst borrowing. T&Cs apply.</StandardText>,
			heading: 'Insurance & 24/7 support',
			iconDataUrl: crownIcon
		},
		{
			body: <StandardText>From occasional walks, regular care, or support on weekends and holidays, a trusted borrower can provide support.</StandardText>,
			heading: 'Arrangement for every need',
			iconDataUrl: crownIcon
		},
		{
			body: <StandardText>Borrowers join for a love of dogs and don’t charge owners when caring for their dog.</StandardText>,
			heading: 'It’s a win-win',
			iconDataUrl: crownIcon
		}
	];

	const borrowerFeatures = [
		{
			body: <StandardText>Covers our safety checks to help build trust in our community.</StandardText>,
			heading: 'Verified profile',
			iconDataUrl: crownIcon
		},
		{
			body: <StandardText>Access to around-the-clock veterinary professionals & insurance coverage whilst borrowing.</StandardText>,
			heading: 'Insurance & 24/7 support',
			iconDataUrl: crownIcon
		},
		{
			body: <StandardText>Pay once a year to arrange as many meet-ups as you like and find the right dog for you.</StandardText>,
			heading: 'Unlimited messages',
			iconDataUrl: crownIcon
		},
		{
			body: <StandardText>96% of borrowers get more exercise when they borrow a dog and feel less stressed.</StandardText>,
			heading: 'Improve your wellbeing',
			iconDataUrl: crownIcon
		}
	];

	const featureList = <FeatureList items={profileType === 'owner' ? ownerFeatures : borrowerFeatures} />;

	return <>{featureList}</>;
};
