import { gqlCommand } from '@borrowmydoggy/api-client';
import { Mutation, MutationCreateMemberFeedbackArgs, MutationUpdateMemberFeedbackApprovalStateArgs } from '@borrowmydoggy/bmd-api';

export const mutations = {
  createMemberFeedback: gqlCommand<{ createMemberFeedback: Mutation['createMemberFeedback']; }, MutationCreateMemberFeedbackArgs>`
    mutation CreateMemberFeedback(
      $recipientProfileType: Profile!,
      $recipientProfileUuid: String!,
      $positiveFeedback: Boolean!,
      $reviewText: String!,
      $contactRequested: Boolean!
    ) {
      createMemberFeedback(
        recipientProfileType: $recipientProfileType,
        recipientProfileUuid: $recipientProfileUuid,
        positiveFeedback: $positiveFeedback,
        reviewText: $reviewText,
        contactRequested: $contactRequested
      ) {
        result
      }
    }
  `,
  updateMemberFeedbackApprovalState: gqlCommand<
    { updateMemberFeedbackApprovalState: Mutation['updateMemberFeedbackApprovalState']; },
    MutationUpdateMemberFeedbackApprovalStateArgs
  >`
    mutation UpdateMemberFeedbackApprovalState($memberFeedbackUuid: String!, $approved: Boolean!) {
      updateMemberFeedbackApprovalState(memberFeedbackUuid: $memberFeedbackUuid, approved: $approved) {
        memberFeedbackStateUpdated
        errors
      }
    }
  `
};
