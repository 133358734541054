import React, { FunctionComponent, ReactNode } from 'react';
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import styled from 'styled-components';

import { PaymentRequestButton } from './PaymentRequestButton';

export interface ISelectPaymentMethodProps {
  title: string;
  formattedMonthlyPrice: string;
  formattedPrice: string;
  formattedSubTotal: string;
  formattedDiscount?: string;
  currency: string;
  country: string;
  productDescription: string;
  amountInSubUnits: number;
  paypalRequestPath: string;
  eligibleForVoucherRedemption: boolean;
  enablePaypal: boolean;
  enablePaymentRequestButton: boolean;
  onSetCardPayment?: () => void;
  onSetVoucherPayment?: () => void;
  onPaymentRequested?: (stripePaymentMethodId: string, event: PaymentRequestPaymentMethodEvent) => void;
}

const DiscountedPrice = styled.span`
  color: ${props => props.theme.negative.mid};
`;

const OriginalPrice = styled.span`
  color: ${props => props.theme.neutral.mid};
  text-decoration: line-through;
`;

export const SelectPaymentMethod: FunctionComponent<ISelectPaymentMethodProps> = (props: ISelectPaymentMethodProps) => {
  function handleCardPaymentChosen(): void {
    if (props.onSetCardPayment) {
      props.onSetCardPayment();
    }
  }

  function handleVoucherPaymentChosen(): void {
    if (props.onSetVoucherPayment) {
      props.onSetVoucherPayment();
    }
  }

  function renderPaypalButton(): ReactNode | undefined {
    if (props.enablePaypal) {
      return (
        <div className='element-group centred space-above-small-desktop space-above-medium-tablet space-above-medium-mobile'>
          <a className='new-button large paypal' href={props.paypalRequestPath}>Pay with PayPal</a>
        </div>
      );
    }
  }

  function renderPaymentRequestButton(): ReactNode | undefined {
    if (props.enablePaymentRequestButton) {
      return (
        <div className='element-group centred space-above-small-desktop space-above-medium-tablet space-above-medium-mobile'>
          <PaymentRequestButton
            country={props.country}
            currency={props.currency}
            label={props.productDescription}
            amountInSubUnits={props.amountInSubUnits}
            onPaymentRequested={props.onPaymentRequested}
          />
        </div>
      );
    }
  }

  function renderVoucherRedemptionLink(): ReactNode | undefined {
    if (props.eligibleForVoucherRedemption) {
      return (
        <div className='element-group'>
          <a className='new-link large icon primary block' href='#' onClick={handleVoucherPaymentChosen}><i className='fas fa-gift'></i> Use a voucher</a>
        </div>
      );
    }
  }

  function renderAnnualPrice(): ReactNode {
    if (props.formattedDiscount) {
      return (
        <>
          (
          <OriginalPrice>{props.formattedSubTotal}</OriginalPrice>{' '}
          <DiscountedPrice>{props.formattedPrice}</DiscountedPrice>{' '}
          billed annually)
        </>
      );
    }
    return `(${props.formattedPrice} billed annually)`;
  }

  function renderMonthlyFirstPayment(): ReactNode {
    return (
      <>
        <p className='highlight-text space-above-small'><strong>{props.formattedMonthlyPrice} / month</strong></p>
        <span className='highlight-heading'>{renderAnnualPrice()}</span>
      </>
    );
  }

  return (
    <div>
      <h1 className='page-title premium'>{props.title}</h1>
      {renderMonthlyFirstPayment()}
      <div className='element-group centred space-above-small'>
        <a href='#' className='new-button large icon primary' onClick={handleCardPaymentChosen}><i className='fas fa-credit-card'></i> Pay by card</a>
      </div>
      {renderPaypalButton()}
      {renderPaymentRequestButton()}
      <div className='element-group centred space-above-small'>
        {renderVoucherRedemptionLink()}
      </div>
    </div>
  );
};
