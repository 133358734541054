import { BrowserRouter } from 'react-router-dom';
import React, { FunctionComponent, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { BorrowMyDoggyTheme } from '@borrowmydoggy/core-components';

import { defaultContext as defaultProfileContext, ProfileContext } from '@borrowmydoggy/profile-components';
import { defaultContext as defaultSearchContext, SearchContext } from '@borrowmydoggy/search-components';

const defaultWebContext = {
  images: {
    'illustrations/img-birthday-person-with-dog.svg': '',
    'illustrations/img-child-with-dog-in-heart.svg': '',
    'illustrations/img-dog-blanket-flat.svg': '',
    'illustrations/img-dog-digging.svg': '',
    'illustrations/img-dog-digging-flat.svg': '',
    'illustrations/img-dog-gift-envelope.svg': '',
    'illustrations/img-dog-hiding-in-bushes.svg': '',
    'illustrations/img-dog-holding-envelope.svg': '',
    'illustrations/img-dog-licking-person.svg': '',
    'illustrations/img-dog-of-the-world.svg': '',
    'illustrations/img-dog-sitting-upright.svg': '',
    'illustrations/img-dog-sleeping-in-house.svg': '',
    'illustrations/img-dog-taking-selfie.svg': '',
    'illustrations/img-dog-waiting-at-door.svg': '',
    'illustrations/img-dog-wearing-cone.svg': '',
    'illustrations/img-dog-wearing-life-belt.svg': '',
    'illustrations/img-dog-wearing-party-hat.svg': '',
    'illustrations/img-dog-with-cat.svg': '',
    'illustrations/img-dog-with-clock.svg': '',
    'illustrations/img-dog-with-heart-in-speech-bubble.svg': '',
    'illustrations/img-dog-with-person.svg': '',
    'illustrations/img-dog-receiving-gift.svg': '',
    'illustrations/img-fluffy-white-dog.svg': '',
    'illustrations/img-high-five.svg': '',
    'illustrations/img-identity-cards.svg': '',
    'illustrations/img-person-holding-dog.svg': '',
    'illustrations/img-person-holding-envelope.svg': '',
    'illustrations/img-person-holding-leaflet.svg': '',
    'illustrations/img-person-holding-massive-pencil.svg': '',
    'illustrations/img-person-holding-papers.svg': '',
    'illustrations/img-person-holding-phone.svg': '',
    'illustrations/img-person-taking-selfie.svg': '',
    'illustrations/img-person-waving.svg': '',
    'illustrations/img-person-with-dog.svg': '',
    'illustrations/img-person-with-dog-and-heart.svg': '',
    'illustrations/img-person-with-dog-and-heart2.svg': '',
    'illustrations/img-person-with-facebook.svg': '',
    'illustrations/img-person-with-frame.svg': '',
    'illustrations/img-person-with-giant-phone.svg': '',
    'illustrations/img-person-with-iphone.svg': '',
    'illustrations/img-person-with-two-dogs.svg': '',
    'illustrations/img-two-dogs-with-bone.svg': '',
    'illustrations/img-two-people-with-dog.svg': '',
    'pages/mobile-app/apple-download.svg': '',
    'pages/mobile-app/google-download.svg': ''
  },
  mapkitJsToken: ''
};

export const WebContext = React.createContext<typeof defaultWebContext>(defaultWebContext);

export interface IAppContextProps {
  context: Record<string, unknown>;
  wrapWithRouter?: boolean;
  children?: ReactNode;
}

export const AppContext: FunctionComponent<IAppContextProps> = props => {
  const wrappedChildren = props.wrapWithRouter ? <BrowserRouter>{props.children}</BrowserRouter> : props.children;
  return (
    <ThemeProvider theme={BorrowMyDoggyTheme}>
      <ProfileContext.Provider value={{ ...defaultProfileContext, ...props.context }}>
        <SearchContext.Provider value={{ ...defaultSearchContext, ...props.context }}>
          <WebContext.Provider value={{ ...defaultWebContext, ...props.context }}>
            {wrappedChildren}
          </WebContext.Provider>
        </SearchContext.Provider>
      </ProfileContext.Provider>
    </ThemeProvider>
  );
};
