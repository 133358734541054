import { StepResolvers } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';

export type UserAccountRegistrationStep =
  | 'user3'
  | 'exp161'
  | 'user4a' // choose auth
  | 'user4b' // apple success
  | 'user4c' // facebook success
  | 'user5a'
  | 'user5b'
  | 'user6'
  | 'user7'
  | 'user8'
  | 'user9a'
  | 'user9b'
  | 'user9c'
  | 'user10'
  | 'user11a'
  | 'user11b'
  | 'user11c'
  | 'user11d'
  | 'userError'
  | 'exitPrompt';

export const resolvers: StepResolvers<IUserAccountRegistrationManuscript, UserAccountRegistrationStep> = [
  manuscript => manuscript.exitPromptShown ? 'exitPrompt' : 'skip',
  manuscript => manuscript.intendedProfileType ? 'skip' : 'user3',
  manuscript => {
    if (manuscript.authenticationProviderConfirmed || manuscript.exp161Variant === 'new') {
      return 'skip';
    }
    if (manuscript.desiredAuthenticationType === 'apple') {
      switch (manuscript.appleSignInStatus) {
        case 'successful':
          return 'user4b';
        case 'failed':
        default:
          return 'user4a';
      }
    }
    if (manuscript.desiredAuthenticationType === 'facebook') {
      switch (manuscript.facebookSignInStatus) {
        case 'successful':
          return 'user4c';
        case 'failed':
        default:
          return 'user4a';
      }
    }
    return 'user4a';
  },
  manuscript => {
    if (manuscript.exp161Variant === 'new') {
      if (manuscript.emailAddressCheckStatus === 'unacceptableDuplicate') {
        return 'user5b';
      } else if (manuscript.emailAddressCheckStatus === 'acceptable') {
        return 'skip';
      } else {
        return 'exp161';
      }
    }

    if (manuscript.emailAddress && manuscript.emailAddressCheckStatus === 'acceptable') {
      return 'skip';
    } else if (manuscript.emailAddressCheckStatus === 'unacceptableDuplicate') {
      return 'user5b';
    }
    return 'user5a';
  },
  manuscript => manuscript.desiredAuthenticationType !== 'password' || manuscript.authenticationProvider !== undefined ? 'skip' : 'user6',
  manuscript => manuscript.firstName && manuscript.surname && manuscript.nameConfirmed ? 'skip' : 'user7',
  manuscript => manuscript.phoneNumber && manuscript.phoneNumberConfirmed ? 'skip' : 'user8',
  manuscript => {
    if (manuscript.latitude && manuscript.longitude && manuscript.searchPhrase && manuscript.country) {
      return 'skip';
    } else if (manuscript.internationalInterestStatus === 'successful') {
        return 'user9c';
    } else if (manuscript.residentOfEligibleCountryConfirmed === false) {
      return 'user9b';
    }
    return 'user9a';
  },
  manuscript => {
    if (manuscript.attributionConfirmed) {
      return 'skip';
    } else {
      return 'user10';
    }
  },
  manuscript => {
    switch (manuscript.accountCreationStatus) {
      case 'pending':
      case 'inProgress':
        return 'user11a';
      case 'failedDueToFirstName':
        return 'user11b';
      case 'failedDueToPhoneNumber':
        return 'user11c';
      case 'successful':
        return 'user11d';
      case 'error':
      default:
        return 'userError'
    }
  }
];
