import React from 'react';

import { ProcessingStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';

export const Owner17A: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {

  return (
    <ProcessingStep
      totalSteps={props.totalStepCount}
      currentStep={17}
      displayCancelButton={false}
    >
      <StandardStepDescription text='We’re creating your profile' />
    </ProcessingStep>
  );
};
