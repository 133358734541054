import React, { useContext } from 'react';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

import { Paragraph, SmallText } from '@borrowmydoggy/core-components';
import { InformationalStep, IStepComponentProps, StepComponent } from '@borrowmydoggy/step-components';

import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { UserAccountRegistrationOperations } from '../operations';
import { WebContext } from '../utils/AppContext';

export const User9C: StepComponent<IUserAccountRegistrationManuscript> = (
  props: IStepComponentProps<IUserAccountRegistrationManuscript, UserAccountRegistrationOperations>
) => {
  const context = useContext(WebContext);

  return (
    <InformationalStep
      stepTitle='Thanks for your interest'
      stepHeading='We’ve got your details'
      totalSteps={props.totalStepCount}
      currentStep={9}
      stepImageUrl={context.images['illustrations/img-dog-of-the-world.svg']}
      nextButton={{ text: 'Go to home', icon: faHome, link: { href: '/' } }}
    >
      <Paragraph>
        <SmallText>
          We can&rsquo;t create an account for you right now, but we&rsquo;ll be in touch as soon as we&rsquo;re ready to launch near you.
        </SmallText>
      </Paragraph>
      <Paragraph>
        <SmallText>
          We&rsquo;ve deleted any information you&rsquo;ve given us that we don&rsquo;t need.
        </SmallText>
      </Paragraph>
    </InformationalStep>
  );
};
