import { StepResolvers } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';

export type OwnerProfileCreationStep =
  | 'owner2a'
  | 'owner2b'
  | 'owner3'
  | 'owner4a'
  | 'owner4b'
  | 'owner5a'
  | 'owner5b'
  | 'owner6'
  | 'owner7'
  | 'owner8'
  | 'owner9'
  | 'owner10'
  | 'owner11'
  | 'owner12a'
  | 'owner12b'
  | 'owner13'
  | 'owner14'
  | 'owner15'
  | 'owner17a'
  | 'owner17b'
  | 'owner17c'
  | 'ownerError'
  | 'exitPrompt'
  | 'switchPrompt';

export const resolvers: StepResolvers<IOwnerProfileCreationManuscript, OwnerProfileCreationStep> = [
  manuscript => manuscript.exitPromptShown ? 'exitPrompt' : 'skip',
  manuscript => manuscript.switchPromptShown ? 'switchPrompt' : 'skip',
  manuscript => {
    if (manuscript.dogNamesSubmitted) {
      if (manuscript.dogNamesConfirmed) {
        return 'skip';
      }
      return 'owner2b';
    }
    return 'owner2a';
  },
  manuscript => manuscript.dogGenderConfirmed ? 'skip' : 'owner3',
  manuscript => {
    if (manuscript.dogBreedCode) {
      return manuscript.dogBreedConfirmed ? 'skip' : 'owner4b';
    }
    return 'owner4a';
  },
  manuscript => {
    if (manuscript.dogDateOfBirth && manuscript.dogAgeConfirmed && manuscript.dogAgeRestrictionsAccepted) {
      return 'skip';
    }
    return manuscript.dogDateOfBirth && manuscript.dogAgeConfirmed ? 'owner5b' : 'owner5a';
  },
  manuscript => manuscript.goodWithChildrenConfirmed ? 'skip' : 'owner6',
  manuscript => manuscript.tolerantOfCatsConfirmed ? 'skip' : 'owner7',
  manuscript => manuscript.okayWithOtherDogsConfirmed ? 'skip' : 'owner8',
  manuscript => manuscript.hypoallergenicBreedConfirmed ? 'skip' : 'owner9',
  manuscript => manuscript.neuteredConfirmed ? 'skip' : 'owner10',
  manuscript => manuscript.rescueDogConfirmed ? 'skip' : 'owner11',
  manuscript => {
    if (manuscript.dogNameLimitationAccepted) {
      if (manuscript.dogNeedsConfirmed) {
        return 'skip';
      }
      return 'owner12b';
    }
    return 'owner12a';
  },
  manuscript => manuscript.availability !== undefined ? 'skip' : 'owner13',
  manuscript => manuscript.descriptionConfirmed ? 'skip' : 'owner14',
  manuscript => manuscript.photoConfirmed ? 'skip' : 'owner15',
  manuscript => {
    switch (manuscript.ownerProfileCreationStatus) {
      case 'pending':
      case 'inProgress':
        return 'owner17a';
      case 'successful':
        return 'skip';
      case 'failedDueToDescription':
        return 'owner17b';
      case 'failedDueToNames':
        return 'owner17c';
      case 'error':
      default:
        return 'ownerError';
    }
  }
];
