import { gqlCommand } from '@borrowmydoggy/api-client';
import {
  Mutation,
  MutationCreateInternationalInterestArgs,
  MutationCreateUserAccountArgs
} from '@borrowmydoggy/bmd-api';

export const mutations = {
  createInternationalInterest: gqlCommand<{ createInternationalInterest: Mutation['createInternationalInterest']; }, MutationCreateInternationalInterestArgs>`
    mutation createInternationalInterest($internationalInterest: InternationalInterestArgument!) {
      createInternationalInterest(internationalInterest: $internationalInterest) {
        successful
        errors
      }
    }
  `,
  createUserAccount: gqlCommand<{ createUserAccount: Mutation['createUserAccount']; }, MutationCreateUserAccountArgs>`
    mutation createUserAccount($userAccountDetails: UserAccountDetailsArgument!) {
      createUserAccount(userAccountDetails: $userAccountDetails) {
        result
        authenticationToken
      }
    }
  `
};
