import { Routes, Route } from 'react-router-dom';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { AvailabilitySelection, CompassBearing } from '@borrowmydoggy/product-components';
import { BorrowerProfileAttributeSelection } from '@borrowmydoggy/profile-components';

import { ProfileAPI } from '../api';
import { BorrowerProfile, IBorrowerLikeResult, IProfileFeedback, PageReloader, ReportType } from '../profile';

export interface IBorrowerProfileContainerProps {
  profileId: string;
  name: string;
  direction: CompassBearing;
  distanceInMiles: number;
  imageUrls: string[];
  liked: boolean;
  likeCount: number;
  premium: boolean;
  ambassador: boolean;
  description: string;
  profileCreatedAtUtc: string;
  activityLevel: string;
  company: boolean;
  exercise: boolean;
  acceptingMessages: boolean;
  conversationInProgress: boolean;
  picsRequested: boolean;
  currentUserPremiumOwner: boolean;
  availability: AvailabilitySelection;
  profileFeedback: IProfileFeedback[];
  attributes: BorrowerProfileAttributeSelection;
  showNavigationLinks?: boolean;
  premiumPath: string;
  conversationPath: string;
}

export const BorrowerProfileContainer: FunctionComponent<IBorrowerProfileContainerProps> = (props: IBorrowerProfileContainerProps) => {
  const [liked, setLiked] = useState(props.liked);
  const [likeCount, setLikeCount] = useState(props.likeCount);
  const [picsRequested, setPicsRequested] = useState(props.picsRequested);
  const [reportProfileOpen, setReportProfileOpen] = useState(false);
  const [focusedImageIndex, setFocusedImageIndex] = useState(0);

  function renderProfile(): ReactNode {
    return (
      <BorrowerProfile
        profileId={props.profileId}
        name={props.name}
        direction={props.direction}
        distanceInMiles={props.distanceInMiles}
        imageUrls={props.imageUrls}
        focusedImageIndex={focusedImageIndex}
        liked={liked}
        likeCount={likeCount}
        premium={props.premium}
        ambassador={props.ambassador}
        description={props.description}
        profileCreatedAtUtc={new Date(props.profileCreatedAtUtc)}
        activityLevel={props.activityLevel}
        company={props.company}
        exercise={props.exercise}
        acceptingMessages={props.acceptingMessages}
        conversationInProgress={props.conversationInProgress}
        conversationPath={props.conversationPath}
        currentUserPremiumOwner={props.currentUserPremiumOwner}
        availability={props.availability}
        profileFeedback={props.profileFeedback}
        attributes={props.attributes}
        picsRequested={picsRequested}
        showNavigationLinks={false}
        premiumPath={props.premiumPath}
        reportProfileOpen={reportProfileOpen}
        onProfileLiked={handleProfileLiked}
        onProfileUnliked={handleProfileUnliked}
        onPhotoRequested={handlePhotoRequested}
        onPhotoSelected={handlePhotoSelected}
        onReportProfileOpen={handleReportProfileOpen}
        onReportProfileClose={handleReportProfileClose}
        onProfileReported={handleProfileReported}
      />
    );
  }

  async function handleProfileLiked(): Promise<void> {
    const likeResult = await recordProfileLike(props.profileId);
    if (likeResult.successful) {
      setLiked(true);
      setLikeCount(likeResult.borrowerLikeCount);
    }
  }

  async function handleProfileUnliked(): Promise<void> {
    const unlikeResult = await recordProfileUnlike(props.profileId);
    if (unlikeResult.successful) {
      setLiked(false);
      setLikeCount(unlikeResult.borrowerLikeCount);
    }
  }

  async function handlePhotoRequested(): Promise<void> {
    const result = await requestPics(props.profileId);
    setPicsRequested(result);
  }

  function handlePhotoSelected(index: number): void {
    setFocusedImageIndex(index);
  }

  function handleReportProfileOpen(): void {
    setReportProfileOpen(true);
  }

  function handleReportProfileClose(): void {
    setReportProfileOpen(false);
  }

  async function handleProfileReported(reportType: ReportType, explanation?: string): Promise<void> {
    await reportProfile(props.profileId, reportType, explanation);
  }

  async function recordProfileLike(profileId: string): Promise<IBorrowerLikeResult> {
    const response = await ProfileAPI.createOwnerLike(profileId);
    if (response.error === undefined && response.data && response.data.createOwnerLike) {
      return { successful: response.data.createOwnerLike.liked, borrowerLikeCount: response.data.createOwnerLike.borrowerLikeCount };
    } else {
      console.warn('API failure when attempting to record profile like.', response.error)
      return { successful: false, borrowerLikeCount: 0 };
    }
  }

  async function recordProfileUnlike(profileId: string): Promise<IBorrowerLikeResult> {
    const response = await ProfileAPI.destroyOwnerLike(profileId);
    if (response.error === undefined && response.data && response.data.destroyOwnerLike) {
      return { successful: !response.data.destroyOwnerLike.liked, borrowerLikeCount: response.data.destroyOwnerLike.borrowerLikeCount };
    } else {
      console.warn('API failure when attempting to record profile unlike.', response.error)
      return { successful: false, borrowerLikeCount: 0 };
    }
  }

  async function requestPics(profileId: string): Promise<boolean> {
    const response = await ProfileAPI.createBorrowerPictureRequest(profileId);
    if (response.error === undefined && response.data && response.data.createBorrowerPictureRequest) {
      return response.data.createBorrowerPictureRequest.pictureRequested;
    } else {
      console.warn('API failure when attempting to request pictures.', response.error)
      return false;
    }
  }

  async function reportProfile(profileId: string, reportType: ReportType, explanation?: string): Promise<boolean> {
    const response = await ProfileAPI.createBorrowerProfileReport(profileId, reportType, explanation);
    if (response.error === undefined && response.data && response.data.createBorrowerProfileReport) {
      return response.data.createBorrowerProfileReport.profileReported;
    } else {
      console.warn('API failure when attempting to report profile.', response.error)
      return false;
    }
  }

  // PageReloader below is used to reload the search page from the server when navigating back through the browser history.
  return (
    <Routes>
      <Route path='/search/borrowers' element={<PageReloader />} />
      <Route path='/borrower_profile/:profileId' element={renderProfile()} />
    </Routes>
  );
};
