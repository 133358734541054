import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ToggleInput } from './ToggleInput';

export interface IToggleFieldProps {
	id: string;
	label: string;
	checked: boolean;
	onChange?: (checked: boolean) => void;
}

const StyledToggleField = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-gap: 10px;
	align-items: center;
	justify-content: start;

	&>label {
		font-family: Lato, sans-serif;
		font-size: 16px;
		color: ${props => props.theme.secondary.mid};
	}
`;

export const ToggleField: FunctionComponent<IToggleFieldProps> = (props: IToggleFieldProps) => {
	function handleChange(checked: boolean): void {
		if (props.onChange) {
			props.onChange(checked);
		}
	}

	return (
		<StyledToggleField>
			<label htmlFor={props.id}>{props.label}</label>
			<ToggleInput id={props.id} checked={props.checked} onChange={handleChange} />
		</StyledToggleField>
	);
};
