import { StepComponent } from '@borrowmydoggy/step-components';

import { ExitPrompt } from './ExitPrompt';
import { Exp161 } from './Exp161';
import { IUserAccountRegistrationManuscript } from './IUserAccountRegistrationManuscript';
import { User10 } from './User10';
import { User11A } from './User11A';
import { User11B } from './User11B';
import { User11C } from './User11C';
import { User11D } from './User11D';
import { User3 } from './User3';
import { User4A } from './User4A';
import { User4B } from './User4B';
import { User4C } from './User4C';
import { User5A } from './User5A';
import { User5B } from './User5B';
import { User6 } from './User6';
import { User7 } from './User7';
import { User8 } from './User8';
import { User9A } from './User9A';
import { User9B } from './User9B';
import { User9C } from './User9C';
import { UserAccountRegistrationStep } from './UserAccountRegistrationConductor';
import { UserError } from './UserError';

export * from './IInternationalInterestDetails';
export * from './IUserAccountRegistrationManuscript';
export * from './UserAccountRegistrationConductor';

export const UserAccountRegistrationStepMap: Record<UserAccountRegistrationStep, StepComponent<IUserAccountRegistrationManuscript>> = {
  user3: User3,
  user4a: User4A,
  user4b: User4B,
  user4c: User4C,
  user5a: User5A,
  user5b: User5B,
  user6: User6,
  user7: User7,
  user8: User8,
  user9a: User9A,
  user9b: User9B,
  user9c: User9C,
  user10: User10,
  user11a: User11A,
  user11b: User11B,
  user11c: User11C,
  user11d: User11D,
  userError: UserError,
  exitPrompt: ExitPrompt,
  exp161: Exp161,
};
