import React from 'react';
import classNames from 'classnames';

export type LinkLooksLike = 'anchor' | 'button';

export interface ILinkProps {
	looksLike: LinkLooksLike;
	href: string;
	children: React.ReactNode;
	className?: string;
}

export function Link({ looksLike, href, className, children }: ILinkProps): JSX.Element {
	switch (looksLike) {
		case 'anchor':
			return (
				<a
					href={href}
					className={classNames('text-[1.75rem] font-lato text-emerald-500 cursor-pointer hover:text-emerald-400 active:text-emerald-600', className)}
				>
					{children}
				</a>
			);
		case 'button':
			return (
				<a
					href={href}
					className={classNames(
						'font-lato text-white shadow text-center px-5 bg-emerald-500 py-3 rounded-lg cursor-pointer duration-500 transition-all hover:bg-emerald-400 active:bg-emerald-600',
						className
					)}
				>
					{children}
				</a>
			);
		default:
			return <></>;
	}
}

export default Link;
