import React, { ChangeEvent, FunctionComponent, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { Icon } from '@borrowmydoggy/core-components';

export interface ICheckBoxProps {
	id: string;
	checked: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
}

interface ICheckBoxStyleProps {
	checked: boolean;
	disabled: boolean;
}

const StyledCheckBox = styled.span<ICheckBoxStyleProps>`
	display: inline-block;
	box-sizing: border-box;
	width: 18px;
	height: 18px;
	border: 2px solid ${props => props.theme.neutral.xDark};
	border-radius: 2px;
	background-color: ${props => props.disabled ? props.theme.secondary.light : (props.checked ? props.theme.primary.mid : props.theme.neutral.xLight)};
	position: relative;

	input {
		visibility: hidden;
	}

	.icon {
		color: ${props => props.theme.base};
		display: block;
		box-sizing: border-box;
		position: absolute;
		padding: 0px;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export const CheckBox: FunctionComponent<ICheckBoxProps> = (props: ICheckBoxProps) => {
	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		if (props.onChange && !props.disabled) {
			props.onChange(event.target.checked);
		}
	}

	function handleClick(event: MouseEvent<HTMLSpanElement>): void {
		event.preventDefault();
		event.stopPropagation();
		if (props.onChange && !props.disabled) {
			props.onChange(!props.checked);
		}
	}

	function renderCheck(): ReactNode {
		return <Icon definition={faCheck} className='icon' />;
	}

	return (
		<StyledCheckBox checked={props.checked} disabled={props.disabled || false} onClick={handleClick}>
			<input type='checkbox' checked={props.checked} id={props.id} disabled={props.disabled} onChange={handleChange} />
			{props.checked && renderCheck()}
		</StyledCheckBox>
	);
};
