import React, { Component, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { Form, NumericField } from '../forms';

export interface IConfirmPhoneVerificationCodeProps {
  telephoneNumber: string;
  failedToSendVerificationCode: boolean;
  errorMessage: string;
  resendEnabled: boolean;
  verificationCodeResent: boolean;
  csrfToken: string;
  phoneNumberVerificationPath: string;
  onChangeNumberClicked?: (event: MouseEvent<HTMLAnchorElement>) => void;
  onResendCodeClicked?: (event: MouseEvent<HTMLAnchorElement>) => void;
  onCancelClicked?: (event: MouseEvent<HTMLAnchorElement>) => void;
  onPhoneNumberVerified?: () => void;
  onPhoneNumberVerificationFailed?: (error: string) => void;
}

interface IConfirmPhoneVerificationCodeState {
  verificationCode?: number;
}

const StyledField = styled.div`
  align-items: center;
`;

export class ConfirmPhoneVerificationCode extends Component<IConfirmPhoneVerificationCodeProps, IConfirmPhoneVerificationCodeState> {
  constructor(props: IConfirmPhoneVerificationCodeProps) {
    super(props);

    this.state = { verificationCode: undefined };

    this.handleCodeChange = this.handleCodeChange.bind(this);

    this.handleVerificationCodeSubmission = this.handleVerificationCodeSubmission.bind(this);

    this.handleChangeNumberClick = this.handleChangeNumberClick.bind(this);
    this.handleResendCodeClick = this.handleResendCodeClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  public render(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>Enter your code</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>We&rsquo;ve sent a text message with a verification code to <strong>{this.props.telephoneNumber}</strong></p>
        </div>
        <div className='element-group centred'>
          <p className='clarification'><a onClick={this.handleChangeNumberClick} href='#' className='new-link primary'>change number</a></p>
        </div>
        {this.props.failedToSendVerificationCode ? this.renderFailureMessage() : this.renderForm()}
      </>
    );
  }

  private handleCodeChange(value: number | undefined): void {
    this.setState({ verificationCode: value });
  }

  private async handleVerificationCodeSubmission(): Promise<void> {
    const data = { verification_phone_number_verification_request: { sms_code: this.state.verificationCode ? this.state.verificationCode.toString() : '' } };

    try {
      const response = await fetch(
        this.props.phoneNumberVerificationPath,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken },
          body: JSON.stringify(data)
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.status && json.status === 'success') {
          if (this.props.onPhoneNumberVerified) {
            this.props.onPhoneNumberVerified();
          }
        } else {
          if (this.props.onPhoneNumberVerificationFailed) {
            this.props.onPhoneNumberVerificationFailed('That code doesn’t look right. Please check it and try again.');
          }
        }
      } else {
        if (this.props.onPhoneNumberVerificationFailed) {
          this.props.onPhoneNumberVerificationFailed('We didn’t get that, please try the "next" button again.');
        }
      }
    } catch (error) {
      console.error(error);
      if (this.props.onPhoneNumberVerificationFailed) {
        this.props.onPhoneNumberVerificationFailed('We didn’t get that, please try the "next" button again.');
      }
    }
  }

  private handleChangeNumberClick(event: MouseEvent<HTMLAnchorElement>): void {
    if (this.props.onChangeNumberClicked) {
      this.props.onChangeNumberClicked(event);
    }
  }

  private handleResendCodeClick(event: MouseEvent<HTMLAnchorElement>): void {
    if (this.props.onResendCodeClicked) {
      this.props.onResendCodeClicked(event);
    }
  }

  private handleCancelClick(event: MouseEvent<HTMLAnchorElement>): void {
    if (this.props.onCancelClicked) {
      this.props.onCancelClicked(event);
    }
  }

  private renderErrorMessage(): ReactNode | undefined {
    if (this.props.errorMessage.length > 0) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{this.props.errorMessage}</p>
          </article>
        </div>
      );
    }
  }

  private renderResendLink(): ReactNode {
    if (this.props.resendEnabled) {
      return <p className='clarification'><a onClick={this.handleResendCodeClick} href='#' className='new-link primary'>Resend</a></p>;
    } else if (this.props.verificationCodeResent) {
      return <p className='clarification'>New code sent</p>;
    } else {
      return <p className='clarification'>&nbsp;</p>;
    }
  }

  private renderFailureMessage(): ReactNode {
    return (
      <div className='message-banner error'>
        <div className='icon'>
          <i className='fas fa-times'></i>
        </div>
        <article>
          <p>Looks like the code didn&rsquo;t send properly - <a onClick={this.handleResendCodeClick} href='#' className='new-link primary'>Resend</a></p>
        </article>
      </div>
    );
  }

  private renderForm(): ReactNode {
    return (
      <>
        {this.renderErrorMessage()}
        <div className='element-group'>
          <Form className='new-form space-above-medium' onSubmit={this.handleVerificationCodeSubmission}>
            <fieldset>
              <legend>Verification Code</legend>
              <StyledField className='element-group centred'>
                <label htmlFor='code'>Enter code:</label>
                <NumericField
                  className='half-width'
                  value={this.state.verificationCode}
                  id='code'
                  name='code'
                  maxLength={5}
                  placeholder='Code'
                  autoComplete='off'
                  onChange={this.handleCodeChange}
                />
              </StyledField>
              {this.renderResendLink()}
              <div className='action space-above-medium'>
                <button
                  type='submit'
                  className='new-button primary wide'
                  disabled={this.state.verificationCode === undefined || this.state.verificationCode < 10000}
                >
                  Next
                </button>
                <p className='clarification space-above-small'><a onClick={this.handleCancelClick} href='#' className='new-link primary'>Cancel</a></p>
              </div>
            </fieldset>
          </Form>
        </div>
      </>
    );
  }
}
