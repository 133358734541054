import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { countries } from '@borrowmydoggy/form-components';

export interface ICountrySelectFieldProps {
	name?: string;
	id?: string;
	className?: string;
	fullNumber: string;
	countryIsoCode: string;
	callingCodeFilter?: string;
	onChange?: (value: string) => void;
}

const StyledCountrySelectField = styled.div`
	&>select {
		box-sizing: border-box;
		grid-area: input;
		border: 1px solid ${props => props.theme.neutral.xDark};
		border-radius: 5px;
		background-color: ${props => props.theme.neutral.xxLight};
		font-family: Lato, sans-serif;
		color: ${props => props.theme.secondary.mid};
		font-size: 16px;
		padding: 10px 5px;
		width: 100%;
		margin: 0;
		height: 42px;
	}
`;

export const CountrySelectField: FunctionComponent<ICountrySelectFieldProps> = (props: ICountrySelectFieldProps) => {
	function handleChange(event: ChangeEvent<HTMLSelectElement>): void {
		const newValue = event.target.value;
		if (props.onChange) {
			props.onChange(newValue);
		}
	}

	function renderOptions(fullNumber: string, callingCodeFilter?: string): ReactNode[] {
		let cleanNumber = fullNumber.replace(/[^0-9]/g, '');
		if (cleanNumber.startsWith('00')) {
			cleanNumber = cleanNumber.slice(2);
		}
		const filteredCountries = callingCodeFilter ? countries.filter(country => {
			return country.callingCode.startsWith(callingCodeFilter) && cleanNumber.startsWith(country.callingCode.slice(0, cleanNumber.length));
		}) : countries;
		return filteredCountries.map(country => {
			return <option value={country.isoCode} key={country.isoCode}>{country.description}</option>;
		});
	}

	return (
		<StyledCountrySelectField className={props.className}>
			<select
				id={props.id}
				name={props.name}
				value={props.countryIsoCode}
				onChange={handleChange}
			>
				{renderOptions(props.fullNumber, props.callingCodeFilter)}
			</select>
		</StyledCountrySelectField>
	);
};
