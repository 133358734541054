import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { QuestionProcessBanner } from '@borrowmydoggy/product-components';

import { RecommendationAPI } from '../api';

export interface IRecommendationBannerProps {
  senderName: string;
  recommendationBody: string;
  recommendationCode: string;
}

const StyledParagraph = styled.p`
  font-size: 1.6rem;
`;

export const RecommendationBanner: FunctionComponent<IRecommendationBannerProps> = (props: IRecommendationBannerProps) => {
  async function updateRecommendationApprovalState(
    recommendationCode: string,
    approved: boolean
  ): Promise<boolean> {
    const response = await RecommendationAPI.updateRecommendationApprovalState(recommendationCode, approved);
    if (response.error === undefined && response.data && response.data.updateRecommendationApprovalState) {
      if (response.data.updateRecommendationApprovalState.recommendationStateUpdated) {
        return true;
      } else {
        console.warn(response.data.updateRecommendationApprovalState.errors);
        return false;
      }
    } else {
      console.warn('API failure when attempting to update recommendation approval state.', response.error)
      return false;
    }
  }

  async function handleYesChosen(): Promise<boolean> {
    return await updateRecommendationApprovalState(props.recommendationCode, true);
  }

  async function handleNoChosen(): Promise<boolean> {
    return await updateRecommendationApprovalState(props.recommendationCode, false);
  }

  const initialContent = (
    <>
      <StyledParagraph>You have a new recommendation! Your friend, <strong>{props.senderName}</strong> says:</StyledParagraph>
      <StyledParagraph><strong>&ldquo;{props.recommendationBody}&rdquo;</strong></StyledParagraph>
      <StyledParagraph>Would you like this to show on your profile?</StyledParagraph>
    </>
  );
  const completePositiveContent = (
    <StyledParagraph>Great, that recommendation will now appear on your profile!</StyledParagraph>
  );
  const completeNegativeContent = (
    <StyledParagraph>OK, we won&rsquo;t show it on your profile.</StyledParagraph>
  );

  return (
    <QuestionProcessBanner
      initialContent={initialContent}
      completePositiveContent={completePositiveContent}
      completeNegativeContent={completeNegativeContent}
      onYesChosen={handleYesChosen}
      onNoChosen={handleNoChosen}
    />
  );
};
