import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';
import { WebContext } from '../utils/AppContext';

export const Owner11: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName, multipleDogs } = parseDogNames(props.manuscript.dogNames || []);
  const pronoun = props.manuscript.dogGender ? parseDogGender(props.manuscript.dogGender).genderedPronoun : firstDogName;
  const puppyAvoidanceTechnique = props.manuscript.dogGender === 'female' ? 'spayed' : 'neutered';

  return (
    <BinaryStep
      stepTitle={`Tell us about ${firstDogName}`}
      stepHeading={`Is ${firstDogName} a rescue dog?`}
      totalSteps={props.totalStepCount}
      currentStep={11}
      stepImageUrl={context.images['illustrations/img-dog-wearing-life-belt.svg']}
      backButton={{
        text: 'Back'
      }}
      onBinaryButtonClick={polarity => props.record({ rescueDog: polarity, rescueDogConfirmed: true, dogNameLimitationAccepted: !multipleDogs })}
      onBackClick={() => props.record({ neuteredConfirmed: false })}
    >
      <StandardStepDescription text={`Is ${pronoun} from a rescue home or adoption centre rather than a breeder?`} />
    </BinaryStep>
  );
};
