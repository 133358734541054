import React, { Component, ErrorInfo, ReactNode } from 'react';

import { ErrorHandlingClass } from './ErrorHandlingClass';
import { MajorErrorFallback } from './MajorErrorFallback';
import { MinorErrorFallback } from './MinorErrorFallback';

interface IDevelopmentErrorBoundaryProps {
  errorHandlingClass: ErrorHandlingClass;
  children?: ReactNode;
}

interface IDevelopmentErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  info?: ErrorInfo;
}

export class DevelopmentErrorBoundary extends Component<IDevelopmentErrorBoundaryProps, IDevelopmentErrorBoundaryState> {
  public static getDerivedStateFromError(error: Error): IDevelopmentErrorBoundaryState {
    return { hasError: true, error };
  }

  public constructor(props: IDevelopmentErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined, info: undefined };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log error here.
    console.error('Error in React component tree', error, errorInfo);
    this.setState({ info: errorInfo });
  }

  public render(): ReactNode {
    if (this.state.hasError && this.state.error && this.state.info) {
      const ErrorFallback = this.props.errorHandlingClass === 'minor' ? MinorErrorFallback : MajorErrorFallback;
      return <ErrorFallback error={this.state.error} info={this.state.info} />;
    } else {
      return this.props.children;
    }
  }
}
