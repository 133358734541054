import React, { ChangeEvent, ReactNode, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { Area, cropImage, IImageDetails, ImageCropper, loadImageFile } from '@borrowmydoggy/product-components';
import { IStepComponentProps, StandardStepDescription, StepComponent, StepContainer, StepHeading, StepNavigation } from '@borrowmydoggy/step-components';
import { LoadingIndicator, responsiveQuery } from '@borrowmydoggy/core-components';
import { PhotoSelectionButton } from '@borrowmydoggy/profile-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { WebContext } from '../utils/AppContext';

const ContentContainer = styled.div`
  padding-bottom: 30px;
  display: grid;
  position: relative;
`;

const StyledPhotoSelectionButton = styled(PhotoSelectionButton)`
  margin: auto;
`;

const LoadingContainer = styled.div`
  height: 500px;
  display: grid;
  justify-content: center;
  align-content: center;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 80px;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const CroppedImageContainer = styled.div`
  background-color: ${props => props.theme.neutral.xLight};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-content: start;

  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait', 'mobileLandscape')} {
    padding-top: 50px;
  }

  ${responsiveQuery('mobilePortrait')} {
    padding-top: 38px;
  }
`;

const CroppedImage = styled.img`
  ${responsiveQuery('desktop', 'tabletLandscape', 'tabletPortrait', 'mobileLandscape')} {
    width: 400px;
    height: 400px;
  }
  ${responsiveQuery('mobilePortrait')} {
    width: 225px;
    height: 225px;
  }
`;

const StyledStepHeading = styled(StepHeading)`
  &:focus {
    border: none;
    outline: none;
  }
`;

type ProfileImageHandlingStatus =
  | 'pending'
  | 'loading'
  | 'loadError'
  | 'cropPending'
  | 'rejectedTooSmall'
  | 'rejectedTooLarge'
  | 'cropProcessing'
  | 'cropFailed'
  | 'uploading'
  | 'cropTooLarge'
  | 'uploadRequestFailed'
  | 'uploadDataFailed'
  | 'uploadError';

const minImageSize = 600;
const maxImageSize = 10000;
const maxFileSize = 50*1024*1024;

export const Owner15: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const [stepStatus, setStepStatus] = useState<ProfileImageHandlingStatus>('pending');
  const [image, setImage] = useState<IImageDetails>();
  const [cropArea, setCropArea] = useState<Area>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const stepHeadingRef = useRef<HTMLHeadingElement>(null);

  function focusStepHeading(): void {
    if (stepHeadingRef.current) {
      stepHeadingRef.current.focus();
    }
  }

  const { multipleDogs } = parseDogNames(props.manuscript.dogNames || []);

  function handlePhotoSelectionClick(): void {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  async function handleFileInputChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    if (event.target.files && event.target.files.length > 0) {
      setStepStatus('loading');
      props.record({ photoFileName: event.target.files[0].name, photoMimeType: event.target.files[0].type });
      const imageLoadResult = await loadImageFile(event.target.files[0], {
        minWidth: minImageSize,
        maxWidth: maxImageSize,
        minHeight: minImageSize,
        maxHeight: maxImageSize,
        maxSizeInBytes: maxFileSize
      });
      if (imageLoadResult.loadResult === 'success') {
        setImage(imageLoadResult.details);
        setStepStatus('cropPending');
        focusStepHeading();
      } else {
        switch (imageLoadResult.reason) {
          case 'widthTooSmall':
          case 'heightTooSmall':
            setStepStatus('rejectedTooSmall');
            break;
          case 'widthTooLarge':
          case 'heightTooLarge':
          case 'fileSizeTooLarge':
            setStepStatus('rejectedTooLarge');
            break;
          default:
            setStepStatus('loadError');
        }
      }
    }
  }

  function handleCropAreaChange(newCropArea: Area): void {
    setCropArea(newCropArea);
  }

  async function handleCropClick(): Promise<void> {
    setStepStatus('cropProcessing');
    if (image && cropArea) {
      try {
        const croppedImage = await cropImage(image.imageUrl, cropArea, maxFileSize);
        const tinyImage = await cropImage(image.imageUrl, cropArea, 2048, 32);
        const newCroppedImageUrl = URL.createObjectURL(croppedImage);
        setCroppedImageUrl(newCroppedImageUrl);
        setStepStatus('uploading');
        const result = await props.operations.createOwnerProfilePhoto(croppedImage, tinyImage);
        switch (result.ownerProfilePhotoStatus) {
          case 'successful':
            props.operations.createOwnerProfile();
            break;
          case 'requestFailed':
            setStepStatus('uploadRequestFailed');
            break;
          case 'uploadFailed':
            setStepStatus('uploadDataFailed');
            break;
          case 'error':
          default:
            setStepStatus('uploadError');
        }
      } catch (error) {
        if (error instanceof Error) {
          console.warn(error);
          setStepStatus('cropFailed');
        } else if (typeof error === 'string') {
          switch (error) {
            case 'tooLarge':
              setStepStatus('cropTooLarge');
              break;
            default:
              setStepStatus('cropFailed');
          }
        }
      }
    } else {
      setStepStatus('cropFailed');
    }
  }

  async function handleSkipClick(): Promise<void> {
    await props.record({ photoConfirmed: true, permissionToUse: false, ownerProfileCreationStatus: 'pending' });
    props.operations.createOwnerProfile();
  }

  function handleChangeDescription(): void {
    props.record({ descriptionConfirmed: false });
  }

  function handleChangePhotoClick(): void {
    setStepStatus('pending');
    setImage(undefined);
    setCropArea(undefined);
    setCroppedImageUrl(undefined);
  }

  function renderInitialStepContent(): ReactNode {
    return (
      <>
        <StyledPhotoSelectionButton
          backgroundImageUrl={context.images['illustrations/img-dog-taking-selfie.svg']}
          onClick={handlePhotoSelectionClick}
        />
        <HiddenFileInput type='file' accept='image/*' ref={fileInputRef} onChange={handleFileInputChange} />
      </>
    );
  }

  function renderHeading(): ReactNode {
    switch (stepStatus) {
      case 'cropPending':
      case 'cropProcessing':
      case 'cropFailed':
      case 'cropTooLarge':
      case 'uploading':
      case 'uploadRequestFailed':
      case 'uploadDataFailed':
      case 'uploadError':
        return <StyledStepHeading text='Adjust your photo' tabIndex={-1} ref={stepHeadingRef} />;
      case 'pending':
      default:
        return <StyledStepHeading text={`Show us your pawsome ${multipleDogs ? 'pups' : 'pup'}!`} />;
    }
  }

  function renderDescription(): ReactNode {
    const initialDescription =
      `Profiles with photos are 15 times more likely to get likes and messages. Help borrowers to imagine enjoying spending time with your ${multipleDogs ? 'dogs' : 'dog'}.`;
    switch (stepStatus) {
      case 'cropPending':
      case 'cropProcessing':
      case 'uploading':
        return (
          <StandardStepDescription
            text='Drag to reposition'
          />
        );
      case 'cropFailed':
        return (
          <StandardStepDescription
            text='Drag to reposition'
            inError
            errorText='We couldn’t crop your photo, please try again'
          />
        );
      case 'cropTooLarge':
        return (
          <StandardStepDescription
            text='Drag to reposition'
            inError
            errorText='Your photo is too large, please choose a smaller image'
          />
        );
      case 'uploadRequestFailed':
        return (
          <StandardStepDescription
            text='Drag to reposition'
            inError
            errorText='We couldn’t start the photo upload process, please try again'
          />
        );
      case 'uploadDataFailed':
        return (
          <StandardStepDescription
            text='Drag to reposition'
            inError
            errorText='We couldn’t upload your photo, please try again'
          />
        );
      case 'uploadError':
        return (
          <StandardStepDescription
            text='Drag to reposition'
            inError
          />
        );
      case 'rejectedTooSmall':
        return (
          <StandardStepDescription
            text={initialDescription}
            inError
            errorText='Your photo is too small, please choose a photo that is at least 600 x 600 pixels'
          />
        );
      case 'rejectedTooLarge':
        return (
          <StandardStepDescription
            text={initialDescription}
            inError
            errorText='Your photo is too large, please choose a photo that is under 50mb and smaller than 10,000 x 10,000 pixels'
          />
        );
      case 'pending':
      case 'loading':
      default:
        return (
          <StandardStepDescription
            text={initialDescription}
          />
        );
    }
  }

  function renderStepContent(): ReactNode {
    if (image === undefined) {
      return renderInitialStepContent();
    }

    switch (stepStatus) {
      case 'loading':
        return <LoadingContainer><StyledLoadingIndicator /></LoadingContainer>;
      case 'cropPending':
      case 'cropProcessing':
      case 'cropFailed':
      case 'cropTooLarge':
      case 'uploading':
      case 'uploadRequestFailed':
      case 'uploadDataFailed':
      case 'uploadError':
        return (
          <>
            <ImageCropper
              image={image}
              minCropSize={600}
              onCropAreaChange={handleCropAreaChange}
            />
            {stepStatus === 'uploading' && <CroppedImageContainer><CroppedImage src={croppedImageUrl} /></CroppedImageContainer>}
          </>
        );
      case 'pending':
      default:
        return renderInitialStepContent();
    }
  }

  function renderNavigation(): ReactNode {
    switch (stepStatus) {
      case 'cropPending':
      case 'cropProcessing':
      case 'cropFailed':
      case 'cropTooLarge':
      case 'uploading':
      case 'uploadRequestFailed':
      case 'uploadDataFailed':
      case 'uploadError':
        return (
          <StepNavigation
            nextButton={{ text: 'Next', icon: faChevronRight, iconAlignment: 'right' }}
            backButton={{ text: 'Change photo' }}
            inProgress={stepStatus === 'cropProcessing' || stepStatus === 'uploading'}
            onNextClick={handleCropClick}
            onBackClick={handleChangePhotoClick}
          />
        );
      case 'pending':
      default:
        return (
          <StepNavigation
            nextButton={{ text: 'Skip' }}
            backButton={{ text: 'Edit description' }}
            onNextClick={handleSkipClick}
            onBackClick={handleChangeDescription}
          />
        );
    }
  }

  return (
    <StepContainer
      stepTitle='Add your cutest photo'
      totalSteps={props.totalStepCount}
      currentStep={15}
      stepPanelWidth='wide'
    >
      {renderHeading()}
      {renderDescription()}
      <ContentContainer>
        {renderStepContent()}
      </ContentContainer>
      {renderNavigation()}
    </StepContainer>
  );
};
