import React, { Component, ReactNode } from 'react';

import styled from 'styled-components';

import { Form, TextField } from '../forms';
import { ContactDetailsDetectionValidator, IValidationState, LengthValidator, PresenceValidator } from '@borrowmydoggy/bmd-validators';

export interface IConfirmNameProps {
  forename: string;
  surname: string;
  csrfToken: string;
  nameConfirmationPath: string;
  onForenameChange?: (forename: string) => void;
  onSurnameChange?: (surname: string) => void;
  onNameConfirmed?: () => void;
}

interface IConfirmNameState {
  forenameValid: boolean;
  surnameValid: boolean;
  errorMessage: string;
  forenameMessage: string;
  surnameMessage: string;
}

const StyledTextField = styled(TextField)`
  &.invalid>input {
    border-color: var(--toy-red);
    background-color: var(--toy-red-light);
  }
`;

export class ConfirmName extends Component<IConfirmNameProps, IConfirmNameState> {
  constructor(props: IConfirmNameProps) {
    super(props);

    this.state = { forenameValid: true, surnameValid: true, errorMessage: '', forenameMessage: '', surnameMessage: '' };

    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleForenameChange = this.handleForenameChange.bind(this);
    this.handleSurnameChange = this.handleSurnameChange.bind(this);
    this.handleForenameBlur = this.handleForenameBlur.bind(this);
    this.handleSurnameBlur = this.handleSurnameBlur.bind(this);
  }

  public render(): ReactNode {
    return (
      <>
        <h2 className='section-title space-above-medium'>What is your full legal name?</h2>
        <div className='element-group centred space-above-small'>
          <p className='clarification'>
            We check your name and address against national databases so please ensure that this is the name that we will find for you.
          </p>
        </div>
        <Form className='new-form' onSubmit={this.handleFormSubmission}>
          <fieldset>
            <legend>Your Legal Name</legend>
            <div className='field'>
              <label htmlFor='forename'>Legal first name</label>
              <StyledTextField
                value={this.props.forename}
                id='forename'
                name='forename'
                maxLength={50}
                autoComplete='given-name'
                inputPattern='^[^0-9@]*$'
                blurValidators={[
                  new PresenceValidator('Please tell us your first name'),
                  new LengthValidator("We can't accept first names that long, is there a shorter version that you use?", { max: 50 }),
                  new ContactDetailsDetectionValidator("We can't accept contact information here")
                ]}
                className={this.state.forenameValid ? '' : 'invalid'}
                onChange={this.handleForenameChange}
                onBlur={this.handleForenameBlur}
              />
              <span className='feedback'>{this.state.forenameMessage}</span>
            </div>
            <div className='field'>
              <label htmlFor='surname'>Legal last name</label>
              <StyledTextField
                value={this.props.surname}
                id='surname'
                name='surname'
                maxLength={50}
                autoComplete='family-name'
                inputPattern='^[^0-9@]*$'
                blurValidators={[
                  new PresenceValidator('Please tell us your last name'),
                  new LengthValidator("We can't accept last names that long, is there a shorter version that you use?", { max: 50 }),
                  new ContactDetailsDetectionValidator("We can't accept contact information here")
                ]}
                className={this.state.surnameValid ? '' : 'invalid'}
                onChange={this.handleSurnameChange}
                onBlur={this.handleSurnameBlur}
              />
              <span className='feedback'>{this.state.surnameMessage}</span>
            </div>
            {this.renderErrorMessage()}
            <div className='action'>
              <button type='submit' className='new-button primary wide'>Next</button>
            </div>
          </fieldset>
        </Form>
      </>
    );
  }

  private handleFormSubmission(): void {
    if (this.state.forenameValid && this.state.surnameValid) {
      const data = { verification_name_change_request: { firstname: this.props.forename, lastname: this.props.surname } };

      const requestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.props.csrfToken },
        body: JSON.stringify(data)
      };
      fetch(this.props.nameConfirmationPath, requestOptions).then(response => {
        return response.json();
      }).then(json => {
        if (json.status && json.status === 'success') {
          if (this.props.onNameConfirmed) {
            this.props.onNameConfirmed();
          }
        } else {
          const errorMessage = json.errors || 'Sorry, there was a problem processing your name details.';
          this.setState({ errorMessage });
        }
      }).catch(error => {
        console.error(error);
        const errorMessage = 'Sorry, there was a problem processing your name details.';
        this.setState({ errorMessage });
      });
    } else {
      console.log('the form was invalid');
    }
  }

  private handleForenameChange(value: string): void {
    if (this.props.onForenameChange) {
      this.props.onForenameChange(value);
    }
  }

  private handleSurnameChange(value: string): void {
    if (this.props.onSurnameChange) {
      this.props.onSurnameChange(value);
    }
  }

  private handleForenameBlur(validationState: IValidationState): void {
    this.setState({ forenameValid: validationState.status === 'valid', forenameMessage: validationState.message || '' });
  }

  private handleSurnameBlur(validationState: IValidationState): void {
    this.setState({ surnameValid: validationState.status === 'valid', surnameMessage: validationState.message || '' });
  }

  private renderErrorMessage(): ReactNode | undefined {
    if (this.state.errorMessage.length > 0) {
      return (
        <div className='message-banner error'>
          <div className='icon'>
            <i className='fas fa-times'></i>
          </div>
          <article>
            <p>{this.state.errorMessage}</p>
          </article>
        </div>
      );
    }
  }
}
