import React, { ChangeEvent, FocusEvent, FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IValidationState, IValidator, stringMatchesPattern, validateAgainstCollection } from '@borrowmydoggy/bmd-validators';

export interface INumericFieldProps {
	name?: string;
	id?: string;
	className?: string;
	value?: number;
	placeholder?: string;
	maxLength?: number;
	autoComplete?: string;
	changeValidators?: Array<IValidator<number>>;
	blurValidators?: Array<IValidator<number>>;
	onChange?: (value: number | undefined, validationState: IValidationState) => void;
	onBlur?: (validationState: IValidationState) => void;
}

const StyledNumericField = styled.div`
	&>input[type=text] {
		box-sizing: border-box;
		grid-area: input;
		border: 1px solid ${props => props.theme.neutral.xDark};
		border-radius: 5px;
		background-color: ${props => props.theme.neutral.xxLight};
		font-family: Lato, sans-serif;
		color: ${props => props.theme.secondary.mid};
		font-size: 16px;
		padding: 10px 5px;
		width: 100%;
		margin: 0;
		height: 42px;
	}
`;

const inputPattern = '^[0-9]*$';

export const NumericField: FunctionComponent<INumericFieldProps> = (props: INumericFieldProps) => {
	const [valueAsString, setValueAsString] = useState(props.value === undefined ? '' : props.value.toString());

	function performValidation(value: number | undefined, validators: Array<IValidator<number>> | undefined): IValidationState {
    if (validators) {
      return validateAgainstCollection(value, validators);
    }
    return { status: 'pending' };
	}

	function valueAsInt(value: string): number | undefined {
		return value.length === 0 ? undefined : parseInt(value, 10);
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		const newValue = event.target.value;
		if (stringMatchesPattern(newValue, inputPattern)) {
			setValueAsString(event.target.value);
			if (props.onChange) {
				const numericValue = valueAsInt(newValue);
				const validationState = performValidation(numericValue, props.changeValidators);
				props.onChange(numericValue, validationState);
			}
		}
	}

	function handleBlur(event: FocusEvent<HTMLInputElement>): void {
		if (props.onBlur) {
			const numericValue = valueAsInt(event.target.value);
			const validationState = performValidation(numericValue, props.blurValidators);
			props.onBlur(validationState);
		}
	}

	return (
		<StyledNumericField className={props.className}>
			<input
				id={props.id}
				name={props.name}
				type='text'
				pattern='[0-9]*'
				inputMode='numeric'
				value={valueAsString}
				placeholder={props.placeholder}
				maxLength={props.maxLength}
				autoComplete={props.autoComplete}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		</StyledNumericField>
	);
};
