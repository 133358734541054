import React from 'react';

import { ErrorStep, IStepComponentProps, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';

export const OwnerError: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  async function handleTryAgainClick(): Promise<void> {
    await props.record({ ownerProfileCreationStatus: 'pending' });
    props.operations.createOwnerProfile();
  }

  return (
    <ErrorStep
      stepHeading='We couldn’t create your profile, please try again'
      totalSteps={props.totalStepCount}
      currentStep={17}
      onTryAgainClick={handleTryAgainClick}
    />
  );
};
