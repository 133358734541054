import { gqlCommand } from '@borrowmydoggy/api-client';
import { Mutation, MutationCreateEmailReferralsArgs } from '@borrowmydoggy/bmd-api';

export const mutations = {
  createEmailReferrals: gqlCommand<{ createEmailReferrals: Mutation['createEmailReferrals']; }, MutationCreateEmailReferralsArgs>`
    mutation CreateEmailReferrals($emailAddresses: [String!]!) {
      createEmailReferrals(emailAddresses: $emailAddresses) {
        successful
        emailedAddresses
        addressesWithErrors
      }
    }
  `
};
