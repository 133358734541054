import React from 'react';

import { IActivityStreamEventComponent, IActivityStreamEventComponentProps } from './IActivityStreamEventComponent';
import { IOutboundLikeBorrowerActivityStreamEvent } from './IOutboundLikeBorrowerActivityStreamEvent';
import { LikeEvent } from './LikeEvent';

export const OutboundLikeBorrowerActivityStreamEvent: IActivityStreamEventComponent<IOutboundLikeBorrowerActivityStreamEvent> = (
  props: IActivityStreamEventComponentProps<IOutboundLikeBorrowerActivityStreamEvent>
) => {
  return (
    <LikeEvent eventData={props.eventData} missingBorrowerImageURL={props.missingBorrowerImageURL} missingOwnerImageURL={props.missingOwnerImageURL}>
      <div className='activity-stream-event__content-heading-text'>
        <span className='gray'>You</span>
        {' '}
        <span className='blue'>liked</span>
        {' '}
        <span className='green'>
          <a href={props.eventData.profileUrl} className='link_standard-green'>{props.eventData.dogName}</a>
        </span>
      </div>
    </LikeEvent>
  );
};
