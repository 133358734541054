import React, { Component, ReactNode } from 'react';

export interface IPhotoUploadPromptProps {
  requestUrl: string;
  onComplete?: () => void;
}

interface IPhotoUploadPromptState {
  hidden: boolean;
}

export class PhotoUploadPrompt extends Component<IPhotoUploadPromptProps, IPhotoUploadPromptState> {
  constructor(props: IPhotoUploadPromptProps) {
    super(props);
    this.state = { hidden: false };
    this.handleNo = this.handleNo.bind(this);
  }

  public render(): ReactNode | string {
    if (this.state.hidden) {
      return '';
    } else {
      return (
        <div className='component-photo-upload-prompt'>
          <div className='notification notification_info'>
            <div className='notification-content'>
              <div className='notification-icon'></div>
              <div className='notification-text'>
                <h1>Why not upload a photo?</h1>
                <p>Members with photos are 15 times more likely to receive a message.</p>
              </div>
            </div>
            <div className='component-photo-upload-prompt__actions'>
              <a href={this.props.requestUrl} className='button button_default button_primary component-photo-upload-prompt__action'>
                <i className='fas fa-check'></i>{' '}
                Do it now
              </a>
              <a href='#' className='button button_default button_danger component-photo-upload-prompt__action' onClick={this.handleNo}>
                <i className='fas fa-times'></i>{' '}
                Do it later
              </a>
            </div>
          </div>
        </div>
      );
    }
  }

  private handleNo(): void {
    this.setState({ hidden: true });
    if (this.props.onComplete) {
      this.props.onComplete();
    }
  }
}
