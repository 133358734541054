import React, { useContext } from 'react';

import { BinaryStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { parseDogGender } from './parseDogGender';
import { WebContext } from '../utils/AppContext';

export const Owner9: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const { firstDogName } = parseDogNames(props.manuscript.dogNames || []);
  const pronoun = props.manuscript.dogGender ? parseDogGender(props.manuscript.dogGender).genderedPronoun : firstDogName;

  return (
    <BinaryStep
      stepTitle={`Tell us about ${firstDogName}`}
      stepHeading={`Is ${firstDogName} a hypoallergenic breed?`}
      totalSteps={props.totalStepCount}
      currentStep={9}
      stepImageUrl={context.images['illustrations/img-dog-licking-person.svg']}
      backButton={{
        text: 'Back'
      }}
      onBinaryButtonClick={polarity => props.record({ hypoallergenicBreed: polarity, hypoallergenicBreedConfirmed: true })}
      onBackClick={() => props.record({ okayWithOtherDogsConfirmed: false })}
    >
      <StandardStepDescription text={`Could ${pronoun} be borrowed by someone who suffers from a dog allergy? If you’re not sure, select no.`} />
    </BinaryStep>
  );
};
