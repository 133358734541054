import { gqlCommand } from '@borrowmydoggy/api-client';
import {
  Mutation,
  MutationCreateBorrowerLikeArgs,
  MutationCreateBorrowerPictureRequestArgs,
  MutationCreateBorrowerProfileArgs,
  MutationCreateBorrowerProfileReportArgs,
  MutationCreateOwnerLikeArgs,
  MutationCreateOwnerPictureRequestArgs,
  MutationCreateOwnerProfileArgs,
  MutationCreateOwnerProfileReportArgs,
  MutationCreateProfilePhotoUploadRequestArgs,
  MutationDestroyBorrowerLikeArgs,
  MutationDestroyOwnerLikeArgs,
  MutationDestroyPendingProfilePhotoArgs,
  MutationDestroyProfilePhotoArgs,
  MutationDisableMessagingForProfileArgs,
  MutationEnableMessagingForProfileArgs,
  MutationImportProfilePhotosArgs,
  MutationSetPendingProfilePhotoPreviewArgs,
  MutationUpdateBorrowerSearchPreferencesArgs,
  MutationUpdateOwnerSearchPreferencesArgs,
  MutationUpdateProfilePrimaryPhotoArgs
} from '@borrowmydoggy/bmd-api';

export const mutations = {
  createBorrowerLike: gqlCommand<{ createBorrowerLike: Mutation['createBorrowerLike']; }, MutationCreateBorrowerLikeArgs>`
    mutation CreateBorrowerLike($ownerProfileUuid: String!) {
      createBorrowerLike(ownerProfileUuid: $ownerProfileUuid) {
        ownerProfileUuid
        dogLikeCount
        liked
        errors
      }
    }
  `,
  createBorrowerPictureRequest: gqlCommand<
    { createBorrowerPictureRequest: Mutation['createBorrowerPictureRequest']; },
    MutationCreateBorrowerPictureRequestArgs
  >`
    mutation CreateBorrowerPictureRequest($borrowerProfileUuid: String!) {
      createBorrowerPictureRequest(borrowerProfileUuid: $borrowerProfileUuid) {
        borrowerProfileUuid
        pictureRequested
        errors
      }
    }
  `,
  createBorrowerProfile: gqlCommand<{ createBorrowerProfile: Mutation['createBorrowerProfile']; }, MutationCreateBorrowerProfileArgs>`
    mutation createBorrowerProfile($borrowerProfileDetails: BorrowerProfileDetailsArgument!) {
      createBorrowerProfile(borrowerProfileDetails: $borrowerProfileDetails) {
        successful
        errors
        result
        currentSubscriptionProfileType
        verified
      }
    }
  `,
  createBorrowerProfileReport: gqlCommand<{ createBorrowerProfileReport: Mutation['createBorrowerProfileReport']; }, MutationCreateBorrowerProfileReportArgs>`
    mutation CreateBorrowerProfileReport(
      $borrowerProfileUuid: String!,
      $reportType: ProfileReport!,
      $explanation: String
    ) {
      createBorrowerProfileReport(borrowerProfileUuid: $borrowerProfileUuid, reportType: $reportType, explanation: $explanation) {
        borrowerProfileUuid
        profileReported
        errors
      }
    }
  `,
  createOwnerLike: gqlCommand<{ createOwnerLike: Mutation['createOwnerLike']; }, MutationCreateOwnerLikeArgs>`
    mutation CreateOwnerLike($borrowerProfileUuid: String!) {
      createOwnerLike(borrowerProfileUuid: $borrowerProfileUuid) {
        borrowerProfileUuid
        borrowerLikeCount
        liked
        errors
      }
    }
  `,
  createOwnerPictureRequest: gqlCommand<{ createOwnerPictureRequest: Mutation['createOwnerPictureRequest']; }, MutationCreateOwnerPictureRequestArgs>`
    mutation CreateOwnerPictureRequest($ownerProfileUuid: String!) {
      createOwnerPictureRequest(ownerProfileUuid: $ownerProfileUuid) {
        ownerProfileUuid
        pictureRequested
        errors
      }
    }
  `,
  createOwnerProfile: gqlCommand<{ createOwnerProfile: Mutation['createOwnerProfile']; }, MutationCreateOwnerProfileArgs>`
    mutation createOwnerProfile($ownerProfileDetails: OwnerProfileDetailsArgument!) {
      createOwnerProfile(ownerProfileDetails: $ownerProfileDetails) {
        successful
        errors
        result
        currentSubscriptionProfileType
        verified
      }
    }
  `,
  createOwnerProfileReport: gqlCommand<{ createOwnerProfileReport: Mutation['createOwnerProfileReport']; }, MutationCreateOwnerProfileReportArgs>`
    mutation CreateOwnerProfileReport(
      $ownerProfileUuid: String!,
      $reportType: ProfileReport!,
      $explanation: String
    ) {
      createOwnerProfileReport(ownerProfileUuid: $ownerProfileUuid, reportType: $reportType, explanation: $explanation) {
        ownerProfileUuid
        profileReported
        errors
      }
    }
  `,
  createProfilePhotoUploadRequest: gqlCommand<
    { createProfilePhotoUploadRequest: Mutation['createProfilePhotoUploadRequest']; },
    MutationCreateProfilePhotoUploadRequestArgs
  >`
    mutation createProfilePhotoUploadRequest($profileType: Profile!) {
      createProfilePhotoUploadRequest(profileType: $profileType) {
        successful
        profilePhotoUploadRequest {
          algorithm
          credential
          destinationKey
          policy
          requestDate
          requestUrl
          signature
          successActionStatus
        }
        taskId
        errors
      }
    }
  `,
  destroyBorrowerLike: gqlCommand<{ destroyBorrowerLike: Mutation['destroyBorrowerLike']; }, MutationDestroyBorrowerLikeArgs>`
    mutation DestroyBorrowerLike($ownerProfileUuid: String!) {
      destroyBorrowerLike(ownerProfileUuid: $ownerProfileUuid) {
        ownerProfileUuid
        dogLikeCount
        liked
        errors
      }
    }
  `,
  destroyOwnerLike: gqlCommand<{ destroyOwnerLike: Mutation['destroyOwnerLike']; }, MutationDestroyOwnerLikeArgs>`
    mutation DestroyOwnerLike($borrowerProfileUuid: String!) {
      destroyOwnerLike(borrowerProfileUuid: $borrowerProfileUuid) {
        borrowerProfileUuid
        borrowerLikeCount
        liked
        errors
      }
    }
  `,
  destroyIncompleteBorrowerProfile: gqlCommand<{ destroyIncompleteBorrowerProfile: Mutation['destroyIncompleteBorrowerProfile']; }>`
    mutation destroyIncompleteBorrowerProfile {
      destroyIncompleteBorrowerProfile {
        successful
        errors
      }
    }
  `,
  destroyIncompleteOwnerProfile: gqlCommand<{ destroyIncompleteOwnerProfile: Mutation['destroyIncompleteOwnerProfile']; }>`
    mutation destroyIncompleteOwnerProfile {
      destroyIncompleteOwnerProfile {
        successful
        errors
      }
    }
  `,
  // TODO fix signature when bmd-api updated!
  switchToBorrowerProfile: gqlCommand<{ switchToBorrowerProfile: { successful: boolean; errors?: string[]; }; }>`
    mutation switchToBorrowerProfile {
      switchToBorrowerProfile {
        successful
        errors
      }
    }
  `,
  switchToOwnerProfile: gqlCommand<{ switchToOwnerProfile: { successful: boolean; errors?: string[]; }; }>`
    mutation switchToOwnerProfile {
      switchToOwnerProfile {
        successful
        errors
      }
    }
  `,
  disableMessagingForProfile: gqlCommand<{ disableMessagingForProfile: Mutation['disableMessagingForProfile']; }, MutationDisableMessagingForProfileArgs>`
    mutation DisableMessagingForProfile($profileType: Profile!, $acceptingMessages: Boolean!) {
      disableMessagingForProfile(profileType: $profileType, acceptingMessages: $acceptingMessages) {
        errors
      }
    }
  `,
  enableMessagingForProfile: gqlCommand<{ enableMessagingForProfile: Mutation['enableMessagingForProfile']; }, MutationEnableMessagingForProfileArgs>`
    mutation EnableMessagingForProfile($profileType: Profile!, $lookingForMatch: Boolean!) {
      enableMessagingForProfile(profileType: $profileType, lookingForMatch: $lookingForMatch) {
        errors
      }
    }
  `,
  importProfilePhotos: gqlCommand<{ importProfilePhotos: Mutation['importProfilePhotos']; }, MutationImportProfilePhotosArgs>`
    mutation importProfilePhotos($profileType: Profile!, $photos: [ProfilePhotoArgument!]!) {
      importProfilePhotos(profileType: $profileType, photos: $photos) {
        successful
        updatedPhotos {
          imageId
          imageUrl
          isPrimary
        }
        errors
      }
    }
  `,
  updateProfilePrimaryPhoto: gqlCommand<{ updateProfilePrimaryPhoto: Mutation['updateProfilePrimaryPhoto']}, MutationUpdateProfilePrimaryPhotoArgs>`
    mutation updateProfilePrimaryPhoto($profileType: Profile!, $primaryPhotoImageId: String!) {
      updateProfilePrimaryPhoto(profileType: $profileType, primaryPhotoImageId: $primaryPhotoImageId) {
        successful
        updatedPhotosWithPending {
          ...on PendingOwnProfileImage {
            taskId
          }
          ...on ReadyOwnProfileImage {
            imageId
          }
          imageUrl
          status
        }
        errors
      }
    }
  `,
  updateBorrowerSearchPreferences: gqlCommand<
    { updateBorrowerSearchPreferences: Mutation['updateBorrowerSearchPreferences']; },
    MutationUpdateBorrowerSearchPreferencesArgs
  >`
    mutation UpdateBorrowerSearchPreferences($preferences: PersistentBorrowerSearchPreferencesArgument!) {
      updateBorrowerSearchPreferences(preferences: $preferences) {
        errors
      }
    }
  `,
  updateOwnerSearchPreferences: gqlCommand<
    { updateOwnerSearchPreferences: Mutation['updateOwnerSearchPreferences']; },
    MutationUpdateOwnerSearchPreferencesArgs
  >`
    mutation UpdateOwnerSearchPreferences($preferences: PersistentOwnerSearchPreferencesArgument!) {
      updateOwnerSearchPreferences(preferences: $preferences) {
        errors
      }
    }
  `,
  destroyProfilePhoto: gqlCommand<{ destroyProfilePhoto: Mutation['destroyProfilePhoto']}, MutationDestroyProfilePhotoArgs>`
    mutation destroyProfilePhoto($profileType: Profile!, $photoImageId: String!) {
      destroyProfilePhoto(profileType: $profileType, photoImageId: $photoImageId) {
        successful
        updatedPhotosWithPending {
          ...on PendingOwnProfileImage {
            taskId
          }
          ...on ReadyOwnProfileImage {
            imageId
          }
          imageUrl
          status
        }
        errors
      }
    }
  `,
  setPendingProfilePhotoPreview: gqlCommand<
    { setPendingProfilePhotoPreview: Mutation['setPendingProfilePhotoPreview']; },
    MutationSetPendingProfilePhotoPreviewArgs
  >`
    mutation setPendingProfilePhotoPreview($taskId: String!, $previewUrl: String!) {
      setPendingProfilePhotoPreview(taskId: $taskId, previewUrl: $previewUrl) {
        successful
        errors
      }
    }
  `,
  destroyPendingProfilePhoto: gqlCommand<{ destroyPendingProfilePhoto: Mutation['destroyPendingProfilePhoto']; }, MutationDestroyPendingProfilePhotoArgs>`
    mutation destroyPendingProfilePhoto($taskId: String!) {
      destroyPendingProfilePhoto(taskId: $taskId) {
        successful
        errors
      }
    }
  `
};
