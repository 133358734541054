import React, { useContext, useState } from 'react';
import { faCircleXmark, faForward, faRedoAlt } from '@fortawesome/pro-solid-svg-icons';

import { DecisionStep, IStepComponentProps, StandardStepDescription, StepComponent } from '@borrowmydoggy/step-components';
import { UEnhancedButtonProps } from '@borrowmydoggy/product-components';

import { IOwnerProfileCreationManuscript } from './IOwnerProfileCreationManuscript';
import { OwnerProfileCreationOperations } from '../operations';
import { parseDogNames } from './parseDogNames';
import { ProfileAPI } from '../api';
import { WebContext } from '../utils/AppContext';

export const ExitPrompt: StepComponent<IOwnerProfileCreationManuscript> = (
  props: IStepComponentProps<IOwnerProfileCreationManuscript, OwnerProfileCreationOperations>
) => {
  const context = useContext(WebContext);

  const [inProgress, setInProgress] = useState(false);
  const [inError, setInError] = useState(false);

  async function handleActionClick(actionId: 'continue' | 'restart' | 'delete'): Promise<void> {
    try {
      if (actionId === 'continue') {
        props.record({ exitPromptShown: false });
      } else {
        let requestSuccess = true;
        if (props.manuscript.existingBorrowerProfile) {
          setInProgress(true);
          setInError(false);
          const response = await ProfileAPI.destroyIncompleteOwnerProfile();
          if (response.error !== undefined || response?.data?.destroyIncompleteOwnerProfile?.successful !== true) {
            setInError(true);
            setInProgress(false);
            requestSuccess = false;
          }
          // Note that if this worked, we don't leave the in-progress state (as we're about to navigate away).
        }
        if (requestSuccess) {
          if (props.persistenceAdapter) {
            props.persistenceAdapter.clear();
          }
          window.location.href = '/home';
        }
      }
    } catch {
      setInError(true);
      setInProgress(false);
    }
  }

  function handleCancelClick(): void {
    if (props.persistenceAdapter) {
      props.persistenceAdapter.clear();
    }
    window.location.href = '/signout';
  }

  const { multipleDogs } = parseDogNames(props.manuscript.dogNames || []);
  const possessive = multipleDogs ? 'dogs’' : 'dog’s';

  let firstButton: UEnhancedButtonProps<'delete' | 'restart'>;
  let stepDescriptionText: string;

  if (props.manuscript.existingBorrowerProfile) {
    firstButton = {
      id: 'delete',
      title: `Delete my ${possessive} profile (I just want to be a borrower)`,
      buttonType: 'negative',
      buttonText: 'Delete profile',
      buttonIcon: faCircleXmark,
      illustrationURL: context.images['illustrations/img-dog-waiting-at-door.svg']
    };
    stepDescriptionText = 'You’ve already got a borrower profile and you’ve started creating an owner profile. Do you want to complete your owner profile or delete it?';
  } else {
    firstButton = {
      id: 'restart',
      title: `Delete my ${possessive} profile and start again`,
      buttonType: 'negative',
      buttonText: 'Start again',
      buttonIcon: faRedoAlt,
      illustrationURL: context.images['illustrations/img-dog-waiting-at-door.svg']
    };
    stepDescriptionText = `You have a BorrowMyDoggy account but you haven’t completed your ${possessive} profile yet. Let us know what you want to do.`;
  }

  const buttons: Array<UEnhancedButtonProps<'delete' | 'restart' | 'continue'>> = [
    firstButton,
    {
      id: 'continue',
      title: `Carry on creating my ${possessive} profile`,
      buttonText: 'Keep going',
      buttonIcon: faForward,
      illustrationURL: context.images['illustrations/img-identity-cards.svg']
    }
  ];

  return (
    <DecisionStep
      stepTitle='What would you like to do?'
      stepHeading='Want to finish your profile or delete it?'
      totalSteps={props.totalStepCount}
      currentStep={0}
      buttons={buttons}
      cancelButtonText={props.manuscript.existingBorrowerProfile ? undefined : `Sign out and delete my ${possessive} profile`}
      inProgress={inProgress}
      onDecisionClick={handleActionClick}
      onCancelClick={props.manuscript.existingBorrowerProfile ? undefined : handleCancelClick}
    >
      <StandardStepDescription
        text={inProgress ? 'We’re changing your account' : stepDescriptionText}
        inError={inError}
        errorText='Something went wrong, please try again. If it keeps happening please let us know using the contact details in the footer.'
      />
    </DecisionStep>
  );
};
